import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { LogData } from 'src/app/utils/utils';
import { SwiperOptions } from 'swiper';
import SwiperCore, { Zoom } from 'swiper';
SwiperCore.use([Zoom]);

@Component({
  selector: 'app-imageviewer',
  templateUrl: './imageviewer.component.html',
  styleUrls: ['./imageviewer.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ImageviewerComponent implements OnInit {
  @Input() images: any;
  @Input() isMulti: boolean = false;
  swipeConfig:SwiperOptions={
    centeredSlides:true,
    spaceBetween:20,
    zoom:true
  }
  constructor(
    private modalCtrl: ModalController) {
    // this.images = this.navParams.get('images');
    // this.isMulti = this.navParams.get('isMulti');
    LogData("images...", this.images);
    LogData("isMulti...", this.isMulti);
  }

  ngOnInit() { }

  closeModal() {
    this.modalCtrl.dismiss();
  }
}
