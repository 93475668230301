<div class="flex flex-col p-16" *ngIf="!onlymsgView">
  <ng-container *ngIf="listData.transaction.tr_dateo">
    <ion-text class="lbl-op">{{'lbl_led_trans_date' | getLanguagePipe}} :</ion-text>
    <ion-text class="lbl-10"> {{listData.transaction.tr_dateo | dynamicDateTimePipe}}</ion-text>
  </ng-container>
  <ng-container *ngIf="listData.transaction.chequecleardate">
    <ion-text class="lbl-op">{{'lbl_tras_cler_date' | getLanguagePipe}} :</ion-text>
    <ion-text class="lbl-10"> {{listData.transaction.chequecleardate | dynamicDateTimePipe}}</ion-text>
  </ng-container>
  <ng-container *ngIf="listData.transaction.amount_old_symbol">
    <ion-text class="lbl-op mt-4">{{'lbl_tra_amt' |getLanguagePipe}} :</ion-text>
    <ion-text class="lbl-10">{{listData.transaction.amount_old_symbol}}</ion-text>
  </ng-container>
  <!-- <ng-container *ngIf="listData.transaction.narration_approve">
    <ion-text class="lbl-op mt-4">{{'lbl_tras_narra' |getLanguagePipe}} :</ion-text>
    <ion-text class="lbl-10">{{listData.transaction.narration_approve}}</ion-text>
  </ng-container> -->
</div>
<div class="p-16 lh-22" *ngIf="onlymsgView">
<ion-text *ngIf="message">{{message}}</ion-text>
</div>