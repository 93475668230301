<app-dg-header [title]="title" (onClickClose)="closeModal($event)"></app-dg-header>
<ion-content>
  <div>
    <form [formGroup]="commonform">
      <ion-row>
        <!-- class="col1-bg side-1" -->
        <ion-col size="4.5" sizeLg="5" sizeMd="5" sizeXl="5" class="auto-sc">
          <ion-list>
            <ng-container *ngFor="let item of sortFilterList">
              <ion-item class="item-0 item-trans" lines="none" (click)="onClickItem(item)">
                <ion-text class="ml-12 mt-12 mb-12" [ngClass]="item.isChecked ? 'lbl-sec-14':'lbl-14'">{{item.text |
                  getLanguagePipe}}
                </ion-text>
              </ion-item>
              <hr class="w-100" [ngClass]="item.isChecked ? 'divider2':'divider1'" />
            </ng-container>
          </ion-list>
        </ion-col>
        <!-- class="col-2 side-2" -->
        <ion-col size="7.5" sizeLg="7" sizeMd="7" sizeXl="7" class="auto-sc">
          <ng-container *ngIf="filterIndex.value === 1">
            <ion-radio-group formControlName="sortby">
              <ion-item lines="none" class="item-0">
                <ion-label class="lbl-14">{{'lbl_rating' | getLanguagePipe}} : {{'lbl_h_to_l' | getLanguagePipe}}
                </ion-label>
                <ion-radio slot="start" [value]="CONST_DATA.RATING_DESC" class="mr-12"></ion-radio>
              </ion-item>

              <ion-item lines="none" class="item-0">
                <ion-label class="lbl-14">{{'lbl_rating' | getLanguagePipe}} : {{'lbl_l_to_h' | getLanguagePipe}}
                </ion-label>
                <ion-radio slot="start" [value]="CONST_DATA.RATING_ASC" class="mr-12"></ion-radio>
              </ion-item>

              <ion-item lines="none" class="item-0">
                <ion-label class="lbl-14">{{'lbl_a_to_z' | getLanguagePipe}}</ion-label>
                <ion-radio slot="start" [value]="CONST_DATA.AZSORT_ASC" class="mr-12"></ion-radio>
              </ion-item>

              <ion-item lines="none" class="item-0">
                <ion-label class="lbl-14">{{'lbl_z_to_a' | getLanguagePipe}}</ion-label>
                <ion-radio slot="start" [value]="CONST_DATA.AZSORT_DESC" class="mr-12"></ion-radio>
              </ion-item>
            </ion-radio-group>

          </ng-container>
          <ng-container *ngIf="filterIndex.value === 2">
            <app-item-selection [commonItems]="businessType" [isCheckboxUI]="true"
              (onSelectedItem)="getSelectedBusinessTypeItem($event)">
            </app-item-selection>
          </ng-container>

          <ng-container *ngIf="filterIndex.value === 3">
            <!-- <app-searchbar [isFilter]="false" [colSize]="'12'" (getSearchableText)="getSearchValue($event)"></app-searchbar> -->
            <app-item-selection [commonItems]="saleslist" [isCheckboxUI]="true"
              (onSelectedItem)="getSelectedSalesItem($event)">
            </app-item-selection>
          </ng-container>

          <ng-container *ngIf="filterIndex.value === 4">
            <!-- <app-searchbar [isFilter]="false" [colSize]="'12'" (getSearchableText)="getSearchValue($event)"></app-searchbar> -->
            <app-item-selection [commonItems]="serviceslist" [isCheckboxUI]="true"
              (onSelectedItem)="getSelectedServicesItem($event)">
            </app-item-selection>
          </ng-container>

          <ng-container *ngIf="filterIndex.value === 5">
            <!-- <app-searchbar [isFilter]="false" [colSize]="'12'" (getSearchableText)="getSearchValue($event)"></app-searchbar> -->
            <app-item-selection [commonItems]="manuflist" [isCheckboxUI]="true"
              (onSelectedItem)="getSelectedManufItem($event)">
            </app-item-selection>
          </ng-container>

          <!-- <app-address [isAdditionalAddress]="false" [innerSize]="'12'" [outerSize]="'12'"></app-address> -->
          <!-- <ng-container *ngIf="filterIndex.value === 5"> -->
          <div [ngClass]="filterIndex.value !== 6 ? 'disp-hid':''">
            <app-address
              [userProfileData]="(selectedFilter && selectedFilter.addressForm)?selectedFilter.addressForm:null"
              [isState]="true" [isDistrict]="true" [isTaluka]="true" [isVillage]="true" [isVillageArea]="true"
              [isStatus]="false" [isNoOther]="true" [isFilter]="true"></app-address>
          </div>

          <!-- </ng-container> -->

          <!-- <ng-container *ngIf="filterIndex.value === 6">
            <app-item-selection [commonItems]="productCompney" [isCheckboxUI]="true"
              (onSelectedItem)="getSelectedProdCompItem($event)">
            </app-item-selection>
          </ng-container> -->

          <ng-container *ngIf="filterIndex.value === 7">
            <ion-radio-group formControlName="isgst">
              <ion-item lines="none" class="item-0">
                <ion-label class="lbl-14">{{'lbl_both' | getLanguagePipe}}</ion-label>
                <ion-radio slot="start" [value]="CONST_DATA.GST_BOTH" class="mr-12"></ion-radio>
              </ion-item>

              <ion-item lines="none" class="item-0">
                <ion-label class="lbl-14">{{'lbl_yes' | getLanguagePipe}}</ion-label>
                <ion-radio slot="start" [value]="CONST_DATA.GST_YES" class="mr-12"></ion-radio>
              </ion-item>

              <ion-item lines="none" class="item-0">
                <ion-label class="lbl-14">{{'lbl_no' | getLanguagePipe}}</ion-label>
                <ion-radio slot="start" [value]="CONST_DATA.GST_NO" class="mr-12"></ion-radio>
              </ion-item>
            </ion-radio-group>
          </ng-container>
        </ion-col>
      </ion-row>
    </form>
  </div>
</ion-content>
<ion-footer class="ion-no-border pl-16 pr-16 pb-16 pt-8 bg-white" >
  <app-dg-buttons [btn1]="'lbl_dg_show_bus'" [btn2]="'lbl_dg_cls_all'" (onClickCloseBtn1)="showBusiness($event)"
  (onClickCloseBtn2)="clearAll($event)">
</app-dg-buttons>
</ion-footer>
<!-- <div class="ftr-iv pb-12 pl-12 pr-12">
  <app-dg-buttons [btn1]="'lbl_dg_show_bus'" [btn2]="'lbl_dg_cls_all'" (onClickCloseBtn1)="showBusiness($event)"
    (onClickCloseBtn2)="clearAll($event)">
  </app-dg-buttons>
</div> -->