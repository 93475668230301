import { ADVERTISEBANNERS_MC, BUSINESSES_MC, BUSINESSLISTS_MC, COMPLAINS_MC, CUSTOMERS_MC, DEALERS_MC, DISTRICTS_MC, EMAILTEMPLATES_MC, GROUPS_MC, INQUIRIES_MC, ITEMGROUPS_MC, LEDGERS_MC, PRODUCTCOMPANIES_MC, PRODUCTLISTS_MC, PUSHADVERTISEBANNERS_MC, ROLES_MC, SMSTEMPLATES_MC, STAFFS_MC, STATES_MC, SUBJECTS_MC, TALUKAS_MC, TRADINGTYPES_MC, TRANSACTIONS_MC, TRANSLATIONS_MC, VILLAGEAREAS_MC, VILLAGES_MC } from "./menu-code";
import {
  ACCOUNT_LADGER_REPORT,
  ACCOUNT_SETTING,
  ADVERTISE_BANNER,
  ADVERTISE_PUSH_BANNER,
  BRANCH_DEALER,
  BUSINESS_LIST,
  BUSINESS_TYPE,
  CHANGE_PASSWORD,
  COMPLAINS_LIST,
  COMPLAIN_SUBJECTS,
  DEALER_BUSINESS_ROUTE,
  DEALER_CUSTOMER_ROUTE,
  DEALER_TAB1_ROUTE,
  DEALER_TAB2_ROUTE,
  DEALER_TAB4_ROUTE,
  DEALER_TAB5_ROUTE,
  DEALER_TAB6_ROUTE,
  DISTRCT_ROUTE,
  EDIT_DEALER_CONTACT_BD,
  EDIT_DEALER_DETAILS_BD,
  EMAIL_ROUTE,
  GALARRY_SETTINGS_ROUTE,
  INQUIRE_LIST,
  INVITE_EARN_RT,
  PRODUCT_COMPNEY_LIST,
  PRODUCT_LIST,
  REG_STEP1,
  ROLES_ROUTE,
  SMS_ROUTE,
  STAFF_ROUTE,
  STATES_ROUTE,
  TALUKAS_ROUTE,
  TRADING_LIST,
  VILLAGES_AREA_ROUTE,
  VILLAGES_ROUTE,
} from "../../utils/routes";
import { BUSINESS_REG_SLUG, CUSTOMER_REG_SLUG } from "src/app/utils/constant";
import { Capacitor } from "@capacitor/core";

export const DealerMenuData = [
    {
      id: 1000,
      title: "mn_visit_website",
      url: DEALER_TAB1_ROUTE,
      icon: "ic_visit_web",
      isChecked: false,
    },
    {
      id: 3101,
      title: "profile",
      url: DEALER_TAB2_ROUTE,
      icon: "profile_sm",
      isChecked: false,
    },
    {
      id: 1,
      title: 'personal_information',
      icon: 'ic_pur_info',
      // url: CREATE_BD_STEP1,
      url: EDIT_DEALER_DETAILS_BD,
      isChecked: false,
    },
    {
      id: 2,
      title: 'mn_contact_information',
      icon: 'ic_con_info',
      url: EDIT_DEALER_CONTACT_BD,
      isChecked: false,
    },
    {
      id: 3106,
      title: "mn_change_pass",
      url: CHANGE_PASSWORD,
      icon: "mn_cp",
      isChecked: false,
    },
    // {
    //   id: 3,
    //   title: "mn_kyc_bank_details",
    //   url: "",
    //   icon: "mn_kyc_bank",
    //   isChecked: false,
    //   subMenu: [
    //     {
    //       id: 301,
    //       title: "mn_kyc",
    //       // url: KYC_DETAILS_RT,
    //       url:'',
    //       icon: "mn_kyc",
    //       isChecked: false,
    //     },
    //     {
    //       id: 302,
    //       title: "mn_bank_details",
    //       // url: BANK_DETAILS_RT,
    //       url:'',
    //       icon: "mn_kyc_bank",
    //       isChecked: false,
    //     },
    //   ],
    // },
    // {
    //   id: 303,
    //   title: 'mn_wallet',
    //   icon: 'mn_wallet',
    //   //url: WALLET_RT,
    //  url:"",
    //   isChecked: false,
    // },
    {
      id: 4,
      title: "advertise",
      url: "",
      icon: "adv_sm",
      isChecked: false,
      subMenu: [
        {
          id: 41,
          title: "advertise_banners",
          url: ADVERTISE_BANNER,
          icon: "adv_banner_sm",
          isChecked: false,
          menuCode: ADVERTISEBANNERS_MC
        },
        {
          id: 42,
          title: "push_notification",
          url: ADVERTISE_PUSH_BANNER,
          icon: "push_add_sm",
          isChecked: false,
          menuCode: PUSHADVERTISEBANNERS_MC
        },
      ],
    },
    // {
    //   id: 5,
    //   title: 'mn_taluka_area_branch',
    //   icon: 'mn_create',
    //   url: CREATE_BD_STEP2,
    //   isChecked: false,
    // },
    {
      id: 6,
      title: 'mn_business_reg',
      icon: 'mn_bus_reg',
      // url: BUSINESS_TYPE,
      url: Capacitor.getPlatform() !== 'ios' ? BUSINESS_TYPE:'',
      isBrowser:true,
      param:{
        type:BUSINESS_REG_SLUG
      },
      isChecked: false,
    },
    {
      id: 7,
      title: 'mn_customer_reg',
      icon: 'mn_cst_reg',
      url: REG_STEP1,
      param:{
        type:CUSTOMER_REG_SLUG
      },
      isChecked: false,
    },
    {
      id: 8,
      title: 'mn_invite_earn',
      icon: 'mn_invite',
       url: INVITE_EARN_RT,
      //url: '',
      isChecked: false,
    },
    {
      id: 9,
      title: "mn_contactus",
      url: DEALER_TAB6_ROUTE,
      icon: "mn_con_us",
      isChecked: false,
    },
    {
      id: 98,
      title: "notifications",
      url: DEALER_TAB4_ROUTE,
      // url: '',
      icon: "notification_sm",
      isChecked: false,
    },
    {
      id: 99,
      title: "chat",
      url: DEALER_TAB5_ROUTE,
      // url: '',
      icon: "chat_sm",
      isChecked: false,
    },
    {
      id: 15,
      title: "information",
      url: "",
      icon: "info_sm",
      isChecked: false,
      subMenu: [
        {
          id: 1501,
          title: "address",
          url: "",
          icon: "address_sm",
          isChecked: false,
          isExpand: false,
          innerSubMenu: [
            // { id: 511, title: "countries", url: "" },
            { id: 1512, title: "state", url: STATES_ROUTE, menuCode: STATES_MC },
            { id: 1513, title: "districts", url: DISTRCT_ROUTE, menuCode: DISTRICTS_MC },
            { id: 1514, title: "talukas", url: TALUKAS_ROUTE, menuCode: TALUKAS_MC },
            { id: 1515, title: "villages", url: VILLAGES_ROUTE, menuCode: VILLAGES_MC },
            { id: 1516, title: "village_areas", url: VILLAGES_AREA_ROUTE, menuCode: VILLAGEAREAS_MC },
          ],
        },
        // {
        //   id: 16,
        //   title: "banks",
        //   url: "'",
        //   icon: "bank_sm",
        //   isChecked: false,
        // },
        // {
        //   id: 17,
        //   title: "post_offices",
        //   url: "",
        //   icon: "post_sm",
        //   isChecked: false,
        // },
        // {
        //   id: 18,
        //   title: "police_stations",
        //   url: "",
        //   icon: "police_sm",
        //   isChecked: false,
        // },
      ],
    },
    {
      id: 10,
      title: "branch_dealers",
      url: BRANCH_DEALER,
      icon: "branch_sm",
      isChecked: false,
      menuCode: DEALERS_MC
    },
    {
      id: 11,
      title: "businesse",
      url: DEALER_BUSINESS_ROUTE,
      icon: "bus_sm",
      isChecked: false,
      menuCode: BUSINESSES_MC
    },
    {
      id: 12,
      title: "mn_customers",
      url: DEALER_CUSTOMER_ROUTE,
      icon: "cst_sm",
      isChecked: false,
      menuCode: CUSTOMERS_MC
    },
    {
      id: 13,
      title: "staff",
      url: STAFF_ROUTE,
      icon: "staff_sm",
      isChecked: false,
      menuCode: STAFFS_MC
    },
    {
      id: 14,
      title: "roles",
      url: ROLES_ROUTE,
      icon: "role_sm",
      isChecked: false,
      menuCode: ROLES_MC
    },
    {
      id: 19,
      title: "mn_business_master",
      url: "",
      icon: "bus_sm",
      isChecked: false,
      subMenu: [
        {
          id: 20,
          title: "business_list",
          url: BUSINESS_LIST,
          icon: "bus_list_sm",
          isChecked: false,
          menuCode: BUSINESSLISTS_MC
        },
        {
          id: 21,
          title: "trading_types",
          url: TRADING_LIST,
          icon: "trad_sm",
          isChecked: false,
          menuCode: TRADINGTYPES_MC
        },
        {
          id: 22,
          title: "product",
          url: PRODUCT_LIST,
          icon: "product_sm",
          isChecked: false,
          menuCode: PRODUCTLISTS_MC
        },
        {
          id: 23,
          title: "product_company",
          url: PRODUCT_COMPNEY_LIST,
          icon: "pro_compl_sm",
          isChecked: false,
          menuCode: PRODUCTCOMPANIES_MC
        },
      ],
    },
    // {
    //   id: 24,
    //   title: "accounts",
    //   url: "",
    //   icon: "acc_sm",
    //   isExpand: false,
    //   isChecked: false,
    //   subMenu: [
    //     {
    //       id: 25,
    //       title: "master",
    //       url: "",
    //       icon: "master_sm",
    //       innerSubMenu: [
    //         // { id: 811, title: "gst_master", url: "" },
    //         { id: 2501, title: "account_group", url: GROUP_LIST, menuCode: GROUPS_MC },
    //         { id: 2502, title: "item_group", url: ITEM_GROUP, menuCode: ITEMGROUPS_MC },
    //         { id: 2503, title: "account_ledger", url: ACCOUNT_LADGER, menuCode: LEDGERS_MC },
    //         { id: 2504, title: "item_ledger", url: ITEM_LADGER, menuCode: LEDGERS_MC },
    //       ],
    //     },
    //     {
    //       id: 26,
    //       title: "transaction",
    //       url: "",
    //       icon: "trans_sm",
    //       menuCode: TRANSACTIONS_MC,
    //       innerSubMenu: [
    //         { id: 2601, title: "sales_entry", url: SALES_ENTRY },
    //         { id: 2602, title: "purchase_entry", url: PURCHASE_ENTRY },
    //         // { id: 2603, title: "sales_return", url: SALES_RETURN },
    //         // { id: 2604, title: "purchase_return", url: PURCHASE_RETURN },
    //         { id: 2605, title: "bank_entry", url: BANK_TRANSACTIONS },
    //         { id: 2606, title: "payment_entry", url: PAYMENT_TRANSACTIONS },
    //         { id: 2607, title: "receipt_entry", url: RECEIPT_TRANSACTIONS },
    //         { id: 2608, title: "journal_entry", url: JOURNAL_TRANSACTIONS },
    //       ],
    //     },
    //     {
    //       id: 27,
    //       title: "report",
    //       url: "",
    //       icon: "report_sm",
    //       // menuCode: TRANSACTIONS_MC,
    //       innerSubMenu: [
    //         { id: 2701, title: "account_ledger", url: ACCOUNT_LADGER_REPORT },
    //         // { id: 2702, title: "item_ledger", url: ITEM_LADGER_REPORT },
    //       ],
    //     },
    //   ],
    // },
    {
      id: 80,
      title: "accounts",
      url: "",
      icon: "acc_sm",
      isExpand: false,
      isChecked: false,
      subMenu: [
        {
          id: 81,
          title: "report",
          url: "",
          icon: "report_sm",
          // menuCode: TRANSACTIONS_MC,
          innerSubMenu: [
            { id: 831, title: "account_ledger", url: ACCOUNT_LADGER_REPORT},
            // { id: 842, title: "item_ledger", url: ITEM_LADGER_REPORT },
          ],
        },
      ],
    },
    {
      id: 28,
      title: "inquiries",
      url: INQUIRE_LIST,
      icon: "inq_sm",
      isChecked: false,
      menuCode: INQUIRIES_MC,
      isCounter:true
    },
    {
      id: 29,
      title: "complains_tickets",
      url: '',
      icon: "complain_sm",
      isExpand: false,
      isChecked: false,
      subMenu: [
        {
          id: 2901,
          title: "mn_tickets",
          url: COMPLAINS_LIST,
          icon: "tkt_sm",
          isChecked: false,
          menuCode: COMPLAINS_MC
        },
        {
          id: 2902,
          title: "mn_subjects",
          url: COMPLAIN_SUBJECTS,
          icon: "subject_sm",
          isChecked: false,
          menuCode: SUBJECTS_MC
        }
      ],
    },
    // {
    //   id: 30,
    //   title: "mn_member_chart",
    //   url: REGISTRATION_REPORT,
    //   icon: "chart_sm",
    //   isExpand: false,
    //   isChecked: false,
    // },
    {
      id: 31,
      title: "settings",
      url: "",
      icon: "setting_sm",
      isExpand: false,
      isChecked: false,
      subMenu: [
        {
          id: 3102,
          title: "mn_gal_sett",
          url: GALARRY_SETTINGS_ROUTE,
          icon: "gs_sm",
          isChecked: false,
        },
        {
          id: 3103,
          title: "mn_sms",
          url: SMS_ROUTE,
          icon: "sms_sm",
          isChecked: false,
          menuCode: SMSTEMPLATES_MC
        },
        {
          id: 3104,
          title: "mn_email",
          url: EMAIL_ROUTE,
          icon: "email_sm",
          isChecked: false,
          menuCode: EMAILTEMPLATES_MC
        },
        {
          id: 3105,
          title: "mn_accounts",
          url: ACCOUNT_SETTING,
          icon: "account_sm",
          isChecked: false,
        },
      ],
    },
    // {
    //   id: 32,
    //   title: "mn_pricing",
    //   url: DEALER_TABS_PRICING,
    //   icon: "mn_pricing",
    //   isExpand: false,
    //   isChecked: false,
    // },
  ];
  