import { Component, Input, OnInit, Output } from '@angular/core';
import { ReviewDetails } from 'src/app/models/comments-data';
import { LogData } from 'src/app/utils/utils';
import { CommentPopoverComponent } from '../comment-popover/comment-popover.component';

@Component({
  selector: 'app-comment-avtar',
  templateUrl: './comment-avtar.component.html',
  styleUrls: ['./comment-avtar.component.scss'],
})
export class CommentAvtarComponent implements OnInit {

  @Input() name;
  @Input() img;
  @Input() address;
  @Input() comment;
  @Input() date;
  @Input() avtCls='avt-42';
  @Input() uiType=1;
  @Input() isFullWidthText:boolean=false;
  constructor() { }

  ngOnInit() {
  }

}
