import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { IonInput, IonSearchbar, PopoverController } from "@ionic/angular";
import { CommonData } from "src/app/models/common-data";
import { UtilsService } from "src/app/services/uitils.services";
import { LogData } from "src/app/utils/utils";
import { SortDataComponent } from "../sort-data/sort-data.component";

@Component({
  selector: "app-searchbar",
  templateUrl: "./searchbar.component.html",
  styleUrls: ["./searchbar.component.scss"],
})
export class SearchbarComponent implements OnInit,AfterViewInit {
  searchData;
  @Input() isPageRefresh:boolean=false;
  @Input() placeHolderText = "search_here";
  @Input() isBigTitle: boolean = false;
  @Input() isFilter: boolean = true;
  @Input() isGeolocation;
  @Input() colSize = "5";
  @Input() isOnlyEmit;
  @Input() displayAddresStr;
  @Input() isAutoSuggestion:boolean=false;
  @Input() isBackBtn:boolean=false;
  @Output() getSearchableText: EventEmitter<any> = new EventEmitter();
  @Output() getDialog: EventEmitter<any> = new EventEmitter();
  @Output() onClickLocation: EventEmitter<any> = new EventEmitter();
  isDisplaySuggestion:boolean=false;
  suggestionList:Array<any>=[
    "Ajay","Avinash","Vijay","vivek","Astha","Aswtaneb","Aswtatanert","Aswthanertew"
  ];
  items=[];
  public searchTerm: string = "";
  @ViewChild(IonInput, { static: false }) private search: IonInput;
  
  constructor(private popCtrl: PopoverController,
    private utilsService:UtilsService) {
    if(this.isPageRefresh){
      this.searchData="";
    }
  }

  ngOnInit() {
    this.setFilteredItems();
  }

  ngAfterViewInit(){
    LogData("ngAfterViewInit().....call");
    if(this.isBackBtn){
      setTimeout(() => {
        this.search.setFocus();
      }, 500);
    }
  }

  openLoaction(){
    this.onClickLocation.emit()
  }

  onChangeInputValue(ev) {
    // this.getSearchableText.emit(ev.detail.value);
    if(ev){
      this.isDisplaySuggestion=true;
      this.searchTerm=ev;
    }else{
      this.isDisplaySuggestion=false;
      this.searchTerm='';
    }
    this.setFilteredItems();
    this.getSearchableText.emit(ev);
  }

  selectSuggestion(ev){
    LogData("selectSuggestion",ev);
    this.searchData=ev;
    setTimeout(()=>{
      this.isDisplaySuggestion=false;
    },800)
  }

  setFilteredItems() {
    // this.items = this.filterItems();
    if(this.isAutoSuggestion){
      this.items = this.filterItemsByName(this.suggestionList, this.searchTerm);
    }
    
  }

  filterItemsByName(searchItems,searchTerm) {
    return searchItems.filter(item => {
      return item.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1;
    });
  }

  async openSortFilter() {
    if (this.isOnlyEmit) {
      this.getDialog.emit(true);
    } else {
      const modal = await this.popCtrl.create({
        component: SortDataComponent,
        componentProps: { title: "Sort List by Status" },
        cssClass: "custom-popover",
        showBackdrop: true,
        backdropDismiss: false,
        // breakpoints: [0, 0.3, 0.5, 0.8],
        // initialBreakpoint: 0.5
      });

      modal.onDidDismiss().then((data) => {
        LogData("onDidDismiss...", data);
        if (data.data != null) {
        }
      });

      await modal.present();
    }
  }

  onBack(){
    this.utilsService.onBack();
  }
}
