import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonData } from 'src/app/models/common-data';
import { LogData } from 'src/app/utils/utils';

@Component({
  selector: 'app-auto-suggest',
  templateUrl: './auto-suggest.component.html',
  styleUrls: ['./auto-suggest.component.scss'],
})
export class AutoSuggestComponent implements OnInit {

  @Input() marginTop;
  @Input() dataList:Array<any>=[];
  @Output() onItemClick: EventEmitter<any> = new EventEmitter();
  constructor() { }

  ngOnInit() {
    
  }

  itemClick(item){
    LogData("itemClick",item);
    this.onItemClick.emit(item);
  }

}
