import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
    name: 'diffDatePipe'
})
export class DiffernceDatePipe implements PipeTransform {

    transform(date1,date2): any {
        const a = moment(date1 ? new Date(date1) :new Date());
        const b = moment(new Date(date2));
        return a.diff(b, 'days');
    }
}
