import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-dg-header',
  templateUrl: './dg-header.component.html',
  styleUrls: ['./dg-header.component.scss'],
})
export class DgHeaderComponent implements OnInit {

  @Input() title;
  @Output() onClickClose: EventEmitter<any> = new EventEmitter();
  constructor() { }

  ngOnInit() {}

  closeModal(){
    this.onClickClose.emit();
  }

}
