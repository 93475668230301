<!-- <input
  #fileIn
  type="file"
  id="fileIn"
  (click)="docUpload($event)"
  style="display: none"
/> -->

<input #fileIn type="file"  (change)="docUpload(fileIn.files[0])" style="display: none" />
<input #fileInVideo type="file" (change)="videoUpload(fileInVideo.files[0])" style="display: none" accept="video/*,audio/*"> 
<input #fileInImages multiple type="file" (change)="pickImages(fileInImages.files)" style="display: none" accept="image/*"> 
