import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { LogData } from 'src/app/utils/utils';
@Component({
  selector: 'app-refresher',
  templateUrl: './refresher.component.html',
  styleUrls: ['./refresher.component.scss'],
})
export class RefresherComponent implements OnInit {

  @Output() @Output() doRefreshList: EventEmitter<any> = new EventEmitter();
  constructor() { }

  ngOnInit() {}

  doRefresh(event) {
    setTimeout(() => {
      LogData('Async operation has ended');
      this.doRefreshList.emit();
      event.target.complete();
    }, 2000);

  }

}
