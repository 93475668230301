import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-bbs-card',
  templateUrl: './bbs-card.component.html',
  styleUrls: ['./bbs-card.component.scss'],
})
export class BbsCardComponent implements OnInit {

  @Input() businessData;
  constructor() { }

  ngOnInit() {}

}
