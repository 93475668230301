<div class="flex flex-col inq-card cursor-ptr">
  <div class="inq-top"></div>
  <ion-item lines="none" class="ml-12 mr-12 mt-12">
    <ion-text class="lbl-sb-sec-12" slot="start" *ngIf="complaininquiryData.status && complaininquiryData.status.length > 0">{{complaininquiryData.status[0].text}}</ion-text>
    <div class="flex flex-row ion-align-items-center" *ngIf="complaininquiryData.on_date" slot="end">
      <ion-icon [src]="'ic_cal_g' | getImagePipe"></ion-icon>
      <ion-text class="lbl-10 ml-12">{{complaininquiryData.on_date | dynamicDateTimePipe:2}}</ion-text>
    </div>
  </ion-item>
  <ion-text *ngIf="complaininquiryData.closed_date" class="lbl-10 ml-12">{{'lbl_close_date' | getLanguagePipe}} : {{complaininquiryData.closed_date | dynamicDateTimePipe:2}}</ion-text>
  <ion-item lines="none" class="ml-12 mr-12 mt-12 mb-8">
    <div class="flex flex-col">
      <ion-text class="lbl-med-14" *ngIf="complaininquiryData.subject_id && complaininquiryData.subject_id.length > 0">{{complaininquiryData.subject_id[0].text}}</ion-text>
      <ion-text class="mlines-2 lbl-op05-11 lh-18 mt-8" *ngIf="complaininquiryData.message">{{complaininquiryData.message}}</ion-text>
    </div>
  </ion-item>
  <!--  -->
  <div class="inner-inq mt-8 p-12" *ngIf="!isBusinessUser">
    <div class="flex flex-row mt-8 mb-8" *ngIf="isComplain">
      <div *ngIf="complaininquiryData.on_date && !isClosedStatus()" class="flex flex-row days-left ion-align-items-center pl-8 pr-8 pt-4 pb-4 mr-8">
        <ion-icon [src]="'ic_cal_pr' | getImagePipe" class="ft-16"></ion-icon>
        <!-- <ion-text class="ml-8 days-lbl">30 Days Left</ion-text> -->
        <ion-text class="ml-8 days-lbl">{{ '' | diffDatePipe:complaininquiryData.on_date}} {{'lbl_days_left' | getLanguagePipe}}</ion-text>
      </div>
      <div class="flex flex-row user-name ion-align-items-center pl-8 pr-8 pt-4 pb-4"
      *ngIf="complaininquiryData.userprofile_id && complaininquiryData.userprofile_id.length > 0">
        <ion-icon [src]="'ic_user_md' | getImagePipe" class="ft-16"></ion-icon>
        <ion-text class="ml-8 user-lbl">{{complaininquiryData.userprofile_id[0].full_name}} <span *ngIf="isDealar()">&nbsp;({{'lbl_branch' | getLanguagePipe}})</span></ion-text>
      </div>
    </div>
    <ion-item lines="none" *ngIf="(isComplain && complaininquiryData.userprofile_id_for && complaininquiryData.userprofile_id_for.length > 0) || 
      (!isComplain && (complaininquiryData.name || complaininquiryData.email))">
      <div class="flex flex-row ion-align-items-center" >
        <ion-icon [src]="'ic_ro_user_g' | getImagePipe" class="ft-24"></ion-icon>
        <ion-text class="lbl-10 ml-8">{{isComplain ? complaininquiryData.userprofile_id_for[0].full_name:complaininquiryData.name}}</ion-text>
      </div>
      <div class="flex flex-row ion-align-items-center ml-0" slot="end">
        <ion-icon [src]="'ic_ro_msg_g' | getImagePipe"  class="ft-24"></ion-icon>
        <ion-text class="lbl-10 ml-8">{{isComplain ? complaininquiryData.userprofile_id_for[0].email:complaininquiryData.email}}</ion-text>
      </div>
    </ion-item>
    <ion-item lines="none">
      <div class="flex flex-row ion-align-items-center  mt-16" *ngIf="(isComplain && complaininquiryData.userprofile_id_for.length > 0 && complaininquiryData.userprofile_id_for[0].phone) || 
        (!isComplain && complaininquiryData.phone)">
        <ion-icon [src]="'ic_ro_call_g' | getImagePipe"  class="ft-24"></ion-icon>
        <ion-text class="lbl-10 ml-8">{{isComplain ? complaininquiryData.userprofile_id_for[0].phone:complaininquiryData.phone}}</ion-text>
      </div>
      <div class="flex flex-row ion-align-items-center ml-0" slot="end" *ngIf="complaininquiryData.assign_userprofile_id && 
      complaininquiryData.assign_userprofile_id.length > 0">
        <ion-icon [src]="'assign_user' | getImagePipe"  class="ft-24"></ion-icon>
        <ion-text class="lbl-10 ml-8">{{complaininquiryData.assign_userprofile_id[0].full_name}}</ion-text>
      </div>
    </ion-item>
    </div>
</div>