<div class="amt-card">
<ion-row>
  <ion-col class="flex flex-col">
    <ion-text class="lbl-op05-12">{{'lbl_tra_amt' | getLanguagePipe}} :</ion-text>
    <ion-text class="lbl-med-13">{{accountsDetails.grand_amount_symbol}}</ion-text>
  </ion-col>
  <ion-col class="flex flex-col" *ngIf="accountsDetails.reference_no">
    <ion-text class="lbl-op05-12">{{'lbl_ref_no' |getLanguagePipe}} :</ion-text>
    <ion-text class="lbl-med-13">{{accountsDetails.reference_no}}</ion-text>
  </ion-col>
</ion-row>
<ion-row>
  <ion-col class="flex flex-col" *ngIf="accountsDetails.created">
    <ion-text class="lbl-op05-12">{{'lbl_led_trans_date' | getLanguagePipe}} :</ion-text>
    <ion-text class="lbl-med-13">{{accountsDetails.created | dynamicDateTimePipe}}</ion-text>
  </ion-col>
  <ion-col class="flex flex-col" *ngIf="accountsDetails.status && accountsDetails.status.length > 0">
    <ion-text class="lbl-op05-12">{{'status' | getLanguagePipe}} :</ion-text>
    <ion-text class="lbl-med-13">{{accountsDetails.status[0].text}}</ion-text>
  </ion-col>
</ion-row>

<ion-row>
  <ion-col class="flex flex-col" *ngIf="accountsDetails.account_notes">
    <ion-text class="lbl-op05-12">{{'lbl_tras_narra' | getLanguagePipe}} :</ion-text>
    <ion-text class="lbl-med-13">{{accountsDetails.account_notes}}</ion-text>
  </ion-col>
</ion-row>
<ion-row *ngIf="imageUrl && imageUrl.square_url">
  <ion-col class="flex flex-col">
    <ion-text class="lbl-op05-12">{{'lbl_rec_pay_img' | getLanguagePipe}} :</ion-text>
    <div class="div-pay-img">
      <img [src]="imageUrl.square_url" class="pay-img mt-4" (click)="openImageViewrComponent()" />
      </div>
  </ion-col>
</ion-row>
</div>