<div class="container cursor-ptr">
  <div [ngClass]="utilsService.getStatus(ladgerData.status) === 2 ? 'bus-item':'bus-item-sel'">
    <div class="flex flex-col p-16 ion-text-start" (press)="onPress($event)" (pressup)="onPressUp($event)"
    (click)="onClickItem()">
    <ion-text class="created-by-lbl mb-4" *ngIf="ladgerData.createdby && ladgerData.createdby.length > 0">{{'lbl_creatd_by' | getLanguagePipe}} : {{ladgerData.createdby[0].text}}</ion-text>
      <ion-text class="lbl-bold-18" *ngIf="ladgerData.name">{{ladgerData.name}}</ion-text>
      <div class="flex flex-col mt-8" *ngIf="(ladgerData.itemgroup_id && ladgerData.itemgroup_id.length > 0) || 
        (ladgerData.taxcategory_id && ladgerData.taxcategory_id.length > 0)">
        <div *ngIf="ladgerData.itemgroup_id && ladgerData.itemgroup_id.length > 0" class="mr-16">
          <ion-icon [src]="'item_grp' | getImagePipe"></ion-icon>
          <ion-text class="lbl_disc ml-8">{{ladgerData.itemgroup_id[0].text}}</ion-text>
        </div>
        <div  *ngIf="ladgerData.taxcategory_id && ladgerData.taxcategory_id.length > 0">
          <ion-icon [src]="'text_cate' | getImagePipe"></ion-icon>
          <ion-text class="lbl_disc ml-8">{{ladgerData.taxcategory_id[0].text}}</ion-text>
        </div>
      </div>
    </div>
    <div class="top-left">
      <ion-icon [src]="'ic_sel' | getImagePipe" class="ft-24" *ngIf="ladgerData.isChecked"></ion-icon>
    </div>
  </div>
</div>