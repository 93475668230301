import { Injectable } from '@angular/core';
import { CommonData } from '../models/common-data';
import { CurrentUserData } from '../models/user-data';
import { UserprofileData } from '../models/user-profile-data';
import { ADMIN_ROLE_ID, ALLOW_PERMISSION_LST_KEY, APPSTORE_LINK_LST_KEY, BUSINESS_ROLE_ID, DEALER_ROLE_ID, DEVICENAME_KEY, DEVICEUUID_KEY, FCMTOKEN_LST_KEY, FORID_LST_KEY, OTHER_SETTINGS_LST_KEY, PLAYSTORE_LINK_LST_KEY, STAFFS_ROLL_ID, SUPER_ADMIN_ROLE_ID, TOKEN_LST_KEY, USER_LST_KEY } from '../utils/constant';
import { USER_LANGUAGE_ARR_KEY, USER_LANGUAGE_DATA_KEY, USER_LANGUAGE_KEY } from '../utils/params-data';
import { ADMIN_TAB1_ROUTE, TAB1_ROUTE } from '../utils/routes';
import { Storage } from '@capacitor/storage';
import { LogData } from '../utils/utils';
import { OtherSettings } from '../models/other-settings';


@Injectable({
    providedIn: 'root'
})
export class AuthServices {
    // currentUserData:CurrentUserData;
    // constructor(){
    //     this.currentUserData=this.getUserLoginData();
    // }

    setUserLoginData(loginData: CurrentUserData) {
        LogData("loginData", loginData);

        localStorage.setItem(USER_LST_KEY, JSON.stringify(loginData));
    }

    getUserLoginData() {
        return JSON.parse(localStorage.getItem(USER_LST_KEY));
    }

    setUserOtherSettingsData(otherSettings: OtherSettings) {
        LogData("OtherSettings", otherSettings);
        localStorage.setItem(OTHER_SETTINGS_LST_KEY, JSON.stringify(otherSettings));
    }

    getUserOtherSettingsData() {
        return JSON.parse(localStorage.getItem(OTHER_SETTINGS_LST_KEY));
    }

    setAppstoreLink(url) {
        localStorage.setItem(APPSTORE_LINK_LST_KEY, JSON.stringify(url));
    }

    getAppstoreLink() {
        return JSON.parse(localStorage.getItem(APPSTORE_LINK_LST_KEY));
    }
    setPlaystoreLink(url) {
        localStorage.setItem(PLAYSTORE_LINK_LST_KEY, JSON.stringify(url));
    }

    getPlaystoreLink() {
        return JSON.parse(localStorage.getItem(PLAYSTORE_LINK_LST_KEY));
    }

    getUserRoll() {
        const user: CurrentUserData = this.getUserLoginData();
        return (user && user.role_id) ? user.role_id : null;
    }

    isAdminRoll(){
        const user: CurrentUserData = this.getUserLoginData();
        // return (user && user.role_id && user.role_id === ADMIN_ROLE_ID) ? true : false;
        return (user && user.role_id && (user.role_id === SUPER_ADMIN_ROLE_ID || user.role_id === ADMIN_ROLE_ID 
        || user.role_id === DEALER_ROLE_ID || user.role_id === STAFFS_ROLL_ID)) ? true : false;
    }

    isAdminMainRoll(){
        const user: CurrentUserData = this.getUserLoginData();
        // return (user && user.role_id && user.role_id === ADMIN_ROLE_ID) ? true : false;
        return (user && user.role_id && (user.role_id === SUPER_ADMIN_ROLE_ID || user.role_id === ADMIN_ROLE_ID)) ? true : false;
    }

    isBusinessRoll(){
        const user: CurrentUserData = this.getUserLoginData();
        return (user && user.role_id && user.role_id === BUSINESS_ROLE_ID) ? true : false;
    }

    getSelfReferalCode() {
        const userData: CurrentUserData = this.getUserLoginData();
        return (userData && userData.userprofile && userData.userprofile.self_referral_code) ? userData.userprofile.self_referral_code: null;
    }
    getForId() {
        const userData: CurrentUserData = this.getUserLoginData();
        return userData ? userData.user_for_id : null;
    }

    // getUserId() {
    //     const userData: CurrentUserData = this.getUserLoginData();
    //     return userData ? userData.id : null;
    // }

    setForIdNotlogin(data){
        localStorage.setItem(FORID_LST_KEY, JSON.stringify(data));
    }
    getForIdNotlogin(){
        return JSON.parse(localStorage.getItem(FORID_LST_KEY));
    }

    getLoginUserId() {
        const user: CurrentUserData = this.getUserLoginData();
        return user ? user.id:null;
    }


    setUserToken(token: string) {
        localStorage.setItem(TOKEN_LST_KEY, JSON.stringify(token));
    }

    getUserToken() {
        return JSON.parse(localStorage.getItem(TOKEN_LST_KEY));
    }

    getRollWiseRoute() {
        const currentUser = this.getUserLoginData();
        const rollId = currentUser.role_id;
        let route;
        if (currentUser) {
            route = (rollId === 1 || rollId === 2) ? TAB1_ROUTE :
                (rollId === 3) ? ADMIN_TAB1_ROUTE : '';
        }
        return route;
    }

    setAllowPermission(allowPermission: Array<CommonData>) {
        localStorage.setItem(ALLOW_PERMISSION_LST_KEY, JSON.stringify(allowPermission));
    }

    getAllowPermission() {
        return JSON.parse(localStorage.getItem(ALLOW_PERMISSION_LST_KEY));
    }

    getAllowAction(menuCode){
        let actionArr=[];
        const allPermissions: Array<CommonData> = this.getAllowPermission();
        if(allPermissions && allPermissions.length > 0){
            const findObj=allPermissions.find((element)=> element.value === menuCode);
            if(findObj){
                actionArr=findObj.actions_arr;
            }
        }
        return actionArr;
        
    }

    getLoginUserStateId() {
        const currentUserData: CurrentUserData = this.getUserLoginData();
        let tempArry = [];
        if (currentUserData) {
            const profileData: UserprofileData = currentUserData.userprofile;
            if (profileData.state_id && profileData.state_id.length > 0) {
                tempArry.push(profileData.state_id[0].value)
            }
        }
        return tempArry.length > 0 ? JSON.stringify(tempArry) : null;
    }

    getLoginUserDistrictId() {
        const currentUserData: CurrentUserData = this.getUserLoginData();
        let tempArry = [];
        if (currentUserData) {
            const profileData: UserprofileData = currentUserData.userprofile;
            if (profileData.district_id && profileData.district_id.length > 0) {
                tempArry.push(profileData.district_id[0].value)
            }
        }
        return tempArry.length > 0 ? JSON.stringify(tempArry) : null;
    }

    getLoginUserTalukaId() {
        const currentUserData: CurrentUserData = this.getUserLoginData();
        let tempArry = [];
        if (currentUserData) {
            const profileData: UserprofileData = currentUserData.userprofile;
            if (profileData.taluka_id && profileData.taluka_id.length > 0) {
                tempArry.push(profileData.taluka_id[0].value)
            }
        }
        return tempArry.length > 0 ? JSON.stringify(tempArry) : null;
    }

    getLoginUserVillageId() {
        const currentUserData: CurrentUserData = this.getUserLoginData();
        let tempArry = [];
        if (currentUserData) {
            const profileData: UserprofileData = currentUserData.userprofile;
            if (profileData.village_id && profileData.village_id.length > 0) {
                tempArry.push(profileData.village_id[0].value)
            }
        }
        return tempArry.length > 0 ? JSON.stringify(tempArry) : null;
    }

    getLoginUserProductCompney() {
        const currentUserData: CurrentUserData = this.getUserLoginData();
        let tempArry = [];
        if (currentUserData) {
            const profileData: UserprofileData = currentUserData.userprofile;
            if (profileData.productcompany_id && profileData.productcompany_id.length > 0) {
                profileData.productcompany_id.forEach(element => {
                    tempArry.push(element.value) 
                });  
            }
        }
        return tempArry.length > 0 ? JSON.stringify(tempArry) : null;
    }

    getLoginUserVillageAreaId() {
        const currentUserData: CurrentUserData = this.getUserLoginData();
        let tempArry = [];
        if (currentUserData) {
            const profileData: UserprofileData = currentUserData.userprofile;
            if (profileData.villagearea_id && profileData.villagearea_id.length > 0) {
                tempArry.push(profileData.villagearea_id[0].value)
            }
        }
        return tempArry.length > 0 ? JSON.stringify(tempArry) : null;
    }

    getTradingServices() {
        const currentUserData: CurrentUserData = this.getUserLoginData();
        let tempArry = [];
        if (currentUserData) {
            const profileData: UserprofileData = currentUserData.userprofile;
            if (profileData.tradingtype_id) {
                if (profileData.tradingtype_id[2] && profileData.tradingtype_id[2].length > 0) {
                    profileData.tradingtype_id[2].forEach(element => {
                        tempArry.push(element.value);
                    });
                }
            }
        }
        return tempArry.length > 0 ? JSON.stringify(tempArry) : null;
    }

    getTradingSales() {
        const currentUserData: CurrentUserData = this.getUserLoginData();
        let tempArry = [];
        if (currentUserData) {
            const profileData: UserprofileData = currentUserData.userprofile;
            if (profileData.tradingtype_id) {
                if (profileData.tradingtype_id[1] && profileData.tradingtype_id[1].length > 0) {
                    profileData.tradingtype_id[1].forEach(element => {
                        tempArry.push(element.value);
                    });
                }
            }
        }
        return tempArry.length > 0 ? JSON.stringify(tempArry) : null;
    }

    getProductServices() {
        const currentUserData: CurrentUserData = this.getUserLoginData();
        let tempArry = [];
        if (currentUserData) {
            const profileData: UserprofileData = currentUserData.userprofile;
            if (profileData.productlist_id) {
                if (profileData.productlist_id[1] && profileData.productlist_id[1].length > 0) {
                    profileData.productlist_id[1].forEach(element => {
                        tempArry.push(element.value);
                    });
                }
            }
        }
        return tempArry.length > 0 ? JSON.stringify(tempArry) : null;
    }

    getProductSales() {
        const currentUserData: CurrentUserData = this.getUserLoginData();
        let tempArry = [];
        if (currentUserData) {
            const profileData: UserprofileData = currentUserData.userprofile;
            if (profileData.productlist_id) {
                if (profileData.productlist_id[2] && profileData.productlist_id[2].length > 0) {
                    profileData.productlist_id[2].forEach(element => {
                        tempArry.push(element.value);
                    });
                }
            }
        }
        return tempArry.length > 0 ? JSON.stringify(tempArry) : null;
    }

    setLanguage(language){
        localStorage.setItem(USER_LANGUAGE_KEY,JSON.stringify(language));
    }

    getLanguage(){
        return JSON.parse(localStorage.getItem(USER_LANGUAGE_KEY));
    }

    setLanguageArr(language){
        localStorage.setItem(USER_LANGUAGE_ARR_KEY,JSON.stringify(language));
    }

    getLanguageArr(){
        return JSON.parse(localStorage.getItem(USER_LANGUAGE_ARR_KEY));
    }

     setLanguageData(language){
        localStorage.setItem(USER_LANGUAGE_DATA_KEY,JSON.stringify(language));
        // await Storage.set({
        //     key: USER_LANGUAGE_DATA_KEY,
        //     value: JSON.stringify(language),
        //   });
    }

      getLanguageData(){
        return JSON.parse(localStorage.getItem(USER_LANGUAGE_DATA_KEY));
        // const ret = await Storage.get({ key: USER_LANGUAGE_DATA_KEY });
        // return JSON.parse(ret.value);
    }

    isExitLanguageData(){
        return this.getLanguageData() ? true:false;
    }

    setLocalData(key,data) {
        localStorage.setItem(key, JSON.stringify(data));
    }

    getLocalData(key) {
        return JSON.parse(localStorage.getItem(key));
    }

    setDevicesFcmToken(token:string){
        localStorage.setItem(FCMTOKEN_LST_KEY, JSON.stringify(token));
    }

    getDevicesFcmToken(){
        return JSON.parse(localStorage.getItem(FCMTOKEN_LST_KEY));
    }

    
    setDevicesUUID(uuid){
        localStorage.setItem(DEVICEUUID_KEY, JSON.stringify(uuid));
    }

    getDevicesUUID(){
        return JSON.parse(localStorage.getItem(DEVICEUUID_KEY));
    }
    setDevicesName(name:string){
        localStorage.setItem(DEVICENAME_KEY, JSON.stringify(name));
    }

    getDevicesName(){
        return JSON.parse(localStorage.getItem(DEVICENAME_KEY));
    }

    removeLocalData(key){
        localStorage.removeItem(key);
    }

    logout() {
        // localStorage.clear();
        // window.caches.delete;
        localStorage.removeItem(USER_LST_KEY);
        localStorage.removeItem(TOKEN_LST_KEY);
        localStorage.removeItem(ALLOW_PERMISSION_LST_KEY);
    }


}