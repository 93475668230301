import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { LoadingController, ModalController, PopoverController } from '@ionic/angular';
import { finalize } from 'rxjs/operators';
import { InputTypeEnum } from 'src/app/enums/inputtypes-enum';
import { CommonData } from 'src/app/models/common-data';
import { UserprofileData } from 'src/app/models/user-profile-data';
import { AuthServices } from 'src/app/services/auth.services';
import { UtilsService } from 'src/app/services/uitils.services';
import { UserServices } from 'src/app/services/user.services';
import { getRatingsBusinessesArr, getRatingstatusArr, getStatusArr, SELECT_LIST_ITEM } from 'src/app/utils/constant';
import { LogData } from 'src/app/utils/utils';
import { SingleSearchFilterComponent } from '../single-search-filter/single-search-filter.component';

@Component({
  selector: 'app-comments-filter',
  templateUrl: './comments-filter.component.html',
  styleUrls: ['./comments-filter.component.scss'],
})
export class CommentsFilterComponent implements OnInit {
  commonForm:FormGroup;
  modelist=[];
  @Input() lblStartDate;
  @Input() lblEndDate;
  @Input() btn1="apply";
  @Input() btn2="clear_all";
  @Input() title="dg_tl_more_filters";
  @Input() isDateField:boolean=false;
  @Input() isStatusField:boolean=false;
  @Input() isModeField:boolean=false;
  @Input() isRoleField:boolean=false;
  @Input() userProfileData:UserprofileData;
  @Input() selectedFilter;
  @Input() pageName;
  @Input() isBusinessField:boolean=false;
  statusList:Array<CommonData>=[];
  businessUser:Array<CommonData>=[];
  maxDateStart: Date;
  maxDateEnd: Date;
  inputTypeEnum=InputTypeEnum;
  constructor(public utilsService:UtilsService,
    private formBuilder:FormBuilder,
    private popoverCtrl:PopoverController,
    private userServices:UserServices,
    private loadingCtrl:LoadingController,
    public authServices:AuthServices,
    private modalCtrl:ModalController) { 
      const currentYear = new Date();
      this.maxDateStart = new Date(currentYear);
      this.maxDateEnd = new Date(currentYear);
    }

  ngOnInit() {
    this.getMasters();
    this.buildForm();
  }

  onBusinessChange(ev){

  }

  getMinDate(){
    return this.commonForm.value.start_date ? this.commonForm.value.start_date:new Date();
  }

  buildForm() {
    this.commonForm = this.formBuilder.group({
      userprofile_id:[""],
      userprofile_id_expand:[""],
      start_date:[""],
      end_date:[""],
      status: [""],
    });
  }
  onStartDateChange(data) {
    LogData("onStartDateChange", data);
    this.commonForm.patchValue({ end_date: '' });
  }

  onStatusChange(ev){

  }

  onStartDateClear(data) {
    LogData("onStartDateClear", data);
    this.commonForm.patchValue({ start_date: '' });
  }

  onEndDateChange(ev){

  }

  onEndDateClear(ev){
    this.commonForm.patchValue({ end_date: '' });
  }

  updateBuildForm(){
    LogData("selectedFilter",this.selectedFilter);
    if(this.selectedFilter){
      if(this.selectedFilter){
        this.commonForm.patchValue(this.selectedFilter);
      }
    }
  }
  
  closeModal(ev?) {
    this.popoverCtrl.dismiss();
  }

  apply(ev?){
    this.popoverCtrl.dismiss(this.commonForm.value);
  }

  clearAll(ev?){
    this.buildForm();
    this.utilsService.setAddressMaster({ page: this.pageName, value: true, isClear: true });
    this.closeModal();
  }

  //api call
  async getMasters() {
    const loading = await this.loadingCtrl.create(
      this.utilsService.getLoaderUI()
    );
    await loading.present();
    LogData("loader_start");
    let list = "";
    list = (this.isBusinessField ? getRatingsBusinessesArr+",":'')+getRatingstatusArr;
    const formData = new FormData();
    formData.append("master", list);
    this.userServices
      .getMasters(formData)
      .pipe(
        finalize(() => {
          LogData("loader_dismiss");
          loading.dismiss();
        })
      )
      .subscribe(
        (res) => {
          LogData("getMasters_res", res);
          res = this.utilsService.getDecriptReasponse(res);
          if (res && res.result && res.result.responce) {
            if (res.result.responce.getRatingstatusArr && res.result.responce.getRatingstatusArr.length > 0) {
              this.statusList = res.result.responce.getRatingstatusArr;
            } else {
              this.statusList = [];
            }
            if (res.result.responce.getRatingsBusinessesArr && res.result.responce.getRatingsBusinessesArr.length > 0) {
              this.businessUser = res.result.responce.getRatingsBusinessesArr;
            } else {
              this.businessUser = [];
            }
          } 
          this.updateBuildForm();
        },
        (err) => {
          LogData("loader_dismiss");
          loading.dismiss();
          LogData("getState_error", err);
        }
      );
  }

  async openSingleSelection(array, title, formControlId,formControlExpand: any) {
    LogData("array", array);
    if (array.length > 0) {
      formControlExpand.setValue(true);
      const modal = await this.modalCtrl.create({
        component: SingleSearchFilterComponent,
        componentProps: { title: title, selectedData: array, selectedId: (formControlId.value && formControlId.value.length > 0) ? formControlId.value[0].value:'' },
        cssClass: 'custom-modal-filter',
        showBackdrop: true,
        backdropDismiss: false,
        // breakpoints: [0, 0.3, 0.5, 0.8],
        // initialBreakpoint: 0.5
      });

      modal.onDidDismiss().then((data) => {
        LogData("onDidDismiss...", data);
        if (data.data != null) {
          if (data.data !== SELECT_LIST_ITEM) {
            const selectedId = data.data.value;
            let tempArr = [];
            tempArr.push(data.data);
            formControlId.setValue(tempArr);
          } else {
            this.commonForm.patchValue({ userprofile_id:''});
          }

        }
        formControlExpand.setValue(false);
      
      });

      await modal.present();
    }
  }

}
