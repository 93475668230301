<div class="container">
<div class="adv-card flex flex-col p-12" (press)="onPress($event)" (pressup)="onPressUp($event)"
(click)="onClickItem()">
  <ion-item class="item-0" lines="none">
    <ion-text slot="start" class="run-ad">{{advertisementData.status[0].text}}</ion-text>
    <div class="flex-row-center" slot="end" *ngIf="advertisementData.start_date || advertisementData.end_date">
      <ion-icon [src]="'ic_cal_g' | getImagePipe"></ion-icon>
      <ion-text class="date-lbl ml-8" *ngIf="advertisementData.start_date">{{advertisementData.start_date |
        dynamicDateTimePipe}} | </ion-text>
      <ion-text class="date-lbl" *ngIf="advertisementData.end_date">{{advertisementData.end_date |
        dynamicDateTimePipe}}</ion-text>
    </div>
  </ion-item>
  <ion-text class="created-by-lbl-sm ion-text-start"
    *ngIf="advertisementData.createdby && advertisementData.createdby.length > 0">{{'lbl_creatd_by' |
    getLanguagePipe}} :
    {{advertisementData.createdby[0].text}}</ion-text>
  <hr class="divider1 mt-8" />
  <div class="flex flex-row mt-12">
    <div class="ver-line">
    </div>
    <div class="flex flex-col ml-12 w-100">
      <ion-text class="lbl-bold-18 ion-text-start"
        *ngIf="advertisementData.userprofile_id && advertisementData.userprofile_id.length > 0">{{advertisementData.userprofile_id[0].text}}</ion-text>
      <!-- <ion-text class="disc-lbl mt-8">Clothing for womens</ion-text> -->
      <ion-row class="mt-2" *ngIf="!isPushAdv">
        <ion-col *ngIf="advertisementData.advertise_space && advertisementData.advertise_space.length > 0">
          <ion-item class="item-0" lines="none">
            <ion-icon [src]="'adv_va' | getImagePipe">
            </ion-icon>
            <ion-text class="disc-lbl ml-4">{{advertisementData.advertise_space[0].text}}</ion-text>
          </ion-item>
        </ion-col>
        <ion-col size="8" *ngIf="advertisementData.display && advertisementData.display.length > 0">
          <ion-item class="item-0" lines="none">
            <ion-icon [src]="'adv_line' | getImagePipe">
            </ion-icon>
            <ion-text class="disc-lbl ml-4">{{advertisementData.display[0].text}}</ion-text>
          </ion-item>
        </ion-col>
        <ion-col size="4">
          <ion-item class="item-0" lines="none">
            <ion-icon [src]="'adv_rs' | getImagePipe">
            </ion-icon>
            <ion-text class="disc-lbl ml-4">{{advertisementData.grand_amount_symbol}}</ion-text>
          </ion-item>
        </ion-col>
      </ion-row>
      <ion-row class="mt-2" *ngIf="isPushAdv">
        <ion-col *ngIf="advertisementData.noofnotification">
          <ion-item class="item-0" lines="none">
            <ion-icon [src]="'adv_noti' | getImagePipe">
            </ion-icon>
            <ion-text class="disc-lbl ml-4">{{advertisementData.noofnotification}} {{'lbl_adv_qty' |
              getLanguagePipe}}.</ion-text>
          </ion-item>
        </ion-col>
        <!-- <ion-col>
          <ion-item class="item-0" lines="none">
            <ion-icon [src]="'adv_freq' | getImagePipe">
            </ion-icon>
            <ion-text class="disc-lbl ml-4">400 Freq.</ion-text>
          </ion-item>
        </ion-col> -->
        <ion-col>
          <ion-item class="item-0" lines="none">
            <ion-icon [src]="'adv_rs' | getImagePipe">
            </ion-icon>
            <ion-text class="disc-lbl ml-4">{{advertisementData.grand_amount_symbol}}</ion-text>
          </ion-item>
        </ion-col>
      </ion-row>
      <ion-row *ngIf="advertisementData.status && advertisementData.status.length > 0 && 
        advertisementData.status[0].value === 5 && utilsService.isAdvButtonAccess(advertisementData)" class="w-100 ion-justify-content-end">
        <ion-button class="btn-3 btn-height" (click)="onClickPending();$event.stopPropagation()">{{'lbl_adv_pen_pay'  | getLanguagePipe}}</ion-button>
        <ion-button class="btn-3 btn-height" (click)="onClickEdit();$event.stopPropagation()">{{'lbl_edit' | getLanguagePipe}}</ion-button>
      </ion-row>
    </div>
  </div>
</div>
<div class="top-left">
  <ion-icon [src]="'ic_sel' | getImagePipe" class="ft-24" *ngIf="advertisementData.isChecked"></ion-icon>
</div>
</div>