<ion-toolbar class="header"  *ngIf="authservices.isExitLanguageData()">
  <ion-row class="ion-align-items-center">
    <ion-col *ngIf="isLoginUser" [size]="isLoginUser ? '0.5':''" class="menu-pad">
      <ion-menu-button class="menu-pad">
        <ion-icon [src]="'ic_menu' | getImagePipe"></ion-icon>
      </ion-menu-button>
    </ion-col>
    <ion-col size="2">
      <ion-tab-button [tab]="CONST_DATA.HOME_ROUTE" >
        <ion-img [src]="'main_home_logo' | getImagePipe:2" class="cursor-ptr">
        </ion-img>
      </ion-tab-button>
    </ion-col>
    <ion-col [size]="isLoginUser ? '9.5':'10'"  class="menu-last">

      <div class="flex flex-row">
        <ion-tab-button [tab]="CONST_DATA.HOME_ROUTE" >
          <div class="flex flex-col ion-align-items-start"
            [ngClass]="selectedTab === CONST_DATA.HOME_ROUTE ? 'nav-ui-active':'nav-ui'">
            <ion-label class="nav-font cursor-ptr">{{'home' | getLanguagePipe}}</ion-label>
            <div class="btn-div flex flex-row ion-align-items-center">
              <div class="line-div"></div>
              <div class="ro-div"></div>
            </div>
          </div>
        </ion-tab-button>

        <ion-tab-button [tab]="CONST_DATA.BUSINESS_DIRECTORY_LIST" class="nav-ml">
          <!-- <ion-label>Profile</ion-label> -->
          <div class="flex flex-col ion-align-items-start"
            [ngClass]="selectedTab === CONST_DATA.BUSINESS_DIRECTORY_LIST ? 'nav-ui-active':'nav-ui'">
            <ion-label class="nav-font cursor-ptr">{{'businesse' | getLanguagePipe}}</ion-label>
            <div class="btn-div flex flex-row ion-align-items-center">
              <div class="line-div"></div>
              <div class="ro-div"></div>
            </div>
          </div>
        </ion-tab-button>

        <ion-tab-button [tab]="CONST_DATA.CONTACTUS_ROUTE" class="nav-ml">
          <!-- <ion-label>Profile</ion-label> -->
          <div class="flex flex-col ion-align-items-start"
            [ngClass]="selectedTab === CONST_DATA.CONTACTUS_ROUTE ? 'nav-ui-active':'nav-ui'">
            <ion-label class="nav-font cursor-ptr">{{'contact' | getLanguagePipe}}</ion-label>
            <div class="btn-div flex flex-row ion-align-items-center">
              <div class="line-div"></div>
              <div class="ro-div"></div>
            </div>
          </div>
        </ion-tab-button>

        <!-- <ion-tab-button [tab]="CONST_DATA.PRICING_RT" class="nav-ml">
          <div class="flex flex-col ion-align-items-start"
            [ngClass]="selectedTab === CONST_DATA.PRICING_RT  ? 'nav-ui-active':'nav-ui'">
            <ion-label class="nav-font cursor-ptr">{{'mn_pricing' | getLanguagePipe}}</ion-label>
            <div class="btn-div flex flex-row ion-align-items-center">
              <div class="line-div"></div>
              <div class="ro-div"></div>
            </div>
          </div>
        </ion-tab-button> -->

        <ion-tab-button *ngIf="!authservices.getUserLoginData()"  class="nav-ml" (click)="onClickListBusiness()">
          <ion-button class="list-btn list-btn-w btn-font btn-h" expand="block" mode="ios">
            <ion-text>
             {{'lbl_signup' | getLanguagePipe}}
            </ion-text>
          </ion-button>
        </ion-tab-button>

         <ion-tab-button *ngIf="!authservices.getUserLoginData()" class="nav-ml" (click)="onClickSignIn()">
          <ion-button class="signin-btn signin-btn-w btn-h" expand="block" mode="ios">
            <ion-text>
              {{'sign_in' | getLanguagePipe}}
            </ion-text>
          </ion-button>
        </ion-tab-button>

        <!-- <ion-tab-button *ngIf="!authservices.getUserLoginData()" (click)="openLoginPage()" class="nav-ml">
          <div class="flex flex-col ion-align-items-start"
            [ngClass]="selectedTab === CONST_DATA.LOGIN_ROUTE ? 'nav-ui-active':'nav-ui'">
            <ion-label class="nav-font cursor-ptr">Login</ion-label>
            <div class="btn-div flex flex-row ion-align-items-center">
              <div class="line-div"></div>
              <div class="ro-div"></div>
            </div>
          </div>
        </ion-tab-button> -->
      
        <ion-tab-button (click)="openPopover($event)" class="nav-ml nav-mr" > 
          <div class="flex flex-col ion-align-items-start nav-ui cursor-ptr">
            <div class="flex flex-row ion-align-items-start">
              <ion-text class="nav-font">
                {{selectedFilter ? selectedFilter.text:''}}
              </ion-text>
              <ion-icon [name]="!isExpand ? 'chevron-down-outline':'chevron-up-outline'" class="ml-8"></ion-icon>
            </div>
          </div>
        </ion-tab-button>
        <!-- <h2>{{selectedTab}}</h2> -->
        <div class="ver-line"></div>
        <ion-tab-button [tab]="CONST_DATA.NOTIFICATIONS_ROUTE" class="ml-16">
          <ion-icon [src]="(selectedTab === CONST_DATA.NOTIFICATIONS_ROUTE ? 'top_noti_sel':'top_noti') | getImagePipe"></ion-icon>
        </ion-tab-button>
        <ion-tab-button *ngIf="authservices.getUserLoginData()" [tab]="CONST_DATA.CHAT_ROUTE" class="ml-16 mr-16">
          <ion-icon [src]="(selectedTab === CONST_DATA.CHAT_ROUTE ? 'top_chat_sel':'top_chat') | getImagePipe"></ion-icon>
        </ion-tab-button>
        <div class="ver-line" *ngIf="authservices.getUserLoginData()"></div>
        <ion-tab-button [tab]="CONST_DATA.PROFILE_ROUTE" *ngIf="authservices.getUserLoginData()" class="nav-x-ml">
          <ion-item button lines="none" class="profile-item-bg" *ngIf="userProfileData">
            <ion-avatar slot="start" class="avt-32">
              <img *ngIf="!isBusinessUser" [src]="
          userProfileImg &&
          userProfileImg.display_files &&
          userProfileImg.display_files.userprofiles_img &&
          userProfileImg.display_files.userprofiles_img
            .square_url
            ? userProfileImg.display_files.userprofiles_img
                .square_url
            : userProfileData | getUserImagePipe" />
            <!-- <img *ngIf="!isBusinessUser" [src]="
            businessUserLogoImg &&
            businessUserLogoImg.display_files &&
            businessUserLogoImg.display_files.business_logo &&
            businessUserLogoImg.display_files.business_logo
              .square_url
              ? businessUserLogoImg.display_files.business_logo
                  .square_url
              : userProfileData | getUserImagePipe"/> -->
              <img *ngIf="isBusinessUser" [src]="utilServices.getLogoImageDisplay(userProfileData)"/>
            </ion-avatar>
            <ion-label class="nav-font" *ngIf="userProfileData.f_name || userProfileData.l_name">{{
              userProfileData.f_name
              | firstLatterPipe: userProfileData.l_name
              }}</ion-label>

            <ion-icon (click)="openPopoverForUser($event);$event.stopPropagation();" slot="end"
              [name]="!isExpandForUser ? 'chevron-down-outline':'chevron-up-outline'" class="ml-8">
            </ion-icon>
          </ion-item>
        </ion-tab-button>
      </div>
    </ion-col>
  </ion-row>