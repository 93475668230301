import { Capacitor } from "@capacitor/core";
import { BUSINESS_REG_SLUG, BUSINESS_TO_CUSTOMER, CUSTOMER_REG_SLUG, CUSTOMER_TO_BUSINESS, CUSTOMER_TO_DEALER, LOGOUT_CODE, USER_DELETE_ACCOUNT } from "../utils/constant";
import { ADDRESS_MASTER, ADMIN_TAB1_ROUTE, ADVERTISE_BANNER, ADVERTISE_PUSH_BANNER, BANK_DETAILS_RT, BUSINESS_INFORMATION, BUSINESS_SUBSCRIPTION, BUSINESS_TYPE, CHANGE_PASSWORD, CONTACT_INFORMATION, DEALER_TAB2_ROUTE, INVITE_EARN_RT, KYC_DETAILS_RT, RECEIVED_PAYMENTS, REG_STEP1, REG_STEP2, SOCIAL_MEDIA_LINKS, STAFF_TAB2_ROUTE, TAB2_ROUTE, TRADING_TYPE, UPLOAD_PICTURES, WALLET_RT, USER_BUSINESS_LIST_RT } from '../utils/routes';

export const BusinessProfileMenu = [
  {
    id: 1,
    name: 'business_information',
    icon: 'ic_bi',
    url: BUSINESS_INFORMATION,
    isDisplay:true
  },
    {
      id: 2,
      name: 'mn_contact_information',
      icon: 'ic_pi',
      url: CONTACT_INFORMATION,
      isDisplay:true
    },
    {
      id: 3,
      name: 'address',
      icon: 'ic_am',
      url: ADDRESS_MASTER,
      isDisplay:true
    },
    {
      id: 4,
      name: 'change_password',
      icon: 'ic_cp',
      url: CHANGE_PASSWORD,
      
    },
  //   {
  //     id: 5,
  //     name: 'mn_kyc',
  //     icon: 'pm_kyc',
  //     ////url: KYC_DETAILS_RT,
  //  url:""
  //   },
  //   {
  //     id: 6,
  //     name: 'mn_bank_details',
  //     icon: 'pm_bank',
  //     ////url: BANK_DETAILS_RT,
  //  url:""
  //   },
  //   {
  //     id: 61,
  //     name: 'mn_wallet',
  //     icon: 'ic_wallet_ro',
  //    //url: WALLET_RT,
  //  url:""
  //     // url:''
  //   },
    // {
    //   id: 7,
    //   name: 'advertise_banners',
    //   icon: 'pm_adv_ban',
    //   // url: ADVERTISE_BANNER
    //   url: ''
    // },
    // {
    //   id: 8,
    //   name: 'push_notification',
    //   icon: 'pm_adv_push',
    //   // url: ADVERTISE_PUSH_BANNER
    //   url: ''
    // },
    {
      id: 9,
      name: 'trading_type',
      icon: 'ic_tp',
      // url: TRADING_TYPE,
      url: USER_BUSINESS_LIST_RT,
      isDisplay:true
    },
    {
      id: 10,
      name: 'gallery',
      icon: 'ic_up',
      url: UPLOAD_PICTURES,
      isDisplay:true
    },
    {
      id: 11,
      name: 'social_media',
      icon: 'ic_sml',
      url: SOCIAL_MEDIA_LINKS,
      isDisplay:true
    },
    {
      id: 12,
      name: 'mn_business_reg',
      icon: 'pm_bus_reg',
      // url: BUSINESS_TYPE,
      url: Capacitor.getPlatform() !== 'ios' ? BUSINESS_TYPE:'',
      isBrowser:true,
      param:{
        type:BUSINESS_REG_SLUG
      }
    },
    {
      id: 13,
      name: 'mn_customer_reg',
      icon: 'pm_cst_reg',
      url: REG_STEP1,
      param:{
        type:CUSTOMER_REG_SLUG
      },
    },
    {
      id: 14,
      name: 'mn_invite_earn',
      icon: 'ic_cp',
     url: INVITE_EARN_RT,
  //  url:""
      // url:''
    },
    {
      id: 15,
      name: 'mn_billing_history',
      icon: 'ic_bs',
      // url: BUSINESS_SUBSCRIPTION
      // url: RECEIVED_PAYMENTS
      url: Capacitor.getPlatform() !== 'ios' ? RECEIVED_PAYMENTS:'',
      isBrowser:true
    },
    {
      id: 16,
      name: 'mn_business_to_customer',
      code:BUSINESS_TO_CUSTOMER,
      icon: 'convert_ro',
      isAdmin:true,
      url: ""
    },
    {
      id: 17,
      name: 'dele_acc',
      code:USER_DELETE_ACCOUNT,
      icon: 'ic_acc_del',
      // url: BUSINESS_SUBSCRIPTION
      url: ""
    },
  ];
  
  export const CustomerProfileMenu = [
    {
      id: 1,
      name: 'mn_contact_information',
      icon: 'ic_pi',
      url: REG_STEP1,
      isDisplay:true
    },
    {
      id: 2,
      name: 'address',
      icon: 'ic_am',
      url: REG_STEP2,
      isDisplay:true
    },
    {
      id: 3,
      name: 'change_password',
      icon: 'ic_cp',
      url: CHANGE_PASSWORD,
      isDisplay:true
    },
  //   {
  //     id: 4,
  //     name: 'mn_kyc',
  //     icon: 'pm_kyc',
  //    //url: KYC_DETAILS_RT,
  //  url:""
  //   },
  //   {
  //     id: 5,
  //     name: 'mn_bank_details',
  //     icon: 'pm_bank',
  //    //url: BANK_DETAILS_RT,
  //  url:""
  //   },
  //   {
  //     id: 51,
  //     name: 'mn_wallet',
  //     icon: 'ic_wallet_ro',
  //    //url: WALLET_RT,
  //  url:""
  //     // url:''
  //   },
    {
      id: 6,
      name: 'mn_business_reg',
      icon: 'pm_bus_reg',
      // url: BUSINESS_TYPE,
      url: Capacitor.getPlatform() !== 'ios' ? BUSINESS_TYPE:'',
      isBrowser:true,
      param:{
        type:BUSINESS_REG_SLUG
      },
    },
    {
      id: 7,
      name: 'mn_customer_reg',
      icon: 'pm_cst_reg',
      url: REG_STEP1,
      param:{
        type:CUSTOMER_REG_SLUG
      },
    },
    {
      id: 8,
      name: 'mn_invite_earn',
      icon: 'ic_cp',
     url: INVITE_EARN_RT,
  //  url:""
    },
    {
      id: 9,
      name: 'mn_customer_to_business',
      icon: 'convert_ro',
      url: '',
      code:CUSTOMER_TO_BUSINESS
    },
    {
      id: 10,
      name: 'mn_customer_to_dealer',
      icon: 'convert_ro',
      url: "",
      code:CUSTOMER_TO_DEALER,
      
    },
    {
      id: 17,
      name: 'dele_acc',
      code:USER_DELETE_ACCOUNT,
      icon: 'ic_acc_del',
      // url: BUSINESS_SUBSCRIPTION
      url: ""
    },
    
  ];

  export const CustomerPopoverMenu = [
    {
      value: 1,
      text: "profile",
      icon: 'ic_cp',
      url: TAB2_ROUTE
    },
  //   {
  //     value: 2,
  //     text: 'mn_kyc',
  //     icon: 'pm_kyc',
  //    //url: KYC_DETAILS_RT,
  //  url:""
  //   },
  //   {
  //     value: 3,
  //     text: 'mn_bank_details',
  //     icon: 'pm_bank',
  //    //url: BANK_DETAILS_RT,
  //  url:""
  //   },
  //   {
  //     value: 33,
  //     text: 'mn_wallet',
  //     icon: 'mn_wallet',
  //    //url: WALLET_RT,
  //  url:"",
  //   },
    {
      value: 4,
      text: 'mn_business_reg',
      icon: 'pm_bus_reg',
      // url: BUSINESS_TYPE,
      url: Capacitor.getPlatform() !== 'ios' ? BUSINESS_TYPE:'',
      isBrowser:true,
      param:{
        type:BUSINESS_REG_SLUG
      },
    },
    {
      value: 5,
      text: 'mn_customer_reg',
      icon: 'pm_cst_reg',
      url: REG_STEP1,
      param:{
        type:CUSTOMER_REG_SLUG
      },
    },
    {
      value: 6,
      text: 'mn_invite_earn',
      icon: 'ic_cp',
     url: INVITE_EARN_RT,
  //  url:""
    },
    {
      value: 7,
      text: 'logout',
      icon: 'ic_cp',
      code:LOGOUT_CODE,
      url: ''
    }
  ];

  export const BusinessPopoverMenu = [
    {
      value: 1,
      text: "profile",
      icon: 'ic_cp',
      url: TAB2_ROUTE
    },
  //   {
  //     value: 2,
  //     text: 'mn_kyc',
  //     icon: 'pm_kyc',
  //    //url: KYC_DETAILS_RT,
  //  url:""
  //   },
  //   {
  //     value: 3,
  //     text: 'mn_bank_details',
  //     icon: 'pm_bank',
  //    //url: BANK_DETAILS_RT,
  //  url:""
  //   },
  //   {
  //     value: 33,
  //     text: 'mn_wallet',
  //     icon: 'mn_wallet',
  //    //url: WALLET_RT,
  //  url:"",
  //   },
    {
      value: 4,
      text: 'mn_business_reg',
      icon: 'pm_bus_reg',
      // url: BUSINESS_TYPE,
      url: Capacitor.getPlatform() !== 'ios' ? BUSINESS_TYPE:'',
      isBrowser:true,
      param:{
        type:BUSINESS_REG_SLUG
      },
    },
    {
      value: 5,
      text: 'mn_customer_reg',
      icon: 'pm_cst_reg',
      url: REG_STEP1,
      param:{
        type:CUSTOMER_REG_SLUG
      },
    },
    {
      value: 6,
      text: 'mn_invite_earn',
      icon: 'ic_cp',
     url: INVITE_EARN_RT,
  //  url:""
    },
    {
      value: 7,
      text: 'logout',
      icon: 'ic_cp',
      code:LOGOUT_CODE,
      url: ''
    }
  ];

  export const DealerPopoverMenu = [
    {
      value: 1,
      text: "profile",
      icon: 'ic_cp',
      url: DEALER_TAB2_ROUTE
    },
  //   {
  //     value: 2,
  //     text: 'mn_kyc',
  //     icon: 'pm_kyc',
  //    //url: KYC_DETAILS_RT,
  //  url:""
  //   },
  //   {
  //     value: 3,
  //     text: 'mn_bank_details',
  //     icon: 'pm_bank',
  //    //url: BANK_DETAILS_RT,
  //  url:""
  //   },
  //   {
  //     value: 33,
  //     text: 'mn_wallet',
  //     icon: 'mn_wallet',
  //    //url: WALLET_RT,
  //  url:"",
  //   },
    {
      value: 4,
      text: 'mn_business_reg',
      icon: 'pm_bus_reg',
      // url: BUSINESS_TYPE,
      url: Capacitor.getPlatform() !== 'ios' ? BUSINESS_TYPE:'',
      isBrowser:true,
      param:{
        type:BUSINESS_REG_SLUG
      },
    },
    {
      value: 5,
      text: 'mn_customer_reg',
      icon: 'pm_cst_reg',
      url: REG_STEP1,
      param:{
        type:CUSTOMER_REG_SLUG
      },
    },
  //   {
  //     value: 6,
  //     text: 'mn_invite_earn',
  //     icon: 'ic_cp',
  //    url: INVITE_EARN_RT,
  // //  url:""
  //   },
    {
      value: 7,
      text: 'logout',
      icon: 'ic_cp',
      code:LOGOUT_CODE,
      url: ''
    }
  ];

  export const StaffPopoverMenu = [
    {
      value: 1,
      text: "profile",
      icon: 'ic_cp',
      url: STAFF_TAB2_ROUTE
    },
  //   {
  //     value: 2,
  //     text: 'mn_kyc',
  //     icon: 'pm_kyc',
  //    //url: KYC_DETAILS_RT,
  //  url:""
  //   },
  //   {
  //     value: 3,
  //     text: 'mn_bank_details',
  //     icon: 'pm_bank',
  //    //url: BANK_DETAILS_RT,
  //  url:""
  //   },
  //   {
  //     value: 33,
  //     text: 'mn_wallet',
  //     icon: 'mn_wallet',
  //    //url: WALLET_RT,
  //  url:"",
  //   },
  //   {
  //     value: 4,
  //     text: 'mn_business_reg',
  //     icon: 'pm_bus_reg',
  //     url: ''
  //   },
  //   {
  //     value: 5,
  //     text: 'mn_customer_reg',
  //     icon: 'pm_cst_reg',
  //     url: ''
  //   },
    {
      value: 6,
      text: 'mn_invite_earn',
      icon: 'ic_cp',
     url: INVITE_EARN_RT,
  //  url:""
    },
    {
      value: 7,
      text: 'logout',
      icon: 'ic_cp',
      code:LOGOUT_CODE,
      url: ''
    }
  ];

  export const AdminPopoverMenu = [
    {
      value: 1,
      text: 'dashboard',
      icon: 'ic_cp',
      url: ADMIN_TAB1_ROUTE
    },
    {
      value: 1,
      text: 'logout',
      icon: 'ic_cp',
      code:LOGOUT_CODE,
      url: ''
    }
  ];