<ion-tabs #tabs (ionTabsDidChange)="setCurrentTab()">

  <ion-tab-bar slot="bottom" class="mobile-header tab-lbl">
    <ion-tab-button [tab]="tab1" >
      <ion-icon [src]="(selectedTab === tab1 ? icTab1s:icTab1) | getImagePipe" ></ion-icon>
      <ion-label>{{tab1lbl | getLanguagePipe}}</ion-label>
    </ion-tab-button>

    <ion-tab-button [tab]="tab2"  *ngIf="tab2">
      <ion-icon [src]="(selectedTab === tab2 ? icTab2s:icTab2) | getImagePipe" ></ion-icon>
      <ion-label>{{tab2lbl | getLanguagePipe}}</ion-label>
    </ion-tab-button>

    <ion-tab-button [tab]="tab3">
      <ion-icon [src]="(selectedTab === tab3 ? icTab3s:icTab3) | getImagePipe" ></ion-icon>
      <ion-label>{{tab3lbl | getLanguagePipe}}</ion-label>
    </ion-tab-button>

    <ion-tab-button [tab]="tab4" >
      <ion-icon [src]="(selectedTab === tab4 ? icTab4s:icTab4) | getImagePipe" ></ion-icon>
      <ion-label>{{tab4lbl | getLanguagePipe}}</ion-label>
    </ion-tab-button>
    <ion-tab-button [tab]="tab5"  *ngIf="tab5">
      <ion-icon [src]="(selectedTab === tab5 ? icTab5s:icTab5) | getImagePipe" ></ion-icon>
      <ion-label>{{tab5lbl | getLanguagePipe}}</ion-label>
    </ion-tab-button>
  </ion-tab-bar>
  <ion-tab-bar slot="top" class="header h-p-135">
  <app-web-top-bar [selectedTab]="selectedTab"></app-web-top-bar>
</ion-tab-bar>
</ion-tabs>