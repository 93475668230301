<!-- <ion-content> -->
  <!-- <ion-list> -->
    <form [formGroup]="commonForm" *ngIf="commonForm">
      <ion-grid class="p-0">
        <ion-row class="center-row">
          <ion-col style="align-self: center;" class="no-margin-padding" [attrSizeMd]="isFilter ? '12':'6'" [attrSizeLg]="isFilter ? '12':'6'" [attrSizeXl]="isFilter ? '12':'5'"  appSizeAttribute>
            <ion-row>

              <ng-container formArrayName="addressDetails">
                <ng-container *ngIf="commonForm.get('addressDetails')">
                  <ng-container *ngFor="let item of addressDetails.controls; let i = index;let isLast=last;">
                    <ng-container [formGroupName]="i" class="ion-align-items-center">
                      <ion-col *ngIf="isState" size="12">
                        <ion-label class="lbl-med-12">{{'select_state' | getLanguagePipe}} :</ion-label>
                        <ion-item class=" mt-8" lines="none"
                          [ngClass]="utilsService.getValidCss(addressDetails.at(i).get('state_id'))"
                          (click)="openSingleSelection(0,stateList,'select_state',addressDetails.at(i).get('state_id'),addressDetails.at(i).get('state_name'),addressDetails.at(i).get('state_expand'),i)">
                          <ion-text slot="start" class="lbl-med-15">
                            {{(item.value.state_id && item.value.state_id.length >
                            0)?item.value.state_id[0].text:''}}
                          </ion-text>
                          <ion-icon [name]="item.value.state_expand ? 'chevron-up-outline':'chevron-down-outline'"
                            class="ft-20 cursor-ptr" slot="end">
                          </ion-icon>
                        </ion-item>
                        <app-validation [validationMessages]="validationMessages.state" [ValidationForm]="item"
                        [type]="'state_id'" [isFormSubmit]="isFormSubmit"></app-validation>
                      </ion-col>

                      <ion-col *ngIf="isDistrict" class="mt-12" size="12">
                        <ion-label class="lbl-med-12">{{'select_district' | getLanguagePipe}} :</ion-label>
                        <ion-item class=" mt-8" lines="none"
                          [ngClass]="utilsService.getValidCss(addressDetails.at(i).get('district_id'))"
                          (click)="openSingleSelection(1,addressDetails.at(i).get('districtList').value,'select_district',addressDetails.at(i).get('district_id'),addressDetails.at(i).get('district_name'),addressDetails.at(i).get('district_expand'),i)">
                          <ion-text slot="start" class="lbl-med-15">
                            {{(item.value.district_id && item.value.district_id.length >
                            0)?item.value.district_id[0].text:''}}
                            <!-- {{item.value.district_name}} -->
                          </ion-text>
                          <ion-icon
                            [name]="item.value.district_expand ? 'chevron-up-outline':'chevron-down-outline'"
                            class="ft-20 cursor-ptr" slot="end">
                          </ion-icon>
                        </ion-item>
                        <app-validation [validationMessages]="validationMessages.district" [ValidationForm]="item"
                        [type]="'district_id'" [isFormSubmit]="isFormSubmit"></app-validation> 
                      </ion-col>

                      <ion-col *ngIf="isTaluka" class="mt-12" size="12">
                        <ion-label class="lbl-med-12">{{'select_taluka' | getLanguagePipe}} :</ion-label>
                        <ion-item class=" mt-8" lines="none"
                          [ngClass]="utilsService.getValidCss(addressDetails.at(i).get('taluka_id'))"
                          (click)="openSingleSelection(2,addressDetails.at(i).get('talukaList').value,'select_taluka',addressDetails.at(i).get('taluka_id'),addressDetails.at(i).get('taluka_name'),addressDetails.at(i).get('taluka_expand'),i)">
                          <ion-text slot="start" class="lbl-med-15">
                            {{(item.value.taluka_id && item.value.taluka_id.length >
                            0)?item.value.taluka_id[0].text:''}}
                            <!-- {{item.value.taluka_name}} -->
                          </ion-text>
                          <ion-icon [name]="item.value.taluka_expan ? 'chevron-up-outline':'chevron-down-outline'"
                            class="ft-20 cursor-ptr" slot="end">
                          </ion-icon>
                        </ion-item>
                        <app-validation [validationMessages]="validationMessages.taluka" [ValidationForm]="item"
                        [type]="'taluka_id'" [isFormSubmit]="isFormSubmit"></app-validation>
                      </ion-col>

                      <ion-col class="mt-12"  *ngIf="item.value.is_city && item.value.is_city === 1 && isZoneDisplay && zoneList && zoneList.length > 0" [attrSizeXl]="'12'" appSizeAttribute>
                        <ion-label class="lbl-med-12">{{'lbl_add_village_zone_name' | getLanguagePipe}} :</ion-label>
                        <ion-item class="mt-8" [ngClass]="utilsService.getValidCss(addressDetails.at(i).get('zone_name'),isFormSubmit)"
                          lines="none">
                          <ion-select interface="popover" formControlName="zone_name" (ionChange)="onChangeZone($event)">
                            <ion-select-option [value]="item.value" *ngFor="let item of zoneList">{{item.text}}
                            </ion-select-option>
                          </ion-select>
                        </ion-item>
                        <app-validation [validationMessages]="validationMessages.zone_name" [ValidationForm]="item"
                        [type]="'zone_name'" [isFormSubmit]="isFormSubmit"></app-validation>
                      </ion-col>
          

                      <ion-col *ngIf="isVillage" class="mt-12" size="12">
                        <ion-label class="lbl-med-12">{{ (item.value.is_city === 1 ? 'lbl_add_vill_are_sel_area_nm':'select_village') | getLanguagePipe}} :</ion-label>
                        <ion-item class=" mt-8" lines="none"
                          [ngClass]="utilsService.getValidCss(addressDetails.at(i).get('village_id'))"
                          (click)="openSingleSelection(3,villageList,item.value.is_city === 1 ? 'lbl_add_vill_are_sel_area_nm':'select_village',addressDetails.at(i).get('village_id'),addressDetails.at(i).get('village_name'),addressDetails.at(i).get('village_expand'),i)">
                          <ion-text slot="start" class="lbl-med-15" *ngIf="!isVillageMultiple">
                            {{(item.value.village_id && item.value.village_id.length >
                            0)?item.value.village_id[0].text:''}}
                            <!-- {{item.value.village_name}} -->
                          </ion-text>
                          <ion-icon
                            [name]="item.value.village_expand ? 'chevron-up-outline':'chevron-down-outline'"
                            class="ft-20 cursor-ptr" slot="end">
                          </ion-icon>
                        </ion-item>
                        <app-validation [validationMessages]="validationMessages.village" [ValidationForm]="item"
                        [type]="'village_id'" [isFormSubmit]="isFormSubmit"></app-validation>
                      </ion-col>
                      <ion-col *ngIf="isVillageMultiple && selectedVillagesList && selectedVillagesList.length > 0" class="mt-12" [attrSizeXl]="'12'"
                        appSizeAttribute>
                        <app-selected-chip [selectedList]="selectedVillagesList" [isMultiSelectAccess]="true" (getItems)="getVillageList($event)">
                        </app-selected-chip>
                      </ion-col>

                      <ion-col *ngIf="isVillageArea" class="mt-12" size="12">
                        <ion-label class="lbl-med-12">{{(item.value.is_city === 1 ? 'lbl_add_vill_are_sel_society':'select_village_area') | getLanguagePipe}} :</ion-label>
                        <ion-item class=" mt-8" lines="none"
                          [ngClass]="utilsService.getValidCss(addressDetails.at(i).get('villagearea_id'))"
                          (click)="openSingleSelection(4,villageAreaList,item.value.is_city === 1 ? 'lbl_add_vill_are_sel_society':'select_village_area',addressDetails.at(i).get('villagearea_id'),addressDetails.at(i).get('village_area_name'),addressDetails.at(i).get('village_area_expand'),i)">
                          <ion-text slot="start" class="lbl-med-15">
                            {{(item.value.villagearea_id && item.value.villagearea_id.length >
                            0)?item.value.villagearea_id[0].text:''}}
                            <!-- {{item.value.village_area_name}} -->
                          </ion-text>
                          <ion-icon
                            [name]="item.value.village_area_expand ? 'chevron-up-outline':'chevron-down-outline'"
                            class="ft-20 cursor-ptr" slot="end">
                          </ion-icon>
                        </ion-item>
                      </ion-col>

                      <ion-col *ngIf="isStatus && statusList && statusList.length > 0" class="mt-12" size="12">
                        <ion-label class="lbl-med-12">{{ 'status' | getLanguagePipe }} :</ion-label>
                        <ion-item class="mt-8"
                          [ngClass]="utilsService.getValidCss(addressDetails.at(i).get('status'))" lines="none">
                          <ion-select interface="popover" formControlName="status">
                            <ion-select-option [value]="item.value" *ngFor="let item of statusList">{{ item.text }}
                            </ion-select-option>
                          </ion-select>
                        </ion-item>
                      </ion-col>
                      <ion-col *ngIf="isMultiple" class="ion-no-padding" [attrSizeXl]="'12'" appSizeAttribute>
                        <ion-item lines="none">
                          <ion-text *ngIf="isLast" slot="start" class="add-lbl" (click)="addMoreDetail()">+ {{'lbl_add_brdl_stp2_area_details' | getLanguagePipe}} </ion-text>
                          <ion-text *ngIf="i !== 0" slot="end" class="lbl-dg-14 ml-0" (click)="openConfirmation(item.value,i)">- {{'delete' | getLanguagePipe}}</ion-text>
                        </ion-item>
                      </ion-col>
                    </ng-container>
                  </ng-container>
                </ng-container>
              </ng-container>

            </ion-row>
          </ion-col>
        </ion-row>
      </ion-grid>
    </form>
  <!-- </ion-list> -->
<!-- </ion-content> -->