import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import "hammerjs"; // HAMMER TIME
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import { LogData } from './app/utils/utils';



if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => LogData(err));

  // Call the element loader after the platform has been bootstrapped
defineCustomElements(window);
