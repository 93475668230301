import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonData } from 'src/app/models/common-data';
import { LedgerDetails } from 'src/app/models/ladger-data';
import { UtilsService } from 'src/app/services/uitils.services';
import { LogData } from 'src/app/utils/utils';

@Component({
  selector: 'app-ladger-card',
  templateUrl: './ladger-card.component.html',
  styleUrls: ['./ladger-card.component.scss'],
})
export class LadgerCardComponent implements OnInit {
  @Input() ladgerData: LedgerDetails;
  @Output() getItems: EventEmitter<any> = new EventEmitter();
  @Input() checkedCount;
  isLongPressClick: boolean = false;
  @Input() redirectUrl;
  constructor(private utilsService: UtilsService) { }

  ngOnInit() { }

  isMinusAmt() {
    let flag = false;
    if (this.ladgerData && this.ladgerData.cl_balance && parseFloat(this.ladgerData.cl_balance) < 0) {
      flag = true;
    }
    return flag;
  }


  getStatus(status: Array<CommonData>) {
    return (status && status.length > 0) ? status[0].value : '';
  }
  onClickItem() {
    // this.setCheckedData();
    LogData("onClickItem", this.isLongPressClick);

    if (!this.isLongPressClick) {
      if (this.checkedCount === 0) {
        this.utilsService.setNavigationRoute(this.redirectUrl + this.ladgerData.id,);
      } else {
        this.setCheckedData();
      }
    } else {
      this.isLongPressClick = false;
    }


  }

  setCheckedData() {
    if (this.utilsService.isAccessLadgerCrud(this.ladgerData)) {
      if (this.ladgerData.isChecked) {
        this.ladgerData.isChecked = false;
      } else {
        this.ladgerData.isChecked = true;
      }
      this.getItems.emit(true);
    }
  }

  onPress($event) {
    if (this.utilsService.isAccessLadgerCrud(this.ladgerData)) {
      LogData("onPress", $event);
      this.isLongPressClick = true;
      this.setCheckedData();
    }
  }

  onPressUp($event) {
    LogData("onPressUp", $event);

  }

}
