import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AdvsetsData } from 'src/app/models/adv-settings-data';
import { CommonData } from 'src/app/models/common-data';
import { UtilsService } from 'src/app/services/uitils.services';
import { BANNER_SLUG } from 'src/app/utils/constant';
import { ADVERTISE_SETTINGS } from 'src/app/utils/routes';
import { LogData } from 'src/app/utils/utils';

@Component({
  selector: 'app-setting-card',
  templateUrl: './setting-card.component.html',
  styleUrls: ['./setting-card.component.scss'],
})
export class SettingCardComponent implements OnInit {

  @Input() settingData: AdvsetsData;
  @Output() getItems: EventEmitter<any> = new EventEmitter();
  @Input() checkedCount;
  @Input() uiType;
  @Input() redirectUrl;
  @Input() queryparam;
  @Input() type;
  isLongPressClick: boolean = false;
  CONST_DATA={
    BANNER_SLUG
  };
  constructor(private utilsService: UtilsService) { }

  ngOnInit() { }


  getStatus(status: Array<CommonData>) {
    return (status && status.length > 0) ? status[0].value : '';
  }

  isAddressStr() {
    let flag = false;
    if ((this.settingData.state_id && this.settingData.state_id.length > 0) ||
      (this.settingData.district_id && this.settingData.district_id.length > 0) ||
      (this.settingData.taluka_id && this.settingData.taluka_id.length > 0) ||
      (this.settingData.village_id && this.settingData.village_id.length > 0)) {
        flag=true;
    }
    return flag;
  }

  onClickItem() {
    // this.setCheckedData();
    LogData("onClickItem", this.isLongPressClick);

    if (!this.isLongPressClick) {
      if (this.checkedCount === 0) {
        this.utilsService.setNavigationRouteQuery("edit-" + ADVERTISE_SETTINGS,{id:this.settingData.id,type:this.type});
        // this.utilsService.setNavigationRoute("edit-" + ADVERTISE_SETTINGS+"/"+this.settingData.id);
      } else {
        this.setCheckedData();
      }
    } else {
      this.isLongPressClick = false;
    }


  }

  setCheckedData() {
    if (this.settingData.isChecked) {
      this.settingData.isChecked = false;
    } else {
      this.settingData.isChecked = true;
    }
    this.getItems.emit(true);
  }

  onPress($event) {
    LogData("onPress", $event);
    this.isLongPressClick = true;
    this.setCheckedData();
  }

  onPressUp($event) {
    LogData("onPressUp", $event);

  }
}
