import { Injectable, NgZone } from "@angular/core";
import {
  ActivatedRoute,
  NavigationExtras,
  Router,
  RoutesRecognized,
} from "@angular/router";
import { Capacitor } from "@capacitor/core";
import {
  LoadingController,
  NavController,
  Platform,
  PopoverController,
  ToastController,
} from "@ionic/angular";
import { BehaviorSubject, Observable, Observer } from "rxjs";
import { BusinessStepper, GlobalData } from "../models/global-data";
import { CurrentUserData, UserData } from "../models/user-data";
import {
  ADMIN_ROLE_ID,
  ANDROID,
  BUSINESS_ROLE_ID,
  COVER_DEFALUT_IMG,
  CUSTOMER_ROLE_ID,
  DEALER_ROLE_ID,
  FEMALE_GN,
  FILE_SIZE_1MB,
  FILE_SIZE_2MB,
  FILE_SIZE_80KB,
  INPUTTYPE_DR,
  IOS,
  LOGO_DEAFLUT_IMG,
  MALE_GN,
  MIME_TYPE_JPG,
  MIME_TYPE_PNG,
  STAFFS_ROLL_ID,
  SUPER_ADMIN_ROLE_ID,
  BUSINESSES_USER_TYPE,
  WEB,
} from "../utils/constant";
import { AuthServices } from "./auth.services";
import * as moment from "moment";
import { LogData } from "../utils/utils";
import { SuccessErrorComponent } from "../components/success-error/success-error.component";
import { AlphaNumeric } from "../validators/validations";
import { FormGroup, Validators } from "@angular/forms";
import {
  ImageData,
  UserDocument,
  UserprofileData,
} from "../models/user-profile-data";
import { filter, pairwise } from "rxjs/operators";
import {
  ADVERTISEMENT_PIC_KEY,
  BUSINESS_LOGO_IMG_KEY,
  USERPROFILES_IMG_KEY,
} from "../utils/params-data";
import { GetLanguagePipe } from "../pipes/get-language.pipe";
import { MasterData } from "../models/master-data";
import { LedgerDetails } from "../models/ladger-data";
import { AccCommonData, CommonData } from "../models/common-data";
import { ResponseBean } from "../models/responsebean";
import {
  TransactionResponce,
  TransactionResponceSingle,
} from "../models/transaction-data";
import {
  ADMIN_CHAT_ROUTE,
  ADMIN_TAB1_ROUTE,
  ADMIN_TABS_BUSINESS_DIRECTORY_LIST,
  DEALER_TAB1_ROUTE,
  DEALER_TAB5_ROUTE,
  DEALER_TABS_BUSINESS_DIRECTORY_LIST,
  STAFF_TAB1_ROUTE,
  STAFF_TAB5_ROUTE,
  STAFF_TABS_BUSINESS_DIRECTORY_LIST,
  TAB1_ROUTE,
  TAB5_ROUTE,
  TABS_BUSINESS_DIRECTORY_LIST,
  VISITOR_BUSINESS_DIRECTORY_LIST,
} from "../utils/routes";
import { AddressDetails } from "../models/address-details";
import { de } from "date-fns/locale";
import { AccountsDetails } from "../models/accounts-data";
// import { Share } from '@capacitor/share';
// import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';
import { NgNavigatorShareService } from "ng-navigator-share";
// import { FileTransfer, FileTransferObject } from '@awesome-cordova-plugins/file-transfer/ngx';
// import { File } from '@awesome-cordova-plugins/file/ngx';
import { AppVersion } from "@awesome-cordova-plugins/app-version/ngx";
import { environment } from "src/environments/environment";
import { Share } from "@capacitor/share";
import { SafariViewController } from "@awesome-cordova-plugins/safari-view-controller/ngx";
import { ReviewDetails } from "../models/comments-data";
import { Device } from "@capacitor/device";
import { Browser } from "@capacitor/browser";
import { MimeTypesData } from "../models/mime-types";
import { DocumentType } from "../models/chat-setting";
import { HttpClient, HttpResponse } from "@angular/common/http";
import { DomSanitizer } from "@angular/platform-browser";
import { Filesystem, FilesystemDirectory } from "@capacitor/filesystem";
import { NotePopoverComponent } from "../components/note-popover/note-popover.component";
import { AdvertisementDetails } from "../models/advertisements-data";
import { ADVSCHEDULES_PIC_KEY } from "../utils/params-data";

declare var CryptoJSAesJson;

@Injectable({
  providedIn: "root",
})
export class UtilsService {
  //Time picker format
  TIME_PICK_FORMAT_24 = "HH:mm";
  TIME_PICK_FORMAT_12 = "hh:mm A";

  //Time display format
  TIME_DISP_FORMAT_24 = "HH:mm";
  TIME_DISP_FORMAT_12 = "hh:mmA";

  isBusinessSelect: boolean = false;
  selectedTab;
  public userDataSubject: BehaviorSubject<CurrentUserData> =
    new BehaviorSubject<CurrentUserData>(null);
  userData$: Observable<CurrentUserData> = this.userDataSubject.asObservable();
  public selectedMenuSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );
  selectedMenu$: Observable<any> = this.selectedMenuSubject.asObservable();
  public updateSideMenuSubject: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  updateSideMenu$: Observable<boolean> =
    this.updateSideMenuSubject.asObservable();
  public updateUserProfileSubject: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  updateUserProfile$: Observable<boolean> =
    this.updateUserProfileSubject.asObservable();
  public closeDateTimePickerSubject: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  closeDateTimePicker$: Observable<boolean> =
    this.closeDateTimePickerSubject.asObservable();
  public memberIdUpdateSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );
  memberIdUpdate$: Observable<any> = this.memberIdUpdateSubject.asObservable();
  public updateBusinessListSubject: BehaviorSubject<any> =
    new BehaviorSubject<any>(null);
  updateBusinessList$: Observable<any> =
    this.updateBusinessListSubject.asObservable();

  public updateTradingListSubject: BehaviorSubject<any> =
    new BehaviorSubject<any>(false);
  updateTradingList$: Observable<any> =
    this.updateTradingListSubject.asObservable();

  public updateProductListSubject: BehaviorSubject<any> =
    new BehaviorSubject<any>(null);
  updateProductList$: Observable<any> =
    this.updateProductListSubject.asObservable();

  public updateProductCompneyListSubject: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  updateProductCompneyList$: Observable<boolean> =
    this.updateProductCompneyListSubject.asObservable();

  public addressMasterSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );
  addressMaster$: Observable<any> = this.addressMasterSubject.asObservable();

  public getUserProfileDataSubject: BehaviorSubject<UserprofileData> =
    new BehaviorSubject<UserprofileData>(null);
  getUserProfileData$: Observable<UserprofileData> =
    this.getUserProfileDataSubject.asObservable();

  public complaintSubjectListSubject: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  complaintSubjectList$: Observable<boolean> =
    this.complaintSubjectListSubject.asObservable();

  public translationListSubject: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  translationSubjectList$: Observable<boolean> =
    this.translationListSubject.asObservable();

  public isSuccessSubject: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  isSuccess$: Observable<boolean> = this.isSuccessSubject.asObservable();

  public getDataSectionSubject: BehaviorSubject<MasterData> =
    new BehaviorSubject<MasterData>(null);
  getDataSection$: Observable<MasterData> =
    this.getDataSectionSubject.asObservable();

  public transactionSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );
  transactionMaster$: Observable<any> = this.transactionSubject.asObservable();

  public transactionReasonseSubject: BehaviorSubject<TransactionResponceSingle> =
    new BehaviorSubject<TransactionResponceSingle>(null);
  transactionReasonse$: Observable<TransactionResponceSingle> =
    this.transactionReasonseSubject.asObservable();

  public socialResponseSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );
  socialResponse$: Observable<any> = this.socialResponseSubject.asObservable();

  public globalData: GlobalData = {};
  public currentUserData: CurrentUserData = {};
  public accountsData: AccountsDetails = {};
  public resettoken = "";
  public businessStepper: BusinessStepper = {};
  public userProfileData: UserprofileData = {};
  public ledgerDetailsStep1: LedgerDetails = {};
  public ledgerDetailsStep2: LedgerDetails = {};
  public prevRouteForBusinessInformaton;
  public transactionFirstData;
  public transactionSecondData = [];
  public deletedList = [];
  public tr_date;
  public commonForm: FormGroup;
  querparamSubscriber: any;
  queryParamData: any;
  businessname;
  businessStatus;
  isLanguageChange: boolean = true;
  public deleteChatListSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );
  deleteChatList$: Observable<any> = this.deleteChatListSubject.asObservable();
  constructor(
    private toastCtrl: ToastController,
    private ngZone: NgZone,
    private router: Router,
    private route: ActivatedRoute,
    private platform: Platform,
    private authServices: AuthServices,
    private popoverCtrl: PopoverController,
    private getLanguagePipe: GetLanguagePipe,
    private navCtrl: NavController,
    // private socialSharing: SocialSharing,
    private ngNavigatorShareService: NgNavigatorShareService,
    // private fileTransfer: FileTransfer,
    // private file: File,
    private loadingController: LoadingController,
    private appVersion: AppVersion,
    private safariViewController: SafariViewController,
    private http: HttpClient,
    private sanitizer: DomSanitizer
  ) {}

  clearTransactionData() {
    this.transactionFirstData = null;
    this.transactionSecondData = [];
    this.tr_date = "";
    this.deletedList = [];
    this.commonForm = null;
  }

  // messagin toast display
  async presentToast(msg, duration = 3000, position: any = "bottom") {
    const toast = await this.toastCtrl.create({
      message: msg,
      duration: duration,
      position: position,
      color: "dark",
      mode: "ios",
      buttons: [
        {
          text: "Close",
          role: "cancel",
          handler: () => {
            LogData("Close clicked");
          },
        },
      ],
    });
    toast.present();
  }

  async errorToast(msg, duration = 3000, position: any = "bottom") {
    const toast = await this.toastCtrl.create({
      message: msg,
      duration: duration,
      position: position,
      color: "danger",
      mode: "ios",
      buttons: [
        {
          text: "Close",
          role: "cancel",
          handler: () => {
            LogData("Close clicked");
          },
        },
      ],
    });
    toast.present();
  }

  getCoverImageDisplay(data) {
    let imageUrl = COVER_DEFALUT_IMG; // default img
    if (data && data.documents && data.documents.length > 0) {
      const img_obj = data.documents.find(
        (element) =>
          element.display_files &&
          element.display_files.business_cover_photo &&
          element.display_files.business_cover_photo.main_url
      );
      if (img_obj) {
        imageUrl = img_obj.display_files.business_cover_photo.main_url;
      }
    }
    return imageUrl;
  }

  getLogoImageDisplay(data) {
    let imageUrl = LOGO_DEAFLUT_IMG; // default img
    if (data && data.documents && data.documents.length > 0) {
      const img_obj = data.documents.find(
        (element) =>
          element.display_files &&
          element.display_files.business_logo &&
          element.display_files.business_logo.square_url
      );
      if (img_obj) {
        imageUrl = img_obj.display_files.business_logo.main_url;
      }
    }
    return imageUrl;
  }

  getProfileDisplayImg(data) {
    let imageUrl = ""; // default img
    if (data.gender) {
      if (data.gender === MALE_GN) {
        imageUrl = "assets/img/male_us.svg";
      } else if (data.gender === FEMALE_GN) {
        imageUrl = "assets/img/female_us.svg";
      } else {
        imageUrl = "assets/img/def_user.svg";
      }
    } else {
      imageUrl = "assets/img/def_user.svg";
    }
    if (data && data.documents && data.documents.length > 0) {
      const img_obj = data.documents.find(
        (element) =>
          element.display_files &&
          element.display_files.userprofiles_img &&
          element.display_files.userprofiles_img.square_url
      );
      if (img_obj) {
        imageUrl = img_obj.display_files.userprofiles_img.main_url;
      }
    }
    return imageUrl;
  }

  getAddressDisplay(addresssDetails: AddressDetails) {
    let addressStr = "";
    if (addresssDetails) {
      if (
        addresssDetails.villagearea_id &&
        addresssDetails.villagearea_id.length > 0
      ) {
        addressStr = addressStr + addresssDetails.villagearea_id[0].text + ", ";
      }
      if (addresssDetails.village_id && addresssDetails.village_id.length > 0) {
        addressStr = addressStr + addresssDetails.village_id[0].text + ", ";
      }
      if (addresssDetails.taluka_id && addresssDetails.taluka_id.length > 0) {
        addressStr = addressStr + addresssDetails.taluka_id[0].text + ", ";
      }
      if (
        addresssDetails.district_id &&
        addresssDetails.district_id.length > 0
      ) {
        addressStr = addressStr + addresssDetails.district_id[0].text + ", ";
      }
      if (addresssDetails.state_id && addresssDetails.state_id.length > 0) {
        addressStr = addressStr + addresssDetails.state_id[0].text;
      }
      if (addresssDetails.pincode) {
        addressStr = addressStr + "-" + addresssDetails.pincode;
      }
    }
    return addressStr;
  }

  isDRInputType(item) {
    let flag = false;
    if (item.inputtype === INPUTTYPE_DR) {
      flag = true;
    }
    return flag;
  }

  async presentToastNoMoreData(msg, duration = 2000, position: any = "bottom") {
    const toast = await this.toastCtrl.create({
      // message: this.getLanguagePipe.transform(msg),
      message: this.getLanguageValue(msg),
      duration: duration,
      position: position,
      color: "dark",
      mode: "ios",
      cssClass: "toast-pagination",
    });
    toast.present();
  }

  errorHandling(err) {
    if (err.error && err.error.result && err.error.result.error) {
      this.errorToast(err.error.result.error);
    } else if (err.statusText) {
      this.errorToast(err.statusText);
    }
  }

  printFormData(formData: FormData) {
    //  formdata print
    formData.forEach((value, key, parent) => {
      LogData("key", key);
      LogData("value", value);
    });
  }

  reloadApp() {
    setTimeout(() => {
      location.reload();
      // window.location.reload();
    }, 500);
  }

  getLoaderUI() {
    return {
      spinner: null,
      message: '<img src="assets/img/dots1.gif" >',
      cssClass: "custom-loading",
      mode: "ios" as any,
    };
  }

  setValidation(formControl: any, isReq?) {
    // formControl.setValidators(Validators.required);
    formControl.setValidators(isReq ? Validators.required : AlphaNumeric);
    formControl.updateValueAndValidity();
  }

  clearValidation(formControl: any) {
    formControl.clearValidators();
    formControl.updateValueAndValidity();
  }

  async openForSuccessError(isError, msg) {
    const modal = await this.popoverCtrl.create({
      component: SuccessErrorComponent,
      componentProps: { isError: isError, msg: msg, isButtonHide: true },
      cssClass: "custom-popover",
    });

    modal.onDidDismiss().then((data) => {
      LogData("onDidDismiss...", data);
      if (data.data != null) {
      }
    });

    await modal.present();
  }

  getDateTimeFixFormat(date, isTimeOnly?: boolean) {
    if (isTimeOnly) {
      return moment(date).format("hh:mm A");
    } else {
      return moment(date).format("YYYY-MM-DD ");
    }
  }

  isToday(momentDate) {
    // const REFERENCE = moment(momentDate); // fixed just for testing, use moment();
    // const TODAY = REFERENCE.clone().startOf('day');
    // return REFERENCE.isSame(TODAY, 'd');
    const today = new Date();
    const dt1 = moment(today).format("YYYY-MM-DD");
    const dt2 = moment(momentDate).subtract(0, "days").format("YYYY-MM-DD");
    return dt1 === dt2 ? true : false;
  }
  isYesterday(momentDate) {
    // const REFERENCE = moment(momentDate); // fixed just for testing, use moment();
    //  const YESTERDAY = REFERENCE.clone().subtract(1, 'days').startOf('day');
    // return YESTERDAY.isSame(YESTERDAY, 'd');
    const date = new Date();
    const dt1 = moment(date).format("YYYY-MM-DD");
    const dt2 = moment(momentDate).subtract(1, "days").format("YYYY-MM-DD");
    return dt1 === dt2 ? true : false;
  }

  getFilteredList(dataList, selectedItem, firstData, secondData) {
    let filterList: Array<AccCommonData> = dataList;
    let lastIndex;
    if (selectedItem) {
      lastIndex = dataList.findIndex(
        (element) => selectedItem.ledger_id[0].value === element.value
      );
    }
    if (firstData) {
      filterList = dataList.filter(
        (dataEl) => dataEl.value !== firstData.ledger_id[0].value
      );
    }
    if (secondData && secondData.length > 0) {
      if (filterList && filterList.length > 0) {
        const filter_data = [];
        filterList.forEach((element) => {
          const findObj = secondData.find(
            (scEl) => element.value === scEl.ledger_id[0].value
          );
          //LogData("findObj",findObj);

          if (!findObj) {
            filter_data.push(element);
          }
        });
        filterList = filter_data;
        // filterList= filterList.filter((dataEl)=>{
        //   secondData.filter((scEl)=>{
        //     dataEl.value !== scEl.ledger_id[0].value
        //   });
        // });
      } else {
        // filterList= dataList.filter((dataEl)=>{
        //   secondData.filter((scEl)=>{
        //     dataEl.value !== scEl.ledger_id[0].value
        //   });
        // });
        const filter_data = [];
        dataList.forEach((element) => {
          const findObj = secondData.find(
            (scEl) => element.value !== scEl.ledger_id[0].value
          );
          if (findObj) {
            filter_data.push(element);
          }
        });
        filterList = filter_data;
      }
    }
    if (lastIndex !== -1 && selectedItem) {
      filterList.splice(lastIndex, 0, selectedItem.ledger_id[0]);
    }
    return filterList;
  }

  isValidTransaction(firstData, secondData) {
    let flag = false;
    if (firstData && secondData && secondData.length > 0) {
      const f_amt = parseFloat(firstData.amount);
      let s_amt = 0;
      if (secondData.length > 0) {
        secondData.forEach((element) => {
          s_amt = s_amt + parseFloat(element.amount);
        });
        //LogData("f_amt",f_amt);
        //LogData("s_amt",s_amt);
        if (f_amt === s_amt) {
          flag = true;
        }
      }
    }
    return flag;
  }

  getAmountDifference(firstData, secondData) {
    let amount_diff;
    let f_amt = 0;
    let s_amt = 0;
    if (firstData || secondData) {
      if (firstData) {
        f_amt = parseFloat(firstData.amount);
      }
      if (secondData && secondData.length > 0) {
        secondData.forEach((element) => {
          s_amt = s_amt + parseFloat(element.amount);
        });
      }
      let amt: number = f_amt - s_amt;
      if (amt < 0) {
        amount_diff = "- " + "₹" + amt * -1;
      } else {
        amount_diff = "₹" + amt;
      }
    }
    return amount_diff;
  }

  isDesktop() {
    return this.platform.is("desktop");
  }

  isPlatformiOs() {
    return this.platform.is("ios") ? true : false;
  }

  getValidCss(commonForm, isFormSubmit, isNovalidation?) {
    //LogData("loginForm...",commonForm);
    //LogData("isFormSubmit...",isFormSubmit);
    return !isNovalidation
      ? !commonForm.valid && (commonForm.dirty || isFormSubmit)
        ? "invalid-item"
        : commonForm.valid && commonForm.value
        ? "valid-item"
        : "ion-item-bg"
      : commonForm && commonForm.value
      ? "valid-item"
      : "ion-item-bg";
  }

  getIndex(array, selectedId, addIndex?) {
    LogData("array....", array);
    LogData("selectedId....", selectedId);
    let index = 0;
    if (selectedId !== -1) {
      if (addIndex) {
        const idx = array.findIndex(
          (element) =>
            element.value ===
            (isNaN(selectedId) ? selectedId : parseInt(selectedId))
        );
        index = idx + addIndex;
      } else {
        index = array.findIndex(
          (element) =>
            element.value ===
            (isNaN(selectedId) ? selectedId : parseInt(selectedId))
        );
      }
    }

    LogData("index....", index);

    return index;
  }

  setEncryptedData() {}

  getDecryptedData(encrypted) {
    // let encrypted = '{"ct":"hQDvpbAKTGp1mXgzSShR9g==","iv":"57fd85773d898d1f9f868c53b436e28f","s":"a2dac436512077c5"}'
    const password = "G+paSYft#l3el$d&)e@m(!%QoTr^aZ^4";
    const decrypted = CryptoJSAesJson.decrypt(encrypted, password);
    // LogData('getDecryptedData', decrypted);
    return decrypted;
  }

  getDecriptReasponse(res) {
    if (res.resultEnc && res.resEnc === 1) {
      res.result = this.getDecryptedData(res.resultEnc);
    } else if (res.resultEnc) {
      res.result = res.resultEnc;
    }
    return res;
  }

  hasAccess(acessCode) {
    let flag = false;
    if (acessCode) {
      const allPermissions: Array<CommonData> =
        this.authServices.getAllowPermission();

      if (allPermissions && allPermissions.length > 0) {
        const findAcces = allPermissions.find(
          (element) => element.value === acessCode
        );
        if (findAcces) {
          flag = true;
        }
      }
    }
    return flag;
  }

  onlyPositiveNumber(event) {
    // LogData("onlyPositiveNumber",event.target.value);
    event.target.value = event.target.value.replace(/^-?[^0-9\.]/g, "");
  }

  onlyPositiveNumberNoZero(event) {
    // LogData("onlyPositiveNumber",event.target.value);
    // event.target.value = event.target.value.replace(/^(?![,.0]*$)\d{1,4}(?:[.,]\d{1,2})?$/gm, "");
    event.target.value = event.target.value.replace(/^-?[^1-9\.]/g, "");
  }

  isNumericValuExit(str) {
    console.log("isNumericValuExit", str);

    let flag = false;
    let matchPattern = str.match(/\d+/g);
    if (matchPattern != null) {
      console.log("The input string contain numbers");
      flag = true;
    } else {
      console.log("The input string does not contain numbers");
      flag = false;
    }
    return flag;
  }

  onlyNumericNumber(event) {
    // LogData("onlyPositiveNumber",event.target.value);
    event.target.value = event.target.value.replace(/\D/g, "");
  }

  getStatus(status: Array<CommonData>) {
    return status && status.length > 0 ? status[0].value : "";
  }

  setNavigationRoute(route: string, data?: any) {
    if (data) {
      const navigationExtras: NavigationExtras = {
        state: {
          data,
        },
      };
      this.ngZone.run(() => {
        LogData("ngZone....call");

        this.router.navigate([route], navigationExtras);
      });
    } else {
      this.ngZone.run(() => {
        LogData("ngZone....call");
        this.router.navigate([route]);
      });
    }

    // localStorage.setItem("splaseData", JSON.stringify("other"));
  }

  setNavigationRouteQuery(route: string, data) {
    if (data) {
      this.router.navigate([route], { queryParams: data });
    } else {
      this.router.navigate([route]);
    }
  }

  getQueryParamData() {
    this.querparamSubscriber = this.route.queryParams.subscribe((params) => {
      if (!this.isEmptyObj(params)) {
        this.queryParamData = params;
      }
    });
    return this.queryParamData;
  }

  isEmptyObj(obj) {
    return Object.keys(obj).length === 0;
  }

  setNavigationRouteRP(route: string, data?: any) {
    if (data) {
      const navigationExtras: NavigationExtras = {
        skipLocationChange: true,
        state: {
          data,
        },
      };
      this.ngZone.run(() => {
        LogData("ngZone....call");

        this.router.navigate([route], navigationExtras);
      });
    } else {
      this.ngZone.run(() => {
        LogData("ngZone....call");
        this.router.navigate([route], { skipLocationChange: true });
      });
    }

    // localStorage.setItem("splaseData", JSON.stringify("other"));
  }

  getPreviousRoutes() {
    // let prevUrl;
    // this.router.events
    //   .pipe(filter((evt: any) => evt instanceof RoutesRecognized), pairwise())
    //   .subscribe((events: RoutesRecognized[]) => {
    //    LogData('url_events',events);
    //     prevUrl= events[0].urlAfterRedirects;
    //    LogData('previous url', events[0].urlAfterRedirects);
    //    LogData('current url', events[1].urlAfterRedirects);
    //   });

    //   return prevUrl;
    return this.router
      .getCurrentNavigation()
      .previousNavigation.finalUrl.toString();
  }

  setUpdateUser(data) {
    this.userDataSubject.next(data);
    this.authServices.setUserLoginData(data);
  }

  setMenu(menuItem) {
    this.selectedMenuSubject.next(menuItem);
  }

  setUpdateSideMenu(data) {
    this.updateSideMenuSubject.next(data);
  }

  setUpdateuserProfile(data) {
    this.updateUserProfileSubject.next(data);
  }

  setCloseDateTimePicker(data) {
    this.closeDateTimePickerSubject.next(data);
  }

  setMemberIdUpdate(data) {
    this.memberIdUpdateSubject.next(data);
  }

  setUpdatedBusinessList(data) {
    this.updateBusinessListSubject.next(data);
  }

  setUpdatedTradingList(data) {
    this.updateTradingListSubject.next(data);
  }

  setUpdatedProductList(data) {
    this.updateProductListSubject.next(data);
  }

  setUpdatedProductCompneyList(data) {
    this.updateProductCompneyListSubject.next(data);
  }

  setAddressMaster(data) {
    this.addressMasterSubject.next(data);
  }

  setUserProfileDataInSubject(data) {
    this.getUserProfileDataSubject.next(data);
  }

  setUpdateComplainSubjectList(data) {
    this.complaintSubjectListSubject.next(data);
  }

  setTranslationData(data) {
    this.translationListSubject.next(data);
  }

  setSuccessResponse(data) {
    this.isSuccessSubject.next(data);
  }

  setDataSection(data) {
    this.getDataSectionSubject.next(data);
  }

  setTransactionMaster(data) {
    this.transactionSubject.next(data);
  }

  setTransactionReasonse(data) {
    this.transactionReasonseSubject.next(data);
  }

  setSocialResponse(data) {
    this.socialResponseSubject.next(data);
  }

  setDeleteChatList(data) {
    this.deleteChatListSubject.next(data);
  }

  isWeb() {
    return Capacitor.getPlatform() === WEB ? true : false;
  }
  isAndroid() {
    return Capacitor.getPlatform() === ANDROID ? true : false;
  }
  isIos() {
    return Capacitor.getPlatform() === IOS ? true : false;
  }
  getDateTimeFormat(date: string, format: string, isTimeOnly?: boolean) {
    if (isTimeOnly) {
      return moment(date).format(format);
    } else {
      return moment(date).format(format);
    }
  }

  getUserProfileImg(images: Array<UserDocument>) {
    let findObj;
    if (images && images.length > 0) {
      findObj = images.find(
        (element) => element.field === USERPROFILES_IMG_KEY
      );
    }
    return findObj;
  }

  getUserBusinessLogoImg(images: Array<UserDocument>) {
    let findObj;
    if (images && images.length > 0) {
      findObj = images.find(
        (element) => element.field === BUSINESS_LOGO_IMG_KEY
      );
    }
    return findObj;
  }

  getAdvertiseImg(images: Array<UserDocument>) {
    let findObj;
    if (images && images.length > 0) {
      findObj = images.find(
        (element) => element.field === ADVERTISEMENT_PIC_KEY
      );
    }
    return findObj;
  }

  getAdvertiseScheduleImg(images: Array<UserDocument>) {
    let findObj;
    if (images && images.length > 0) {
      findObj = images.find(
        (element) => element.field === ADVSCHEDULES_PIC_KEY
      );
    }
    return findObj;
  }

  getTradingIcon(categoryData) {
    let image_url = "assets/img/trading_d.svg";
    if (
      categoryData &&
      categoryData.documents &&
      categoryData.documents.length > 0 &&
      categoryData.documents[0].display_files &&
      categoryData.documents[0].display_files.tradingtype_icon
    ) {
      if (categoryData.documents[0].display_files.tradingtype_icon.main_url) {
        image_url =
          categoryData.documents[0].display_files.tradingtype_icon.main_url;
      } else if (
        categoryData.documents[0].display_files.tradingtype_icon.square_url
      ) {
        image_url =
          categoryData.documents[0].display_files.tradingtype_icon.square_url;
      }
    }
    return image_url;
  }

  openBusinesListPage(queryParams?) {
    let routeurl = VISITOR_BUSINESS_DIRECTORY_LIST;
    const rollId = this.authServices.getUserRoll();
    switch (rollId) {
      case SUPER_ADMIN_ROLE_ID:
      case ADMIN_ROLE_ID:
        routeurl = ADMIN_TABS_BUSINESS_DIRECTORY_LIST;
        break;
      case BUSINESS_ROLE_ID:
      case CUSTOMER_ROLE_ID:
        routeurl = TABS_BUSINESS_DIRECTORY_LIST;
        break;
      case DEALER_ROLE_ID:
        routeurl = DEALER_TABS_BUSINESS_DIRECTORY_LIST;
        break;
      case STAFFS_ROLL_ID:
        routeurl = STAFF_TABS_BUSINESS_DIRECTORY_LIST;
        break;
    }
    if (queryParams) {
      this.setNavigationRouteQuery(routeurl, queryParams);
    } else {
      this.setNavigationRoute(routeurl);
    }
  }

  getServerError(err) {
    let serverSideError = "";
    err.error = this.getDecriptReasponse(err.error);
    if (err && err.error) {
      const user_error: UserData = err.error;
      serverSideError = user_error.result.error;
    }
    return serverSideError;
  }

  getTranslatedData(key) {
    return this.getLanguagePipe.transform(key);
  }

  getLanguageValue(langKey) {
    const languageData = this.authServices.getLanguageData();
    // LogData("languageData",languageData);
    return languageData && languageData[langKey]
      ? languageData[langKey]
      : langKey;
  }

  getRandomNumber() {
    return Math.floor(Math.random() * 100000 + 1);
  }

  isAccessLadgerCrud(data) {
    return (data && data.is_auto) === 1 ? false : true;
  }

  getFormData(dataModel) {
    const formData = new FormData();
    for (const [key, value] of Object.entries(dataModel)) {
      formData.append(key, "" + value);
    }
    return formData;
  }

  setVilageIdArray(listData: Array<CommonData>) {
    const tempArray = [];
    listData.forEach((element) => {
      tempArray.push(element.value);
    });
    return JSON.stringify(tempArray);
  }

  isBase64(isCropping) {
    return isCropping ? true : false;
    // return (isCropping && !this.utilsService.isWeb()) ? true : false;
    // return true;
  }

  // async shareData(data) {
  //   // await Share.share({
  //   //   title: 'See cool stuff',
  //   //   text: 'Really awesome thing you need to see right meow',
  //   //   url: 'http://ionicframework.com/',
  //   //   dialogTitle: 'Share with buddies',
  //   // });
  //   let predifineTxt = this.getTranslatedData('txt_share_data');
  //   let msg = data.msg ? predifineTxt + " : " + data.msg : null;
  //   let subject = data.msg ? data.subject : null;;
  //   let file = data.msg ? data.file : null;;
  //   let url = data.msg ? data.url : null;
  //   if (!this.isWeb()) {
  //     this.socialSharing.share(msg, subject, file, url).then(() => {
  //       // Sharing via email is possible
  //     }).catch((err) => {
  //       this.errorToast(err);
  //       // Sharing via email is not possible
  //     });
  //   } else {
  //     // navigator.share({
  //     //   'title': 'Share',
  //     //   'text': msg,
  //     //   // 'url': 'http://www.myurl.com'
  //     // }).then(function() {
  //     //   console.log('Successful share');
  //     // }).catch(function(error) {
  //     //   console.log('Error sharing:', error)
  //     // });
  //     // try{
  //     //   const sharedResponse = await this.ngNavigatorShareService.share({
  //     //     title:'Share',
  //     //     text: msg,
  //     //     // url: 'www.codershood.info'
  //     //   });
  //     //   LogData('sharedResponseweb_res',sharedResponse);
  //     // } catch(error) {
  //     //   LogData('sharedResponseweb_error',error);
  //     //   // this.errorToast(error.error ? error.error:error);
  //     // }
  //   }

  // }

  async shareData(data) {
    // let predifineTxt = this.getTranslatedData('txt_share_data');
    let predifineTxt = this.getLanguageValue("txt_share_data");
    let msg = data.msg ? predifineTxt + " : " + data.msg : null;
    let subject = data.msg ? data.subject : null;
    let file = data.msg ? data.file : null;
    let url = data.msg ? data.url : null;
    try {
      await Share.share({
        text: msg,
      });
    } catch (ex) {
      LogData("share_ex", ex);
    }
  }

  openBrowser(url) {
    console.log("openBrowser...", url);

    window.open(url, "_self");
  }

  openPlayStoreLink() {
    const url = this.authServices.getPlaystoreLink();
    if (url) {
      this.openBrowser(url);
    }
  }

  openAppStoreLink() {
    const url = this.authServices.getAppstoreLink();
    if (url) {
      this.openBrowser(url);
    }
  }

  async downloadImage(url, name) {
    const loading = await this.loadingController.create(this.getLoaderUI());
    await loading.present();
    fetch(url)
      .then((resp) => resp.blob())
      .then((blob) => {
        loading.dismiss();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        // the filename you want
        a.download = name;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch((err) => {
        loading.dismiss();
        this.errorToast(err);
      });
  }

  // async downloadFile(pdfURL?) { //name : any file name
  //   const loading = await this.loadingController.create(
  //     this.getLoaderUI()
  //   );
  //   await loading.present();
  //   if(!this.isWeb()){
  //     LogData("loader_start");;
  //     // const pdfURL = "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf";
  //     const index = pdfURL.lastIndexOf("/") + 1;
  //     LogData("index....", index);
  //     const filename = pdfURL.substr(index);
  //     LogData("filename....", filename);
  //     const fileTransfer: FileTransferObject = this.fileTransfer.create();
  //     // const url = pdfURL + filename;
  //     fileTransfer.download(pdfURL, this.file.dataDirectory + filename).then((entry) => {
  //       LogData("entry....", entry);
  //       LogData("loader_dismiss");
  //       loading.dismiss();

  //     }, (error) => {
  //       LogData(error);
  //       this.errorToast(error);

  //     });
  //   }else{
  //     window.open(pdfURL, "_self");
  //   }

  // }

  async getAppversion() {
    return this.platform.is("cordova")
      ? await this.appVersion.getVersionNumber()
      : environment.appVersion;
  }

  openSfariView(userType) {
    this.safariViewController.isAvailable().then((available: boolean) => {
      if (available) {
        this.safariViewController
          .show({
            url: environment.paymentRedirectionUrl,
            hidden: false,
            animated: false,
            transition: "curl",
            enterReaderModeIfAvailable: true,
            tintColor: "#ff0000",
          })
          .subscribe(
            (result: any) => {
              LogData("safari_res", result);
              if (result.event) {
                switch (result.event) {
                  case "opened":
                    LogData("opened");
                    break;
                  case "loaded":
                    LogData("loaded");
                    break;
                  case "closed":
                    LogData("closed");
                    switch (userType) {
                      case BUSINESS_ROLE_ID:
                        this.setNavigationRoute(TAB1_ROUTE);
                        break;
                      case DEALER_ROLE_ID:
                        this.setNavigationRoute(DEALER_TAB1_ROUTE);
                        break;
                      case STAFFS_ROLL_ID:
                        this.setNavigationRoute(STAFF_TAB1_ROUTE);
                        break;
                      case ADMIN_ROLE_ID:
                      case SUPER_ADMIN_ROLE_ID:
                        this.setNavigationRoute(ADMIN_TAB1_ROUTE);
                        break;
                    }
                    break;
                  default:
                    LogData("default");
                    break;
                }
              }

              // if (result.event === 'opened') console.log('Opened');
              // else if (result.event === 'loaded') console.log('Loaded');
              // else if (result.event === 'closed') console.log('Closed');
            },
            (error: any) => {
              LogData("safariview_error", error);
            }
          );
      } else {
        // use fallback browser, example InAppBrowser
      }
    });
  }

  openSfariViewForReg() {
    this.safariViewController.isAvailable().then((available: boolean) => {
      if (available) {
        this.safariViewController
          .show({
            url: environment.paymentRedirectionUrl,
            hidden: false,
            animated: false,
            transition: "curl",
            enterReaderModeIfAvailable: true,
            tintColor: "#ff0000",
          })
          .subscribe(
            (result: any) => {
              LogData("safari_res", result);
              if (result.event) {
                switch (result.event) {
                  case "opened":
                    LogData("opened");
                    break;
                  case "loaded":
                    LogData("loaded");
                    break;
                  case "closed":
                    LogData("closed");
                    break;
                  default:
                    LogData("default");
                    break;
                }
              }

              // if (result.event === 'opened') console.log('Opened');
              // else if (result.event === 'loaded') console.log('Loaded');
              // else if (result.event === 'closed') console.log('Closed');
            },
            (error: any) => {
              LogData("safariview_error", error);
            }
          );
      } else {
        // use fallback browser, example InAppBrowser
      }
    });
  }

  isEmptyData(data) {
    let flag = false;
    if (data instanceof Array) {
      // LogData("Data_Is _Array");
      if (data.length === 0) {
        flag = true;
      }
    } else if (data instanceof Object) {
      // LogData("Data_Is _Object");
      if (Object.keys(data).length === 0) {
        flag = true;
      }
    } else {
      // LogData("Data_Is _NO");
      if (!data) {
        flag = true;
      }
    }
    return flag;
  }

  isInValidFile(fileSizelimit, actualFileObj) {
    let flag = false;
    LogData("actualFileObj", actualFileObj);
    const fsize = actualFileObj.data.size;
    LogData("fsize..", fsize);
    const file = Math.round(fsize / 1024);
    LogData("file..", file);
    const fileType = actualFileObj.data.type;
    LogData("fileType..", fileType);
    if (fileType === MIME_TYPE_JPG || fileType === MIME_TYPE_PNG) {
      if (file > fileSizelimit) {
        LogData("INVALID_FILE_SIZE");
        this.setFileInvalidMessage(fileSizelimit);
        flag = true;
      }
    } else {
      LogData("INVALID_FILE_TYPE");
      this.errorToast(this.getLanguageValue("val_upload_vali_file_extenion"));
      flag = true;
    }

    return flag;
  }

  // setInValidFileExtension(){
  //   // image/jpeg for .jpeg .jpg
  //   // image/png for .png
  //   // application/pdf for .pdf
  // }

  setFileInvalidMessage(fileSizelimit) {
    switch (fileSizelimit) {
      case FILE_SIZE_1MB:
        this.errorToast(
          this.getLanguageValue("val_invalid_photo_size") +
            "&nbsp;1&nbsp;" +
            this.getLanguageValue("lbl_mb")
        );
        break;
      case FILE_SIZE_2MB:
        this.errorToast(
          this.getLanguageValue("val_invalid_photo_size") +
            "&nbsp;2&nbsp;" +
            this.getLanguageValue("lbl_mb")
        );
        break;
      case FILE_SIZE_80KB:
        this.errorToast(
          this.getLanguageValue("val_invalid_photo_size") +
            "&nbsp;80&nbsp;" +
            this.getLanguageValue("lbl_kb")
        );
        break;
    }
  }

  getFullName(item: ReviewDetails, isBusiness?) {
    let displayName = "";
    if (item.sender_id && item.sender_id.length > 0) {
      if (!isBusiness) {
        if (item.sender_id[0].full_name) {
          displayName = item.sender_id[0].full_name;
        } else if (item.sender_id[0].f_name || item.sender_id[0].l_name) {
          if (item.sender_id[0].f_name) {
            displayName = item.sender_id[0].f_name;
          }
          if (item.sender_id[0].l_name) {
            displayName = displayName + " " + item.sender_id[0].l_name;
          }
        }
      } else {
        displayName = item.sender_id[0].business_name;
      }
    }
    return displayName;
  }

  getFullNameUser(userData: UserprofileData) {
    let displayName = "";
    if (userData) {
      let isBusiness =
        userData.user_type && userData.user_type === BUSINESSES_USER_TYPE;
      if (!isBusiness) {
        if (userData.full_name) {
          displayName = userData.full_name;
        } else if (userData.f_name || userData.l_name) {
          if (userData.f_name) {
            displayName = userData.f_name;
          }
          if (userData.l_name) {
            displayName = displayName + " " + userData.l_name;
          }
        }
      } else {
        displayName = userData.business_name;
      }
    }
    return displayName;
  }

  getComAddress(item: ReviewDetails) {
    let displayAddress = "";
    if (item.sender_id && item.sender_id.length > 0) {
      if (
        item.sender_id[0].taluka_id &&
        item.sender_id[0].taluka_id.length > 0
      ) {
        displayAddress =
          displayAddress + item.sender_id[0].taluka_id[0].text + ", ";
      }
      if (
        item.sender_id[0].district_id &&
        item.sender_id[0].district_id.length > 0
      ) {
        displayAddress =
          displayAddress + item.sender_id[0].district_id[0].text + ".";
      }
    }
    return displayAddress;
  }

  getComAddressUser(userData: UserprofileData) {
    let displayAddress = "";
    if (userData) {
      if (userData.taluka_id && userData.taluka_id.length > 0) {
        displayAddress = displayAddress + userData.taluka_id[0].text + ", ";
      }
      if (userData.district_id && userData.district_id.length > 0) {
        displayAddress = displayAddress + userData.district_id[0].text + ".";
      }
    }
    return displayAddress;
  }

  getComImage(documents: Array<UserDocument>) {
    let url = "";
    if (documents && documents.length > 0 && documents[0].display_files) {
      if (documents[0].display_files.business_logo) {
        if (documents[0].display_files.business_logo.square_url) {
          url = documents[0].display_files.business_logo.square_url;
        } else if (documents[0].display_files.business_logo.main_url) {
          url = documents[0].display_files.business_logo.main_url;
        }
      } else if (documents[0].display_files.userprofiles_img) {
        if (documents[0].display_files.userprofiles_img.square_url) {
          url = documents[0].display_files.userprofiles_img.square_url;
        } else if (documents[0].display_files.userprofiles_img.main_url) {
          url = documents[0].display_files.userprofiles_img.main_url;
        }
      }
    }
    return url;
  }

  getComImageUser(userData: UserprofileData) {
    let url = "";
    if (
      userData &&
      userData.documents &&
      userData.documents.length > 0 &&
      userData.documents[0].display_files
    ) {
      if (userData.documents[0].display_files.business_logo) {
        if (userData.documents[0].display_files.business_logo.square_url) {
          url = userData.documents[0].display_files.business_logo.square_url;
        } else if (userData.documents[0].display_files.business_logo.main_url) {
          url = userData.documents[0].display_files.business_logo.main_url;
        }
      } else if (userData.documents[0].display_files.userprofiles_img) {
        if (userData.documents[0].display_files.userprofiles_img.square_url) {
          url = userData.documents[0].display_files.userprofiles_img.square_url;
        } else if (
          userData.documents[0].display_files.userprofiles_img.main_url
        ) {
          url = userData.documents[0].display_files.userprofiles_img.main_url;
        }
      }
    }
    return url;
  }

  getComUserDocments(commentsData) {
    return commentsData &&
      commentsData.sender_id &&
      commentsData.sender_id.length > 0 &&
      commentsData.sender_id[0].documents
      ? commentsData.sender_id[0].documents
      : [];
  }

  initLanguageChange() {
    this.isLanguageChange = false;
    setTimeout(() => {
      this.isLanguageChange = true;
    });
  }

  getChatRoute() {
    const rollId = this.authServices.getUserRoll();
    LogData("rollId", rollId);
    return rollId === SUPER_ADMIN_ROLE_ID || rollId === ADMIN_ROLE_ID
      ? ADMIN_CHAT_ROUTE
      : rollId === STAFFS_ROLL_ID
      ? STAFF_TAB5_ROUTE
      : rollId === DEALER_ROLE_ID
      ? DEALER_TAB5_ROUTE
      : rollId === CUSTOMER_ROLE_ID || rollId === BUSINESS_ROLE_ID
      ? TAB5_ROUTE
      : "";
  }

  getUserImage(userData: any) {
    let userImage = "";
    if (userData && userData.documents && userData.documents.length > 0) {
      let image_data: ImageData;
      if (
        userData.documents[0].field &&
        userData.documents[0].display_files[userData.documents[0].field]
      ) {
        image_data =
          userData.documents[0].display_files[userData.documents[0].field];
        if (image_data.square_url) {
          userImage = image_data.square_url;
        } else if (image_data.main_url) {
          userImage = image_data.main_url;
        }
      }
    }
    return userImage;
  }

  async getDeviceId() {
    const id_ = await Device.getId();
    LogData("getId", id_.uuid);
    this.authServices.setDevicesUUID(id_.uuid);
  }
  async getDeviceName() {
    let deviceName;
    if (Capacitor.isNativePlatform()) {
      const info_ = await Device.getInfo();
      deviceName = info_.name;
    } else {
      deviceName = this.detectBrowserName();
    }
    LogData("deviceName", deviceName);
    this.authServices.setDevicesName(deviceName);
  }
  async getDeviceInfo() {
    const info_ = await Device.getInfo();
    LogData("getDeviceInfo", info_);
    // this.authServices.setDevicesName(info_.name);
  }

  detectBrowserName() {
    const agent = window.navigator.userAgent.toLowerCase();
    LogData("agent", agent);
    switch (true) {
      case agent.indexOf("edge") > -1:
        return "edge";
      case agent.indexOf("opr") > -1 && !!(<any>window).opr:
        return "opera";
      case agent.indexOf("chrome") > -1 && !!(<any>window).chrome:
        return "chrome";
      case agent.indexOf("trident") > -1:
        return "ie";
      case agent.indexOf("firefox") > -1:
        return "firefox";
      case agent.indexOf("safari") > -1:
        return "safari";
      default:
        return "other";
    }
  }

  detectBrowserVersion() {
    var userAgent = navigator.userAgent,
      tem,
      matchTest =
        userAgent.match(
          /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
        ) || [];

    if (/trident/i.test(matchTest[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(userAgent) || [];
      return "IE " + (tem[1] || "");
    }
    if (matchTest[1] === "Chrome") {
      tem = userAgent.match(/\b(OPR|Edge)\/(\d+)/);
      if (tem != null) return tem.slice(1).join(" ").replace("OPR", "Opera");
    }
    matchTest = matchTest[2]
      ? [matchTest[1], matchTest[2]]
      : [navigator.appName, navigator.appVersion, "-?"];
    if ((tem = userAgent.match(/version\/(\d+)/i)) != null)
      matchTest.splice(1, 1, tem[1]);
    return matchTest.join(" ");
  }

  async openBrowserCap(url) {
    if (url) {
      await Browser.open({ url: url });
      Browser.addListener("browserFinished", () => {
        LogData("browserFinished");
      });
    }
  }

  getExtensionByMime(mimeTye) {
    let extension;
    const mime_data = MimeTypesData;
    for (const key in mime_data) {
      // LogData("mime_data[key]",mime_data[key]);
      if (mime_data[key].indexOf(mimeTye) !== -1) {
        extension = key;
      }
    }
    return extension;
  }

  getDocumentType(fileExtension, chatSetting) {
    let documentType;
    for (const key in chatSetting) {
      let value_: DocumentType = chatSetting[key];
      // let result=value_.extension.indexOf(exrtension_);
      // LogData("result",result);
      if (value_.extension.indexOf(fileExtension) !== -1) {
        LogData("value_", value_);
        documentType = value_;
      }
    }
    return documentType;
  }

  isExitExtension(data, fileExtension) {
    let flag = false;
    if (data.indexOf(fileExtension) !== -1) {
      flag = true;
    }
    return flag;
  }

  async downloadImageFromServer(url: string, extension) {
    const loading = await this.loadingController.create(this.getLoaderUI());
    await loading.present();
    this.downloadFile(url).subscribe(async (response) => {
      // LogData("response", response);
      const blob = new Blob([response], { type: "application/octet-stream" });
      const blob_url = window.URL.createObjectURL(blob);
      const fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(blob_url);
      LogData("fileUrl", fileUrl);
      if (Capacitor.isNativePlatform()) {
        try {
          const base64 = (await this.convertBlobToBase64(blob)) as string;
          const file = await Filesystem.writeFile({
            path: extension,
            data: base64,
            directory: FilesystemDirectory.Documents,
          });
          this.presentToast(
            this.getLanguageValue("file_download_successfully")
          );
        } catch (err) {
          this.errorToast(err);
        }
      } else {
        var link = document.createElement("a");
        document.body.appendChild(link); // for Firefox

        link.setAttribute("href", blob_url);
        link.setAttribute("download", extension);
        link.click();
      }
      loading.dismiss();
    }),
      (error) => {
        loading.dismiss();
        this.errorToast(error);
      };
    (success) => {
      loading.dismiss();
    };
  }

  private convertBlobToBase64 = (blob: Blob) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onerror = reject;
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.readAsDataURL(blob);
    });

  downloadFile(url): any {
    // "http://192.168.1.100/amarucity-api/api/downfile/NjQ5/view
    return this.http.get(url, { responseType: "blob" });
  }

  downloadImageFromServer1(url: string) {
    LogData("downloadImageFromServer", url);
    // fhgfhg
    let base64Image = "";
    // let imageUrl =
    //   "http://192.168.1.100/amarucity-api/api/downfile/NjQ4/view";

    this.getBase64ImageFromURL(
      "http://192.168.1.100/amarucity-api/api/downfile/NjQ5/view"
    ).subscribe((base64data) => {
      console.log(base64data);
      base64Image = "data:image/jpg;base64," + base64data;
      // save image to disk
      var link = document.createElement("a");

      document.body.appendChild(link); // for Firefox

      link.setAttribute("href", base64Image);
      link.setAttribute("download", "amcity_file");
      link.click();
    });
  }

  getBase64ImageFromURL(url: string) {
    return Observable.create((observer: Observer<string>) => {
      const img: HTMLImageElement = new Image();
      img.crossOrigin = "Anonymous";
      img.src = url;
      if (!img.complete) {
        img.onload = () => {
          observer.next(this.getBase64Image(img));
          observer.complete();
        };
        img.onerror = (err) => {
          observer.error(err);
        };
      } else {
        observer.next(this.getBase64Image(img));
        observer.complete();
      }
    });
  }

  getBase64Image(img: HTMLImageElement) {
    const canvas: HTMLCanvasElement = document.createElement("canvas");
    canvas.width = img.width;
    canvas.height = img.height;
    const ctx: CanvasRenderingContext2D = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0);
    const dataURL: string = canvas.toDataURL("image/png");

    return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
  }

  async openNotes(ev) {
    const modal = await this.popoverCtrl.create({
      component: NotePopoverComponent,
      componentProps: {
        onlymsgView: true,
        message: this.getLanguageValue("one_char_one_num_one_spe_char"),
      },
      event: ev,
      // cssClass: "custom-popover",
    });

    modal.onDidDismiss().then((data) => {
      LogData("onDidDismiss...", data);
      if (data && data.data) {
      }
    });

    await modal.present();
  }

  isAdvButtonAccess(advertisementData: AdvertisementDetails) {
    const id = this.authServices.getLoginUserId();
    if (advertisementData) {
      if (
        advertisementData.createdby &&
        advertisementData.createdby.length > 0
      ) {
        if (id === advertisementData.createdby[0].value) {
          return true;
        } else if (
          advertisementData.userprofile_id &&
          advertisementData.userprofile_id.length > 0 &&
          advertisementData.userprofile_id[0].value === id
        ) {
          return true;
        } else {
          return false;
        }
      }
    }
    return false;
  }

  onBack() {
    LogData("onBack");
    this.navCtrl.back();
  }
}
