<!-- <ion-app *ngIf="!userData">
  <ion-router-outlet></ion-router-outlet>
</ion-app>

<app-side-menu *ngIf="userData"></app-side-menu>  -->

<!-- <ion-app *ngIf="utilsService.isWeb()">
  <ion-router-outlet></ion-router-outlet>
</ion-app> -->

  <!-- <app-web-side-menu></app-web-side-menu> -->
  <!-- *ngIf="utilsService.isLanguageChange" -->
<app-side-menu ></app-side-menu> 