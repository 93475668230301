import { Component, Input, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { YES } from 'src/app/utils/constant';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss'],
})
export class ConfirmationComponent implements OnInit {

  @Input() msg;
  @Input() icon;
  @Input() isTranslateUsed:boolean=true;
  @Input() isUserProfileCloseMsg:boolean=false;
  @Input() userProfileCloseMsg:boolean=false;
  constructor(private popCtrl:PopoverController) { }

  ngOnInit() {}

  confirm(){
    this.popCtrl.dismiss(YES);
  }

  cancel(){
    this.popCtrl.dismiss();
  }

}
