<ion-item-divider sticky="fixed" class="ion-no-border" mode="md">
  <div class="flex flex-col w-100">
    <div class="top-line">
      <div class="div-line"></div>
    </div>
    <ion-row class="pt-24 pb-4">
      <ion-col size="10" sizeXl="9" class="ion-align-self-center">
        <ion-text class="lbl-bold-18">Image View</ion-text>
      </ion-col>
      <ion-col size="2" sizeXl="3" class="ion-text-center">
        <ion-icon [src]="'ic_close_ro' | getImagePipe" (click)="closeModal()" class="ft-36"></ion-icon>
      </ion-col>
    </ion-row>
  </div>
</ion-item-divider>
<ion-content>
  <div class="flex flex-col h-100 ion-justify-content-center">
    <div>
    <swiper [config]="swipeConfig">
      <ng-template swiperSlide>
        <div class="swiper-zoom-container">
          <img title="" alt="" text="" tappable [src]="images">
          </div>
      </ng-template>
    </swiper>
    </div>
  </div>
</ion-content>