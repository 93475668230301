import { Component, Input, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { TransactionsDetails } from 'src/app/models/transaction-data';

@Component({
  selector: 'app-trans-details-popover',
  templateUrl: './trans-details-popover.component.html',
  styleUrls: ['./trans-details-popover.component.scss'],
})
export class TransDetailsPopoverComponent implements OnInit {

  @Input() title;
  @Input() listData:TransactionsDetails;
  constructor(private popCtrl:PopoverController) { }

  ngOnInit() {}

  closeModal(){
    this.popCtrl.dismiss();
  }

}
