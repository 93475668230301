import { Component, Input, OnInit } from '@angular/core';
import { CommonData } from 'src/app/models/common-data';
import { LogData } from 'src/app/utils/utils';

@Component({
  selector: 'app-according-card',
  templateUrl: './according-card.component.html',
  styleUrls: ['./according-card.component.scss'],
})
export class AccordingCardComponent implements OnInit {
  isExpandWork:boolean=false;
  @Input() dataArray:Array<CommonData>=[];
  constructor() { }

  ngOnInit() {
    LogData("dataArray",this.dataArray);
  }
  onExpand(ev){
    if(ev.detail.value){
     this.isExpandWork=true;
    }else{
      this.isExpandWork=false;
    }
    LogData('isExpandWork',this.isExpandWork)
   }
 
}
