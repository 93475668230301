import { Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss'],
})
export class MessageComponent implements OnInit {
  message1: string;
  message2: string;
  btn1 = "No";
  btn2 = "Yes";
  rollId;
  status;
  isSigleBtn: boolean = false;
  jobAppliicationId;
  backData;
  isSingle:boolean=false;
  constructor(private popoverCtrl:PopoverController) { }

  ngOnInit() {}

  apply() {
    this.popoverCtrl.dismiss("Yes");
  }

  clearData() {
    this.popoverCtrl.dismiss("No");
  }


}
