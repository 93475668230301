import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UtilsService } from 'src/app/services/uitils.services';
import { DATE_TIME_FORMAT_FOR_USER } from 'src/app/utils/constant';
import { LogData } from 'src/app/utils/utils';

@Component({
  selector: 'app-entry-card',
  templateUrl: './entry-card.component.html',
  styleUrls: ['./entry-card.component.scss'],
})
export class EntryCardComponent implements OnInit {

  tr_date = new Date();
  @Input() transactionDetails;
  @Input() uiIndex;
  @Input() firstData;
  @Input() secondData = [];
  @Input() firstLbl;
  @Input() firstTxt;
  @Input() secondLbl;
  @Input() secondTxt;
  @Input() entryData;
  past_date_allow;
  @Output() onClickBtn: EventEmitter<any> = new EventEmitter();
  @Output() onDeleteClick: EventEmitter<any> = new EventEmitter();
  secondDataTotal = 0;
  minDate: Date;
  maxDate: Date;
  deletedList = [];
  constructor(private utilServices:UtilsService) {
    this.maxDate = new Date();
    // this.setPastDateAllow();
  }

  ngOnInit() {

  }

  setPastDateAllow(){
    if(this.past_date_allow || this.past_date_allow === 0) {
      if(this.past_date_allow !== 'all'){
        const d = new Date();
        d.setDate(d.getDate() - this.past_date_allow);
        this.minDate=d;
      }
      
    }
  }

  setInitData() {
    setTimeout(() => {
      LogData("transactionDate...", this.transactionDetails);
      if (this.transactionDetails && this.transactionDetails.tr_date) {
        this.tr_date = new Date(this.transactionDetails.tr_date);
      }
      this.getSecondDataTotal();
    }, 200)

  }

  renderUI(index, data) {
    LogData("index", index);
    LogData("data", data);
    switch (index) {
      case 1:
        this.firstData = data;
        break;
      case 2:
        this.secondData.push(data);
        this.getSecondDataTotal();
        break;
    }
  }

  onEditItem(index, data) {
    LogData("onEditItem", data);
    LogData("onEditItem_idx", data);

    if (index === 2) {
      if (this.secondData && this.secondData.length > 0) {
        const lastIndex = this.secondData.findIndex(
          (element) => data.id === element.id
        )
        if (lastIndex !== -1)
          this.secondData[lastIndex] = data;
      }
      this.getSecondDataTotal();
    } else {
      this.firstData = data;
    }
  }

  deleteEntry(cardIdx, itemIndex?) {
    // this.onDeleteClick.emit();
    if (cardIdx === 1) {
      this.createDeleteList(this.firstData);
      this.firstData = null;
    } else {
      this.createDeleteList(this.secondData[itemIndex]);
      this.secondData.splice(itemIndex, 1);
      this.getSecondDataTotal();
    }
  }

  createDeleteList(item){
    if(item.id){
      this.deletedList.push({ id: item.id, deleted: this.utilServices.getDateTimeFormat(new Date().toISOString(), DATE_TIME_FORMAT_FOR_USER) });
    }
  }

  getSecondDataTotal() {
    LogData("secondData..", this.secondData);

    if (this.secondData && this.secondData.length > 0) {
      this.secondDataTotal = 0;
      this.secondData.forEach(element => {
        if (element.amount) {
          this.secondDataTotal = this.secondDataTotal + parseFloat(element.amount);
        }
      });
    }
  }

  firstClick(item?) {
    this.onClickBtn.emit({ index: 1, item: item });
  }

  secondClick(item?) {
    this.onClickBtn.emit({ index: 2, item: item });
  }

}
