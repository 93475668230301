import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { LogData } from 'src/app/utils/utils';
import { EntryFieldComponent } from '../entry-field/entry-field.component';

@Component({
  selector: 'app-entry-item',
  templateUrl: './entry-item.component.html',
  styleUrls: ['./entry-item.component.scss'],
})
export class EntryItemComponent implements OnInit {

  @Input() enteryItem;
  @Input() isSecond: boolean = false;
  @Output() onEditClick: EventEmitter<any> = new EventEmitter();
  constructor(private popCtrl: PopoverController) { }

  ngOnInit() { }

  isMinusAmt() {
    let flag = false;
    if (this.enteryItem && this.enteryItem.ledger_id && this.enteryItem.ledger_id.length > 0) {
      if(parseFloat(this.enteryItem.ledger_id[0].cl_balance) < 0){
        flag = true;
      } 
    }
    return flag;
  }

  // async editEntry() {
  //   LogData("enteryItem",this.enteryItem);
  //   const modal = await this.popCtrl.create({
  //     component: EntryFieldComponent,
  //     componentProps: {
  //       title: "dg_tl_ban_ent_bank_entry",
  //       lbl1: "lbl_dg_ent_sel_cr_led",
  //       lbl2: "lbl_dg_ent_amt",
  //       btn1: "create",
  //       btn2: "cancel",
  //       selectedData:this.enteryItem
  //     },
  //     cssClass: "custom-popover",
  //     showBackdrop: true,
  //     backdropDismiss: false,
  //     // breakpoints: [0, 0.3, 0.5, 0.8],
  //     // initialBreakpoint: 0.5
  //   });

  //   modal.onDidDismiss().then((data) => {
  //     LogData("onDidDismiss...", data);
  //     if (data.data != null) {
  //       this.enteryItem=data.data;
  //       this.onEditClick.emit(this.enteryItem);
  //     }else{
  //     }
  //   });

  //   await modal.present();
  // }

}
