import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-wallet-data',
  templateUrl: './wallet-data.component.html',
  styleUrls: ['./wallet-data.component.scss'],
})
export class WalletDataComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
