import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { CommonData } from 'src/app/models/common-data';
import { SubjectDetails } from 'src/app/models/complaints';
import { UtilsService } from 'src/app/services/uitils.services';
import { COMPLAIN_SUBJECTS, EDIT_COMPLAIN_SUBJECTS } from 'src/app/utils/routes';
import { LogData } from 'src/app/utils/utils';
@Component({
  selector: 'app-subject',
  templateUrl: './subject.component.html',
  styleUrls: ['./subject.component.scss'],
})
export class SubjectComponent implements OnInit {


  @Input() subjectData:SubjectDetails;
  @Output() getItems: EventEmitter<any> = new EventEmitter();
  @Input() checkedCount;
  @Input() UIType = 1;
  @Input() urlIdx;
  isLongPressClick: boolean = false;
  constructor(private utilsService: UtilsService) { }

  ngOnInit() { }

  setCheckedData() {
    if (this.subjectData.isChecked) {
      this.subjectData.isChecked = false;
    } else {
      this.subjectData.isChecked = true;
    }
    this.getItems.emit(true);
  }

  getStatus(status: Array<CommonData>) {
    return (status && status.length > 0) ? status[0].value : '';
  }

  onClickItem() {

    if (!this.isLongPressClick) {
      if (this.checkedCount === 0) {
        this.utilsService.setNavigationRoute(EDIT_COMPLAIN_SUBJECTS + this.subjectData.id);
      } else {
        this.setCheckedData();
      }
    } else {
      this.isLongPressClick = false;
    }

  }

  onPress($event) {
    LogData("onPress", $event);
    this.isLongPressClick = true;
    this.setCheckedData();
  }

  onPressUp($event) {
    LogData("onPressUp", $event);

  }
  // Controller Functions

}
