import { Injectable } from "@angular/core";
import { Capacitor } from "@capacitor/core";
import { PushNotifications } from "@capacitor/push-notifications";
import { NotificationData } from "../models/notifications";
import {
  FCMTOKEN_LST_KEY,
  MESSAGEDETAILS_PUSH_MODULE,
} from "../utils/constant";
import { BUSINESS_DETAIL, CHAT_CONVERSATION } from "../utils/routes";
import { LogData } from "../utils/utils";
import { AuthServices } from "./auth.services";
import { UtilsService } from "./uitils.services";
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class FcmServices {
  constructor(
    private utilsService: UtilsService,
    private authServices: AuthServices,
    private afMessaging: AngularFireMessaging
  ) {}

  initPush() {
    if (Capacitor.getPlatform() !== "web") {
      this.registerPush();
    }else{
      // this.registerPushForWeb();
    }
  }

  private registerPush() {
    PushNotifications.requestPermissions().then((permission) => {
      if (permission.receive) {
        // Register with Apple / Google to receive push via APNS/FCM
        PushNotifications.register();
      } else {
        // No permission for push granted
      }
    });

    PushNotifications.addListener("registration", (token) => {
      LogData("registration_token: " + JSON.stringify(token.value));
      // this.authServices.setDevicesFcmToken(token.value);
      localStorage.setItem(FCMTOKEN_LST_KEY, JSON.stringify(token.value));
    });

    PushNotifications.addListener("registrationError", (error: any) => {
      LogData("registration_Error: " + JSON.stringify(error));
    });

    PushNotifications.addListener(
      "pushNotificationReceived",
      async (notification) => {
        LogData(
          "pushNotificationReceived_received: " + JSON.stringify(notification)
        );
      }
    );

    PushNotifications.addListener(
      "pushNotificationActionPerformed",
      async (notification) => {
        LogData(
          "pushNotificationActionPerformed: " + JSON.stringify(notification)
        );
        const noti_data: NotificationData = notification.notification.data;
        this.setNotificationDataForRedirection(noti_data);

        // if (data.notiId) {
        //     //   this.router.navigateByUrl(`/home/${data.detailsId}`);
        //     this.utilsService.setNavigationRoute(BUSINESS_DETAIL + "/" + data.notiId);
        //     // this.utilsService.setNavigationRouteQuery(CHAT_CONVERSATION,{id:this.userData.id});
        // }
      }
    );
  }

  setNotificationDataForRedirection(noti_data:NotificationData){
    const sender_id=JSON.parse(noti_data.sender_id);
    const receiver_id=JSON.parse(noti_data.receiver_id);
    LogData("receiver_id",receiver_id);
    LogData("sender_id",sender_id);
    if (noti_data.module) {
      switch (noti_data.module) {
        case MESSAGEDETAILS_PUSH_MODULE:
          if (sender_id.value+"" === ""+this.authServices.getLoginUserId()) {
          
            // this.utilsService.setNavigationRouteQuery(CHAT_CONVERSATION, {
            //   id: receiver_id.value,
            //   title:receiver_id.text,
            //   isPushNotification:true,
            // });
            this.setNavigation(receiver_id);
          } else if (
            receiver_id.value+"" === ""+this.authServices.getLoginUserId()
          ) {
           this.setNavigation(sender_id);
          }
          break;
        default:
          break;
      }
    }
  }

  setNavigation(data){
    if(Capacitor.isNativePlatform()){
      this.utilsService.setNavigationRouteQuery(CHAT_CONVERSATION, {
        id:data.value,
        isPushNotification:true,
      });
    }else{
      let str="";
      if(data.value){
        str=str+"?id"+data.value;
      }
      if(data.text){
        str=str+"&title"+data.text;
      }
       str=str+"&isPushNotification"+true;
      // window.open(environment.webChatRedirect+CHAT_CONVERSATION+str, '_self');
    }
  }

  //For Web
  private registerPushForWeb(){
    //permision
    this.afMessaging.requestPermission.subscribe(
      async permision => {
        LogData("requestPermission_permision",permision);
        if (permision === 'granted') {
          //do something
      }
      else if (Notification.permission === 'default') {
          // $('#allow-push-notification-bar').show();
      }
      },
      async (err) => {
       LogData("requestPermission_permision_err",err);
      }
    );
    this.afMessaging.requestToken.subscribe(
      async token => {
        LogData("requestPermission_token",token);
        localStorage.setItem(FCMTOKEN_LST_KEY, JSON.stringify(token));
      },
      async (err) => {
       LogData("requestPermission_token_err",err);
      }
    );
    ////
    this.afMessaging.messages.subscribe(
      async (noti_data:any) => {
        LogData("getWebPushMessages_res",noti_data);
        this.setNotificationDataForRedirection(noti_data.data);
      },
      async (err) => {
       LogData("getWebPushMessages_err",err);
      }
    );
  }
  
}
