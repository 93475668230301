import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-no-data-found',
  templateUrl: './no-data-found.component.html',
  styleUrls: ['./no-data-found.component.scss'],
})
export class NoDataFoundComponent implements OnInit {

  @Input() message="lbl_no_data_avai";
  @Input() height=80;
  @Input() width;
  @Input() fontSize=25;
  constructor() { }

  ngOnInit() {}

}