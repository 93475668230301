<ion-content>
  <ion-grid class="pl-16 pr-16">

    <ion-row class="center-row mt-16">
      <ion-col style="align-self: center;" class="no-margin-padding" [attrSizeXl]="'8'" appSizeAttribute>
        <div>
          <ion-row>
            <ion-col size="12" class="ion-text-center">
              <ion-img [src]="'otp' | getImagePipe:2" class="img-pad"></ion-img>
            </ion-col>
          </ion-row>
          <ion-row class="mt-16">
            <ion-col class="flex flex-col ion-align-items-center">
              <ion-text class="lbl-bold-25">{{'verify_otp' | getLanguagePipe}}</ion-text>
              <ion-text class="lbl-mm-13 mt-16">{{'please_enter_the_digit_code_sent_to' | getLanguagePipe}}</ion-text>
              <!-- <ion-text class="lbl-mm-13 mt-8">+91 XXXXXXX789  |  SXXXXX01@gmail.com </ion-text> -->
              <ion-text class="lbl-mm-13 mt-8">{{mobile}}  |  {{email}} </ion-text>
            </ion-col>
          </ion-row>
          <ion-row class="mt-24">
            <ion-col class="flex flex-col ion-align-items-center">
              <ng-otp-input #ngOtpInput (onInputChange)="onOtpChange($event)" [config]="config" (keyup.enter)="varyfyOtp(null)"></ng-otp-input>
              <div style="align-self:start;margin-left: 12px;">
                <app-validation *ngIf="isFormSubmit && (!otp || otp.length < 4)"
                  [message]="validationMessages.otp[0].message" [isServerSide]="true">
                </app-validation>
                <app-validation *ngIf="serverSideError" [message]="serverSideError" [isServerSide]="true">
                </app-validation>
              </div>
            </ion-col>
          </ion-row>
          <ion-row class="mt-12">
            <ion-col class="ion-text-start" size="7.5">
              <ion-label *ngIf="timerOn" class="lbl-15">{{'code_expires_in' | getLanguagePipe}}  {{maxtimeEmail}}</ion-label>
            </ion-col>
            <ion-col class="ion-text-end" size="4.5">
              <ion-label [ngClass]="!timerOn ? 'lbl-bold-sec-13 cursor-ptr':'lbl-mm-13'" (click)="resendOTP()" >{{'resend_otp' | getLanguagePipe}}</ion-label>
            </ion-col>
          </ion-row>

          <ion-row class="mt-36">
            <ion-col size="12" class="ion-no-padding">
              <app-ui-cmp [title]="'verify_otp'" (buttonClicked)="varyfyOtp($event)"></app-ui-cmp>
            </ion-col>
          </ion-row>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>

</ion-content>