<!-- 
<div class="trans-card">
  <ion-item class="item-0" lines="none">
    <ion-text slot="end" class="created-by-lbl mb-4" *ngIf="reportData.transaction && reportData.transaction.createdby && reportData.transaction.createdby.length > 0">{{'lbl_creatd_by' | getLanguagePipe}} : {{reportData.transaction.createdby[0].text}}</ion-text>
    <ion-text slot="start" class="created-by-lbl mb-4" *ngIf="reportData.transaction && reportData.transaction.tr_date">{{'lbl_date' | getLanguagePipe}} : {{reportData.transaction.tr_date | dynamicDateTimePipe}}</ion-text>
    </ion-item>
  <ion-item class="item-0 mt-4" lines="none">
    <ion-thumbnail slot="start" class="m-0 ion-align-self-start">
      <ion-img [src]="(isMinusAmt(reportData.amount) ?'rs_minus': 'rs_plus') | getImagePipe"></ion-img>
    </ion-thumbnail>
    <div class="flex flex-col ml-12 w-100">
      <ion-item lines="none" class="item-0">
        <div class="flex flex-col">
          <div class="flex flex-row" *ngIf="reportData.transaction">
            <ion-text class="lbl-sb-12" (click)="openTransaction();$event.stopPropagation();">
              {{reportData.transaction.transaction_no}}</ion-text>
            <ion-icon *ngIf="reportData.transaction && reportData.transaction.is_open_note === 1"
              [src]="'info_note' | getImagePipe" class="ion-align-self-center ml-4 cursor-ptr"
              (click)="openNotes($event);$event.stopPropagation();"></ion-icon>
          </div>
          <ion-text class="plus-amt-ch"
            *ngIf="reportData.transaction && reportData.transaction.createdby && reportData.transaction.createdby.length > 0">
            {{reportData.transaction.createdby[0].text}}</ion-text>
          <ion-text class="lbl-op05-11"
            *ngIf="reportData.transactiondetails && reportData.transactiondetails.length > 0"
            (click)="openListItem(reportData);$event.stopPropagation()">
            {{reportData.transactiondetails[0].ledger_id[0].text}}</ion-text>
        </div>

        <div slot="end" class="flex flex-col ml-4 ion-text-end">
          <ion-text [ngClass]="isMinusAmt(reportData.amount) ? 'minus-amt':'plus-amt'">{{reportData.amount_text}}
          </ion-text>
          <ion-text class="bal-amt">{{'lbl_led_bal' |getLanguagePipe}} : {{reportData.cl_balance_symbol}}</ion-text>
        </div>
      </ion-item>



      <div class="flex flex-col"
        *ngIf="reportData.isExpand && reportData.transactiondetails && reportData.transactiondetails.length > 1">
        <div class="w-100 flex ion-justify-content-end">
          <hr class="divider1 mt-8 mb-8 w-100" />
        </div>
        <div class="w-100">
          <ng-container *ngFor="let item of reportData.transactiondetails;let i=index;">
            <ng-container *ngIf="item.ledger_id && item.ledger_id.length>0">
              <ion-item class="item-0" lines="none">
                <ion-text class="lbl-op05-11">{{item.ledger_id[0].text}}</ion-text>
                <ion-text [ngClass]="utilsService.isDRInputType(item) ?'lbl-dg-13':'lbl-sec-13'" class="ml-4" slot="end"
                  *ngIf="item.amount_text">{{item.amount_text}}</ion-text>
              </ion-item>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>


  </ion-item>
  <ng-container *ngIf="isNarration">
    <div class="flex flex-col" *ngIf="reportData.transaction.narration">
      <div class="w-100 flex ion-justify-content-end">
        <hr class="divider1 mt-8 mb-8 w-100" />
      </div>
      <div class="w-100 flex ion-justify-content-end">
        <ion-item class="item-0 w-100" lines="none">
          <ion-icon slot="start" class="mr-12 avt-20" [src]="'naration' | getImagePipe"></ion-icon>
          <ion-text class="lbl-op05-11">{{reportData.transaction.narration}}</ion-text>
        </ion-item>
      </div>
    </div>
    <div class="flex flex-col" *ngIf="reportData.transaction.narration_approve">
      <div class="w-100 flex ion-justify-content-end">
        <hr class="divider1 mt-8 mb-8 w-100" />
      </div>
      <div class="w-100 flex ion-justify-content-end">
        <ion-item class="item-0 w-100" lines="none">
          <ion-icon slot="start" class="mr-12 avt-20" [src]="'naration' | getImagePipe"></ion-icon>
          <ion-text class="lbl-op05-11">{{reportData.transaction.narration_approve}}</ion-text>
        </ion-item>
      </div>
    </div>
  </ng-container>

</div> -->


<div class="trans-card cursor-ptr">
  <ion-item class="item-0 mt-4" lines="none">
    <ion-thumbnail slot="start" class="m-0 ion-align-self-start avt-36">
      <ion-img [src]="(isMinusAmt(reportData.amount) ?'rs_minus': 'rs_plus') | getImagePipe"></ion-img>
    </ion-thumbnail>
    <div class="flex flex-col ml-12 w-100">
      <ion-item lines="none" class="item-0">
        <ion-label>
          <p class="created-by-lbl" *ngIf="reportData.transaction && reportData.transaction.tr_date">
            {{reportData.transaction.tr_date | dynamicDateTimePipe}}</p>
          <div class="flex flex-row" *ngIf="reportData.transaction">
            <p class="lbl-sb-12" (click)="openTransaction();$event.stopPropagation();">
              {{reportData.transaction.transaction_no}}</p>
            <ion-icon *ngIf="reportData.transaction && reportData.transaction.is_open_note === 1"
              [src]="'info_note' | getImagePipe" class="ion-align-self-center ml-4 cursor-ptr"
              (click)="openNotes($event);$event.stopPropagation();"></ion-icon>
          </div>
        </ion-label>
        <div slot="end" class="flex flex-col ml-4 ion-text-end">
          <ion-text [ngClass]="isMinusAmt(reportData.amount) ? 'minus-amt':'plus-amt'">{{reportData.amount_text}}
          </ion-text>
          <ion-text class="bal-amt">{{'lbl_led_bal' |getLanguagePipe}} : {{reportData.cl_balance_symbol}}</ion-text>
        </div>
      </ion-item>
    </div>
  </ion-item>
  <ng-container *ngIf="reportData.transactiondetails && reportData.transactiondetails.length > 0">
    <hr class="divider1 mt-8 mb-8 w-100" />
  <div class="flex flex-col">
    <ion-text class="plus-amt-ch"
      *ngIf="reportData.transaction && reportData.transaction.createdby && reportData.transaction.createdby.length > 0">
      {{reportData.transaction.createdby[0].text}}</ion-text>
    <ion-text class="lbl-op05-11" *ngIf="reportData.transactiondetails && reportData.transactiondetails.length < 2">
      <b>{{(reportData.transactiondetails[0].inputtype | crdrDisplayPipe) |
        getLanguagePipe}} :&nbsp;</b>{{reportData.transactiondetails[0].ledger_id[0].text}}</ion-text>
      <ion-item lines="none" class="item-0"  (click)="openListItem(reportData);$event.stopPropagation()"
       *ngIf="reportData.transactiondetails && reportData.transactiondetails.length > 1">
        <ion-text class="created-by-lbl-sm mt-4">
          {{'lbl_sh_led'  | getLanguagePipe}}
        </ion-text>
        <ion-icon name="chevron-forward-outline" color="secondary" class="ft-16 mt-0" ></ion-icon>
      </ion-item>

    <ng-container
      *ngIf="reportData.isExpand && reportData.transactiondetails && reportData.transactiondetails.length > 1">
      <div class="w-100 flex ion-justify-content-end">
        <hr class="divider1 mt-8 mb-8 w-100" />
      </div>
      <div class="w-100">
        <ng-container *ngFor="let item of reportData.transactiondetails;let i=index;">
          <ng-container *ngIf="item.ledger_id && item.ledger_id.length>0">
            <ion-item class="item-0" lines="none">
              <ion-text class="lbl-op05-11"> <b>{{(item.inputtype | crdrDisplayPipe) |
                getLanguagePipe}} :&nbsp;</b>{{item.ledger_id[0].text}}</ion-text>
              <ion-text [ngClass]="utilsService.isDRInputType(item) ?'lbl-dg-13':'lbl-sec-13'" class="ml-4"
                slot="end" *ngIf="item.amount_text">{{item.amount_text}}</ion-text>
            </ion-item>
          </ng-container>
        </ng-container>
      </div>
    </ng-container>
  </div>
  </ng-container>
  
  
  <ng-container *ngIf="isNarration">
    <div class="flex flex-col" *ngIf="reportData.transaction.narration">
      <div class="w-100 flex ion-justify-content-end">
        <hr class="divider1 mt-8 mb-8 w-100" />
      </div>
      <div class="w-100 flex ion-justify-content-end">
        <ion-item class="item-0 w-100" lines="none">
          <ion-icon slot="start" class="mr-12 avt-20" [src]="'naration' | getImagePipe"></ion-icon>
          <ion-text class="lbl-op05-11">{{reportData.transaction.narration}}</ion-text>
        </ion-item>
      </div>
    </div>
    <div class="flex flex-col" *ngIf="reportData.transaction.narration_approve">
      <div class="w-100 flex ion-justify-content-end">
        <hr class="divider1 mt-8 mb-8 w-100" />
      </div>
      <div class="w-100 flex ion-justify-content-end">
        <ion-item class="item-0 w-100" lines="none">
          <ion-icon slot="start" class="mr-12 avt-20" [src]="'naration' | getImagePipe"></ion-icon>
          <ion-text class="lbl-op05-11">{{reportData.transaction.narration_approve}}</ion-text>
        </ion-item>
      </div>
    </div>
  </ng-container>
  <ion-text slot="end" class="created-by-lbl-sm mt-4"
  *ngIf="reportData.transaction && reportData.transaction.createdby && reportData.transaction.createdby.length > 0">
  {{'lbl_creatd_by' | getLanguagePipe}} : {{reportData.transaction.createdby[0].text}}</ion-text>
</div>