<app-dg-header [title]="'dg_tl_user_convert_conf'" (onClickClose)="closeModal($event)"></app-dg-header>
<ion-content>
  <ion-grid class="pl-16 pr-16">
    <ion-row class="center-row">
      <ion-col style="align-self: center;" class="no-margin-padding">
        <!-- <ion-row class="line-container">
          <ion-col>
            <div class="top-line">
              <div class="div-line"></div>
            </div>
          </ion-col>
        </ion-row> -->
        <ion-row>

          <ion-col class="mt-12 flex flex-col" >
            <ion-img [src]="icon | getImagePipe" class="hp-50 mt-24"></ion-img>
            <ion-text class="lbl-14 lh-20 mt-24 ion-text-center" *ngIf="msg">{{isTranslateUsed ? (msg | getLanguagePipe):msg}}</ion-text>
          </ion-col>
        </ion-row>
    
        <ion-row class="mt-16 mb-8" *ngIf="!isBusinessName() || !isBusinesslistId()">
          <ion-col [size]="isBusinesslistId () ? '12':'6'" *ngIf="!isBusinessName()">
            <ion-button class="btn-1 hp-45"  expand="block" mode="ios" (click)="openBusinessInfo()">
              <ion-text class="lbl-sb-12">
                {{'btn_business_info' | getLanguagePipe}}
              </ion-text>
            </ion-button>
          </ion-col>
          <!-- <ion-col size="0.4">
            </ion-col> -->
          <ion-col [size]="isBusinessName () ? '12':'6'" *ngIf="!isBusinesslistId()">
            <ion-button class="btn-1 hp-45"  expand="block" mode="ios" (click)="openBusinessType()">
              <ion-text class="lbl-sb-12">
                {{'btn_business_type' | getLanguagePipe}}
              </ion-text>
            </ion-button>
          </ion-col>
        </ion-row>

      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>