import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { AddressDetails } from "src/app/models/address-details";
import { CommonData } from "src/app/models/common-data";
import { UtilsService } from "src/app/services/uitils.services";
import { EDIT_DISTRICT, EDIT_STATE, EDIT_TALUKA, EDIT_VILLAGE, EDIT_VILLAGESAREA } from "src/app/utils/routes";
import { LogData } from "src/app/utils/utils";

@Component({
  selector: "app-address-data",
  templateUrl: "./address-data.component.html",
  styleUrls: ["./address-data.component.scss"],
})
export class AddressDataComponent implements OnInit {
  @Input() addressData: AddressDetails;
  @Output() getItems: EventEmitter<any> = new EventEmitter();
  @Input() checkedCount;
  @Input() uiType;
  @Input() redirectUrl;
  @Input() queryparam;
  isLongPressClick:boolean=false;
  constructor(private utilsService:UtilsService) {}

  ngOnInit() {}

  
  getStatus(status:Array<CommonData>){
    return (status && status.length > 0) ? status[0].value:'';
  }

  onClickItem(){
    // this.setCheckedData();
    LogData("onClickItem",this.isLongPressClick);
    
    if(!this.isLongPressClick){
      if(this.checkedCount === 0){
        if(this.redirectUrl){
          this.utilsService.setNavigationRouteQuery(this.redirectUrl,this.queryparam ? this.queryparam:'');
        }else{
          this.utilsService.setNavigationRoute((this.uiType === 1 ? EDIT_STATE :this.uiType === 2 ? 
            EDIT_DISTRICT:this.uiType === 3 ? EDIT_TALUKA :this.uiType === 4 ? EDIT_VILLAGE :
            this.uiType === 5 ? EDIT_VILLAGESAREA :'')  +this.addressData.id,);
        }
        
      }else{
        this.setCheckedData();
      }
    }else{
      this.isLongPressClick=false;
    }
    

  }

  setCheckedData(){
    if(this.addressData.isChecked){
      this.addressData.isChecked=false;
    }else{
      this.addressData.isChecked=true;
    }
    this.getItems.emit(true);
  }

  onPress($event) {
    LogData("onPress", $event);
    this.isLongPressClick=true;
    this.setCheckedData();
}

onPressUp($event) {
    LogData("onPressUp", $event);

}
}
