import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { LoadingController, PopoverController } from '@ionic/angular';
import { finalize } from 'rxjs/operators';
import { CommonData } from 'src/app/models/common-data';
import { UserprofileData } from 'src/app/models/user-profile-data';
import { UtilsService } from 'src/app/services/uitils.services';
import { UserServices } from 'src/app/services/user.services';
import { getRolesArr, getStatusArr } from 'src/app/utils/constant';
import { LogData } from 'src/app/utils/utils';
import { AddressComponent } from '../address/address.component';

@Component({
  selector: 'app-common-filter',
  templateUrl: './common-filter.component.html',
  styleUrls: ['./common-filter.component.scss'],
})
export class CommonFilterComponent implements OnInit {

  commonForm:FormGroup;
  modelist=[];
  @Input() lblStartDate;
  @Input() lblEndDate;
  @Input() btn1="apply";
  @Input() btn2="clear_all";
  @Input() title="dg_tl_more_filters";
  @Input() isDateField:boolean=false;
  @Input() isStatusField:boolean=false;
  @Input() isModeField:boolean=false;
  @Input() isRoleField:boolean=false;
  @Input() userProfileData:UserprofileData;
  @Input() selectedFilter;
  @Input() pageName;
  roleList: Array<CommonData> = [];
  statusList:Array<CommonData>=[];
  maxDateStart: Date;
  maxDateEnd: Date;
  @ViewChild(AddressComponent, { static: false }) addressComponent: AddressComponent;
  constructor(public utilsService:UtilsService,
    private formBuilder:FormBuilder,
    private popoverCtrl:PopoverController,
    private userServices:UserServices,
    private loadingCtrl:LoadingController) { 
      const currentYear = new Date();
      this.maxDateStart = new Date(currentYear);
      this.maxDateEnd = new Date(currentYear);
    }

  ngOnInit() {
    this.getMasters();
    this.buildForm();
  }

  buildForm() {
    this.commonForm = this.formBuilder.group({
      mode_id: [""],
      role_id:[""],
      start_date:[""],
      end_date:[""],
      status: [""],
    });
  }

  orgValueChange(data){
    LogData("orgValueChange",data.value);
    this.maxDateEnd=data.value;
    this.commonForm.patchValue({end_date:''})
  }
  updateBuildForm(){
    LogData("selectedFilter",this.selectedFilter);
    if(this.selectedFilter){
      if(this.selectedFilter.commonForm){
        this.commonForm.patchValue(this.selectedFilter.commonForm);
      }
    }
  }
  
  closeModal(ev?) {
    this.popoverCtrl.dismiss();
  }

  apply(ev?){
    this.popoverCtrl.dismiss({
      commonForm:this.commonForm.value,
      addressForm:this.addressComponent.commonForm.value.addressDetails[0]
    });
  }

  clearAll(ev?){
    this.addressComponent.clearForm();
    this.buildForm();
    this.utilsService.setUpdatedBusinessList({isClear:true });
    this.closeModal();
  }

  //api call
  async getMasters() {
    const loading = await this.loadingCtrl.create(
      this.utilsService.getLoaderUI()
    );
    await loading.present();
    LogData("loader_start");
    let list = "";
    list = getRolesArr+","+getStatusArr;
    const formData = new FormData();
    formData.append("master", list);
    this.userServices
      .getMasters(formData)
      .pipe(
        finalize(() => {
          LogData("loader_dismiss");
          loading.dismiss();
        })
      )
      .subscribe(
        (res) => {
          LogData("getMasters_res", res);
          res = this.utilsService.getDecriptReasponse(res);
          if (res && res.result && res.result.responce) {
            if (res.result.responce.getRolesArr &&res.result.responce.getRolesArr.length > 0) {
              this.roleList = res.result.responce.getRolesArr;
            }
            if (res.result.responce.getStatusArr && res.result.responce.getStatusArr.length > 0) {
              this.statusList = res.result.responce.getStatusArr;
            } else {
              this.statusList = [];
            }
          } 
          this.updateBuildForm();
        },
        (err) => {
          LogData("loader_dismiss");
          loading.dismiss();
          LogData("getState_error", err);
        }
      );
  }
}
