import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
    name: 'getImagePipe'
})
export class GetImagePipe implements PipeTransform {

    constructor() { }

    transform(imgName: string, imgType?: number): any {
        return "assets/img/"+imgName+this.getExtenation(imgType);
    }

    getExtenation(imgType) {
        return imgType === 1 ? ".jpg":imgType === 2 ? ".png":imgType === 3 ? ".jpeg":".svg";
    }
}
