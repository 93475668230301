<ion-row class="mt-16">
  <ion-col class="p-0">
    <div class="outer-div">
      <div class="flex flex-col inner-div ion-align-items-center">
        <ion-text class="lbl-14 mt-16">{{'lbl_total_pay_amt' | getLanguagePipe}}</ion-text>
        <ion-text class="lbl-med-28 mt-16 mb-16">{{amountDetails.grand_amount_symbol}}</ion-text>
      </div>
    </div>
  </ion-col>
</ion-row>

<ion-row class="mt-16" *ngIf="advertisementsData">
  <ion-col class="p-0">
    <ion-text class="lbl-15">{{'lbl_adv_summary' | getLanguagePipe}}</ion-text>
    <div class="flex flex-col main-srv-div mt-16">
      <ion-text class="lbl-med-14 ion-align-self-start"
        *ngIf="advertisementsData.display && advertisementsData.display.length > 0">
        {{advertisementsData.display[0].text}}</ion-text>
      <ion-text class="lbl-14 mt-8"
        *ngIf="advertisementsData.advsetsdetail_id && advertisementsData.advsetsdetail_id.length >0">
        {{advertisementsData.advsetsdetail_id[0].text}}
      </ion-text>
      <ion-text class="lbl-14 mt-8"
        *ngIf="advertisementsData.advstype && advertisementsData.advstype.length >0">
        {{(advertisementsData.advstype[0].value === CONST_DATA.ADVTYPE_BANNER?'lbl_no_of_month':'lbl_no_of_noti') | getLanguagePipe}} :
        &nbsp;{{advertisementsData.noof}}
      </ion-text>
    </div>
  </ion-col>
</ion-row>

<ion-row class="mt-16">
  <ion-col class="p-0">
    <ion-text class="lbl-15">{{'lbl_pay_summ' | getLanguagePipe}}</ion-text>
    <div class="flex flex-col main-srv-div mt-16">
      <!-- <ion-item lines="none" class="item-min-h-0">
        <ion-text class="lbl-med-14 ion-align-self-start" *ngIf="userprofileData && userprofileData.business_name">
          {{userprofileData.business_name}}</ion-text>
          
      </ion-item> -->
      <!-- <ion-text class="lbl-sb-12">{{amountDetails.amount_symbol}}</ion-text> -->
      <!-- <ion-text class="lbl-sb-12" slot="end" >&#8377;{{amountDetails.subscription_cost}}</ion-text> -->
      <!-- <ng-container *ngIf="amountDetails.discount_amount">
        <ion-item lines="none" class="item-min-h-0">
          <ion-text class="lbl-sb-12" >{{'txt_discount' | getLanguagePipe}}</ion-text>
          <ion-text class="lbl-ro-amt" slot="end">&#8377;{{amountDetails.discount_amount}}</ion-text>
        </ion-item>
        <ion-item lines="none" class="item-min-h-0" *ngIf="amountDetails.amount">
          <ion-text class="lbl-sb-12" >{{'lbl_total' | getLanguagePipe}}</ion-text>
          <ion-text class="lbl-sb-12" slot="end">&#8377;{{amountDetails.amount}}</ion-text>
        </ion-item>
      </ng-container> -->
      <ion-text class="lbl-med-14 ion-align-self-start" *ngIf="userprofileData && userprofileData.business_name">
        {{userprofileData.business_name}}</ion-text>
      <ion-text class="lbl-10 mt-8">
        <span
          *ngIf="userprofileData.villagearea_id && userprofileData.villagearea_id.length > 0">{{userprofileData.villagearea_id[0].text}},&nbsp;</span>
        <span
          *ngIf="userprofileData.village_id && userprofileData.village_id.length > 0">{{userprofileData.village_id[0].text}},&nbsp;</span>
        <span
          *ngIf="userprofileData.taluka_id && userprofileData.taluka_id.length > 0">{{userprofileData.taluka_id[0].text}}.</span>
      </ion-text>
      <div class="srv-div mt-16 mb-16">
        <ion-text class="lbl-sc-11">{{getPrimumLbl() | getLanguagePipe}}</ion-text>
      </div>
      <hr class="dvr-med-05 ml-24 mr-24" />
      <!-- // -->
      <ion-item lines="none" class="item-min-h-0 mt-12 mb-12">
        <ion-text class="lbl-med-14">{{'lbl_service_fee' | getLanguagePipe}}</ion-text>
        <ion-text slot="end" class="lbl-sb-12">&#8377;{{amountDetails.subscription_cost}}</ion-text>
      </ion-item>
      <ng-container *ngIf="amountDetails.discount_amount">
        <ion-item lines="none" class="item-min-h-0 mt-12 mb-12">
          <ion-text class="lbl-med-14">{{'txt_discount' | getLanguagePipe}}</ion-text>
          <ion-text slot="end" class="lbl-ro-amt">&#8377;{{amountDetails.discount_amount}}</ion-text>
        </ion-item>
        <ion-item lines="none" class="item-min-h-0 mt-12 mb-12" *ngIf="amountDetails.amount">
          <ion-text class="lbl-med-14">{{'lbl_sub_total' | getLanguagePipe}}</ion-text>
          <ion-text slot="end" class="lbl-sb-12">&#8377;{{amountDetails.amount}}</ion-text>
        </ion-item>
      </ng-container>
      <!-- // -->
      <ion-item lines="none" class="item-min-h-0 mt-12 mb-12">
        <ion-text class="lbl-med-14">{{'lbl_sgst' | getLanguagePipe}} <span
            *ngIf="amountDetails.gst1_per">({{amountDetails.gst1_per}}%)</span></ion-text>
        <ion-text slot="end" class="lbl-sb-12">{{amountDetails.gst1_amount_symbol}}</ion-text>
      </ion-item>
      <hr class="dvr-med-05 ml-24 mr-24" />
      <ion-item lines="none" class="item-min-h-0 mt-12 mb-12">
        <ion-text class="lbl-med-14">{{'lbl_cgst' | getLanguagePipe}} <span
            *ngIf="amountDetails.gst2_per">({{amountDetails.gst2_per}}%)</span></ion-text>
        <ion-text slot="end" class="lbl-sb-12">{{amountDetails.gst2_amount_symbol}}</ion-text>
      </ion-item>
      <hr class="dvr-med-05 ml-24 mr-24" />
      <ion-item lines="none" class="item-min-h-0 mt-12 mb-12">
        <ion-text class="lbl-med-14">{{'lbl_total' | getLanguagePipe}}</ion-text>
        <ion-text slot="end" class="lbl-sb-12">{{amountDetails.total_amount_symbol}}</ion-text>
      </ion-item>
      <hr class="dvr-med-05 ml-24 mr-24" />
      <ion-item lines="none" class="item-min-h-0 mt-12 mb-12">
        <ion-text class="lbl-med-14">{{'lbl_ro_amt' | getLanguagePipe}}</ion-text>
        <ion-text slot="end" class="lbl-ro-amt">{{amountDetails.rouding_symbol}}</ion-text>
      </ion-item>
      <ion-item lines="none" class="item-min-h-0 mt-12 mb-12">
        <ion-text class="lbl-med-14">{{'lbl_grand_total' | getLanguagePipe}}</ion-text>
        <ion-text slot="end" class="lbl-sb-12">{{amountDetails.grand_amount_symbol}}</ion-text>
      </ion-item>
      <hr class="dvr-med-05 ml-24 mr-24" />
    </div>
  </ion-col>
</ion-row>

<!-- <ion-row class="mt-16">
        <ion-col class="p-0">
          <div class="ref-div mb-8">
            <ion-item lines="none" class="item-min-h-0">
              <ion-input class="lbl-light-13 " placeholder="Enter Referrel ID"></ion-input>
              <ion-button slot="end" class="apply-btn lbl-sb-12" mode="ios">
                Apply
              </ion-button>
            </ion-item>
          </div>
          <ion-text class="lbl-sc-med-13">You are using the referral ID of Fname Laname.
          </ion-text>
        </ion-col>
      </ion-row> -->
<form [formGroup]="commonForm">
  <ion-row class="mt-16">
    <ion-col class="p-0">
      <ion-text class="lbl-15">{{'lbl_tl_gst' | getLanguagePipe}}
      </ion-text>
      <ion-item lines="none" class="no-pad-item mt-4">
        <ion-checkbox formControlName="isgst" class="pl-0 ui-checkbox" (ionChange)="onChangeCheckboxValue($event)">
        </ion-checkbox>
        <ion-label class="lbl-op05-13 ml-12">{{'lbl_is_gst' | getLanguagePipe}}</ion-label>
      </ion-item>
    </ion-col>
  </ion-row>

  <ion-row>
    <ion-col *ngIf="commonForm.get('isgst').value" class="mt-12" size="12">
      <ion-label class="lbl-med-12">{{'enter_gst_number' | getLanguagePipe}} :</ion-label>
      <ion-item lines="none" class="mt-4" [ngClass]="utilsService.getValidCss(commonForm.get('gstno'),isFormSubmit)">
        <ion-input formControlName="gstno">
        </ion-input>
      </ion-item>
      <app-validation [validationMessages]="validationMessages.gst_no" [ValidationForm]="commonForm" [type]="'gstno'"
        [isFormSubmit]="isFormSubmit"></app-validation>
    </ion-col>
  </ion-row>
</form>

<ion-row class="mt-16">
  <ion-col class="p-0">
    <ion-text class="lbl-15">{{'lbl_pay_meth_op' | getLanguagePipe}}
    </ion-text>
    <!-- <div class="pay-div mt-16">
            <ion-item lines="none" class="item-min-h-0">
              <ion-icon [src]="'ic_wallet' | getImagePipe"></ion-icon>
              <ion-text class="lbl-light-13 ml-8">Your wallet balance</ion-text>
              <ion-text class="lbl-sc-med-13" slot="end">540.00</ion-text>
            </ion-item>
          </div> -->
  </ion-col>
</ion-row>