//httpConfig.interceptor.ts
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AuthServices } from './auth.services';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';
import { UtilsService } from './uitils.services';
import { LogData } from '../utils/utils';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {

  constructor(private authServices: AuthServices,
    private utilservices: UtilsService) { }


  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const token = this.authServices.getUserToken();
    const appLang = this.authServices.getLanguage();
    LogData("intercept_token", token);
    LogData("intercept_token", appLang);


    //Authentication by setting header with token value
    if (token && (environment.tokenFlag === 0 || environment.tokenFlag === 2)) {
      request = request.clone({
        setHeaders: {
          'Authorization': "Bearer " + token,
          'AppTimezone': moment().format("YYYY-MM-DD HH:mm:ssZ"),
          'lang': appLang ? appLang.value : 'en'
        }
      });
    } else {
      request = request.clone({
        setHeaders: {
          'AppTimezone':  moment().format("YYYY-MM-DD HH:mm:ssZ"),
          'lang':appLang ? appLang.value : 'en'
        }
      });
    }
    // else{
    //   request = request.clone({
    //     setHeaders: {
    //       'Authorization': "testVVVVerererre dfdfdffd-@#trtrtt"
    //     }
    //   });
    // }

    // if (!request.headers.has('Content-Type')) {
    //   request = request.clone({
    //     setHeaders: {
    //       'content-type': 'application/json'
    //     }
    //   });
    // }

    // request = request.clone({
    //   headers: request.headers.set('Accept', 'application/json')
    // });

    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          LogData('event--->>>', event);
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        console.error("HttpErrorResponse..", error);
        // this.utilservices.presentToast(error.statusText ? error.statusText : "Invalid token");
        // this.utilservices.presentToast(error.statusText);
        return throwError(error);
      }));
  }


}