import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { InputTypeEnum } from 'src/app/enums/inputtypes-enum';
import { UtilsService } from 'src/app/services/uitils.services';
import { SELECT_LIST_ITEM } from 'src/app/utils/constant';
import { LogData } from 'src/app/utils/utils';
import { SingleSearchFilterComponent } from '../single-search-filter/single-search-filter.component';

@Component({
  selector: 'app-amct-input',
  templateUrl: './amct-input.component.html',
  styleUrls: ['./amct-input.component.scss'],
})
export class AmctInputComponent implements OnInit {
  @Input() commonForm:FormGroup  | any;
  @Input() controlExpand: FormGroup | any;
  @Input() controlName;
  @Input() isFormSubmit:boolean=false;
  @Input() maxDate;
  @Input() minDate=new Date();
  @Input() inputType:any;
  @Input() lbl;
  @Input() placeHolder;
  @Input() isValidation:boolean=false;
  @Input() valMessage;
  @Input() isCharCount:boolean=false;
  @Input() rows="4";
  @Input() cols="20";
  @Input() serverSideError;
  @Input() isServerSideError:boolean=false;
  @Input() index;
  @Input() isIndex:boolean=false;
  @Input() dataList=[];
  @Input() isNoMin:boolean=false;
  @Input() isRSSymbol:boolean=true;
  @Input() isDisabled:boolean=false;
  @Input() cstArry: any = [];
  @Input() cstTitle: string = "";
  @Input() cstIndex!: number;
  @Input() prifixIcon: any;
  @Input() suffixIcon: any;
  @Input() isIonicIocns: boolean = false;
  @Input() minLength=10;
  @Input() maxLength=10;
  @Output() onDateTimeValue: EventEmitter<any> = new EventEmitter();
  @Output() onDateValue: EventEmitter<any> = new EventEmitter();
  @Output() onClearDateValue: EventEmitter<any> = new EventEmitter();
  @Output() onTimeValue: EventEmitter<any> = new EventEmitter();
  @Output() onInputChange:EventEmitter<any> = new EventEmitter();
  @Output() onClickSuffixIcon: EventEmitter<any> = new EventEmitter();
  @Output() onMobileBlur: EventEmitter<any> = new EventEmitter();
  @Output() onChangeMobile: EventEmitter<any> = new EventEmitter();
  inputTypeEnum=InputTypeEnum;
  constructor(public utilsService:UtilsService,
    private modalCtrl:ModalController) { }

  ngOnInit() {}

  onDateTimeValueChange(ev){
    // LogData("onDateTimeValueChange..",ev.value);
    this.onDateTimeValue.emit(ev.value);
  }
  onDateValueChange(ev){
    // LogData("onDateValueChange..this.index",this.index);
    this.onDateValue.emit(this.isIndex ? {index:this.index,data:ev.value}:ev.value);
  }

  clearDate(ev){
    this.onClearDateValue.emit(this.isIndex ? {index:this.index,isClear:true}:true);
    
  }

  onTimeValueChange(ev){
    // LogData("onTimeValueChange..",ev.value);
    this.onTimeValue.emit(ev.value);
  }
  onChangeData(ev){
    // LogData("onInputChange_cm",ev);
    this.onInputChange.emit(ev);
  }

  onBlurPhone(ev: any) {
    this.onMobileBlur.emit(true);
  }

  onChangePhone(ev: any) {
    this.onChangeMobile.emit(true);
  }

  openItem() {
    switch (this.inputType) {
      case InputTypeEnum.CST_SELECTION:
        this.openSingleSelection(
          this.cstIndex,
          this.cstArry,
          "Select",
          this.commonForm.get(this.controlName),
          this.commonForm.get(this.controlExpand)
        );
        break;
    }
  }

  async openSingleSelection(
    index: number,
    array: any,
    title: string,
    formControlId: any,
    formControlExpand: any
  ) {
    // LogData("array", array);
    // LogData("ControleName", formControlId);
    // LogData("controlExpand", formControlExpand);
    if (array.length > 0) {
      formControlExpand.setValue(true);
      const modal = await this.modalCtrl.create({
        component: SingleSearchFilterComponent,
        componentProps: {
          title: title,
          selectedData: array,
          selectedId: !this.utilsService.isEmptyData(formControlId.value)
            ? formControlId.value[0].value
            : "",
        },
        cssClass: "custom-modal-filter",
        showBackdrop: true,
        backdropDismiss: false,
        // breakpoints: [0, 0.3, 0.5, 0.8],
        // initialBreakpoint: 0.5
      });

      modal.onDidDismiss().then((data: any) => {
        LogData("onDidDismiss...", data);
        LogData("onDidDismiss...idx", index);
        if (data.data != null) {
          this.onInputChange.emit(data.data);
          if (data.data !== SELECT_LIST_ITEM) {
            const selectedId = data.data.value;
            let tempArr = [];
            tempArr.push(data.data);
            formControlId.setValue(tempArr);
          } else {
            formControlId.setValue("");
          }
        }
        formControlExpand.setValue(false);
      });

      await modal.present();
    } else {
      this.utilsService.presentToastNoMoreData("no_data_found");
    }
  }

  onClickSuffix() {
    this.onClickSuffixIcon.emit();
  }

}
