<ion-item-sliding class="pb-16">
  <ion-item-options side="end" class="pt-4 pb-16">
    <ion-item-option (click)="deleteNotification(notificationData)">
      <ion-icon [src]="'ic_delete_bl' |  getImagePipe" class="ft-20"></ion-icon>
    </ion-item-option>
  </ion-item-options>
  <ion-item lines="none" [ngClass]="notificationData.is_unread === 1  ? 'ion-item-read' : 'ion-item-unread'"
    (click)="onClickPageNavigation();$event.stopPropagation();">
    <ion-avatar slot="start" class="avtart-size" style="margin-right: 10px;">
      <img  [src]="userImage ? userImage:('user_noti' | getImagePipe)"
          [ngClass]="notificationData.is_unread !== 1 ? 'unread-ring' : '' " />
    </ion-avatar>
    <div class="grow flex  flex-col mt-4 w-100">
      <ion-item class="item-0" lines="none">
        <ion-text [ngClass]="notificationData.is_unread === 1  ? 'lbl-sb-14' : 'lbl-sb-op75-14' "
          *ngIf="notificationData.notification_title">
          {{notificationData.notification_title}}</ion-text>
        <div slot="end" class="flex flex-row m-0">
          <div class="dot-time mr-8 ion-align-self-center"></div>
          <ion-text [ngClass]="notificationData.is_unread === 1 ? 'lbl-sb-10' : 'lbl-sb-op75-10' " class="ion-text-end">
            {{notificationData.created |
            dynamicDateTimePipe:1}}
          </ion-text>
        </div>

      </ion-item>
      <ion-text [ngClass]="notificationData.is_unread === 1 ? 'lbl-sb-10' : 'lbl-sb-op75-10'"
        *ngIf="notificationData.notification_text" class="mlines-1">{{notificationData.notification_text}}</ion-text>
        <div *ngIf="isDate" class="flex ion-justify-content-end"><ion-text [ngClass]="notificationData.is_unread === 1 ? 'lbl-sb-10' : 'lbl-sb-op75-10' "
          >{{notificationData.created |
          dynamicDateTimePipe}}</ion-text></div>
      </div>
   
  </ion-item>
</ion-item-sliding>