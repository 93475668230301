import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'arrayToStringPipe'
})
export class ArrayToStringPipe implements PipeTransform {

  transform(data:any[]): any {
    let tempArray=[];
      if(data.length){
          
          data.forEach(element => {
              tempArray.push(element.text);
          });
      }
      // return tempArray.toString();
      return tempArray.join(", ");
   
  }
}
