import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { IonTabs } from '@ionic/angular';
import { AuthServices } from 'src/app/services/auth.services';
import { UtilsService } from 'src/app/services/uitils.services';
import { HOME_ROUTE,PROFILE_ROUTE,SEARCH_ROUTE,NOTIFICATIONS_ROUTE,CHAT_ROUTE } from 'src/app/utils/routes';
import { LogData } from 'src/app/utils/utils';


@Component({
  selector: 'app-tabbar',
  templateUrl: './tabbar.component.html',
  styleUrls: ['./tabbar.component.scss'],
})
export class TabbarComponent implements OnInit {
  selectedTab;
  @ViewChild('tabs', { static: false }) tabs: IonTabs;
  selectedId;
  @Input() tab1=HOME_ROUTE;
  @Input() tab1lbl='home';
  @Input() icTab1='tab1';
  @Input() icTab1s='tab1s';
  @Input() tab2=PROFILE_ROUTE;
  @Input() tab2lbl='profile';
  @Input() icTab2='tab2';
  @Input() icTab2s='tab2s';
  @Input() tab3=SEARCH_ROUTE;
  @Input() tab3lbl='search';
  @Input() icTab3='tab3';
  @Input() icTab3s='tab3s';
  @Input() tab4=NOTIFICATIONS_ROUTE;
  @Input() tab4lbl='notification';
  @Input() icTab4='tab4';
  @Input() icTab4s='tab4s';
  @Input() tab5=CHAT_ROUTE;
  @Input() tab5lbl='chat';
  @Input() icTab5='tab5';
  @Input() icTab5s='tab5s';
  CONST_DATA={
    HOME_ROUTE,PROFILE_ROUTE,SEARCH_ROUTE,NOTIFICATIONS_ROUTE,CHAT_ROUTE
  }

  constructor(private utilServices:UtilsService,
    public authservices:AuthServices) { }

  ngOnInit() {
  }

  setCurrentTab() {
    this.selectedTab = this.tabs.getSelected();
    LogData("setCurrentTab", this.selectedTab);
    this.utilServices.selectedTab=this.selectedTab;
  }

}
