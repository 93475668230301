<ion-grid>
  <ion-row class="center-row">
    <ion-col style="align-self: center" class="no-margin-padding" [attrSizeXl]="'5'" appSizeAttribute>
      <div [ngClass]="isCardBg ? 'bus-details-card':''">
        <form [formGroup]="commonform" class="w-100">
          <ion-row class="ml-8 mr-8">
            <ion-col *ngIf="isTopbar" class="mt-12" size="12">
              <app-name-icon [icon]="'contact_bl'" [lable1]="'lbl_bus_de_tl_con_us'"
                [lable2]="'lbl_bus_de_con_disc'"></app-name-icon>
              <hr class="divider2 w-100 mt-16" />
            </ion-col>
            <ion-col class="mt-12 pl-8 pr-8" size="12">
              <div class="conatct-bg">
                <div class="flex flex-col">
                  <ion-text class="bg-lbl1">{{'txt1_contactus_bg' | getLanguagePipe}}</ion-text>
                  <ion-text class="bg-lbl2 mt-16 w-80">{{'txt2_contactus_bg' | getLanguagePipe}}</ion-text>
                  </div>
                </div>
            </ion-col>
            <ion-col class="mt-12 pl-8 pr-8">
              <ion-label class="lbl-med-12">{{'lbl_enter_your_full_name' | getLanguagePipe}}</ion-label>
              <ion-item lines="none" class="mt-4" [ngClass]="
                  utilsService.getValidCss(commonform.get('name'), isFormSubmit)
                ">
                <ion-input id="ionInput" formControlName="name"> </ion-input>
              </ion-item>
              <app-validation [validationMessages]="validationMessages.name" [ValidationForm]="commonform"
                [type]="'name'" [isFormSubmit]="isFormSubmit"></app-validation>
            </ion-col>

            <ion-col class="mt-12 pl-8 pr-8" size="12">
              <ion-label class="lbl-med-12">{{'enter_your_email_id' | getLanguagePipe}} :</ion-label>
              <ion-item lines="none" class="mt-4" [ngClass]="
                  utilsService.getValidCss(
                    commonform.get('email'),
                    isFormSubmit
                  )
                ">
                <ion-input id="ionInput" formControlName="email"> </ion-input>
              </ion-item>
              <app-validation [validationMessages]="validationMessages.email" [ValidationForm]="commonform"
                [type]="'email'" [isFormSubmit]="isFormSubmit"></app-validation>
            </ion-col>

            <ion-col class="mt-12 pl-8 pr-8" size="12">
              <ion-label class="lbl-med-12">{{'lbl_enter_cont_no' | getLanguagePipe}} :</ion-label>
              <ion-item lines="none" class="mt-4" [ngClass]="
                  utilsService.getValidCss(
                    commonform.get('phone'),
                    isFormSubmit
                  )
                ">
                <ion-input id="ionInput" formControlName="phone" minlength=10 maxlength=10 inputmode="tel" type="text">
                </ion-input>
              </ion-item>
              <app-validation [validationMessages]="validationMessages.mobile_no" [ValidationForm]="commonform"
                [type]="'phone'" [isFormSubmit]="isFormSubmit"></app-validation>
            </ion-col>

            <ion-col class="mt-12 pl-8 pr-8">
              <ion-label class="lbl-med-12">{{'lbl_inq_subject' | getLanguagePipe}}</ion-label>
              <ion-item lines="none" class="mt-4" [ngClass]="
                  utilsService.getValidCss(commonform.get('subject_id_other'), isFormSubmit)
                ">
                <ion-input id="ionInput" formControlName="subject_id_other"> </ion-input>
              </ion-item>
              <app-validation [validationMessages]="validationMessages.inq_subject" [ValidationForm]="commonform"
                [type]="'subject_id_other'" [isFormSubmit]="isFormSubmit"></app-validation>
            </ion-col>

            <ion-col class="mt-12 pl-8 pr-8" size="12">
              <ion-label class="lbl-med-12">{{'lbl_write_your_message' | getLanguagePipe}} :</ion-label>
              <ion-item lines="none" class="mt-4" [ngClass]="
                  utilsService.getValidCss(
                    commonform.get('message'),
                    isFormSubmit)">
                <ion-textarea id="ionInput" formControlName="message">
                </ion-textarea>
              </ion-item>
              <app-character-count *ngIf="commonform.get('message').value" [formValue]="commonform.get('message').value"></app-character-count>
              <app-validation [validationMessages]="validationMessages.message" [ValidationForm]="commonform"
                [type]="'message'" [isFormSubmit]="isFormSubmit"></app-validation>
            </ion-col>
          </ion-row>
          <ion-row class="center-row pl-8 pr-8 pb-16 mt-16">
            <ion-col size="12" class="ion-no-padding" attrSizeXl="5" appSizeAttribute>
              <app-ui-cmp class="btn-1" [title]="'submit'" (buttonClicked)="submit($event)"></app-ui-cmp>
            </ion-col>
          </ion-row>
        </form>
      </div>
    </ion-col>
  </ion-row>
</ion-grid>