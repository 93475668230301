import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { OtherSettings } from 'src/app/models/other-settings';
import { UtilsService } from 'src/app/services/uitils.services';
import { FACEBOOK_LINK, TWITTER_LINK,LINKEDIN_LINK,INSTA_LINK,PINTREST_LINK,YOUTUBE_LINK } from 'src/app/utils/constant';
import { ABOUT_US, PRICING_RT, PRIVACY_POLICY, TERMS_CONDITION,VISITORS_TAB } from 'src/app/utils/routes';

@Component({
  selector: 'app-footer-card',
  templateUrl: './footer-card.component.html',
  styleUrls: ['./footer-card.component.scss'],
})
export class FooterCardComponent implements OnInit {

  CONST_DATA={
    PRIVACY_POLICY,TERMS_CONDITION,ABOUT_US,VISITORS_TAB,
    FACEBOOK_LINK,TWITTER_LINK,LINKEDIN_LINK,INSTA_LINK,PINTREST_LINK,PRICING_RT,YOUTUBE_LINK
  };
  @Output() onClickFooterLogo: EventEmitter<any> = new EventEmitter();
  @Input() otherSettings:OtherSettings;
  constructor(public utilsService:UtilsService) { }

  ngOnInit() {}

  logoClick(){
    // this.onClickFooterLogo.emit(true)
    this.utilsService.setMenu(true);
  }

  

}
