<app-dg-header [title]="title" (onClickClose)="closeModal($event)"></app-dg-header>
<ion-content>
  <hr class="divider1 w-100" />
  <form [formGroup]="commonForm">
    <ion-grid class="pl-16 pr-16">
      <ion-row class="center-row">
        <ion-col style="align-self: center" class="no-margin-padding">

          <ion-col *ngIf="isInqueryForField" class="mt-12" [attrSizeXl]="'6'" appSizeAttribute>
            <ion-label class="lbl-med-12">{{'dg_lbl_inquiries_for' | getLanguagePipe}} :</ion-label>
            <ion-item class=" mt-8" lines="none"
              [ngClass]="utilsService.getValidCss(commonForm.get('inquiry_for'),false,true)"
              (click)="openSingleSelection(0,inquryForList,'select',commonForm.get('inquiry_for'),commonForm.get('inquiry_for_expand'))">
              <ion-text slot="start" class="lbl-med-15">
                {{(commonForm.value.inquiry_for && commonForm.value.inquiry_for.length >
                0)?commonForm.value.inquiry_for[0].text:''}}
              </ion-text>
              <ion-icon [name]="commonForm.value.inquiry_for_expand ? 'chevron-up-outline':'chevron-down-outline'"
                class="ft-20 cursor-ptr" slot="end">
              </ion-icon>
            </ion-item>
          </ion-col>

          <ion-col *ngIf="isBusinessField" class="mt-12" [attrSizeXl]="'6'" appSizeAttribute>
            <ion-label class="lbl-med-12">{{'lbl_add_compl_bus_name' | getLanguagePipe}} :</ion-label>
            <ion-item class=" mt-8" lines="none"
              [ngClass]="utilsService.getValidCss(commonForm.get('userprofile_id'),false,true)"
              (click)="openSingleSelection(1,businessList,'dg_tl_sel_bus',commonForm.get('userprofile_id'),commonForm.get('userprofile_id_expand'))">
              <ion-text slot="start" class="lbl-med-15">
                {{(commonForm.value.userprofile_id && commonForm.value.userprofile_id.length >
                0)?commonForm.value.userprofile_id[0].text:''}}
              </ion-text>
              <ion-icon [name]="commonForm.value.userprofile_id_expand ? 'chevron-up-outline':'chevron-down-outline'"
                class="ft-20 cursor-ptr" slot="end">
              </ion-icon>
            </ion-item>
          </ion-col>

          <ion-row *ngIf="!isHideField">
            <ion-col class="mt-12" size="6">
              <ion-label class="lbl-med-12">{{lblStartDate | getLanguagePipe}} :</ion-label>
              <ion-item lines="none" class="mt-4"
                [ngClass]="utilsService.getValidCss(commonForm.get('start_date'),false,true)">
                <mat-form-field class="w-100 mtn-8">
                  <input matInput class="lbl-med-15" (click)="pickerStart.open()" readonly [max]="maxDateStart"
                    [matDatepicker]="pickerStart" formControlName="start_date" (dateChange)="orgValueChange($event)">
                  <mat-datepicker-toggle matSuffix [for]="pickerStart">
                    <mat-icon matDatepickerToggleIcon>
                      <ion-icon [src]="'cal' | getImagePipe" class="ft-20"></ion-icon>
                    </mat-icon>
                  </mat-datepicker-toggle>
                  <mat-datepicker #pickerStart></mat-datepicker>
                </mat-form-field>
              </ion-item>
            </ion-col>
            <ion-col class="mt-12" size="6">
              <ion-label class="lbl-med-12">{{ lblEndDate | getLanguagePipe}} :</ion-label>
              <ion-item lines="none" class="mt-4"
                [ngClass]="utilsService.getValidCss(commonForm.get('end_date'),false,true)">
                <mat-form-field class="w-100 mtn-8">
                  <input matInput class="lbl-med-15" (click)="pickerEnd.open()" readonly [min]="maxDateEnd"
                    [matDatepicker]="pickerEnd" formControlName="end_date">
                  <mat-datepicker-toggle matSuffix [for]="pickerEnd"
                    [disabled]="(!commonForm.value.start_date) ? true:false">
                    <mat-icon matDatepickerToggleIcon>
                      <ion-icon [src]="'cal' | getImagePipe" class="ft-20"></ion-icon>
                    </mat-icon>
                  </mat-datepicker-toggle>
                  <mat-datepicker #pickerEnd></mat-datepicker>
                </mat-form-field>

              </ion-item>
            </ion-col>
          </ion-row>

          <ion-col *ngIf="isAssignToField" class="mt-12" [attrSizeXl]="'6'" appSizeAttribute>
            <ion-label class="lbl-med-12">{{'dg_lbl_complain_asign_to' | getLanguagePipe}} :</ion-label>
            <ion-item class=" mt-8" lines="none"
              [ngClass]="utilsService.getValidCss(commonForm.get('assign_userprofile_id'),false,true)"
              (click)="openSingleSelection(2,staffList,'select',commonForm.get('assign_userprofile_id'),commonForm.get('assign_userprofile_id_expand'))">
              <ion-text slot="start" class="lbl-med-15">
                {{(commonForm.value.assign_userprofile_id && commonForm.value.assign_userprofile_id.length >
                0)?commonForm.value.assign_userprofile_id[0].text:''}}
              </ion-text>
              <ion-icon
                [name]="commonForm.value.assign_userprofile_id_expand ? 'chevron-up-outline':'chevron-down-outline'"
                class="ft-20 cursor-ptr" slot="end">
              </ion-icon>
            </ion-item>
          </ion-col>

          <ion-col *ngIf="!isHideField" class="mt-12" size="12">
            <ion-label class="lbl-med-12">{{ 'status' | getLanguagePipe }} :</ion-label>
            <ion-item class="mt-8" [ngClass]="utilsService.getValidCss(commonForm.get('status'),false,true)"
              lines="none">
              <ion-select interface="popover" formControlName="status">
                <ion-select-option [value]="item.value" *ngFor="let item of statusList">{{ item.text }}
                </ion-select-option>
              </ion-select>
            </ion-item>
          </ion-col>
          <app-dg-buttons [btn1]="btn1" [btn2]="btn2" (onClickCloseBtn1)="apply($event)"
            (onClickCloseBtn2)="clearAll($event)">
          </app-dg-buttons>
        </ion-col>
      </ion-row>
    </ion-grid>
  </form>
</ion-content>