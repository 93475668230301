<ion-item lines="none">
  <ion-avatar class="avt-88">
    <div class="container">
      <ion-img [src]="(isOdd ? 'el_ro_dot_sc':'el_ro_dot_pr') | getImagePipe"></ion-img>
      <div class="centered">
        <ion-text class="lbl-bold-wh-16">{{index + 1}}</ion-text>
      </div>
    </div>
  </ion-avatar>
  <div class="flex flex-col feture-div">
    <ion-text class="feature-lbl">{{benefitData.title | getLanguagePipe}}</ion-text>
    <ion-text class="feature-disc">{{benefitData.discription | getLanguagePipe}}</ion-text>
  </div>
</ion-item>