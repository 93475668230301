
<div class="flex flex-col" (click)="onItemClick.emit()">
  <div class="container cursor-ptr">
    <img [src]="(isError ? 'round_error':'round_success') | getImagePipe" class="img-cls" />
    <ion-icon
      [name]="icon"
      class="centered"
      size="large"
    ></ion-icon>
  </div>
  <ion-label class="label-5">{{lbl | getLanguagePipe}}</ion-label>
</div>