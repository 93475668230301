import { Pipe, PipeTransform } from '@angular/core';
import { Platform } from '@ionic/angular';

@Pipe({
  name: 'platformInputPipe'
})
export class PlatformInputPipe implements PipeTransform {

  constructor(private platform: Platform) {
  }
  transform(data): any {
    return this.platform.is('ios') ? 'text' : 'number';
  }
}
