<ion-content class="bg-white">
  <div style="padding: 24px">
    <ion-row>
      <ion-col>
        <ion-label class="label-7-bold">{{'lbl_upload' | getLanguagePipe}}</ion-label>
      </ion-col>
    </ion-row>
    <ion-row class="ion-margin-top">
      <ion-col class="ion-text-center" size="4"  *ngIf="document">
        <app-icon-ui [lbl]="'lbl_remove'" [icon]="'trash-outline'" (onItemClick)="removePhoto()"></app-icon-ui>
      </ion-col>
      <ion-col
      size="4"
        class="ion-text-center"
        *ngIf="isUploadDocument"
      >
        <app-icon-ui [lbl]="'lbl_document'" [icon]="'document-outline'" (onItemClick)="uploadDocument()"></app-icon-ui>

      </ion-col>
      <ion-col
      size="4"
        class="ion-text-center"
        *ngIf="isUploadVideo"
      >
        <app-icon-ui [lbl]="'lbl_audio_video'" [icon]="'videocam-outline'" (onItemClick)="openVideo()"></app-icon-ui>

      </ion-col>
      <ion-col size="4" class="ion-text-center" (click)="openGallary()" *ngIf="!isMultipleImage">
        <app-icon-ui [lbl]="'lbl_gallery'" [icon]="'image-outline'" (onItemClick)="openGallary()"></app-icon-ui>

      </ion-col>
      <ion-col size="4" class="ion-text-center" *ngIf="isMultipleImage">
        <app-icon-ui [lbl]="'lbl_photos'" [icon]="'images-outline'" (onItemClick)="pickImages()"></app-icon-ui>

      </ion-col>
      <ion-col size="4" class="ion-text-center">
        <app-icon-ui [lbl]="'lbl_camera'" [icon]="'camera-outline'" (onItemClick)="openCamera()"></app-icon-ui>
      </ion-col>
    </ion-row>
    <!-- <div class="ion-margin-top ion-justify-content-between flex flex-row">
      <app-icon-ui *ngIf="document" [lbl]="'lbl_remove'" [icon]="'trash-outline'" (onItemClick)="removePhoto()"></app-icon-ui>
      <app-icon-ui *ngIf="isUploadDocument" [lbl]="'lbl_document'" [icon]="'document-outline'" (onItemClick)="uploadDocument()"></app-icon-ui>
      <app-icon-ui *ngIf="isUploadVideo" [lbl]="'lbl_audio_video'" [icon]="'videocam-outline'" (onItemClick)="openVideo()"></app-icon-ui>
      <app-icon-ui *ngIf="!isMultipleImage" [lbl]="'lbl_gallery'" [icon]="'image-outline'" (onItemClick)="openGallary()"></app-icon-ui>
      <app-icon-ui *ngIf="isMultipleImage" [lbl]="'lbl_photos'" [icon]="'images-outline'" (onItemClick)="pickImages()"></app-icon-ui>
      <app-icon-ui [lbl]="'lbl_camera'" [icon]="'camera-outline'" (onItemClick)="openCamera()"></app-icon-ui>
    </div> -->
  </div>
</ion-content>
