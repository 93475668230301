import { Component, Input, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { TransactionsDetails } from 'src/app/models/transaction-data';
import { UtilsService } from 'src/app/services/uitils.services';
import { DR_KEY } from 'src/app/utils/params-data';
import { TRANSACTION_ROUTE } from 'src/app/utils/routes';
import { LogData } from 'src/app/utils/utils';
import { NotePopoverComponent } from '../note-popover/note-popover.component';
import { TransDetailsPopoverComponent } from '../trans-details-popover/trans-details-popover.component';

@Component({
  selector: 'app-report-item',
  templateUrl: './report-item.component.html',
  styleUrls: ['./report-item.component.scss'],
})
export class ReportItemComponent implements OnInit {

  @Input() isNarration:boolean=false;
  @Input() reportData:TransactionsDetails;
  CONST_DATA={
    DR_KEY
  };
  constructor(private popCtrl:PopoverController,
    public utilsService:UtilsService) { }

  ngOnInit() {}

  openListItem(item) {
    LogData("openListItem",item);
    if(item.transactiondetails && item.transactiondetails.length > 1){
      if(item.isExpand){
        item.isExpand=false;
      }else{
        item.isExpand=true;
      }
    }
    
  }

  isMinusAmt(amount) {
    let flag = false;
    if(parseFloat(amount) < 0){
      flag = true;
    } 
    return flag;
  }

  // isOpenNotes(){
  //   let flag=false;
  //   if(this.reportData.transaction && this.reportData.transaction.cheque_status && this.reportData.transaction.cheque_status.length > 0 
  //     && this.reportData.transaction.cheque_status[0].value === 0 && this.reportData.transaction.payment_mode && 
  //     this.reportData.transaction.payment_mode.length > 0 && this.reportData.transaction.payment_mode[0] === 3){
  //       flag=true;
  //     }
  //     return flag;
  // }

  openTransaction() {
    if(this.reportData && this.reportData.transaction && this.reportData.transaction.is_approve_payment === 1){
      this.utilsService.setNavigationRoute(TRANSACTION_ROUTE+'/'+this.reportData.transaction.id);
    }
    // this.utilsService.setNavigationRoute(TRANSACTION_ROUTE+'/'+this.reportData.id);
  }

  async openNotes(ev) {
    const modal = await this.popCtrl.create({
      component: TransDetailsPopoverComponent,
      componentProps: { listData:this.reportData },
      event: ev,
      cssClass: "custom-popover",
    });

    modal.onDidDismiss().then((data) => {
      LogData("onDidDismiss...", data);
      if (data && data.data) {
      }
    });

    await modal.present();
  }

  

}
