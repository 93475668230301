import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonData } from 'src/app/models/common-data';
import { UtilsService } from 'src/app/services/uitils.services';
import { LogData } from 'src/app/utils/utils';
import * as moment from 'moment';
import { MatDatepicker } from '@angular/material/datepicker';
import { ValidationMessages } from 'src/app/validators/validations';

@Component({
  selector: 'app-business-hours',
  templateUrl: './business-hours.component.html',
  styleUrls: ['./business-hours.component.scss'],
})
export class BusinessHoursComponent implements OnInit {
  commonForm: FormGroup;
  @Input() businessHoursList: Array<CommonData>;
  // @Input() timeStartList: Array<CommonData>=[];
  // @Input() timeEndList:Array<CommonData>=[];
  @Input() businessTimings:Array<CommonData>=[];
  @Input() isFormSubmit:boolean=false;
  validationMessages=ValidationMessages;
  constructor(private formBuilder: FormBuilder,
    public utilServices:UtilsService) { }

  ngOnInit() {
    this.buildCommonFrom();
  }

  buildCommonFrom() {
    this.commonForm = this.formBuilder.group({
      commonFormArray: this.formBuilder.array([]),
    });
    
    LogData("commonForm...1", this.commonForm.value.commonFormArray);
    const commonform = this.commonForm.get('commonFormArray') as FormArray;
    this.businessHoursList.forEach(element => {
      commonform.push(this.createCommonForm(element));
    });
    LogData("commonForm...2", this.commonForm.value.commonFormArray);

  }

  // createCommonForm(data?: CommonData): FormGroup {
  //   return this.formBuilder.group({
  //     n: [(data && data.n !== -1) ? data.n : ""],
  //     d: [(data && data.d) ? data.d : ""],
  //     t: [(data && data.t) ? data.t : ""],
  //     v: [(data && data.v) ? data.v : ""],
  //     times: [(data && data.times) ? moment(data.times, 'hh:mm A').toISOString() : this.setZeroHours()],
  //     timee: [(data && data.timee) ? moment(data.timee, 'hh:mm A').toISOString() : this.setZeroHours()],
  //     isChecked: [(data && data.v === 1) ? true : false]

  //   });
  // }

  createCommonForm(data?: CommonData): FormGroup {
    return this.formBuilder.group({
      n: [(data && data.n !== -1) ? data.n : ""],
      d: [(data && data.d) ? data.d : ""],
      t: [(data && data.t) ? data.t : ""],
      v: [(data && data.v) ? data.v : ""],
      // times: [(data && data.times) ? data.times:"" ,Validators.required],
      // timee: [(data && data.timee) ? data.timee:"",Validators.required],
      times: [(data && data.times) ? data.times:"" ],
      timee: [(data && data.timee) ? data.timee:""],
      isChecked: [(data && data.v === 1) ? true : false]

    });
  }

  setZeroHours(){
    const _date=new Date();
    _date.setHours(0);
    _date.setMinutes(0);
    _date.setSeconds(0);
    return new Date(_date).toISOString();
  }
  
  get commonFormArray(): FormArray {
    return this.commonForm.get('commonFormArray') as FormArray;
  }

  buildForm() {
    this.commonForm = this.formBuilder.group({
      mondayStartHour: [new Date().toISOString()],
      mondayEndHour: [new Date().toISOString()],
      isMonday: [false],

      tuesdayStartHour: [new Date().toISOString()],
      tuesdayEndHour: [new Date().toISOString()],
      isTuesday: [false],

      wednesdayStartHour: [new Date().toISOString()],
      wednesdayEndHour: [new Date().toISOString()],
      isWednesday: [false],

      thrusDayStartHour: [new Date().toISOString()],
      thrusDayEndHour: [new Date().toISOString()],
      isThrusDay: [false],

      fridayStartHour: [new Date().toISOString()],
      fridayEndHour: [new Date().toISOString()],
      isFriday: [false],

      saturdayStartHour: [new Date().toISOString()],
      saturdayEndHour: [new Date().toISOString()],
      isSaturday: [false],

      sundayStartHour: [new Date().toISOString()],
      sundayEndHour: [new Date().toISOString()],
      isSunday: [false]
    });

  }

}
