<!-- <ion-row class="ml-12 mr-12">
  <ion-col class="pad-chat">
    <ion-item lines="none">
      <ion-avatar slot="start" class="m-0 ion-align-self-end">
        <ion-img src="assets/img/user.png">
        </ion-img>
      </ion-avatar>
      <div class="opp-user pl-16 pr-16 pt-12 pb-12">
        <ion-label>
          <p class="lbl-opp ion-text-wrap mt-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
          <p class="lbl-time-opp ion-text-end">10:45</p>
        </ion-label>
      </div>
    </ion-item>

    <div style="display: flex;float: right;" class="mt-16">
      <ion-item lines="none">
        <div class="avatar">
        </div>
        <div class="login-user pl-12 pr-12 pt-8 pb-8">
          <ion-label>
            <p class="lbl-med-14 ion-text-wrap mt-8 ml-8 mr-8 ls-01">
              He Creates atmoshphere have do</p>
            <p class="lbl-med-op75-12 ion-text-start ml-8">11:05</p>
          </ion-label>
        </div>
      </ion-item>
    </div>
  </ion-col>
</ion-row> -->

<!-- Right -->
<!-- <ion-row class="pl-16 pr-16">
  <ion-col size="2" class="ion-align-self-end" style="padding-bottom: 20px;">
    <ion-avatar style="height: 36px;width: 36px;margin-top: -16px;">
      <ion-img src="assets/img/user.png">
      </ion-img>
    </ion-avatar>
  </ion-col>
  <ion-col size="10">
    <div class="chat left w-ft" style="background: #eff9fd;">
      <div class="pl-16 pr-16 pt-12 pb-12">
        <ion-label>
          <p class="lbl-opp ion-text-wrap mt-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
          <p class="lbl-time-opp ion-text-end">10:45</p>
        </ion-label>
      </div>
    </div>
  </ion-col>
</ion-row> -->
<div class="container cursor-ptr">
  <div (press)="onPress($event)" (pressup)="onPressUp($event)" (click)="onClickItem();$event.stopPropagation()">
    <ion-row class="ml-12 mr-12">
      <ion-col class="pad-chat">
        <!-- Opp user -->
        <div class="flex flex-row" *ngIf="authServices.getLoginUserId() !== this.messageData.sender_id">
          <div class="ion-align-self-end" style="padding-bottom: 20px">
            <ion-avatar style="height: 36px; width: 36px; margin-top: -16px">
              <ion-img *ngIf="!messageData.isChecked" [src]="userImage ? userImage:('def_user' | getImagePipe)">
              </ion-img>
              <ion-icon [src]="'ic_sel' | getImagePipe" class="ft-24 opp-user-ch-icon"
                *ngIf="messageData.isChecked"></ion-icon>
            </ion-avatar>
          </div>
          <div class="chat left w-ft ml-8" style="background: #eff9fd">
            <div class="pl-8 pr-8 pt-4 pb-4">
              <!-- <ng-container *ngIf="isDocument();else noDoc">
                <ng-container *ngIf="isImage();else noImage">
                  <img [src]="getImage() ? getImage():('img_doc' | getAddressStrPipe)" />
                </ng-container>
                <ng-template #noImage>
                  <app-chat-doc [fileName]="messageData.documents[0].messagedetail_doc"></app-chat-doc>
                </ng-template>
              </ng-container>
              <ng-template #noDoc>
                <ion-label>
                  <p class="lbl-opp ion-text-wrap mt-4 mb-4 ion-text-start">
                    <span *ngIf="userMsg.length < 400;else overData">{{userMsg}}</span>
                    <ng-template #overData>
                      <app-read-more [content]="userMsg" [limit]="300" [completeWords]="true">
                      </app-read-more>
                    </ng-template>
                  </p>
                  <p class="lbl-time-opp ion-text-end mb-4" *ngIf="messageData.msgdate">{{messageData.msgdate |
                    dynamicDateTimePipe:1}}</p>
                </ion-label>
              </ng-template> -->
              <app-chat-msg [checkedCount]="checkedCount" [chatSetting]="chatSetting" [userMsg]="userMsg" [dateCss]="'lbl-time-opp ion-text-end mb-4'" [messageData]="messageData"></app-chat-msg>
            </div>
          </div>
        </div>

        <!-- Login User -->
        <div style="display: flex; float: right"
          *ngIf="authServices.getLoginUserId() === this.messageData.sender_id">
          <ion-icon [src]="'ic_sel' | getImagePipe" class="ft-24 login-ch-icon"
            *ngIf="messageData.isChecked"></ion-icon>
          <div class="chat right w-ft" style="background: #fff9dc">
            <div class="pl-8 pr-8 pt-4 pb-4">
              <!-- <ion-label>
                <p class="lbl-med-14 ion-text-wrap mt-8 mb-4 ml-8 mr-8 ls-01 ion-text-start">
                  <span *ngIf="userMsg.length < 400;else overData">{{userMsg}}</span>
                  <ng-template #overData>
                    <app-read-more [content]="userMsg" [limit]="300" [completeWords]="true">
                    </app-read-more>
                  </ng-template>
                </p>
                <p class="lbl-med-op75-12 ion-text-start ml-8 mb-4" *ngIf="messageData.msgdate">{{messageData.msgdate |
                  dynamicDateTimePipe:1}}</p>
              </ion-label> -->
              <app-chat-msg [checkedCount]="checkedCount" [chatSetting]="chatSetting" [userMsg]="userMsg" [dateCss]="'lbl-med-op75-12 ion-text-start ml-8 mb-4'" [messageData]="messageData"></app-chat-msg>
            </div>
          </div>
        </div>
      </ion-col>
    </ion-row>
  </div>
  <!-- <div class="top-left">
    <ion-icon
      [src]="'ic_sel' | getImagePipe"
      class="ft-24"
      *ngIf="messageData.isChecked"
    ></ion-icon>
  </div> -->
</div>

<!-- <ion-row>
  <ion-col>
    <ion-item class="ion-item-bg p-8" lines="none">
      <ion-label>{{seletectedText}}</ion-label>
      <ion-select (ionChange)="onChangeAlert($event)" >
        <ion-select-option value="brown">Brown</ion-select-option>
        <ion-select-option value="blonde">Blonde</ion-select-option>
        <ion-select-option value="black">Black</ion-select-option>
        <ion-select-option value="red">Red</ion-select-option>
      </ion-select>
    </ion-item>
    </ion-col>
  </ion-row> -->