import { Component, OnInit } from '@angular/core';
import { LoadingController, PopoverController } from '@ionic/angular';
import { ImageCroppedEvent, LoadedImage } from 'ngx-image-cropper';
import { UtilsService } from 'src/app/services/uitils.services';
import { LogData, LogDataApi } from 'src/app/utils/utils';


@Component({
  selector: 'app-img-crop-util',
  templateUrl: './img-crop-util.component.html',
  styleUrls: ['./img-crop-util.component.scss'],
})
export class ImgCropUtilComponent implements OnInit {
  imageChangedEvent: any = '';
  croppedImage: any = '';
  imageURL;
  title;
  btn1;
  btn2;
  // loading: any;
  isImgLoading: boolean = false;
  isImgLoadingFailed: boolean = false;
  format;
  selectedRatio = 1 / 1;
  ratioList = [{
    text: '1/1',
    value: 1 / 1
  },
  {
    text: '4/3',
    value: 4 / 3
  },
  {
    text: '16/9',
    value: 16 / 9
  }];
  constructor(private popoverCtrl: PopoverController,
    private utilServices: UtilsService,
    private loadingController: LoadingController) { }

  ngOnInit() {
    this.showHideLoader();
    this.isImgLoadingFailed = false;
    this.isImgLoading = false;
  }

  onChangeRatio(ev) {
    LogData("selectedRatio", ev);
    this.selectedRatio = ev.detail.value;


  }

  async showHideLoader() {
    const loading = await this.loadingController.create(
      this.utilServices.getLoaderUI()
    );
    await loading.present();
  }

  async hideLoader() {
    // const loading = await this.loadingController.getTop();
    // if(loading){
    //   loading.dismiss();
    // }

    setTimeout(() => {
      this.loadingController.dismiss();
    }, 1000);
  }

  closeModal(isClear?) {
    this.popoverCtrl.dismiss({
      image: this.imageURL
    });
  }

  apply() {
    this.popoverCtrl.dismiss({
      image: this.croppedImage
    });

  }


  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
    console.log("imageChangedEvent", this.imageChangedEvent);

  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    // console.log("croppedImage..",this.croppedImage);

  }
  imageLoaded(image?) {
    // show cropper
    // LogData("imageLoaded",this.imageURL);
    // this.showHideLoader(false);
    this.isImgLoading = true;
    this.hideLoader();
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
    LogData("loadImageFailed");
    // this.showHideLoader(false);
    this.isImgLoading = true;
    this.isImgLoadingFailed = true;
    this.utilServices.errorToast("Image failed to load please try agian");
    this.croppedImage=null;
    this.imageURL=null;
    this.hideLoader();
  }
}
