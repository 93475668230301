import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Capacitor } from "@capacitor/core";
import { PopoverController } from "@ionic/angular";
import { ChatSettings, DocumentType } from "src/app/models/chat-setting";
import { MessageTableData } from "src/app/models/message-data";
import { UtilsService } from "src/app/services/uitils.services";
import {
  AUDIO_DOC,
  IMAGE_DOC,
  TEXT_DOC,
  VIDEO_DOC,
  DOCUMENT_DOC,
} from "src/app/utils/constant";
import { VIEWER_RT } from "src/app/utils/routes";
import { LogData } from "src/app/utils/utils";
// import { CapacitorVideoPlayer } from "capacitor-video-player";
// import { VideoPlayer } from "@awesome-cordova-plugins/video-player/ngx";

@Component({
  selector: "app-chat-doc",
  templateUrl: "./chat-doc.component.html",
  styleUrls: ["./chat-doc.component.scss"],
})
export class ChatDocComponent implements OnInit, OnDestroy {
  @Input() fileName: string;
  @Input() docUrl: string;
  @Input() chatSetting: ChatSettings;
  @Input() checkedCount: number = 0;
  @Input() messageData: MessageTableData;
  constructor(
    public utilsService: UtilsService,
    private popCtrl: PopoverController,
  ) {}

  ngOnInit() {}

  openDoc() {
    if (this.checkedCount === 0) {
      // this.utilsService.openBrowserCap(this.docUrl);
      // this.openDocViewer();
      const doc_type=this.getType();
      if(doc_type === AUDIO_DOC || doc_type === VIDEO_DOC || doc_type === DOCUMENT_DOC ||
        doc_type === IMAGE_DOC){
        this.utilsService.setNavigationRouteQuery(VIEWER_RT, {
          url: this.docUrl,
          type:doc_type
        });
      }else{
        this.utilsService.openBrowserCap(this.docUrl);
      }

    }
  }

  // openDoc() {
  //   if (this.checkedCount === 0) {
  //     // this.utilsService.openBrowserCap(this.docUrl);
  //     // this.openDocViewer();
  //     const doc_type = this.getType();
  //     LogData("doc_type", doc_type);
  //     if (doc_type === VIDEO_DOC) {
  //       this.videoPlay(this.docUrl);
  //     } else {
  //       if (
  //         doc_type === AUDIO_DOC ||
  //         doc_type === DOCUMENT_DOC ||
  //         doc_type === IMAGE_DOC
  //       ) {
  //         this.utilsService.setNavigationRouteQuery(VIEWER_RT, {
  //           url: this.docUrl,
  //           type: doc_type,
  //         });
  //       } else {
  //         this.utilsService.openBrowserCap(this.docUrl);
  //       }
  //     }
  //   }
  // }

  // async videoPlay(url: string) {
  //   LogData("video_url", url);
  //   // try {
  //   //   await CapacitorVideoPlayer.initPlayer({
  //   //     mode: "fullscreen",
  //   //     url,
  //   //     playerId: "fullscreen",
  //   //     componentTag: "my-page",
  //   //   });
  //   // } catch (error) {
  //   //   console.log("error in playing video", error);
  //   // }
  //   this.videoPlayer
  //     .play(url)
  //     .then(() => {
  //       console.log("video completed");
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }

  getType() {
    let type = "";
    switch (this.messageData.msgtype) {
      case VIDEO_DOC:
        type = VIDEO_DOC;
        break;
      case DOCUMENT_DOC:
        type = DOCUMENT_DOC;
        break;
      case AUDIO_DOC:
        type = AUDIO_DOC;
        break;
      default:
        type = "";
        break;
    }
    return type;
  }

  downloadDoc() {
    this.utilsService.downloadImageFromServer(this.docUrl, this.fileName);
  }

  getIcon() {
    let doc_icon = "file_doc";
    const fiile_ext = this.fileName.split(".").pop();
    if (this.chatSetting) {
      for (const key in this.chatSetting) {
        let value_: DocumentType = this.chatSetting[key];
        switch (key) {
          case TEXT_DOC:
            if (
              this.utilsService.isExitExtension(value_.extension, fiile_ext)
            ) {
              doc_icon = "txt_doc";
            }
            break;
          case AUDIO_DOC:
            if (
              this.utilsService.isExitExtension(value_.extension, fiile_ext)
            ) {
              doc_icon = "audio_doc";
            }
            break;
          case VIDEO_DOC:
            if (
              this.utilsService.isExitExtension(value_.extension, fiile_ext)
            ) {
              doc_icon = "video_doc";
            }
            break;
          case IMAGE_DOC:
            if (
              this.utilsService.isExitExtension(value_.extension, fiile_ext)
            ) {
              doc_icon = "img_doc";
            }
            break;
        }
      }
    }
    return doc_icon;
  }
  ngOnDestroy(): void {
    // CapacitorVideoPlayer.stopAllPlayers();
  }
}
