import { Component, OnInit, ViewChild } from '@angular/core';
import { IonContent, ModalController, PopoverController } from '@ionic/angular';
import { UtilsService } from 'src/app/services/uitils.services';
import { LogData } from 'src/app/utils/utils';
import Swiper from 'swiper';
import SwiperCore, {
  Autoplay, Keyboard, Pagination, Scrollbar, Zoom,
  EffectCube, EffectCards, EffectCoverflow, EffectCreative, EffectFade, EffectFlip
} from 'swiper';

SwiperCore.use([Autoplay, Keyboard, Pagination, Scrollbar, Zoom, EffectCube, EffectCards, EffectCoverflow, EffectCreative, EffectFade, EffectFlip]);

@Component({
  selector: 'app-gallery-popover',
  templateUrl: './gallery-popover.component.html',
  styleUrls: ['./gallery-popover.component.scss'],
})
export class GalleryPopoverComponent implements OnInit {

  title;
  sliderThree;
  images;
  @ViewChild('slideWithNav3') slideWithNav3: Swiper;
  @ViewChild(IonContent, { static: false }) private content: IonContent;
  constructor(private popCtrl: PopoverController,
    private modalCtrl:ModalController,
    public utilsService:UtilsService) { }

  ngOnInit() {
    this.sliderThree = {
      isBeginningSlide: true,
      isEndSlide: false,
      slidesItems: [] as any
    };
    this.sliderThree.slidesItems = this.images;
    if (this.images && this.images.length > 0) {
      setTimeout(() => {
        this.scrollToCenterOnInit();
      }, 1000)
    }
  }

  closeModal(ev) {
    this.modalCtrl.dismiss();
  }

  scrollToCenterOnInit() {
    //     this.content.scrollToPoint(200,200,1500);
    // this.content.scrollToTop(0);
    // this.scrollElement();
    LogData("scrollToCenterOnInit");
  }

  // scrollElement() {
  //   var topPos = document.getElementById('inner-element').offsetTop;
  //   document.getElementById('container').scrollTop = topPos + 100;
  // }

  onSwiper(event) {
    console.log("onSwiper", event);
  }

  onSlideChange(object, slideView) {
    this.checkIfNavDisabled(object, slideView);
  }

  checkIfNavDisabled(object, slideView) {
    this.checkisBeginning(object, slideView);
    this.checkisEnd(object, slideView);
  }

  checkisBeginning(object, slideView) {
    object.isBeginningSlide = slideView?.swiperRef?.isBeginning;
    console.log('object.isBeginningSlide', object);
  }
  checkisEnd(object, slideView) {
    object.isEndSlide = slideView?.swiperRef?.isEnd;
    console.log('object.isEndSlide', object);
  }

  slideNext(slideView) {
    slideView.swiperRef.slideNext(500);
  }

  //Move to previous slide
  slidePrev(slideView) {
    slideView.swiperRef.slidePrev(500);
  }



}
