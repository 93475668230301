import { Component, Input, OnInit } from '@angular/core';
import { ModalController, PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-success-error',
  templateUrl: './success-error.component.html',
  styleUrls: ['./success-error.component.scss'],
})
export class SuccessErrorComponent implements OnInit {

  @Input() isError;
  @Input() msg;
  @Input() isButtonHide:boolean=false;
  constructor(private modalCtrl:PopoverController) { }

  ngOnInit() {
    if(this.isButtonHide){
      setTimeout(()=>{
        this.modalCtrl.dismiss();
      },2000)
    }
  }

  backToLogin(ev){
    this.modalCtrl.dismiss("Data");
  }

  tryAgain(){
    this.modalCtrl.dismiss();
  }

}
