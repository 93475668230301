import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonData } from 'src/app/models/common-data';
import { LedgerItmDetails } from 'src/app/models/ladger-item-data';
import { UtilsService } from 'src/app/services/uitils.services';
import { LogData } from 'src/app/utils/utils';

@Component({
  selector: 'app-item-led-card',
  templateUrl: './item-led-card.component.html',
  styleUrls: ['./item-led-card.component.scss'],
})
export class ItemLedCardComponent implements OnInit {

 @Input() ladgerData:LedgerItmDetails;
 @Output() getItems: EventEmitter<any> = new EventEmitter();
 @Input() checkedCount;
 isLongPressClick: boolean = false;
 @Input() redirectUrl;
 constructor(public utilsService: UtilsService) { }

 ngOnInit() { }

 getStatus(status: Array<CommonData>) {
   return (status && status.length > 0) ? status[0].value : '';
 }
 onClickItem() {
   // this.setCheckedData();
   LogData("onClickItem", this.isLongPressClick);

   if (!this.isLongPressClick) {
     if (this.checkedCount === 0) {
       this.utilsService.setNavigationRoute('edit-'+this.redirectUrl +'/'+ this.ladgerData.id,);
     } else {
       this.setCheckedData();
     }
   } else {
     this.isLongPressClick = false;
   }


 }

 setCheckedData() {
     if (this.ladgerData.isChecked) {
       this.ladgerData.isChecked = false;
     } else {
       this.ladgerData.isChecked = true;
     }
     this.getItems.emit(true);
 }

 onPress($event) {
     LogData("onPress", $event);
     this.isLongPressClick = true;
     this.setCheckedData();
 }

 onPressUp($event) {
   LogData("onPressUp", $event);

 }

}
