<app-dg-header [title]="'dg_lbl_curren_location'" (onClickClose)="closeModal($event)"></app-dg-header>
<ion-content>
  <hr class="divider1 w-100" />
  <ion-grid class="pl-16 pr-16">
    <ion-row class="center-row">
      <ion-col style="align-self: center" class="no-margin-padding">
        <ion-row>
          <ion-col class="mt-12" size="12">
            <ion-label class="lbl-med-12">{{ 'dg_lbl_location' | getLanguagePipe }} :</ion-label>
            <ion-item class="mt-8 ion-item-bg " lines="none">
              <ion-input [(ngModel)]="addressDisplay" readonly></ion-input>
            </ion-item>
          </ion-col>
          <ion-col class="mt-16 mb-8 ion-text-center" size="12">
            <div class="ion-activatable ripple-parent">
              <ion-text (click)="getCurrentLocation()" class="lbl-sec-bold-14 cursor-ptr">{{ 'dg_lbl_get_curre_location' | getLanguagePipe }}</ion-text>
              <ion-ripple-effect></ion-ripple-effect>
            </div>
            <!-- <ion-item  lines="none" class="cursor-ptr">
              <ion-text class="lbl-sec-bold-14">{{ 'dg_lbl_get_curre_location' | getLanguagePipe }}</ion-text>
            </ion-item> -->
          </ion-col>
        </ion-row>
        <app-dg-buttons [btn1]="'save'" (onClickCloseBtn1)="apply($event)"></app-dg-buttons>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>