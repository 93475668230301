<div class="container cursor-ptr">
  <!-- <div class="bd-div p-12 mb-8"> -->
    <ion-item [ngClass]="getStatus(bdData.status) === 2 ? 'bd-div' : 'bd-div-sel'"  class="item-trans p-12 mb-8" lines="none" (press)="onPress($event)" (pressup)="onPressUp($event)" (click)="onClickItem()">
      <div class="flex flex-col w-100">
        <ion-text class="created-by-lbl mb-4" *ngIf="bdData.createdby && bdData.createdby.length > 0">{{'lbl_creatd_by' | getLanguagePipe}} : {{bdData.createdby[0].text}}</ion-text>
        <ion-text slot="start" class="lbl-med-16" *ngIf="bdData.f_name">{{bdData.f_name}}</ion-text>
        <div class="flex flex-row mt-16">
          <div class="flex flex-row ion-justify-content-center" *ngIf="bdData.usercode">
            <ion-icon [src]="'bd_person' | getImagePipe"></ion-icon>
            <ion-text slot="start" class="lbl-med-12 ml-8">{{bdData.usercode}}</ion-text>
          </div>
          <div class="flex flex-row ml-16 ion-justify-content-center" *ngIf="bdData.phone">
            <ion-icon [src]="'bd_contact' | getImagePipe"></ion-icon>
            <ion-text slot="start" class="lbl-med-12 ml-8">{{bdData.phone}}</ion-text>
          </div>
        </div>
        <div class="mt-12" *ngIf="bdData.userregisterareas && bdData.userregisterareas.length > 0">
          <ion-text class="lbl-med-16">{{'lbl_pg_bd_list_allo_area' | getLanguagePipe}}</ion-text>
          <!-- <div class="flex flex-row mt-8 ion-justify-content-start area-div" >
            <ion-icon [src]="'ic_ro_location' | getImagePipe"></ion-icon>
            <ion-text slot="start" class="lbl-med-12 ml-8">
              <span *ngIf="item.state_id && item.state_id.length > 0">{{item.state_id[0].text}}&nbsp;</span>
              <span *ngIf="item.district_id && item.district_id.length > 0">,{{item.district_id[0].text}}&nbsp;</span>
              <span *ngIf="item.taluka_id && item.taluka_id.length > 0">,{{item.taluka_id[0].text}}</span>
            </ion-text>
          </div> -->
          <ion-item lines="none" class="item-0 item-trans area-div mt-8" *ngFor="let item of bdData.userregisterareas">
           <ion-avatar slot="start" class="ion-align-self-start avt-32">
            <ion-img [src]="'ic_ro_location' | getImagePipe"></ion-img>
            </ion-avatar>
            <div class="flex flex-col w-100">
            <ion-text  class="lbl-med-12">
              <span *ngIf="item.state_id && item.state_id.length > 0">{{item.state_id[0].text}}&nbsp;</span>
              <span *ngIf="item.district_id && item.district_id.length > 0">,&nbsp;{{item.district_id[0].text}}</span>
              <span *ngIf="item.taluka_id && item.taluka_id.length > 0">,&nbsp;{{item.taluka_id[0].text}}</span>
              <span *ngIf="item.zone_name && item.zone_name.length > 0">,&nbsp;{{item.zone_name[0].text}}</span>
              <span *ngIf="item.village_id && item.village_id.length > 0">,&nbsp;{{item.village_id[0].text}}</span>
            </ion-text>
            <ion-text class="created-by-lbl" *ngIf="item.createdby && item.createdby.length > 0">{{'lbl_creatd_by' | getLanguagePipe}} : {{item.createdby[0].text}}</ion-text>
          </div>  
          </ion-item>
        </div>
      </div>
    </ion-item>
  <!-- </div> -->
  <div class="top-left">
    <ion-icon
      [src]="'ic_sel' | getImagePipe"
      class="ft-24"
      *ngIf="bdData.isChecked"
    ></ion-icon>
  </div>
  </div>