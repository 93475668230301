import { Component, Input, OnInit } from "@angular/core";
import { finalize } from "rxjs/operators";
import { NotificationData } from "src/app/models/notifications";
import { AuthServices } from "src/app/services/auth.services";
import { UtilsService } from "src/app/services/uitils.services";
import { UserServices } from "src/app/services/user.services";
import { DELETE_NOTIFICATION_API } from "src/app/utils/api-end-points";
import {
  DELETE_DATA,
  MESSAGEDETAILS_PUSH_MODULE,
  PAGE_NOTIFICATION_LIST,
} from "src/app/utils/constant";
import {
  CURRENTSTATUS_KEY,
  DELETE_KEY,
  RECORDID_KEY,
} from "src/app/utils/params-data";
import { CHAT_CONVERSATION } from "src/app/utils/routes";
import { LogData, LogDataApi } from "src/app/utils/utils";

@Component({
  selector: "app-notification-card",
  templateUrl: "./notification-card.component.html",
  styleUrls: ["./notification-card.component.scss"],
})
export class NotificationCardComponent implements OnInit {
  @Input() notificationData: NotificationData;
  @Input() SendersReceivers;
  @Input() isDate:boolean=false;
  userImage;

  constructor(
    private utilsService: UtilsService,
    private authServices: AuthServices,
    private userServices: UserServices
  ) {}

  ngOnInit() {
    this.getUserData();
  }

  // getUserData() {
  //   let userData;
  //   if (
  //     this.notificationData &&
  //     (this.notificationData.receiver_id || this.notificationData.sender_id)
  //   ) {
  //     if (
  //       this.notificationData.receiver_id !== this.authServices.getLoginUserId()
  //     ) {
  //       userData = this.SendersReceivers[this.notificationData.receiver_id];
  //       this.userImage = this.utilsService.getUserImage(userData);
  //     } else if (
  //       this.notificationData.sender_id !== this.authServices.getLoginUserId()
  //     ) {
  //       userData = this.SendersReceivers[this.notificationData.sender_id];
  //       this.userImage = this.utilsService.getUserImage(userData);
  //     }
  //   }
  //   LogData("userData_test",userData);
  // }

  getUserData() {
    let userData;
    if (
      this.notificationData &&
      (this.notificationData.receiver_id || this.notificationData.sender_id)
    ) {
      if (
        this.notificationData.sender_id === this.authServices.getLoginUserId()
      ) {
        userData = this.SendersReceivers[this.notificationData.receiver_id];
        this.userImage = this.utilsService.getUserImage(userData);
      } else {
        userData = this.SendersReceivers[this.notificationData.sender_id];
        this.userImage = this.utilsService.getUserImage(userData);
      }
    }
    LogData("userData_test",userData);
  }

  deleteNotification(data) {
    let tempArray = [];
    tempArray.push(data.id);
    const formData = new FormData();
    formData.append(RECORDID_KEY, JSON.stringify(tempArray));
    formData.append(CURRENTSTATUS_KEY, DELETE_KEY);
    this.userServices.addressMasterUpdate(
      DELETE_DATA,
      formData,
      null,
      DELETE_NOTIFICATION_API,
      PAGE_NOTIFICATION_LIST,
      "conf_txt_notification_delete",
      "ic_delete_conf"
    );
  }

  onClickPageNavigation() {
    switch (this.notificationData.module) {
      case MESSAGEDETAILS_PUSH_MODULE:
        let sendId;
        if (
          this.notificationData.sender_id === this.authServices.getLoginUserId()
        ) {
          sendId = this.notificationData.receiver_id;
        } else {
          sendId = this.notificationData.sender_id;
        }
        this.utilsService.setNavigationRouteQuery(CHAT_CONVERSATION, {
          id: sendId,
        });
        break;
    }
    this.readMessage(false,this.notificationData.id);
  }

  async readMessage(isLoader, id) {
    // let loading;
    // if (isLoader) {
    //   loading = await this.loadingCtrl.create(this.utilServices.getLoaderUI());
    //   await loading.present();
    //   LogData("loader_start");
    // }
    this.userServices
      .readMessageNotification(id, this.authServices.getUserToken())
      .pipe(
        finalize(() => {
          if (isLoader) {
            LogData("loader_dismiss");
            // loading.dismiss();
          }
        })
      )
      .subscribe(
        async (res: any) => {
          LogDataApi("readMessage_res", res);
          res = this.utilsService.getDecriptReasponse(res);
          if (res && res.status === 1) {
            if(this.notificationData.is_unread !== 1){
              this.notificationData.is_unread=1;
            }
          }
        },
        (err) => {
          if (isLoader) {
            LogData("loader_dismiss");
            // loading.dismiss();
          }
          LogDataApi("readmessage_err", err);
          if (err.error.result.error) {
            this.utilsService.presentToast(err.error.result.error);
            this.userServices.logoutApiCall(err);
          }
        }
      );
  }

  goback() {}
}
