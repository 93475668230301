<!-- <div class="trans-card" (click)="openEditPage();$event.stopPropagation()">
  <ion-item class="item-0" lines="none">
    <ion-text slot="end" class="created-by-lbl mb-4" *ngIf="transactionData.createdby && transactionData.createdby.length > 0">{{'lbl_creatd_by' | getLanguagePipe}} : {{transactionData.createdby[0].text}}</ion-text>
    <ion-text slot="start" class="created-by-lbl mb-4" *ngIf="transactionData.tr_date">{{'lbl_date' | getLanguagePipe}} : {{transactionData.tr_date | dynamicDateTimePipe}}</ion-text>
  </ion-item>
  <ion-item class="item-0 mt-4" lines="none">
    <ion-thumbnail slot="start" class="m-0 ion-align-self-start">
      <ion-img [src]="'transaction' | getImagePipe"></ion-img>
    </ion-thumbnail>
    <div class="flex flex-col ml-12 w-100">
      <ion-item class="item-0" lines="none">
        <ion-text slot="start" class="lbl-sb-14" *ngIf="transactionData.transaction_no">{{transactionData.transaction_no}}</ion-text>
        <ion-text slot="end" class="lbl-bold-sec-13 end-item ion-text-wrap" *ngIf="transactionData.amount_text">{{transactionData.amount_text}}</ion-text>
      </ion-item>
      <ion-item class="item-0" lines="none">
        <ion-text slot="start" class="lbl-op05-12 mt-4" *ngIf="isValidFirstData()" ><b>{{(transactionData.transactiondetails[0].inputtype === CONST_DATA.DR_KEY ? 'lbl_dr':'lbl_cr') | getLanguagePipe}} :</b> {{transactionData.transactiondetails[0].ledger_id[0].text}}</ion-text>
      </ion-item>
      <ion-text [ngClass]="transactionData.isExpand ? 'lbl-sec-12':'lbl-op05-12'" *ngIf="isValidSecondData()" (click)="openListItem(transactionData);$event.stopPropagation()"><b>{{(transactionData.transactiondetails[1].inputtype === CONST_DATA.DR_KEY ? 'lbl_dr':'lbl_cr') | getLanguagePipe}} :</b> {{transactionData.transactiondetails[1].ledger_id[0].text}}</ion-text>
      <div class="flex flex-col"
      *ngIf="transactionData.isExpand && transactionData.transactiondetails && transactionData.transactiondetails.length > 2">
      <div class="w-100 flex ion-justify-content-end">
        <hr class="divider1 mt-8 mb-8 w-100" />
      </div>
      <div class="w-100">
        <ng-container *ngFor="let item of transactionData.transactiondetails;let i=index;">
          <ng-container *ngIf="item.ledger_id && item.ledger_id.length>0 && i > 0">
            <ion-item class="item-0" lines="none">
              <ion-text class="lbl-op05-11">{{item.ledger_id[0].text}}</ion-text>
              <ion-text [ngClass]="isDRInputType(item) ?'lbl-dg-13':'lbl-sec-13'" class="ml-4" slot="end" *ngIf="item.amount_text">{{item.amount_text}}</ion-text>
            </ion-item>
          </ng-container>
        </ng-container>
      </div>
    </div>
    </div>
  </ion-item>
</div> -->

<div class="trans-card cursor-ptr" (click)="openEditPage();$event.stopPropagation()">
  <!-- <ion-item class="item-0" lines="none">
    <ion-text slot="end" class="created-by-lbl mb-4"
      *ngIf="transactionData.createdby && transactionData.createdby.length > 0">{{'lbl_creatd_by' | getLanguagePipe}} :
      {{transactionData.createdby[0].text}}</ion-text>
  </ion-item> -->
  <ion-item class="item-0 mt-4" lines="none">
    <ion-thumbnail slot="start" class="m-0 ion-align-self-start avt-36">
      <ion-img [src]="'transaction' | getImagePipe"></ion-img>
    </ion-thumbnail>
    <div class="flex flex-col ml-12 w-100">
      <ion-item class="item-0" lines="none">
        <ion-label>
          <p class="created-by-lbl" *ngIf="transactionData.tr_date">{{transactionData.tr_date |
            dynamicDateTimePipe}}</p>
          <p class="lbl-sb-14" *ngIf="transactionData.transaction_no">{{transactionData.transaction_no}}</p>

        </ion-label>
        <ion-text slot="end" class="lbl-bold-sec-12 end-item ion-text-wrap" *ngIf="transactionData.amount_text">
          {{transactionData.amount_text}}</ion-text>
      </ion-item>
    </div>
  </ion-item>
  <hr class="divider1 mt-8 mb-8 w-100" />
  <div class="flex flex-col">
    <ion-text slot="start" class="lbl-op05-12 mt-4" *ngIf="isValidFirstData()">
      <b>{{(transactionData.transactiondetails[0].inputtype | crdrDisplayPipe) |
        getLanguagePipe}} :</b> {{transactionData.transactiondetails[0].ledger_id[0].text}}
    </ion-text>
    <ion-text [ngClass]="transactionData.isExpand ? 'lbl-sec-12':'lbl-op05-12'" *ngIf="isValidSecondData() && 
    (transactionData.transactiondetails && transactionData.transactiondetails.length === 2)"
      >
      <b>{{(transactionData.transactiondetails[1].inputtype | crdrDisplayPipe) |
        getLanguagePipe}} :</b> {{transactionData.transactiondetails[1].ledger_id[0].text}}
    </ion-text>

      <ion-item lines="none" class="item-0" *ngIf="transactionData.transactiondetails && transactionData.transactiondetails.length > 2" (click)="openListItem(transactionData);$event.stopPropagation()">
        <ion-text class="created-by-lbl-sm mt-4">
          {{'lbl_sh_led'  | getLanguagePipe}}
        </ion-text>
        <ion-icon name="chevron-forward-outline" color="secondary" class="ft-16 mt-0" ></ion-icon>
      </ion-item>

    <ng-container
      *ngIf="transactionData.isExpand && transactionData.transactiondetails && transactionData.transactiondetails.length > 2">
      <div class="w-100 flex ion-justify-content-end">
        <hr class="divider1 mt-8 mb-8 w-100" />
      </div>
      <div class="w-100">
        <ng-container *ngFor="let item of transactionData.transactiondetails;let i=index;">
          <ng-container *ngIf="item.ledger_id && item.ledger_id.length>0 && i > 0">
            <ion-item class="item-0" lines="none">
              <ion-text class="lbl-op05-11"><b>{{(item.inputtype | crdrDisplayPipe) |
                getLanguagePipe}} :&nbsp;</b>{{item.ledger_id[0].text}}</ion-text>
              <ion-text [ngClass]="isDRInputType(item) ?'lbl-dg-12':'lbl-sec-12'" class="ml-4" slot="end"
                *ngIf="item.amount_text">{{item.amount_text}}</ion-text>
            </ion-item>
          </ng-container>
        </ng-container>
      </div>
    </ng-container>
    <ion-text slot="end" class="created-by-lbl-sm mt-4"
      *ngIf="transactionData.createdby && transactionData.createdby.length > 0">{{'lbl_creatd_by' | getLanguagePipe}} :
      {{transactionData.createdby[0].text}}</ion-text>
  </div>
</div>