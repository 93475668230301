<app-dg-header [title]="title" (onClickClose)="closeModal($event)"></app-dg-header>
<ion-content>
  <hr class="divider1 w-100" />
  <form [formGroup]="commonForm">
    <ion-grid class="pl-16 pr-16">
      <ion-row class="center-row">
        <ion-col style="align-self: center" class="no-margin-padding">
          

          <ion-col *ngIf="groupList && groupList.length > 0" class="mt-12" size="12">
            <ion-label class="lbl-med-12">{{(isItem ? 'lbl_dg_item_led_grp_nm':'lbl_dg_acc_led_grp_nm') | getLanguagePipe}} :</ion-label>
              <ion-item class=" mt-8" lines="none"
                [ngClass]="utilsService.getValidCss(commonForm.get('group_id'))"
                (click)="openSingleSelection()">
                <ion-text slot="start" class="lbl-med-15">
                  {{(commonForm.value.group_id && commonForm.value.group_id.length >
                  0)?commonForm.value.group_id[0].text:''}}
                </ion-text>
                <ion-icon [name]="commonForm.value.group_id_expand ? 'chevron-up-outline':'chevron-down-outline'"
                  class="ft-20 cursor-ptr" slot="end">
                </ion-icon>
              </ion-item>
          </ion-col>

            <ion-col *ngIf="statusList && statusList.length > 0" class="mt-12" size="12">
              <ion-label class="lbl-med-12">{{ 'lbl_status' | getLanguagePipe }} :</ion-label>
              <ion-item class="mt-8"
                [ngClass]="utilsService.getValidCss(commonForm.get('status'))" lines="none">
                <ion-select interface="popover" formControlName="status">
                  <ion-select-option [value]="item.value" *ngFor="let item of statusList">{{ item.text }}
                  </ion-select-option>
                </ion-select>
              </ion-item>
            </ion-col>
          <app-dg-buttons [btn1]="btn1" [btn2]="btn2" (onClickCloseBtn1)="apply($event)"
            (onClickCloseBtn2)="clearAll($event)">
          </app-dg-buttons>
        </ion-col>
      </ion-row>
    </ion-grid>
  </form>
</ion-content>