<!-- (click)="editEntry()" -->
<!-- <div class="trans-card mt-12"> -->
<!-- <ion-item-sliding>
  <ion-item-options side="end" class="pt-4">
    <ion-item-option (click)="deleteEntry();$event.stopPropagation();">
      <ion-icon [src]="'ic_delete_bl' |  getImagePipe" class="ft-20"></ion-icon>
    </ion-item-option>
  </ion-item-options> -->
<ion-item class="item-0 trans-card mt-12 cursor-ptr" lines="none">
  <ion-thumbnail slot="start" class="m-0">
    <ion-img [src]="(isMinusAmt() ?'rs_minus': 'rs_plus')  | getImagePipe">
    </ion-img>
  </ion-thumbnail>
  <div class="flex flex-col ml-12">
    <ion-text class="lbl-sb-12">{{(enteryItem.ledger_id && enteryItem.ledger_id.length > 0) ?
      enteryItem.ledger_id[0].text:''}}</ion-text>
    <!-- <ion-text class="plus-amt-ch">&#8377; {{enteryItem.amount}}</ion-text> -->
    <!-- <ion-text class="plus-amt-ch">&#8377; {{(enteryItem.ledger_id && enteryItem.ledger_id.length > 0) ? enteryItem.ledger_id[0].amount:''}}</ion-text> -->
    <ion-text  [ngClass]="isMinusAmt() ? 'minus-amt-ch':'plus-amt-ch'">{{(enteryItem.ledger_id && enteryItem.ledger_id.length > 0) ?
      enteryItem.ledger_id[0].cl_balance_text:''}}</ion-text>
  </div>

  <div slot="end" class="flex flex-col mr-4 ion-text-end">
    <ion-text class="lbl-amt">{{'lbl_trans_amt' | getLanguagePipe}}</ion-text>
    <!-- <ion-text  [ngClass]="isSecond ? 'minus-amt':'plus-amt'"><span *ngIf="isSecond">-</span>&#8377; {{enteryItem.amount}}</ion-text> -->
    <ion-text [ngClass]="isSecond ? 'plus-amt':'plus-amt'">&#8377; {{enteryItem.amount}}</ion-text>

  </div>
</ion-item>
<!-- </ion-item-sliding> -->
<!-- </div> -->