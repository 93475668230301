import { Pipe, PipeTransform } from '@angular/core';
import { FEMALE_GN, MALE_GN } from '../utils/constant';
@Pipe({
    name: 'getUserImagePipe'
})
export class GetUserImagePipe implements PipeTransform {

    constructor() { }

    transform(data): any {
        if(data.gender){
            if(data.gender === MALE_GN){
                return "assets/img/male_us.svg";
            }else if(data.gender === FEMALE_GN){
                return "assets/img/female_us.svg";
            }else{
                return "assets/img/def_user.svg";
            }
        }else{
            return "assets/img/def_user.svg";
        }
        
    }
}
