import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-benefits-card',
  templateUrl: './benefits-card.component.html',
  styleUrls: ['./benefits-card.component.scss'],
})
export class BenefitsCardComponent implements OnInit {

  @Input() index;
  @Input() isOdd;
  @Input() benefitData;
  constructor() { }

  ngOnInit() {}

}
