import { Component, Input, OnInit } from "@angular/core";
import { UserprofileData } from "src/app/models/user-profile-data";
import { AuthServices } from "src/app/services/auth.services";
import { UtilsService } from "src/app/services/uitils.services";
import { COVER_DEFALUT_IMG, LOGO_DEAFLUT_IMG } from "src/app/utils/constant";
import { ADMIN_BUSINESS_DETAIL, BUSINESS_DETAIL } from "src/app/utils/routes";

@Component({
  selector: "app-business-services-card",
  templateUrl: "./business-services-card.component.html",
  styleUrls: ["./business-services-card.component.scss"],
})
export class BusinessServicesCardComponent implements OnInit {
  typeList = [1, 2, 3];
  @Input() businessData: UserprofileData;
  constructor(private utilServices: UtilsService,
    public authServices:AuthServices) { }

  ngOnInit() {
   }

  onclickBusiness() {
    this.utilServices.setNavigationRoute(BUSINESS_DETAIL + "/" + this.businessData.id);
  }

  isAddressAvailable() {
    let flag = false;
    if ((this.businessData.villagearea_id && this.businessData.villagearea_id.length > 0) ||
      (this.businessData.village_id && this.businessData.village_id.length > 0) ||
      (this.businessData.taluka_id && this.businessData.taluka_id.length > 0) ||
      (this.businessData.district_id && this.businessData.district_id.length > 0) ||
      (this.businessData.state_id && this.businessData.state_id.length > 0)) {
      flag = true;
    }
    return flag;
  }

  getCoverImageDisplay() {
    let imageUrl = COVER_DEFALUT_IMG;   // default img
    if (this.businessData && this.businessData.documents && this.businessData.documents.length > 0) {
      const img_obj = this.businessData.documents.find((element) => (element.display_files && element.display_files.business_cover_photo && element.display_files.business_cover_photo.main_url));
      if (img_obj) {
        imageUrl = img_obj.display_files.business_cover_photo.main_url;
      }
    }
    return imageUrl
  }

  getLogoImageDisplay() {
    let imageUrl = LOGO_DEAFLUT_IMG;   // default img
    if (this.businessData && this.businessData.documents && this.businessData.documents.length > 0) {
      const img_obj = this.businessData.documents.find((element) => (element.display_files && element.display_files.business_logo && element.display_files.business_logo.square_url));
      if (img_obj) {
        imageUrl = img_obj.display_files.business_logo.main_url;
      }
    }
    return imageUrl
  }
}
