<div class="flex flex-col">

<div class="flex flex-row ion-justify-content-end w-100">
  <!-- <ion-item lines="none" class="mt-4">
    <mat-form-field class="w-100 mtn-8">
      <input matInput class="lbl-sb-sec-12 ml-8" readonly  (click)="picker.open()" [matDatepicker]="picker" [(ngModel)]="tr_date">
      <mat-datepicker-toggle matPrefix [for]="picker">
        <mat-icon matDatepickerToggleIcon>
          <ion-icon [src]="'cal' | getImagePipe" class="ft-20"></ion-icon>
        </mat-icon>
      </mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
  </ion-item> -->
  <mat-form-field class="date-width">
    <input matInput class="lbl-sb-sec-12 ml-8 cursor-ptr" readonly  (click)="picker.open()" [matDatepicker]="picker"
     [(ngModel)]="tr_date" [max]="maxDate" [min]="minDate">
    <mat-datepicker-toggle matPrefix [for]="picker">
      <mat-icon matDatepickerToggleIcon>
        <ion-icon [src]="'cal' | getImagePipe" class="ft-20"></ion-icon>
      </mat-icon>
    </mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>
</div>
<!-- First -->
<div>
  <div class="led-card">
    <ion-item class="item-0 item-trans" lines="none">
      <ion-text class="lbl-sb-12" slot="start">{{firstLbl | getLanguagePipe}}</ion-text>
      <ion-text slot="end" class="dr-cr-amt">&#8377; {{(firstData && firstData.amount) ? firstData.amount:'0.00' }}</ion-text>
    </ion-item>
  </div>

  <div class="flex flex-col ion-text-center cursor-ptr" (click)="firstClick();$event.stopPropagation();" *ngIf="!firstData">
    <ion-text class="lbl-13 mt-16">{{firstTxt | getLanguagePipe}}</ion-text>
  </div>
  <div class="mt-16" *ngIf="firstData">
    <ion-item-sliding>
      <ion-item-options side="end" class="pt-4">
        <ion-item-option (click)="deleteEntry(1);$event.stopPropagation();">
          <ion-icon [src]="'ic_delete_bl' |  getImagePipe" class="ft-20"></ion-icon>
        </ion-item-option>
      </ion-item-options>
    <app-entry-item [enteryItem]="firstData" [isSecond]="false" (click)="firstClick(firstData)"></app-entry-item>
  </ion-item-sliding>
  </div>
</div>

<!-- Second -->
<div class="mt-16">
  <div class="led-card mt-24">
    <ion-item class="item-0 item-trans" lines="none">
      <ion-text class="lbl-sb-12" slot="start">{{secondLbl | getLanguagePipe}}</ion-text>
      <ion-text slot="end" class="dr-cr-amt">&#8377; {{(secondDataTotal) ? secondDataTotal:'0.00' }}</ion-text>
    </ion-item>
  </div>
  <div class="flex flex-col ion-text-center cursor-ptr" (click)="secondClick();$event.stopPropagation();" *ngIf="!secondData || secondData.length === 0">
    <ion-text class="lbl-13 mt-16">{{secondTxt | getLanguagePipe}}</ion-text>
  </div>
  <div class="mt-16" *ngIf="secondData && secondData.length > 0">
    <ng-container *ngFor="let item of secondData;let i=index;">
      <!-- (onEditClick)="onEditItem($event)" -->
      <ion-item-sliding>
        <ion-item-options side="end" class="pt-4">
          <ion-item-option (click)="deleteEntry(2,i);$event.stopPropagation();">
            <ion-icon [src]="'ic_delete_bl' |  getImagePipe" class="ft-20"></ion-icon>
          </ion-item-option>
        </ion-item-options>
      <app-entry-item [enteryItem]="item" [isSecond]="true" (click)="secondClick(item);$event.stopPropagation();"></app-entry-item>
      </ion-item-sliding>
    </ng-container>
  </div>
</div>
</div>