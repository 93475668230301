<ion-item-divider sticky="fixed" class="ion-no-border" mode="md">
  <div class="flex flex-col w-100">
    <div class="top-line">
      <div class="div-line"></div>
    </div>
    <ion-row class="pt-24 pb-4">
      <ion-col size="10" sizeXl="9" class="ion-align-self-center">
        <ion-text class="lbl-bold-18">{{ title | getLanguagePipe }}</ion-text>
      </ion-col>
      <ion-col size="2" sizeXl="3" class="ion-text-center">
        <ion-icon [src]="'ic_close_ro' | getImagePipe" (click)="closeModal()" class="ft-36"></ion-icon>
      </ion-col>
    </ion-row>
  </div>
</ion-item-divider>
<ion-content>
<form [formGroup]="commonForm" *ngIf="commonForm">
  <ion-grid class="p-12">
    <ion-row class="center-row">
      <ion-col style="align-self: center;" class="no-margin-padding" size="12">
        <ion-row>
          <ion-col size="12">
            <ion-label class="lbl-med-12">{{lbl1 | getLanguagePipe}} :</ion-label>
            <ion-item class=" mt-8" lines="none"
              [ngClass]="utilsService.getValidCss(commonForm.get('ledger_id'),isFormSubmit)"
              (click)="openSingleSelection()">
              <ion-text slot="start" class="lbl-med-15">
                {{(commonForm.value.ledger_id && commonForm.value.ledger_id.length >
                0)?commonForm.value.ledger_id[0].text:''}}
              </ion-text>
              <ion-icon [name]="commonForm.value.ledger_id_expand? 'chevron-up-outline':'chevron-down-outline'"
                class="ft-20 cursor-ptr" slot="end">
              </ion-icon>
            </ion-item>
            <app-validation [validationMessages]="validationMessages.ladger_comp_name" [ValidationForm]="commonForm"
            [type]="'ledger_id'" [isFormSubmit]="isFormSubmit"></app-validation>
          </ion-col>

          <ion-col class="mt-12" size="12">
            <ion-label class="lbl-med-12">{{ lbl2 |  getLanguagePipe }} :</ion-label>
            <ion-item lines="none" class="mt-4"
              [ngClass]="utilsService.getValidCss(commonForm.get('amount'),isFormSubmit)">
              <!-- <ion-input id="ionInput" [ngClass]="commonForm.value.amount ? 'amount':''" formControlName="amount" [type]="''|platformInputPipe" inputmode="decimal" decimalOnlyDir>
              </ion-input> -->
              <ion-input id="ionInput" (ionInput)="utilsService.onlyPositiveNumber($event)" [ngClass]="commonForm.value.amount ? 'amount':''" formControlName="amount"  type="number" 
              (keyup.enter)="create()">
              </ion-input>
            </ion-item>
            <app-validation [validationMessages]="validationMessages.entry_transa_amt" [ValidationForm]="commonForm"
              [type]="'amount'" [isFormSubmit]="isFormSubmit"></app-validation>
          </ion-col>


        </ion-row>
        <ion-row class="mt-16 mb-8">
          <ion-col size="6">
            <ion-button class="btn-1 hp-45" expand="block" mode="ios" (click)="create()">
              <ion-text class="lbl-12">
                {{ btn1 | getLanguagePipe }}
              </ion-text>
            </ion-button>
          </ion-col>
          <!-- <ion-col size="0.4">
          </ion-col> -->
          <ion-col size="6">
            <ion-button class="btn-2 hp-45" expand="block" mode="ios" (click)="cancel()">
              <ion-text class="lbl-12">
                {{ btn2 | getLanguagePipe }}
              </ion-text>
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-col>
    </ion-row>
    </ion-grid>
    </form>
    </ion-content>