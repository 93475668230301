import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { PopoverController } from "@ionic/angular";
import { LogData } from "src/app/utils/utils";
import { ProfileImageComponent } from "../profile-image/profile-image.component";
import {
  Camera,
  CameraResultType,
  CameraSource,
  Photo,
} from "@capacitor/camera";
import { Capacitor } from "@capacitor/core";
import { Filesystem, Directory } from "@capacitor/filesystem";
import { DomSanitizer } from "@angular/platform-browser";
import { Crop } from "@ionic-native/crop/ngx";
import { UtilsService } from "src/app/services/uitils.services";
import { ImgCropUtilComponent } from "../img-crop-util/img-crop-util.component";
import {
  FILE_SIZE_1MB,
  FILE_SIZE_2MB,
  FILE_SIZE_80KB,
} from "src/app/utils/constant";
import { ChatSettings, DocumentType } from "src/app/models/chat-setting";
import { __values } from "tslib";
import { MimeTypesData } from "src/app/models/mime-types";
import { ConfirmationComponent } from "../confirmation/confirmation.component";
const IMAGE_DIR = "stored-images";
@Component({
  selector: "app-photo-utility",
  templateUrl: "./photo-utility.component.html",
  styleUrls: ["./photo-utility.component.scss"],
})
export class PhotoUtilityComponent implements OnInit {
  @Input() document: string;
  @Input() quality = 90;
  @Input() cameraDirection = "back";
  @Input() targetWidth: number;
  @Input() targetHeight: number;
  @Input() isBase64 = false;
  @Input() onlyCamera = false;
  @Input() actionSheetHeader;
  @Output() fileSelected: EventEmitter<any> = new EventEmitter();
  @Output() base64Selected: EventEmitter<any> = new EventEmitter();
  @Input() allowEdit = false;
  @Input() isImageCroppingImg: boolean = false;
  @Input() isImageCroppingImgOnlyCamera: boolean = false;
  @Input() isUploadDocument: boolean = false;
  @Input() isUploadVideo: boolean = false;
  @Input() fileLimit = FILE_SIZE_1MB;
  @Input() chatSetting: ChatSettings;
  @Input() isAlertMedia: boolean = false;
  @Input() isMultipleImage: boolean = false;
  // maxLenthImage=15;
  selectedImage;
  @ViewChild("fileIn", { static: false }) fileInputBtn: ElementRef;
  @ViewChild("fileInVideo", { static: false }) videoFileInputBtn: ElementRef;
  @ViewChild("fileInImages", { static: false }) imagesInputBtn: ElementRef;
  constructor(
    private popoverCtrl: PopoverController,
    private sanitizer: DomSanitizer,
    private utilsService: UtilsService,
    private crop: Crop,
    private popCtrl: PopoverController
  ) {}

  ngOnInit() {}

  async alertForAddMeda(mediaData: any) {
    const modal = await this.popCtrl.create({
      component: ConfirmationComponent,
      componentProps: {
        msg: "are_you_sure_you_want_media",
        icon: "medias",
      },
      // componentProps: { message1: "Confirmation", message2: "Are you sure you want to exit App?" },
      cssClass: "custom-popover",
    });

    modal.onDidDismiss().then((data) => {
      LogData("onDidDismiss...", data);
      if (data.data != null && data.data === "Yes") {
        this.fileSelected.emit(mediaData);
      }
    });

    await modal.present();
  }

  async takePhoto(document?) {
    const modal = await this.popoverCtrl.create({
      component: ProfileImageComponent,
      componentProps: {
        document: document,
        isUploadDocument: this.isUploadDocument,
        isUploadVideo: this.isUploadVideo,
        isMultipleImage: this.isMultipleImage,
      },
      cssClass: "custom-popover",
    });

    await modal.present();
    modal.onDidDismiss().then((data) => {
      LogData("data..", data);
      if (data.data != null) {
        if (this.isImageCroppingImgOnlyCamera) {
          this.isImageCroppingImg = false;
        }
        switch (data.data) {
          case 0:
            this.fileSelected.emit();
            break;
          case 1:
            this.selectImage(false);
            break;
          case 2:
            this.selectImage(true);
            break;
          case 3:
            // const inp =document
            // window.document.getElementById("fileIn").click();
            // inp.click();
            // this.fileInputBtn.nativeElement.dispatchEvent(new MouseEvent('click'));
            let inputElement: HTMLElement = this.fileInputBtn
              .nativeElement as HTMLElement;
            inputElement.click();
            break;
          case 4:
            let inputElementVideo: HTMLElement = this.videoFileInputBtn
              .nativeElement as HTMLElement;
            inputElementVideo.click();
            break;
          case 5:
            this.multiImageSelect();
            break;
        }
      }
    });
  }
  multiImageSelect() {
    let inputElementImages: HTMLElement = this.imagesInputBtn
      .nativeElement as HTMLElement;
    inputElementImages.click();
  }

  docUpload(event) {
    // LogData("key_mime_value",this.utilsService.getExtensionByMime('application/x-cdf'));
    LogData("docUpload_event", event);
    LogData("chatSetting", this.chatSetting);
    // const {file}= event[0];
    const fileExtension = event.name.split(".").pop();
    LogData("fileExtension", fileExtension);
    let documentType: DocumentType;
    if (this.chatSetting) {
      const result = this.chatSetting.document.extension.indexOf(fileExtension);
      if (result !== -1) {
        const file_size = Math.round(event.size / 1024);
        LogData("file_size", file_size);
        if (file_size > this.chatSetting.document.size) {
          this.utilsService.errorToast(this.chatSetting.document.error_text);
        } else {
          if (this.isAlertMedia) {
            this.alertForAddMeda({
              data: event,
              fileName: event.name,
            });
          } else {
            this.fileSelected.emit({
              data: event,
              fileName: event.name,
            });
          }
        }
      } else {
        this.utilsService.errorToast(this.chatSetting.document.error_doc);
      }
    }
  }

  // videoUpload(event) {
  //   LogData("videoUpload", event);
  //   LogData("chatSetting", this.chatSetting);
  //   // const {file}= event[0];
  //   const fileExtension = event.name.split(".").pop();
  //   LogData("fileExtension", fileExtension);
  //   if (this.chatSetting) {
  //     const result = this.chatSetting.video.extension.indexOf(fileExtension);
  //     if (result !== -1) {
  //       const file_size = Math.round(event.size / 1024);
  //       LogData("file_size", file_size);
  //       if (file_size > this.chatSetting.video.size) {
  //         this.utilsService.errorToast(this.chatSetting.video.error_text);
  //       } else {
  //         this.fileSelected.emit({
  //           data: event,
  //           fileName: event.name,
  //         });
  //       }
  //     } else {
  //       this.utilsService.errorToast(this.chatSetting.video.error_doc);
  //     }
  //   }
  // }

  videoUpload(event) {
    LogData("videoUpload", event);
    LogData("chatSetting", this.chatSetting);
    // const {file}= event[0];
    const fileExtension = event.name.split(".").pop();
    LogData("fileExtension", fileExtension);
    if (this.chatSetting) {
      let finalResult;
      let documentType: DocumentType;
      const videoResult =
        this.chatSetting.video.extension.indexOf(fileExtension);
      if (videoResult !== -1) {
        finalResult = videoResult;
        documentType = this.chatSetting.video;
      } else {
        const audioResult =
          this.chatSetting.audio.extension.indexOf(fileExtension);
        if (audioResult !== -1) {
          finalResult = audioResult;
          documentType = this.chatSetting.audio;
        }
      }
      LogData("documentType", documentType);

      if (finalResult !== -1) {
        const file_size = Math.round(event.size / 1024);
        LogData("file_size", file_size);
        if (file_size > documentType.size) {
          this.utilsService.errorToast(documentType.error_text);
        } else {
          if (this.isAlertMedia) {
            this.alertForAddMeda({
              data: event,
              fileName: event.name,
            });
          } else {
            this.fileSelected.emit({
              data: event,
              fileName: event.name,
            });
          }
        }
      } else {
        this.utilsService.errorToast(
          this.utilsService.getLanguageValue("invalid_file_type")
        );
      }
    }
  }

  pickImages(event) {
    LogData("chatsetting", this.chatSetting);
    const filesData = event;
    const finalFilData = event;
    LogData("pickImages", filesData);
    let finalImages = [];
    if (filesData) {
      // let totalSize=0;
      // for(let i=0;i<filesData.length;i++){
      //   totalSize=totalSize+filesData[i].size;
      // }
      // filesData.files.forEach(element => {
      //   totalSize=totalSize+element.size;
      // });
      // LogData("totalSize",totalSize);
      if (this.chatSetting && filesData.length > this.chatSetting.image.max) {
        this.utilsService.errorToast(
          "Max images allowed :" + this.chatSetting.image.max
        );
      } else {
        // let totalServerSideSize;
        // let totalLocalImagesSize;
        // if(this.chatSetting.image){
        //   // totalServerSideSize=this.chatSetting.image.size *filesData.length;
        //   // totalLocalImagesSize=Math.round(totalSize/1024);
        //   LogData("totalLocalImagesSize",totalLocalImagesSize);
        //   LogData("totalServerSideSize",totalServerSideSize);
        //   if(totalLocalImagesSize > totalServerSideSize){
        //     this.utilsService.errorToast(this.chatSetting.image.error_text);
        //   }
        // }
        let flag = false;
        for (let i = 0; i < filesData.length; i++) {
          const file_size = Math.round(filesData[i].size / 1024);
          LogData("file_size", file_size);
          if (this.chatSetting) {
            if (file_size > this.chatSetting.image.size) {
              if (!flag) {
                this.utilsService.errorToast(this.chatSetting.image.error_text);
              }
              flag = true;
            } else {
              // finalImages.push(filesData);
              finalImages.push(filesData[i]);
            }
          } else {
            if (file_size > this.fileLimit) {
              if (!flag) {
                // this.utilsService.errorToast(this.utilsService.getLanguageValue("val_file_limit_is")+" 1MB");
                this.utilsService.setFileInvalidMessage(this.fileLimit);
              }
              flag = true;
            } else {
              finalImages.push(filesData[i]);
            }
          }
        }
        LogData("filedata_0", filesData);
        LogData("filedata_1", finalImages);

        if (this.isAlertMedia) {
          this.alertForAddMeda({
            data: finalImages,
          });
        } else {
          this.fileSelected.emit({
            data: finalImages,
          });
        }
      }
    }
  }

  getNumberSize() {
    switch (this.fileLimit) {
      case FILE_SIZE_80KB:
        return " 80 kb";
        break;
      case FILE_SIZE_1MB:
        return " 1 MB";
        break;
      case FILE_SIZE_2MB:
        return " 2 MB";
        break;
    }
    return "1 MB";
  }

  // isBas64() {
  //   return (this.isImageCroppingImg && !this.utilsService.isWeb()) ? true : false;
  // }

  async selectImage(isCamera) {
    if (isCamera) {
      if (this.isImageCroppingImgOnlyCamera) {
        this.isImageCroppingImg = true;
      }
    }
    const image = await Camera.getPhoto({
      quality: this.quality,
      // allowEditing: true,
      resultType: this.utilsService.isBase64(this.isImageCroppingImg)
        ? CameraResultType.Base64
        : CameraResultType.Uri,
      source: isCamera ? CameraSource.Camera : CameraSource.Photos,
      // Camera, Photos or Prompt!
    });
    // this.selectedImage = image.webPath;
    this.selectedImage = this.sanitizer.bypassSecurityTrustUrl(image.webPath);
    LogData("Image", this.selectedImage);
    if (image) {
      this.saveImage(image);
    }
  }

  async saveImage(photo: Photo) {
    await this.readAsBase64(photo);
  }

  getRandumNumber() {
    return Math.floor(Math.random() * 100000 + 1);
  }

  //base64 to blob
  b64toBlob(b64Data, contentType = "", sliceSize = 512) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  // https://ionicframework.com/docs/angular/your-first-app/3-saving-photos
  private async readAsBase64(photo: Photo) {
    LogData("photo", photo);
    const fileName = new Date().getTime() + "." + photo.format;

    //Use for Mobile
    if (Capacitor.getPlatform() !== "web") {
      let file;
      if (!this.isImageCroppingImg) {
        LogData("photo_path_no_crop....", photo.path);
        file = await Filesystem.readFile({
          path: photo.path,
        });
        //New code
        const blob = this.b64toBlob(file.data, `image/${photo.format}`);
        if (this.chatSetting) {
          LogData("photo.format", photo);
          const result = this.chatSetting.image.extension.indexOf(photo.format);
          if (result !== -1) {
            const file_size = Math.round(blob.size / 1024);
            LogData("file_size", file_size);
            if (file_size > this.chatSetting.image.size) {
              this.utilsService.errorToast(this.chatSetting.image.error_text);
            } else {
              if (this.isAlertMedia) {
                this.alertForAddMeda({
                  data: blob,
                  selectedImage: this.selectedImage,
                  fileName: fileName,
                  webPath: photo.webPath,
                });
              } else {
                this.fileSelected.emit({
                  data: blob,
                  selectedImage: this.selectedImage,
                  fileName: fileName,
                  webPath: photo.webPath,
                });
              }
            }
          } else {
            this.utilsService.errorToast(this.chatSetting.image.error_doc);
          }
        } else {
          if (
            !this.utilsService.isInValidFile(this.fileLimit, { data: blob })
          ) {
            if (this.isAlertMedia) {
              this.alertForAddMeda({
                data: blob,
                selectedImage: this.selectedImage,
                fileName: fileName,
                webPath: photo.webPath,
              });
            } else {
              this.fileSelected.emit({
                data: blob,
                selectedImage: this.selectedImage,
                fileName: fileName,
                webPath: photo.webPath,
              });
            }
          }
        }
      } else {
        // const fileURIOrData_crop = await this.crop.crop(photo.path, { quality: 75 });
        // LogData("photo_path_crop.....", fileURIOrData_crop);
        // try{
        //   file = await Filesystem.readFile({
        //     path: fileURIOrData_crop
        //   });
        // }catch(err){
        //   LogData("Filesystem_err",err);
        // }
        this.openImagecrop(photo, fileName);
      }

      // LogData("readAsBase64_file", file);
      // const blob = this.b64toBlob(file.data, `image/${photo.format}`);
      // this.fileSelected.emit({ data: blob, selectedImage: this.selectedImage, fileName: fileName,webPath:photo.webPath });
    } else {
      //Use for web
      // Fetch the photo, read as a blob, then convert to base64 format
      if (this.isImageCroppingImg) {
        this.openImagecrop(photo, fileName);
      } else {
        const response = await fetch(photo.webPath);
        const blob = await response.blob();
        LogData("readAsBase64_blob", blob);
        if (this.chatSetting) {
          let file_ext = this.utilsService.getExtensionByMime(blob.type);
          LogData("file_ext", file_ext);
          const fileext1 = file_ext.split(".").pop();
          LogData("fileext1", fileext1);
          const result = this.chatSetting.image.extension.indexOf(fileext1);
          if (result !== -1) {
            const file_size = Math.round(blob.size / 1024);
            LogData("file_size", file_size);
            if (file_size > this.chatSetting.image.size) {
              this.utilsService.errorToast(this.chatSetting.image.error_text);
            } else {
              if (this.isAlertMedia) {
                this.alertForAddMeda({
                  data: blob,
                  selectedImage: this.selectedImage,
                  fileName: fileName,
                  webPath: photo.webPath,
                });
              } else {
                this.fileSelected.emit({
                  data: blob,
                  selectedImage: this.selectedImage,
                  fileName: fileName,
                  webPath: photo.webPath,
                });
              }
            }
          } else {
            this.utilsService.errorToast(this.chatSetting.image.error_doc);
          }
        } else {
          if (
            !this.utilsService.isInValidFile(this.fileLimit, { data: blob })
          ) {
            if (this.isAlertMedia) {
              this.alertForAddMeda({
                data: blob,
                selectedImage: this.selectedImage,
                fileName: fileName,
                webPath: photo.webPath,
              });
            } else {
              this.fileSelected.emit({
                data: blob,
                selectedImage: this.selectedImage,
                fileName: fileName,
                webPath: photo.webPath,
              });
            }
          }
        }
      }
    }
  }

  // setChatSetingData(blob){
  //     let file_ext=this.utilsService.getExtensionByMime(blob.type);
  //     LogData("file_ext",file_ext);
  //     const fileext1=file_ext.split(".").pop();
  //     LogData("fileext1",fileext1);
  //     let documentType:DocumentType=this.utilsService.getDocumentType(fileext1,this.chatSetting);
  //     if(!documentType){
  //       this.utilsService.errorToast(documentType.error_doc);
  //     }else{
  //       const file_size = Math.round((blob.size / 1024));
  //       LogData("file_size",file_size);
  //       if(file_size > documentType.size){
  //         this.utilsService.errorToast(documentType.error_text);
  //       }else{
  //         this.fileSelected.emit({
  //           data: blob,
  //           selectedImage: this.selectedImage,
  //           fileName: fileName,
  //           webPath: photo.webPath,
  //         });
  //       }
  //     }
  // }

  setEmitData() {}

  async openImagecrop(photo, fileName) {
    LogData("imageURL", photo);
    const modal = await this.popoverCtrl.create({
      component: ImgCropUtilComponent,
      componentProps: {
        title: "crop",
        btn1: "apply",
        btn2: "clear",
        imageURL: "data:image/jpeg;base64," + photo.base64String,
        format: photo.format,
      },
      cssClass: "custom-popover",
      showBackdrop: true,
      backdropDismiss: false,
      // breakpoints: [0, 0.3, 0.5, 0.8],
      // initialBreakpoint: 0.5
    });

    modal.onDidDismiss().then((data) => {
      LogData("onDidDismiss...", data);
      if (data.data != null && data.data.image) {
        const base6_str = data.data.image;
        const data_64 = base6_str.split(",")[1];
        const blob = this.b64toBlob(data_64, `image/${photo.format}`);
        LogData("onDidDismiss...blob", blob);
        if (!this.utilsService.isInValidFile(this.fileLimit, { data: blob })) {
          if (this.isAlertMedia) {
            this.alertForAddMeda({
              data: blob,
              selectedImage: this.selectedImage,
              fileName: fileName,
              webPath: base6_str,
            });
          } else {
            this.fileSelected.emit({
              data: blob,
              selectedImage: this.selectedImage,
              fileName: fileName,
              webPath: base6_str,
            });
          }
        }
        // this.fileSelected.emit({ data: blob, selectedImage: this.selectedImage, fileName: fileName, webPath: base6_str });
      }
    });

    await modal.present();
  }
}
