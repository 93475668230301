import { Component, Input, OnInit, Output,EventEmitter } from '@angular/core';
import { AuthServices } from 'src/app/services/auth.services';
import { LogData } from 'src/app/utils/utils';

@Component({
  selector: 'app-selected-chip',
  templateUrl: './selected-chip.component.html',
  styleUrls: ['./selected-chip.component.scss'],
})
export class SelectedChipComponent implements OnInit {

  @Input() selectedList=[];
  @Input() isMultiSelectAccess:boolean=false;
  isAdminRoll;
  @Output() getItems: EventEmitter<any> = new EventEmitter();
  constructor(private authServices:AuthServices) { }

  ngOnInit() {
    this.isAdminRoll = this.authServices.isAdminRoll();
    LogData("selectedList",this.selectedList);
  }

  removeName(i){
    if(!this.isAdminRoll){
      this.removeListItem(i);
    }else{
      if(this.isMultiSelectAccess){
        this.removeListItem(i);
      }
    }
  }

  removeListItem(i){
    this.selectedList.splice(i,1);
    this.getItems.emit(this.selectedList);
  }
  

}
