import { Component, Input, OnInit } from '@angular/core';
import { Platform, PopoverController } from '@ionic/angular';
import { VersionMsgData } from 'src/app/models/appversion';
import { UtilsService } from 'src/app/services/uitils.services';
import { CANCEL } from 'src/app/utils/constant';
import { LogData } from 'src/app/utils/utils';

@Component({
  selector: 'app-version-update',
  templateUrl: './version-update.component.html',
  styleUrls: ['./version-update.component.scss'],
})
export class VersionUpdateComponent implements OnInit {

 
  @Input() versionMsgData:VersionMsgData;
  @Input() isMaintenance;
  @Input() isMajor;
  @Input() playStoreLink;
  constructor(private popoverCtrl:PopoverController,
    private utilServices:UtilsService,
    private platform:Platform) { }

  ngOnInit() {
    LogData("isMaintenance",this.isMaintenance);
    LogData("isMajor",this.isMajor);
  }

  submitData(){
    if(this.isMaintenance){
      this.popoverCtrl.dismiss();
    }else{
      this.utilServices.openBrowser(this.playStoreLink);
    }
  }

  cancel(){
    this.popoverCtrl.dismiss(CANCEL);
  }

}
