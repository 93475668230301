<div class="flex flex-col ion-align-items-center cursor-ptr hvr-float mt-16 cate" >
  <!-- [ngClass]="!isLast ? 'cate-mr':''" -->
  <div class="container">
    <div class="wb-cate-img"></div>
    <div class="centered">
      <ion-img [src]="utilservices.getTradingIcon(categoryData)"></ion-img>
    </div>
  </div>
  <ion-text class="ion-text-center wb-cate-text mb-16"
    >{{categoryData.trading_type}}</ion-text
  >
</div>
