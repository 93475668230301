<div class="footer-bg footer-main-mt" *ngIf="utilsService.isWeb()">
  <div class="footer-pad">
    <ion-row>
      <ion-col size="12" [attrSizeMd]="4" [attrSizeLg]="4" [attrSizeXl]="4" appSizeAttribute>
        <div class="flex flex-col">
          <ion-img [src]="'footer_logo' | getImagePipe:2" class="footer-logo cursor-ptr" (click)="logoClick()">
          </ion-img>
          <ion-text class="footer-content footer-mt text-width">
            {{'hm_ft_disc' | getLanguagePipe}}
          </ion-text>
        </div>
      </ion-col>
      <ion-col size="12" [attrSizeMd]="2.5" [attrSizeLg]="2.5" [attrSizeXl]="2.5" appSizeAttribute>
        <div class="flex flex-col footer-mt">
          <ion-text class="footer-lbl">{{'hm_ft_lbl_reg_bus' | getLanguagePipe}}</ion-text>
          <ion-text class="footer-lbl footer-mt">{{'hm_ft_lbl_get_cust' | getLanguagePipe}}</ion-text>
          <ion-text class="footer-lbl footer-mt">{{'hm_ft_member_area' | getLanguagePipe}}</ion-text>
          <!-- <ion-text class="footer-lbl footer-mt" [routerLink]="CONST_DATA.PRICING_RT | dynamicRoutePipe">{{'hm_ft_pricing' | getLanguagePipe}}</ion-text> -->
        </div>
      </ion-col>
      <ion-col size="12" [attrSizeMd]="2.5" [attrSizeLg]="2.5" [attrSizeXl]="2.5" appSizeAttribute>
        <div class="flex flex-col footer-mt">
          <ion-text class="footer-lbl">{{'hm_ft_lbl_search_bus' | getLanguagePipe}}</ion-text>
          <ion-text class="footer-lbl footer-mt" [routerLink]="'/'+CONST_DATA.PRIVACY_POLICY">{{'hm_ft_lbl_privacy' | getLanguagePipe}}</ion-text>
          <ion-text class="footer-lbl footer-mt" [routerLink]="'/'+CONST_DATA.TERMS_CONDITION">{{'hm_ft_lbl_terms' | getLanguagePipe}}</ion-text>
          <ion-text class="footer-lbl footer-mt" [routerLink]="'/'+CONST_DATA.ABOUT_US">{{'hm_ft_about' | getLanguagePipe}}</ion-text>
        </div>
      </ion-col>
      <ion-col size="12" [attrSizeMd]="3" [attrSizeLg]="3" [attrSizeXl]="3" appSizeAttribute>
        <div class="flex flex-col footer-mt">
          <ion-text class="footer-lbl-pr">{{'hm_ft_lbl_we_social' | getLanguagePipe}}</ion-text>
          <div class="flex flex-row mt-16">
            <a [href]="CONST_DATA.FACEBOOK_LINK">
              <ion-img [src]="'fb' | getImagePipe" class="hvr-float"></ion-img>
            </a>
            <a [href]="CONST_DATA.TWITTER_LINK">
              <ion-img [src]="'twitter' | getImagePipe" class="ml-12 hvr-float"></ion-img>
            </a>
            <a [href]="CONST_DATA.LINKEDIN_LINK">
              <ion-img [src]="'linkedin' | getImagePipe" class="ml-12 hvr-float"></ion-img>
            </a>
            <a [href]="CONST_DATA.INSTA_LINK">
              <ion-img [src]="'insta' | getImagePipe" class="ml-12 hvr-float"></ion-img>
            </a>
            <a [href]="CONST_DATA.YOUTUBE_LINK">
              <ion-img [src]="'youtube' | getImagePipe" class="ml-12 hvr-float"></ion-img>
            </a>
            <!-- <a>
              <ion-img [src]="'pintrest' | getImagePipe" class="ml-12 cursor-ptr hvr-float"></ion-img>
            </a> -->
          </div>
          <ion-text class="footer-lbl-pr mt-16">{{'hm_ft_lbl_down_app' | getLanguagePipe}}</ion-text>
          <div class="flex flex-row mt-16">
            <ion-img [src]="'play_store' | getImagePipe:2" class="cursor-ptr hvr-float " (click)="utilsService.openPlayStoreLink()"></ion-img>
            <ion-img [src]="'app_store' | getImagePipe:2" class="ml-12 cursor-ptr hvr-float" (click)="utilsService.openAppStoreLink()"></ion-img>
          </div>
        </div>
      </ion-col>
    </ion-row>
  </div>
  <hr class="divider3 w-100" />
  <ion-row class="footer-pad-btm">
    <ion-col sizeXs="12" sizeSm="12" sizeMd="6" sizeXl="6" class="bottom-text-1">
      <ion-text class="footer-lbl-copy">Copyright 2022 by SGuru Infotech. All rights are reserved </ion-text>
    </ion-col>
    <ion-col sizeXs="12" sizeSm="12" sizeMd="6" sizeXl="6" class="bottom-text">
      <ion-text><span class="footer-lbl-term cursor-ptr"
          [routerLink]="'/'+CONST_DATA.TERMS_CONDITION">{{'hm_ft_lbl_terms' | getLanguagePipe}} | </span> <span
          class="footer-lbl-privacy cursor-ptr" [routerLink]="'/'+CONST_DATA.PRIVACY_POLICY">{{'hm_ft_lbl_privacy' | getLanguagePipe}}</span></ion-text>
    </ion-col>
  </ion-row>
</div>