<!-- <ion-item-sliding class="pb-16">
  <ion-item-options side="end" class="pt-4 pb-16">
    <ion-item-option (click)="deletechat(messageData)">
      <ion-icon [src]="'ic_delete_bl' |  getImagePipe" class="ft-20"></ion-icon>
    </ion-item-option>
  </ion-item-options>
  <ion-item lines="none" [ngClass]="!messageData.unread_count === 'U'  ? 'ion-item-read' : 'ion-item-unread'">
    <ion-avatar slot="start" class="avtart-size" style="margin-right: 10px;">
      <div class="container">
        <img [src]="'user' | getImagePipe:2" [ngClass]="!messageData.unread_count === 'U' ? 'unread-ring' : '' ">
      </div>
    </ion-avatar>
    <div class="grow flex  flex-col mt-4 w-100">
      <ion-item class="item-0" lines="none">
        <ion-text [ngClass]="!messageData.unread_count === 'U'  ? 'lbl-sb-14' : 'lbl-sb-op75-14' "
          *ngIf="messageData.name">
          {{messageData.name}}</ion-text>
        <div slot="end" class="flex flex-row m-0">
          <div class="dot-time mr-8 ion-align-self-center"></div>
          <ion-text [ngClass]="!messageData.unread_count === 'U' ? 'lbl-sb-10' : 'lbl-sb-op75-10' " class="ion-text-end">
            03:12pm
          </ion-text>
        </div>

      </ion-item>
      <ion-text [ngClass]="!messageData.unread_count === 'U' ? 'lbl-sb-10' : 'lbl-sb-op75-10'"
        *ngIf="messageData.description" class="mlines-1">{{messageData.description}}</ion-text>
    </div>
  </ion-item>
</ion-item-sliding> -->

<ion-item-sliding class="pb-8">
  <ion-item-options side="end" class="pt-4 pb-16">
    <ion-item-option (click)="deletechat(messageData)">
      <ion-icon [src]="'ic_delete_bl' | getImagePipe" class="ft-20"></ion-icon>
    </ion-item-option>
  </ion-item-options>
  <ion-item
    lines="none"
    (click)="openChat(); $event.stopPropagation()"
    [ngClass]="!messageData.unread_count ? 'ion-item-read' : 'ion-item-unread'"
  >
    <ion-avatar slot="start" class="avtart-size" style="margin-right: 10px">
      <!-- <div class="container"> -->
      <img
        [src]="userImage ? userImage : ('def_user' | getImagePipe)"
        [ngClass]="messageData.unread_count ? 'unread-ring' : ''"
      />
      <!-- </div> -->
    </ion-avatar>
    <div class="grow flex flex-col mt-4 w-100">
      <ion-item class="item-0" lines="none">
        <ion-text
          [ngClass]="!messageData.unread_count ? 'lbl-sb-14' : 'lbl-sb-op75-14'"
          *ngIf="userData.text"
          class="mlines-1"
        >
          {{ userData.text }}</ion-text
        >
        <div slot="end" class="flex flex-col m-0">
          <div class="flex flex-row">
            <div class="dot-time mr-8 ion-align-self-center"></div>
            <ion-text
              *ngIf="messageData.lastmassage"
              [ngClass]="
                !messageData.unread_count ? 'lbl-sb-10' : 'lbl-sb-op75-10'
              "
              class="ion-text-end"
            >
              {{ messageData.lastmassage.msgdate | dynamicDateTimePipe : 1 }}
            </ion-text>
          </div>
        </div>
      </ion-item>
      <ng-container *ngIf="messageData.lastmassage">
        <ion-item class="item-0 item-trans" lines="none">
          <ion-item
            class="item-0 item-trans"
            lines="none"
            *ngIf="messageData.lastmassage.msgtype !== CONST_DATA.TEXT_DOC"
          >
            <ion-icon
              slot="start"
              class="avt-18 mr-8 mt-8"
              color="medium"
              name="document"
            ></ion-icon>
            <ion-text
              [ngClass]="
                !messageData.unread_count ? 'lbl-sb-10' : 'lbl-sb-op75-10'
              "
              class="mlines-1"
              >{{ getTextMessage() }}
            </ion-text>
          </ion-item>
          <!-- <ion-text *ngIf="messageData.lastmassage.msgtype === CONST_DATA.TEXT_DOC" [ngClass]="!messageData.unread_count === 'U' ? 'lbl-sb-10' : 'lbl-sb-op75-10'"
         class="mlines-1">{{messageData.lastmassage.smsg}}</ion-text> -->
          <ion-text
            *ngIf="
              messageData.lastmassage.msgtype === CONST_DATA.TEXT_DOC ||
              messageData.lastmassage.msgtype === CONST_DATA.DIRECT_DOC
            "
            [ngClass]="
              !messageData.unread_count === 'U' ? 'lbl-sb-10' : 'lbl-sb-op75-10'
            "
            class="mlines-1"
            >{{ userMsg }}</ion-text
          >
          <div slot="end" *ngIf="messageData.unread_count" class="chat-uncount">
            {{ messageData.unread_count }}
          </div>
        </ion-item>
      </ng-container>
    </div>
  </ion-item>
</ion-item-sliding>
