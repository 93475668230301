//menu code
export const ADVERTISEBANNERS_MC="Advertisebanners";
export const PUSHADVERTISEBANNERS_MC="Pushadvertisebanners";
export const INQUIRIES_MC="Inquiries";
export const BUSINESSES_MC="Businesses";

export const BUSINESSLISTS_MC="Businesslists";
export const BUSINESSTYPES_MC="Businesstypes";
export const COMPLAINS_MC="Complains";
export const COUNTRIES_MC="Countries";
export const CUSTOMERS_MC="Customers";
export const DEALERS_MC="Dealers";
export const DISTRICTS_MC="Districts";
export const EMAILTEMPLATES_MC="Emailtemplates";
export const GROUPS_MC="Groups";
export const ITEMGROUPS_MC="Itemgroups";
export const ITEMS_MC="Items";
export const LEDGERS_MC="Ledgers";
export const PRODUCTCOMPANIES_MC="Productcompanies";
export const PRODUCTLISTS_MC="Productlists";
export const ROLES_MC="Roles";
export const SETTINGS_MC="Settings";
export const SMSTEMPLATES_MC="Smstemplates";
export const STAFFS_MC="Staffs";
export const EMPLOYEES_MC="Employees";
export const STATES_MC="States";
export const SUBJECTS_MC="Subjects";
export const TALUKAS_MC="Talukas";
export const TRADINGTYPES_MC="Tradingtypes";
export const TRANSACTIONS_MC="Transactions";
export const TRANSLATIONS_MC="Translations";
export const UNITS_MC="Units";
export const USERREGISTERAREAS_MC="Userregisterareas";
export const VILLAGEAREAS_MC="Villageareas";
export const VILLAGES_MC="Villages";

export const ACCOUNT_LADGER_REPORT_MC="ledger";