<div class="container">
  <div class="shc-card  flex flex-col p-12" (press)="onPress($event)" (pressup)="onPressUp($event)"
    (click)="onClickItem()">
    <div class="flex flex-col">
      <img *ngIf="scheduleData.documents && scheduleData.documents.length > 0" [src]="getImage()" />
      <ion-text class="lbl-med-14 ion-text-start mlines-3"
        *ngIf="scheduleData.schedule_text">{{scheduleData.schedule_text}}</ion-text>
      <div class="promo-bg p-12 ion-text-start mt-8" *ngIf="scheduleData.promo">
        <ion-text class="lbl-bold-12">{{scheduleData.promo}}</ion-text>
        <ion-text class="lbl-bold-12">{{'lbl_pro_used_schedule' | getLanguagePipe}}:{{scheduleData.promo_used ? scheduleData.promo_used:"0"}}</ion-text>
        <div class="flex ion-justify-content-between">
          <div class="flex flex-col"  *ngIf="scheduleData.promo_start_date">
            <ion-text class="lbl-12">{{'lbl_start_datetime' | getLanguagePipe}}</ion-text>
            <ion-text class="lbl-12 ion-text-start">{{scheduleData.promo_start_date | dynamicDateTimePipe}}</ion-text>
            </div>
            <div class="flex flex-col"  *ngIf="scheduleData.promo_end_date">
              <ion-text class="lbl-12">{{'lbl_end_datetime' | getLanguagePipe}}</ion-text>
              <ion-text class="lbl-12 ion-text-start">{{scheduleData.promo_end_date | dynamicDateTimePipe}}</ion-text>
            </div>
          </div>
      </div>
    </div>
  </div>
  <div class="top-left">
    <ion-icon [src]="'ic_sel' | getImagePipe" class="ft-24" *ngIf="scheduleData.isChecked"></ion-icon>
  </div>
</div>