import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { InputTypeEnum } from 'src/app/enums/inputtypes-enum';
import { CommonData } from 'src/app/models/common-data';
import { LogData } from 'src/app/utils/utils';
import { ValidationMessages } from 'src/app/validators/validations';

@Component({
  selector: 'app-advsetting-form',
  templateUrl: './advsetting-form.component.html',
  styleUrls: ['./advsetting-form.component.scss'],
})
export class AdvsettingFormComponent implements OnInit {

  @Input() commonForm:FormGroup;
  inputTypeEnum=InputTypeEnum;
  @Input() discount;
  @Input() isFormSubmit:boolean=false;
  @Input() mode:any;
  validationMessages=ValidationMessages;
  monthList:Array<CommonData>=[];
  @Output() onClickAddMore: EventEmitter<any> = new EventEmitter();
  @Output() onClickRemove: EventEmitter<any> = new EventEmitter();
  advsetsData;
  isHide:boolean=false;
  @Input() isPushAdv:boolean=false;
  @Input() isKm=true;
  constructor() { }

  ngOnInit() {
    this.monthList=NOOfMonths;
    // this.buildNormalForm();
  }

  onKey(data){
    LogData("onKey",data);
  }

  onDiscountValueChange(data){
    this.discount.controls.forEach(element => {
      if(element.value.dis_type_val && element.value.dis_type_val > 100){
        element.patchValue({ dis_type_val: '' });
      }
    });
   
  }

  // buildNormalForm() {
  //   this.commonForm = this.formBuilder.group({
  //     km_other: [""],
  //     price_other: [""],
  //     km_city: [""],
  //     price_city: [""],
  //     discount: this.formBuilder.array([]),
  //   });
  //   this.setDiscountForm();
  // }

  // setDiscountForm() {
  //   const commonform = this.commonForm.get('discount') as FormArray;
  //   if (this.advsetsData && this.advsetsData.businesslistsdetails &&
  //     this.advsetsData.businesslistsdetails.discount &&
  //     this.advsetsData.businesslistsdetails.discount.length > 0) {
  //     this.advsetsData.businesslistsdetails.discount.forEach(element => {
  //       // commonform.push(this.createDiscountForm(element));
  //       // this.contactPersonCount++;
  //     });
  //     setTimeout(() => {
  //       // this.scrollToBottomOnInit();
  //     }, 500);
  //   } else {
  //     commonform.push(this.createDiscountForm());
  //   }
  //   LogData("commonform", commonform.value);
  // }
  // createDiscountForm(data?): FormGroup {
  //   return this.formBuilder.group({
  //     id: [(data && data.id) ? data.id : ''],
  //     month: ["",Validators.required],
  //     discount:['',Validators.required],
  //   });
  // }
  // get discount(): FormArray {
  //   return this.commonForm.get('discount') as FormArray;
  // }
}
export const NOOfMonths=[
  {
    text:1,
    value:1
  },
  {
    text:2,
    value:2
  },
  {
    text:3,
    value:3
  },
  {
    text:4,
    value:4
  },
  {
    text:5,
    value:5
  },
  {
    text:6,
    value:6
  },
  {
    text:7,
    value:7
  },
  {
    text:8,
    value:8
  },
  {
    text:9,
    value:9
  },
  {
    text:10,
    value:10
  },
  {
    text:11,
    value:11
  },
  {
    text:12,
    value:12
  }
];