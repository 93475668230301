<ion-item-divider sticky="fixed" class="ion-no-border" mode="md">
  <div class="flex flex-col w-100">
    <div class="top-line">
      <div class="div-line"></div>
    </div>
    <ion-row class="pt-24 pb-4">
      <ion-col size="10" sizeXl="9" class="ion-align-self-center">
        <ion-text class="lbl-bold-18">{{ title | getLanguagePipe }}</ion-text>
      </ion-col>
      <ion-col size="2" sizeXl="3" class="ion-text-center">
        <ion-icon [src]="'ic_close_ro' | getImagePipe" (click)="closeModal()" class="ft-36"></ion-icon>
      </ion-col>
    </ion-row>
  </div>
</ion-item-divider>
<ion-content>
  <hr class="divider1 w-100" />
  <form [formGroup]="commonform">
    <ion-grid class="pl-16 pr-16">
      <ion-row class="center-row">
        <ion-col style="align-self: center" class="no-margin-padding">
          <!-- <ion-row>
          <ion-col class="mt-12" *ngIf="isBusinessType">
            <ion-label class="lbl-med-12">Select Business Type :</ion-label>
            <ion-item class=" mt-8" lines="none" [ngClass]="selectedType ? 'valid-item' : 'ion-item-bg'"
              (click)="openType()">
              <ion-text slot="start" class="lbl-med-15">
                {{selectedType}}
              </ion-text>
              <ion-icon [name]="isExpand ? 'chevron-up-outline':'chevron-down-outline'" class="ft-20" slot="end">
              </ion-icon>
            </ion-item>
          </ion-col>
        </ion-row> -->
          <ion-row>
            <ion-col class="mt-12" *ngIf="isBusinessType">
              <ion-label class="lbl-med-12">{{ lbl1 | getLanguagePipe }} :</ion-label>
              <ion-item class="mt-8" [ngClass]="selectedType ? 'valid-item' : 'ion-item-bg'" lines="none">
                <ion-select (ionChange)="onChangeBusinessType($event)" interface="popover"
                  formControlName="businesstype_id">
                  <ion-select-option [value]="item.value" *ngFor="let item of businessTypeList">{{ item.text
                    }}</ion-select-option>
                </ion-select>
              </ion-item>
            </ion-col>
          </ion-row>
          <ion-row>
            <!-- <ion-col class="mt-12" *ngIf="isTradingType">
              <ion-label class="lbl-med-12"
                >{{ lbl3 | getLanguagePipe }} :</ion-label
              >
              <ion-item
                class="mt-8"
                [ngClass]="commonform.value.tradingtype_id ? 'valid-item' : 'ion-item-bg'"
                lines="none"
              >
                <ion-select
                  interface="popover"
                  formControlName="tradingtype_id"
                >
                  <ion-select-option
                    [value]="item.value"
                    *ngFor="let item of tradingTypeList"
                    >{{ item.text }}</ion-select-option
                  >
                </ion-select>
              </ion-item>
            </ion-col> -->
            <ion-col size="12" *ngIf="isTradingType">
              <!-- <ion-label class="lbl-med-12"
                >{{ lbl3 | getLanguagePipe }} :</ion-label
              >
              <ion-item class=" mt-8" lines="none"
                [ngClass]="utilsService.getValidCss(commonform.get('tradingtype_id'),isFormSubmit)"
                (click)="openSingleSelection(0,tradingTypeList,'select',commonform.get('tradingtype_id'),commonform.get('tradingtype_id_expand'))">
                <ion-text slot="start" class="lbl-med-15">
                  {{(commonform.value.tradingtype_id && commonform.value.tradingtype_id.length >
                  0)?commonform.value.tradingtype_id[0].text:''}}
                </ion-text>
                <ion-icon [name]="commonform.value.tradingtype_id_expand ? 'chevron-up-outline':'chevron-down-outline'"
                  class="ft-20 cursor-ptr" slot="end">
                </ion-icon>
              </ion-item> -->
              <app-amct-input [controlName]="'tradingtype_id'" [controlExpand]="'tradingtype_id_expand'"
                [cstArry]="tradingTypeList" [cstIndex]="0" [lbl]="lbl3 | getLanguagePipe" [commonForm]="commonform"
                [suffixIcon]="
          commonform.value.tradingtype_id_expand
            ? 'chevron-up-outline'
            : 'chevron-down-outline'
        " [isIonicIocns]="true" [inputType]="inputTypeEnum.CST_SELECTION">
              </app-amct-input>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col class="mt-12" *ngIf="isStateLbl">
              <ion-label class="lbl-med-12">{{ "select_state" | getLanguagePipe }} :</ion-label>
              <ion-item class="mt-8" [ngClass]="
                  commonform.get('state').value ? 'valid-item' : 'ion-item-bg'
                " lines="none">
                <ion-select interface="popover" formControlName="state">
                  <ion-select-option [value]="item.value" *ngFor="let item of stateList">{{ item.text
                    }}</ion-select-option>
                </ion-select>
              </ion-item>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col class="mt-12">
              <ion-label class="lbl-med-12">{{ lbl2 | getLanguagePipe }} :</ion-label>
              <ion-item class="mt-8" [ngClass]="selectedStatus ? 'valid-item' : 'ion-item-bg'" lines="none">
                <!-- <ion-label>{{selectedType}}</ion-label> -->
                <!-- <ion-label class="lbl-med-12">Status</ion-label> -->
                <ion-select interface="popover" formControlName="status">
                  <ion-select-option [value]="item.value" *ngFor="let item of statusList">{{ item.text
                    }}</ion-select-option>
                </ion-select>
              </ion-item>
            </ion-col>
          </ion-row>
          <!-- <ion-row>
          <ion-col class="mt-12">
            <ion-label class="lbl-med-12">Status</ion-label>
            <ion-item class=" mt-8" lines="none" [ngClass]="selectedValue ? 'valid-item' : 'ion-item-bg'"
              (click)="openStatus()">
              <ion-text slot="start" class="lbl-med-15">
                {{selectedValue}}
              </ion-text>
              <ion-icon [name]="isExpand ? 'chevron-up-outline':'chevron-down-outline'" class="ft-20" slot="end">
              </ion-icon>
            </ion-item>
          </ion-col>
        </ion-row> -->

          <ion-row class="mt-16 mb-8">
            <ion-col size="6">
              <ion-button class="btn-1 hp-45" expand="block" mode="ios" (click)="apply()">
                <ion-text class="lbl-12">
                  {{ btn1 | getLanguagePipe }}
                </ion-text>
              </ion-button>
            </ion-col>
            <!-- <ion-col size="0.4">
            </ion-col> -->
            <ion-col size="6">
              <ion-button class="btn-2 hp-45" expand="block" mode="ios" (click)="clearAll()">
                <ion-text class="lbl-12">
                  {{ btn2 | getLanguagePipe }}
                </ion-text>
              </ion-button>
            </ion-col>
          </ion-row>
        </ion-col>
      </ion-row>
    </ion-grid>
  </form>
</ion-content>