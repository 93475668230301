<app-dg-header [title]="title" (onClickClose)="closeModal($event)"></app-dg-header>
<ion-content>
  <hr class="divider1 w-100" />
  <form [formGroup]="commonForm">
    <ion-grid class="pl-16 pr-16">
      <ion-row class="center-row">
        <ion-col style="align-self: center;" class="no-margin-padding">
          <ion-row>
            <ion-col size="6">
              <app-amct-input [controlName]="'start_date'" [commonForm]="commonForm" [inputType]="inputTypeEnum.DATE"
                [lbl]="'lbl_adv_stdate'" [isValidation]="false" [isNoMin]="true" (onDateValue)="onStartDateChange($event)">
              </app-amct-input>
            </ion-col>
            <ion-col size="6">
              <app-amct-input [controlName]="'end_date'" [commonForm]="commonForm" [inputType]="inputTypeEnum.DATE"
                [lbl]="'lbl_adv_edate'" [isValidation]="true" [minDate]="getMinDate(commonForm.value.start_date)">
              </app-amct-input>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col size="12">
              <app-amct-input [controlName]="'status'" [commonForm]="commonForm" [inputType]="inputTypeEnum.SELECTION"
                [lbl]="'lbl_status'" [isValidation]="false" [dataList]="statusList">
              </app-amct-input>
            </ion-col>
          </ion-row>
          <app-dg-buttons [btn1]="btn1" [btn2]="btn2" (onClickCloseBtn1)="apply($event)"
            (onClickCloseBtn2)="clearAll($event)">
          </app-dg-buttons>
        </ion-col>
      </ion-row>
    </ion-grid>
  </form>
</ion-content>