import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { LoadingController, ModalController, PopoverController } from '@ionic/angular';
import { finalize } from 'rxjs/operators';
import { CommonData } from 'src/app/models/common-data';
import { UtilsService } from 'src/app/services/uitils.services';
import { UserServices } from 'src/app/services/user.services';
import { getComplainInquiriesStaffsArr, getComplaininquiriesStatusArr, getComplainUsersArr, getInquriesforArr, PAGE_COMPLAINS, SELECT_LIST_ITEM } from 'src/app/utils/constant';
import { LogData } from 'src/app/utils/utils';
import { AddressComponent } from '../address/address.component';
import { SingleSearchFilterComponent } from '../single-search-filter/single-search-filter.component';

@Component({
  selector: 'app-complains-filter',
  templateUrl: './complains-filter.component.html',
  styleUrls: ['./complains-filter.component.scss'],
})
export class ComplainsFilterComponent implements OnInit {
  commonForm: FormGroup;
  modelist = [];
  @Input() lblStartDate;
  @Input() lblEndDate;
  @Input() btn1 = "apply";
  @Input() btn2 = "clear_all";
  @Input() title = "dg_tl_more_filters";
  @Input() selectedFilter;
  @Input() pageName;
  @Input() isBusinessField: boolean = false;
  @Input() isAssignToField: boolean = false;
  @Input() isInqueryForField: boolean = false;
  statusList: Array<CommonData> = [];
  businessList: Array<CommonData> = [];
  staffList: Array<CommonData> = [];
  inquryForList: Array<CommonData> = [];
  maxDateStart: Date;
  maxDateEnd: Date;
  isHideField: boolean = false;
  @ViewChild(AddressComponent, { static: false }) addressComponent: AddressComponent;
  constructor(public utilsService: UtilsService,
    private formBuilder: FormBuilder,
    private popoverCtrl: PopoverController,
    private userServices: UserServices,
    private loadingCtrl: LoadingController,
    private modalCtrl: ModalController) {
    const currentYear = new Date();
    this.maxDateStart = new Date(currentYear);
    this.maxDateEnd = new Date(currentYear);
  }

  ngOnInit() {
    this.getMasters();
    this.buildForm();
  }

  buildForm() {
    this.commonForm = this.formBuilder.group({
      inquiry_for: [""],
      inquiry_for_expand: [false],
      userprofile_id: [""],
      userprofile_id_expand: [false],
      start_date: [""],
      end_date: [""],
      assign_userprofile_id: [""],
      assign_userprofile_id_expand: [false],
      status: [""],
    });
  }

  async openSingleSelection(index, array, title, formControlId: any, formControlExpand: any) {
    LogData("array", array);
    if (array.length > 0) {
      formControlExpand.setValue(true);
      const modal = await this.modalCtrl.create({
        component: SingleSearchFilterComponent,
        componentProps: { title: title, selectedData: array, selectedId: formControlId.value },
        cssClass: 'custom-modal-filter',
        showBackdrop: true,
        backdropDismiss: false,
        // breakpoints: [0, 0.3, 0.5, 0.8],
        // initialBreakpoint: 0.5
      });

      modal.onDidDismiss().then((data) => {
        LogData("onDidDismiss...", data);
        LogData("onDidDismiss...idx", index);
        if (data.data != null) {
          if (data.data !== SELECT_LIST_ITEM) {
            const selectedId = data.data.value;
            let tempArr = [];
            tempArr.push(data.data);
            formControlId.setValue(tempArr);
            switch (index) {
              case 0:
                LogData("selectedId..",selectedId);
                LogData("isInqueryForField..",this.isInqueryForField);
                if (this.isInqueryForField && selectedId === 3) {
                  this.isHideField=true;
                  this.commonForm.patchValue({
                    start_date:'',
                    end_date:''
                  });
                }else{
                  this.isHideField=false;
                }
                break;
            }
          } else {
            formControlId.setValue("");
            switch (index) {
              case 0:
                if(this.isInqueryForField){
                  this.isHideField = true;
                }else{
                  this.isHideField=false;
                }
                break;
            }
          }

        }
        formControlExpand.setValue(false);
      });

      await modal.present();
    }
  }

  orgValueChange(data) {
    LogData("orgValueChange", data.value);
    this.maxDateEnd = data.value;
    this.commonForm.patchValue({ end_date: '' })
  }
  updateBuildForm() {
    LogData("selectedFilter", this.selectedFilter);
    this.isHideField=false;
    if (this.selectedFilter) {
      this.commonForm.patchValue(this.selectedFilter);
      if(this.selectedFilter.inquiry_for && this.selectedFilter.inquiry_for.length > 0
        && this.selectedFilter.inquiry_for[0].value === 3){
          this.isHideField=true;
      }
      if(this.selectedFilter && this.selectedFilter.start_date){
        this.maxDateEnd = new Date(this.selectedFilter.start_date);
        LogData("max"); 
      }
    }else
    {
      if(this.isInqueryForField){
        this.isHideField=true;
      }
    }
  }

  closeModal(ev) {
    this.popoverCtrl.dismiss();
  }

  apply(ev) {
    this.popoverCtrl.dismiss(this.commonForm.value);
  }

  clearAll(ev) {
    this.buildForm();
    this.utilsService.setAddressMaster({
      page: PAGE_COMPLAINS,
      value: true,
    });
    this.popoverCtrl.dismiss(); 
  }

  //api call
  async getMasters() {
    const loading = await this.loadingCtrl.create(
      this.utilsService.getLoaderUI()
    );
    await loading.present();
    LogData("loader_start");
    let list = "";
    // list = getComplaininquiriesStatusArr + "," + getComplainUsersArr + "," + getComplainInquiriesStaffsArr;
    list = getComplaininquiriesStatusArr;
    if (this.isBusinessField) {
      list = list + "," + getComplainUsersArr
    }
    if (this.isAssignToField) {
      list = list + "," + getComplainInquiriesStaffsArr;
    }
    if (this.isInqueryForField) {
      list = list + "," + getInquriesforArr;
    }
    const formData = new FormData();
    formData.append("master", list);
    this.userServices
      .getMasters(formData)
      .pipe(
        finalize(() => {
          LogData("loader_dismiss");
          loading.dismiss();
        })
      )
      .subscribe(
        (res) => {
          LogData("getMasters_res", res);
          res = this.utilsService.getDecriptReasponse(res);
          if (res && res.result && res.result.responce) {
            if (res.result.responce.getComplaininquiriesStatusArr && res.result.responce.getComplaininquiriesStatusArr.length > 0) {
              this.statusList = res.result.responce.getComplaininquiriesStatusArr;
            } else {
              this.statusList = [];
            }
            if (res.result.responce.getComplainUsersArr && res.result.responce.getComplainUsersArr.length > 0) {
              this.businessList = res.result.responce.getComplainUsersArr;
            } else {
              this.businessList = [];
            }
            if (res.result.responce.getComplainInquiriesStaffsArr && res.result.responce.getComplainInquiriesStaffsArr.length > 0) {
              this.staffList = res.result.responce.getComplainInquiriesStaffsArr;
            } else {
              this.staffList = [];
            }
            if (res.result.responce.getInquriesforArr && res.result.responce.getInquriesforArr.length > 0) {
              this.inquryForList = res.result.responce.getInquriesforArr;
            } else {
              this.inquryForList = [];
            }
          }
          this.updateBuildForm();
        },
        (err) => {
          LogData("loader_dismiss");
          loading.dismiss();
          LogData("getState_error", err);
        }
      );
  }
}
