import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { PopoverController } from "@ionic/angular";
import { Observable, Subscription } from "rxjs";
import { InputTypeEnum } from "src/app/enums/inputtypes-enum";
import { ReviewDetails } from "src/app/models/comments-data";
import { SocialResponseObj } from "src/app/models/social-obj";
import { UserprofileData } from "src/app/models/user-profile-data";
import { AuthServices } from "src/app/services/auth.services";
import { UtilsService } from "src/app/services/uitils.services";
import { UserServices } from "src/app/services/user.services";
import { NEWREVIEW_API } from "src/app/utils/api-end-points";
import { PAGE_COMMENT_POPOVER } from "src/app/utils/constant";
import {
  ID_KEY,
  MODEL_ID_KEY,
  MODEL_KEY,
  PARENT_ID_KEY,
  REVIEW_KEY,
  USERPROFILES_MODEL_KEY,
} from "src/app/utils/params-data";
import { LogData } from "src/app/utils/utils";
import {
  AlphaNumericTextArea,
  ValidationMessages,
} from "src/app/validators/validations";

@Component({
  selector: "app-comment-popover",
  templateUrl: "./comment-popover.component.html",
  styleUrls: ["./comment-popover.component.scss"],
})
export class CommentPopoverComponent implements OnInit, OnDestroy {
  modelist = [];
  @Input() btn1;
  @Input() btn2;
  @Input() title;
  @Input() isCommentLyt: boolean = false;
  comment =
    "At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus, omnis voluptas assu";
  commonForm = this.formBuilder.group({
    review: ["", AlphaNumericTextArea],
  });
  inputTypeEnum = InputTypeEnum;
  isFormSubmit: boolean = false;
  validationMessages = ValidationMessages;
  socialResponse$: Observable<boolean>;
  socialResponseSubscription: Subscription;
  @Input() isReply: boolean = false;
  @Input() selectedId;
  @Input() businessDetailid;
  @Input() commentData: ReviewDetails;
  rpImg;
  rpName;
  rpAddress;
  @Input() isSame: boolean = false;
  rpReview;
  @Input() isOnlyView: boolean = false;
  @Input() isFullWidthText: boolean = true;
  constructor(
    private popoverCtrl: PopoverController,
    private formBuilder: FormBuilder,
    public utilServices: UtilsService,
    private userServices: UserServices,
    public authServices: AuthServices
  ) {
    this.subScriptionCall();
  }

  ngOnInit() {
    // this.isSame=this.authServices.getForId() === parseInt(this.businessDetailid) ?true:false;
    LogData("commentData", this.commentData);
    if (
      this.commentData &&
      this.commentData.ratingschilds &&
      this.commentData.ratingschilds.length > 0
    ) {
      this.commonForm.patchValue({
        review: this.commentData.ratingschilds[0].review,
      });
      this.rpReview = this.commentData.ratingschilds[0].review;
      this.rpName = this.utilServices.getFullName(
        this.commentData.ratingschilds[0],
        true
      );
      this.rpAddress = this.utilServices.getComAddress(
        this.commentData.ratingschilds[0]
      );
      this.rpImg = this.utilServices.getComImage(
        this.utilServices.getComUserDocments(this.commentData.ratingschilds[0])
      );
    } else {
      const userData = this.authServices.getUserLoginData();
      this.rpName = this.utilServices.getFullNameUser(userData);
      this.rpAddress = this.utilServices.getComAddressUser(userData);
      this.rpImg = this.utilServices.getComImageUser(userData);
    }
  }

  subScriptionCall() {
    this.socialResponse$ = this.utilServices.socialResponse$;
    this.socialResponseSubscription = this.socialResponse$.subscribe(
      (value: any) => {
        LogData("socialResponseSubscription...call", value);
        if (value && value.page === PAGE_COMMENT_POPOVER) {
          this.popoverCtrl.dismiss(value.data);
        }
      }
    );
  }

  closeModal(ev?) {
    this.popoverCtrl.dismiss();
  }

  apply(ev?) {
    this.isFormSubmit = true;
    if (this.commonForm.valid) {
      // this.popoverCtrl.dismiss(this.commonForm.value);
      const formData = new FormData();
      formData.append(MODEL_ID_KEY, "" + this.businessDetailid);
      formData.append(MODEL_KEY, USERPROFILES_MODEL_KEY);
      formData.append(REVIEW_KEY, this.commonForm.value.review);
      if (
        this.commentData &&
        this.commentData.ratingschilds &&
        this.commentData.ratingschilds.length > 0
      ) {
        formData.append(ID_KEY, "" + this.commentData.ratingschilds[0].id);
      } else {
        if (this.isReply) {
          formData.append(PARENT_ID_KEY, "" + this.commentData.id);
        }
      }
      const userObj = {
        isSuccessError: true,
        api: NEWREVIEW_API,
        formData: formData,
        page: PAGE_COMMENT_POPOVER,
        isResponse: true,
      } as SocialResponseObj;
      this.userServices.userSocilaBusinessApi(userObj);
    }
  }

  clearAll(ev?) {
    this.closeModal();
  }

  ngOnDestroy(): void {
    LogData("Comment_close");
    this.utilServices.setSocialResponse(null);
    if (this.socialResponseSubscription) {
      this.socialResponseSubscription.unsubscribe();
    }
  }
}
