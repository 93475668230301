import { Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { GeoLocationData } from 'src/app/models/geolocation-data';
import { GeoLocationUtilsService } from 'src/app/services/geolocation-utils.services';
import { LogData } from 'src/app/utils/utils';

@Component({
  selector: 'app-current-geolocation',
  templateUrl: './current-geolocation.component.html',
  styleUrls: ['./current-geolocation.component.scss'],
})
export class CurrentGeolocationComponent implements OnInit {

  addressDisplay;
  geoLocationData:Array<GeoLocationData>=[];
  constructor(private popoverCtrl:PopoverController,
    private geoLocationUtilsService:GeoLocationUtilsService) { }

  ngOnInit() {
    // this.addressDisplay="";
  }

  closeModal(ev){
    this.popoverCtrl.dismiss();
  }

 async getCurrentLocation(){
    this.geoLocationData= await this.geoLocationUtilsService.getCurrentAddress(true);
    LogData("geoLocationData",this.geoLocationData);
    if(this.geoLocationData && this.geoLocationData.length > 0 && this.geoLocationData[0].address_components && 
      this.geoLocationData[0].address_components.length > 0){
      this.addressDisplay=this.geoLocationUtilsService.getAddressFormat(this.geoLocationData[0].address_components);
      console.log("addressDisplay_dg",this.addressDisplay);
    }
  }

  apply(ev){
    this.popoverCtrl.dismiss({location:this.addressDisplay});
  }

}
