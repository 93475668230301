import { Component, Input, OnInit } from '@angular/core';
import { UserprofileData } from 'src/app/models/user-profile-data';
import { AuthServices } from 'src/app/services/auth.services';
import { UtilsService } from 'src/app/services/uitils.services';
import { ADMIN_ROLE_ID } from 'src/app/utils/constant';
import { ADMIN_BUSINESS_DETAIL } from 'src/app/utils/routes';

@Component({
  selector: 'app-business-card',
  templateUrl: './business-card.component.html',
  styleUrls: ['./business-card.component.scss'],
})
export class BusinessCardComponent implements OnInit {

  @Input() isLast;
  @Input() isCst;
  @Input() userProfileData:UserprofileData;
  @Input() isCustomer:boolean=false;
  CONST_DATA={
    ADMIN_ROLE_ID
  };
  constructor(public utilServices:UtilsService,
    public authServices:AuthServices) { }

  ngOnInit() {}

  isActiveStatus(){
    let flag=false;
    if(this.userProfileData && this.userProfileData.status.length > 0 && 
      this.userProfileData.status[0].value === 1){
        flag=true;
    }
    return flag;
  }

 

}
