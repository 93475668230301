import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'characterCountPipe'
})
export class CharacterCountPipe implements PipeTransform {

    transform(value,charsAllowed): any {
        return value ? (value.length +"/"+charsAllowed):'';
    }
}
