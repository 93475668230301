<ion-grid>
  <ion-row class="center-row">
    <ion-col style="align-self: center" class="no-margin-padding" [attrSizeXl]="'5'" appSizeAttribute>
      <div class="bus-details-card">
        <ion-row>
          <ion-col class="mt-12">
            <app-name-icon [isTranslatelbl2]="false" [icon]="'location_bl'" [lable1]="'lbl_bus_dl_tl_loc'" [lable2]="addressStr">
            </app-name-icon>
            <hr class="divider2 w-100 mt-24" />
            <!-- <ion-img [src]="'map_img' | getImagePipe:2" class="m-16"></ion-img> -->
            <!-- <ng-container > -->
              <!-- <div  id="map" [ngClass]="isLoadMap ? 'map-div':''"></div> -->
            <!-- </ng-container> -->
            <div  id="map"[ngClass]="(isLoadMap && authServices.getUserLoginData()) ? 'map-div':''"></div>
            <ng-container *ngIf="(businessDetail.lat && businessDetail.lng) && !authServices.getUserLoginData()">
              <div class="flex-col-center p-12 ion-text-center">
                <ion-text><span class="lbl-16">{{'lbl_to_view_location_bus_plz' | getLanguagePipe}}&nbsp;</span>
                  <span class="lbl-sec-16 cursor-ptr " (click)="openLogin()">{{'lbl_login_here' | getLanguagePipe}}</span></ion-text>
                </div>
            </ng-container>
          </ion-col>
        </ion-row>
      </div>
    </ion-col>
  </ion-row>
</ion-grid>