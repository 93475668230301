import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gallary-setting-card',
  templateUrl: './gallary-setting-card.component.html',
  styleUrls: ['./gallary-setting-card.component.scss'],
})
export class GallarySettingCardComponent implements OnInit {

  gsCount=0;
  constructor() { }

  ngOnInit() {}

  incCount(){
  this.gsCount++;
  }

  decCount(){
    if(this.gsCount > 0){
      this.gsCount--;
    }else{
      this.gsCount=0;
    }
  }

}
