import { Directive, Input, ElementRef, OnInit } from '@angular/core';

@Directive({
  selector: '[appSizeAttribute]'
})
export class SizeAttributeDirective implements OnInit {

  @Input() attrSizeXs: string = "12";
  @Input() attrSizeSm: string = "12";
  @Input() attrSizeMd: string = "6";
  @Input() attrSizeLg: string = "4";
  @Input() attrSizeXl: string = "4";
  constructor(private elementRef: ElementRef) {

  }

  ngOnInit() {
    this.elementRef.nativeElement.sizeXs = this.attrSizeXs;
    this.elementRef.nativeElement.sizeSm = this.attrSizeSm;
    this.elementRef.nativeElement.sizeMd = this.attrSizeMd;
    this.elementRef.nativeElement.sizeLg = this.attrSizeLg;
    this.elementRef.nativeElement.sizeXl = this.attrSizeXl;
    // LogData("attrSizeXs,,, ", this.attrSizeXs);
    // LogData("attrSizeSm,,, ", this.attrSizeSm);
    // LogData("attrSizeMd,,, ", this.attrSizeMd);
    // LogData("attrSizeLg,,, ", this.attrSizeLg);
    // LogData("attrSizeXl,,, ", this.attrSizeXl);
  }

}
