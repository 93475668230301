import { EDIT_EMPLOYEES_API } from "src/app/utils/api-end-points";
import { CHANGE_PASSWORD, COMPLAINS_LIST, EDIT_EMPLOYEE_DETAILS, EMPLOYEE_TAB1_ROUTE, EMPLOYEE_TAB2_ROUTE, EMPLOYEE_TAB6_ROUTE, INQUIRE_LIST, PRIVACY_POLICY, TAB1_ROUTE, TAB2_ROUTE, TERMS_CONDITION } from "src/app/utils/routes";
import { COMPLAINS_MC, INQUIRIES_MC } from "./menu-code";

export const EmployeeMenuData = [
  {
    id: 1000,
    title: "mn_visit_website",
    url: EMPLOYEE_TAB1_ROUTE,
    icon: "ic_visit_web",
    isChecked: false,

  },
  {
    id: 1,
    title: "profile",
    url: EMPLOYEE_TAB2_ROUTE,
    icon: "profile_sm",
    isChecked: false,
  },
  {
    id: 4,
    title: 'change_password',
    icon: 'mn_cp',
    url: CHANGE_PASSWORD
  },
  {
      id: 99,
      title: "inquiries",
      url: INQUIRE_LIST,
      icon: "inq_sm",
      isChecked: false,
      menuCode: INQUIRIES_MC
    },
    {
      id: 1,
      title: 'personal_information',
      icon: 'ic_pur_info',
      url: EDIT_EMPLOYEE_DETAILS,
      isChecked: false,
    },
  // {
  //   id: 14,
  //   title: "notifications",
  //   // url: TAB4_ROUTE,
  //   url: '',
  //   icon: "notification_sm",
  //   isChecked: false,
  // },
  {
    id: 15,
    title: "mn_contactus",
    url: EMPLOYEE_TAB6_ROUTE,
    icon: "mn_con_us",
    isChecked: false,
  },
  // {
  //   id: 16,
  //   title: "chats",
  //   // url: TAB5_ROUTE,
  //   url: '',
  //   icon: "mn_chat",
  //   isChecked: false,
  // },
  {
    id: 17,
    title: "mn_tickets",
    url: COMPLAINS_LIST,
    icon: "loadge_sm",
    isChecked: false,
    menuCode: COMPLAINS_MC
  },
  {
    id: 18,
    title: "privacy",
    url: PRIVACY_POLICY,
    icon: "privacy_sm",
    isChecked: false,
  },
  {
    id: 19,
    title: "terms_of_use",
    url: TERMS_CONDITION,
    icon: "term_sm",
    isChecked: false,
  },
  // {
  //   id: 20,
  //   title: "mn_pricing",
  //   url: TABS_PRICING,
  //   icon: "mn_pricing",
  //   isExpand: false,
  //   isChecked: false,
  // },
];
