import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LoadingController, PopoverController } from '@ionic/angular';
import { finalize } from 'rxjs/operators';
import { ReviewDetails } from 'src/app/models/comments-data';
import { CommonData } from 'src/app/models/common-data';
import { AuthServices } from 'src/app/services/auth.services';
import { UtilsService } from 'src/app/services/uitils.services';
import { UserServices } from 'src/app/services/user.services';
import {  UPDATE_STATUS_COMMENTS_LIST_API } from 'src/app/utils/api-end-points';
import { BUSINESS_ROLE_ID } from 'src/app/utils/constant';
import { CURRENTSTATUS_KEY, RECORDID_KEY } from 'src/app/utils/params-data';
import { LogData } from 'src/app/utils/utils';
import { CommentPopoverComponent } from '../comment-popover/comment-popover.component';

@Component({
  selector: 'app-comment-card',
  templateUrl: './comment-card.component.html',
  styleUrls: ['./comment-card.component.scss'],
})
export class CommentCardComponent implements OnInit {

  @Input() commentsData: ReviewDetails=null;
  @Output() getItems: EventEmitter<any> = new EventEmitter();
  @Input() checkedCount;
  @Input() redirectUrl;
  @Input() queryparam;
  @Input() businessId;
  isLongPressClick: boolean = false;
  isActive =false;
  comment = "At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus, omnis voluptas assumenda est, omnis dolor repellendus. Temporibus autem quibusdam et aut officiis debitis aut rerum necessitatibus saepe eveniet ut et voluptates repudiandae sint et molestiae non recusandae. Itaque earum rerum hic tenetur a sapiente delectus, ut aut reiciendis voluptatibus maiores alias consequatur aut perferendis doloribus asperiores repellat."
  comment1 = "On the other hand, we denounce with righteous indignation and dislike men who are so beguiled and demoralized by the charms of pleasure of the moment"
  isBusiness: boolean = false;
  isSame:boolean=false;
  constructor(public utilsService: UtilsService,
    private popCtrl: PopoverController, private authServices: AuthServices,
    private lodingCtrl:LoadingController,
    private userServices:UserServices,
    private authservices:AuthServices) { }

  ngOnInit() {
    this.isActive=(this.commentsData && this.commentsData.status && this.commentsData.status.length > 0 && this.commentsData.status[0].value === 1) ? true:false;
    this.isBusiness = this.authServices.getUserRoll() === BUSINESS_ROLE_ID ? true : false;
  }

  onChangeToggel(ev) {
    LogData("onChangeToggel", ev);
    this.statusUpdate();
  }


  getStatus(status: Array<CommonData>) {
    return (status && status.length > 0) ? status[0].value : 2;
  }

  onClickItem() {
    // this.setCheckedData();
    LogData("onClickItem", this.isLongPressClick);
    if (!this.isBusiness) {
      if (!this.isLongPressClick) {
        if (this.checkedCount === 0) {
          this.openComment(true,false);
        } else {
          this.setCheckedData();
        }
      } else {
        this.isLongPressClick = false;
      }
    } else {
      LogData("businessId",this.businessId);
      if(this.businessId){
        this.isSame=this.authServices.getForId() === parseInt(this.businessId) ?true:false;
      }else{
        this.isSame=true;
      }
      LogData("isSame",this.isSame);
      this.openComment((this.isSame ? false:true),this.isSame);
    }

  }

  setCheckedData() {
    if (this.commentsData.isChecked) {
      this.commentsData.isChecked = false;
    } else {
      this.commentsData.isChecked = true;
    }
    this.getItems.emit(true);
  }

  onPress($event) {
    if (!this.isBusiness) {
      LogData("onPress", $event);
      this.isLongPressClick = true;
      this.setCheckedData();
    }
  }

  onPressUp($event) {
    LogData("onPressUp", $event);

  }

  async openComment(isOnlyView,isReply) {
    LogData("isOnlyView",isOnlyView);
    LogData("isReply",isReply);
    const modal = await this.popCtrl.create({
      component: CommentPopoverComponent,
      componentProps: {
        title: "dg_lbl_comment",
        btn1: "submit",
        btn2: "cancel",
        isReply:isReply,
        isSame:this.isSame,
        commentData:this.commentsData,
        isCommentLyt: true,
        isOnlyView:isOnlyView,
        businessDetailid:this.businessId ? this.businessId:this.commentsData ? this.commentsData.model_id:''
      },
      cssClass: "custom-popover pop-width-50",
      showBackdrop: true,
      backdropDismiss: false,
    });

    modal.onDidDismiss().then((data) => {
      LogData("onDidDismiss...", data);
      if (data.data != null) {
        if(this.commentsData && this.commentsData.ratingschilds &&
           this.commentsData.ratingschilds.length > 0){
            this.commentsData.ratingschilds[0]=data.data.Ratings;
        }else{
          let tempArray=[];
          tempArray.push(data.data.Ratings);
          this.commentsData['ratingschilds']=tempArray;
        }
      }
    });

    await modal.present();
  }

  async statusUpdate() {
    const loading = await this.lodingCtrl.create(
      this.utilsService.getLoaderUI()
    );
    await loading.present();
    LogData("loader_start");
    let tempArray=[];
    tempArray.push(this.commentsData.id);
    const formData=new FormData();
    formData.append(RECORDID_KEY, JSON.stringify(tempArray));
    formData.append(CURRENTSTATUS_KEY,this.isActive ? "1":"2");
    this.userServices.updateStatusForListData(
      formData,
      UPDATE_STATUS_COMMENTS_LIST_API,
      this.authservices.getUserToken()
    )
      .pipe(
        finalize(() => {
          LogData("loader_dismiss");
          loading.dismiss();
        })
      )
      .subscribe(
        (res) => {
          LogData("updateStatusFor_res", res);
          res = this.utilsService.getDecriptReasponse(res);
          if (res) {
            this.utilsService.presentToast(res.result.success);
          }
        },
        (err) => {
          LogData("loader_dismiss");
          loading.dismiss();
          LogData("updateStatusForListData_error", err);
          this.userServices.logoutApiCall(err);
        }
      );
  }

}
