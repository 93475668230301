import { Pipe, PipeTransform } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
    name: 'firstLatterPipe'
})
export class FirstLatterPipe implements PipeTransform {

    constructor() {

    }
    transform(value1:string,value2:string): string {
        return (value1 && value2) ? value1.substr(0,1).toUpperCase()+". "+value2:(value1 && !value2)?value1:(!value1 && value2)?value2:"";
      }
}
