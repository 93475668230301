<ion-item-divider sticky="fixed" class="ion-no-border" mode="md">
  <div class="flex flex-col w-100">
    <div class="top-line">
      <div class="div-line"></div>
    </div>
    <ion-row class="pt-24 pb-4">
      <ion-col size="10" sizeXl="9" class="ion-align-self-center">
        <ion-text class="lbl-bold-18">{{ title | getLanguagePipe }}</ion-text>
      </ion-col>
      <ion-col size="2" sizeXl="3" class="ion-text-center">
        <ion-icon [src]="'ic_close_ro' | getImagePipe" (click)="closeModal()" class="ft-36"></ion-icon>
      </ion-col>
    </ion-row>
  </div>
</ion-item-divider>
<ion-content>
  <form [formGroup]="commonForm" *ngIf="commonForm">
    <ion-grid class="p-12">
      <ion-row class="center-row">
        <ion-col style="align-self: center;" class="no-margin-padding" size="12">
          <ion-row>
            <ion-col class="mt-12" size="12">
              <ion-label class="lbl-med-12">{{ 'lbl_enter_amt' |  getLanguagePipe }} :</ion-label>
              <ion-item lines="none" class="mt-4"
                [ngClass]="utilsService.getValidCss(commonForm.get('amount'),isFormSubmit)">
                <ion-input id="ionInput" (ionInput)="utilsService.onlyPositiveNumber($event)" [ngClass]="commonForm.value.amount ? 'amount':''" formControlName="amount"  type="number" 
                >
                </ion-input>
              </ion-item>
              <app-validation [validationMessages]="validationMessages.transfer_amt" [ValidationForm]="commonForm"
                [type]="'amount'" [isFormSubmit]="isFormSubmit"></app-validation>
            </ion-col>

            <ion-col class="mt-12" size="12">
              <ion-label class="lbl-med-12">{{ 'lbl_memberid' |  getLanguagePipe }} :</ion-label>
              <ion-item lines="none" class="mt-4"
                [ngClass]="utilsService.getValidCss(commonForm.get('uniqueid'),isFormSubmit)">
                <ion-input id="ionInput" formControlName="uniqueid"  type="text" 
                >
                </ion-input>
              </ion-item>
              <app-validation [validationMessages]="validationMessages.transfer_memberid" [ValidationForm]="commonForm"
                [type]="'uniqueid'" [isFormSubmit]="isFormSubmit"></app-validation>
            </ion-col>
  
  
          </ion-row>
          <ion-row class="mt-16 mb-8">
            <ion-col size="6">
              <ion-button class="btn-1 hp-45" expand="block" mode="ios" (click)="transfer()">
                <ion-text class="lbl-12">
                  {{ btn1 | getLanguagePipe }}
                </ion-text>
              </ion-button>
            </ion-col>
            <!-- <ion-col size="0.4">
            </ion-col> -->
            <ion-col size="6">
              <ion-button class="btn-2 hp-45" expand="block" mode="ios" (click)="cancel()">
                <ion-text class="lbl-12">
                  {{ btn2 | getLanguagePipe }}
                </ion-text>
              </ion-button>
            </ion-col>
          </ion-row>
        </ion-col>
      </ion-row>
      </ion-grid>
      </form>
      </ion-content>