import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ChatSettings } from 'src/app/models/chat-setting';
import { CommonData } from 'src/app/models/common-data';
import { ImageExtension } from 'src/app/models/doc-ext';
import { MessageTableData } from 'src/app/models/message-data';
import { CurrentUserData } from 'src/app/models/user-data';
import { ImageData } from 'src/app/models/user-profile-data';
import { AuthServices } from 'src/app/services/auth.services';
import { LogData } from 'src/app/utils/utils';

@Component({
  selector: 'app-chat-card',
  templateUrl: './chat-card.component.html',
  styleUrls: ['./chat-card.component.scss'],
})
export class ChatCardComponent implements OnInit {

  seletectedText="select";
  @Input() messageData: MessageTableData;
  @Output() getItems: EventEmitter<any> = new EventEmitter();
  @Input() checkedCount=0;
  @Input() selectedUserId;
  @Input() chatSetting:ChatSettings;
  @Input() SendersReceivers;
  isLongPressClick: boolean = false;
  userData:CurrentUserData;
  oppUserData:CurrentUserData;
  userMsg:string="";
  oppUserMsg:string="";
  userImage;
  constructor(public authServices:AuthServices) {
    
   }

  ngOnInit() {
    this.getUserData();
  }

  onChangeAlert(ev){
    this.seletectedText=ev.detail.value;
    LogData("seletectedText...",this.seletectedText);
    
  }

  isDocument(){
    let isDoc=false;
    if(this.messageData.documents && this.messageData.documents.length > 0){
      isDoc=true;
    }
    return isDoc;
  }

  getImage(){
    let image="";
    if(this.messageData.documents[0].display_files && this.messageData.documents[0].display_files.messagedetail_doc){
      if(this.messageData.documents[0].display_files.messagedetail_doc.main_url){
        image=this.messageData.documents[0].display_files.messagedetail_doc.main_url;
      }
      if(this.messageData.documents[0].display_files.messagedetail_doc.square_url){
        image=this.messageData.documents[0].display_files.messagedetail_doc.square_url;
      }
    }
    return image;
  }

  isImage(){
    let isImage=false;
    const file_ext=this.messageData.documents[0].messagedetail_doc.split(".").pop();
    if (ImageExtension.indexOf(file_ext) !== -1) {
      isImage=true;
    }
    return isImage;
  }

  // getUserData() {
  //   if (
  //     (this.messageData &&
  //       this.messageData.receiver_id &&
  //       this.messageData.receiver_id.length > 0) ||
  //     (this.messageData.sender_id && this.messageData.sender_id.length > 0)
  //   ) {
  //     if (
  //       this.messageData.receiver_id[0].id !==
  //       this.authServices.getLoginUserId()
  //     ) {
  //       this.userData = this.messageData.receiver_id[0];
  //       this.userMsg= this.messageData.rmsg;
  //       this.getUserImage();
  //     }else{
  //       // this.oppUserData=this.messageData.receiver_id[0];
  //       // this.oppUserMsg= this.messageData.rmsg;
  //     }
      
  //     if (
  //       this.messageData.sender_id[0].id !== this.authServices.getLoginUserId()
  //     ) {
  //       this.userData = this.messageData.sender_id[0];
  //       this.userMsg= this.messageData.smsg;
  //       this.getUserImage();
  //     }else{
  //       // this.oppUserData = this.messageData.sender_id[0];
  //       // this.oppUserMsg= this.messageData.smsg;
  //     }
  //   }
  //   // console.log("userData",this.userData);
  //   // console.log("oppUserData",this.oppUserData);
  // }
  getUserData() {
    if(this.messageData &&
        (this.messageData.receiver_id || this.messageData.sender_id)) {
      if (
        this.messageData.receiver_id !==
        this.authServices.getLoginUserId()
      ) {
        // this.userData = this.messageData.receiver_id[0];
        this.userData = this.SendersReceivers[this.messageData.receiver_id];
        this.userMsg= this.messageData.smsg;
        this.getUserImage();
      }else{
      }
      
      if (
        this.messageData.sender_id !== this.authServices.getLoginUserId()
      ) {
        // this.userData = this.messageData.sender_id[0];
        this.userData = this.SendersReceivers[this.messageData.sender_id];
        this.userMsg= this.messageData.rmsg;
        this.getUserImage();
      }else{
      }
    }
  }
  getUserImage(){
    // if(this.userData.user_type === BUSINESSES_USER_TYPE){
    //   this.userImage=this.utilsService.getLogoImageDisplay(this.userData);
    // }else{
    //   this.userImage=this.utilsService.getProfileDisplayImg(this.userData);
    // }
    if(this.userData.documents && this.userData.documents.length > 0){
      let image_data:ImageData;
      if(this.userData.documents[0].field && this.userData.documents[0].display_files[this.userData.documents[0].field]){
        image_data=this.userData.documents[0].display_files[this.userData.documents[0].field];
        if(image_data.square_url){
          this.userImage=image_data.square_url;
        }else if(image_data.main_url){
          this.userImage=image_data.main_url;
        }
      }
      
    }
  }

  setCheckedData() {
    if (this.messageData.isChecked) {
      this.messageData.isChecked = false;
    } else {
      this.messageData.isChecked = true;
    }
    this.getItems.emit(true);
  }

  onClickItem() {
    // this.setCheckedData();

    if (!this.isLongPressClick) {
      if (this.checkedCount === 0) {
        LogData("checkedCount",this.checkedCount);
      } else {
        this.setCheckedData();
      }
    } else {
      this.isLongPressClick = false;
    }
  }

  onPress($event) {
    LogData("onPress", $event);
    this.isLongPressClick=true;
    this.setCheckedData();
  }

  onPressUp($event) {
    LogData("onPressUp", $event);
  }

}
