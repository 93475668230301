import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { IonSearchbar } from '@ionic/angular';
import { LogData } from 'src/app/utils/utils';

@Component({
  selector: 'app-item-selection',
  templateUrl: './item-selection.component.html',
  styleUrls: ['./item-selection.component.scss'],
})
export class ItemSelectionComponent implements OnInit {

  @Input() commonItems = [];
  @Input() otherData: string;
  @Input() isSingleSelection: boolean = false;
  @Input() isHideOtherValue: boolean = false;
  @Input() isCheckboxUI:boolean=false;
  isCustomeCheck: boolean = false;
  isFormSubmit: boolean = false;
  isSelectedItem: boolean = false;
  isFormSave: boolean = false;
  @Output() onSelectedItem: EventEmitter<any>;
  searchItems: any = [];
  message: string;
  searching;
  public searchTerm: string = "";
  @ViewChild(IonSearchbar, { static: false }) private search: IonSearchbar;
  constructor() {
    LogData("isCustomeCheck...const", this.isCustomeCheck);
    LogData("otherData...final", this.otherData);
    this.onSelectedItem = new EventEmitter<any>();
  }

  ngOnInit() {
    this.searchItems=this.commonItems;
    LogData("searchItems",this.searchItems);
    this.setFilteredItems();
    setTimeout(()=>{
      LogData("isHideOtherValue...",this.isHideOtherValue);
      LogData("commonItems...before",this.commonItems);
      if(this.isHideOtherValue){
        for(let i=0;i<this.commonItems.length;i++){
          if(this.commonItems[i].id === "Other"){
            this.commonItems.splice(i,1);
            break;
          }
        }
      }
      LogData("commonItems...after",this.commonItems);
    },200);
    
  }

  selectedItem(value) {
    if (value.id === 'Other') {
      if (value.isChecked) {
        this.isCustomeCheck = false;
        this.otherData = "";
      } else {
        this.isCustomeCheck = true;

      }
    }
    if (value.isChecked) {
      value.isChecked = false;
    } else {
      value.isChecked = true;
    }

    // this.getSelectedItem(value);
    this.onSelectedItem.emit({ data: this.searchItems, other: this.otherData, isCustomeCheck: this.isCustomeCheck });
  }

  onChangeCheckboxValue(ev){
    LogData("onChangeCheckboxValue",ev);
    this.onSelectedItem.emit({ data: this.searchItems, other: this.otherData, isCustomeCheck: this.isCustomeCheck });

  }

  submitData() {
    this.isFormSubmit = true;
  }

  getSelectedItem(selectedValue?) {
    this.isFormSave = true;
    for (let i = 0; i < this.searchItems.length; i++) {
      if (this.isSingleSelection) {
        this.isSelectedItem = false;
        if (selectedValue.id === this.searchItems[i].id) {
          if (selectedValue.id !== 'Other') {
            this.isCustomeCheck = false;
            this.otherData = "";
          }
          this.isSelectedItem = true;
          this.searchItems.forEach(element => {
            if (selectedValue.id !== element.id) {
              element.isChecked = false;
            }
          });
          break;
        }
      } else {
        if (this.searchItems[i].isChecked) {
          this.isSelectedItem = true;
          break;
        } else {
          this.isSelectedItem = false;
        }
      }

    }
  }

  //search item
  getSearchValue(event){
    this.searching = false;
    if(event){
      this.searchTerm = event.target.value;
      this.setFilteredItems();
    }else{
      this.searchTerm='';
      this.setFilteredItems();
    }
  }

  setFilteredItems() {
    this.commonItems = this.filterItemsByName(this.searchItems, this.searchTerm);
  }

  filterItemsByName(searchItems,searchTerm) {
    return searchItems.filter(item => {
      return item.text.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1;
    });
  }

  onClear() {
    this.searchTerm = '';
    this.setFilteredItems();
  }


}
