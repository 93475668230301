import { Component, Input, NgZone, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IonContent, LoadingController, ModalController, PopoverController } from '@ionic/angular';
import { element } from 'protractor';
import { finalize } from 'rxjs/operators';
import { CommonData } from 'src/app/models/common-data';
import { UserprofileData } from 'src/app/models/user-profile-data';
import { AuthServices } from 'src/app/services/auth.services';
import { UtilsService } from 'src/app/services/uitils.services';
import { UserServices } from 'src/app/services/user.services';
import { DEALER_ROLE_ID, getStatesArr, getStatusArr, SELECT_LIST_ITEM, USERREGISTERAREAS_SC } from 'src/app/utils/constant';
import { NOOTHER_KEY } from 'src/app/utils/params-data';
import { LogData } from 'src/app/utils/utils';
import { ValidationMessages } from 'src/app/validators/validations';
import { ConfirmationComponent } from '../confirmation/confirmation.component';
import { MultiSelectionComponent } from '../multi-selection/multi-selection.component';
import { SingleSearchFilterComponent } from '../single-search-filter/single-search-filter.component';

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss'],
})
export class AddressComponent implements OnInit {


  commonForm: FormGroup;
  validationMessages = ValidationMessages;
  stateList: Array<CommonData> = [];
  districtList: Array<CommonData> = [];
  talukaList: Array<CommonData> = [];
  villageList: Array<CommonData> = [];
  zoneLevelVillageList: Array<CommonData> = [];
  villageAreaList: Array<CommonData> = [];
  statusList: Array<CommonData> = [];
  isFormSubmit: boolean = false;
  title;
  @Input() userProfileData: UserprofileData;
  serverSideError: string;
  @Input() isState: boolean = false;
  @Input() isDistrict: boolean = false;
  @Input() isTaluka: boolean = false;
  @Input() isVillage: boolean = false;
  @Input() isVillageArea: boolean = false;
  @Input() isStatus: boolean = true;
  @Input() pageName;
  @Input() isMultiple: boolean = false;
  @Input() isValidation: boolean = false;
  @Input() isTalukaisCity: boolean = false;
  @Input() isTalukaVal = true;
  @Input() isDistrictVal = true;
  @Input() isVillageVal = true;
  deletedAddressList = [];
  @Input() isNoOther: boolean = false;
  @Input() isFilter: boolean = false;
  @Input() isZoneLevelVillages: boolean = false;
  @Input() isZoneDisplay = false;
  @Input() module;
  @Input() isVillageMultiple:boolean=false;
  @Input() statusMaster;
  selectedVillagesList = [];
  zoneList;
  @ViewChild(IonContent, { static: false }) private content: IonContent;
  @Output() onChangeTaluka: EventEmitter<any> = new EventEmitter();
  constructor(private modalCtrl: ModalController,
    private formBuilder: FormBuilder,
    public utilsService: UtilsService,
    private loadingCtrl: LoadingController,
    private userServices: UserServices,
    private authServices:AuthServices,
    private ngZone: NgZone, private popCtrl: PopoverController) {
   LogData("add_com", this.userProfileData);
  }

  ngOnInit() {
    if (this.isState || this.isStatus) {
      this.getMasters();
    }
    this.buildForm();
  }

  getIdValue(commonArry: Array<CommonData>, isId?) {
    let text, value;
    let isValue = false;
    if (this.userProfileData && commonArry && commonArry.length > 0) {
      isValue = true;
      text = commonArry[0].text;
      value = commonArry[0].value;
    }
    return isValue ? (isId ? value : text) : '';
  }

  // buildForm1() {
  //   this.commonForm = this.formBuilder.group({
  //     state_id: [(this.userProfileData && this.userProfileData.state_id && this.userProfileData.state_id.length > 0) ? this.userProfileData.state_id : ''],
  //     state_expand: [false],
  //     district_id: [(this.userProfileData && this.userProfileData.district_id && this.userProfileData.district_id.length > 0) ? this.userProfileData.district_id : ''],
  //     district_expand: [false],
  //     taluka_id: [(this.userProfileData && this.userProfileData.taluka_id && this.userProfileData.taluka_id.length > 0) ? this.userProfileData.taluka_id : ''],
  //     taluka_expand: [false],
  //     village_id: [(this.userProfileData && this.userProfileData.village_id && this.userProfileData.village_id.length > 0) ? this.userProfileData.village_id : ''],
  //     village_expand: [false],
  //     villagearea_id: [(this.userProfileData && this.userProfileData.villagearea_id && this.userProfileData.villagearea_id.length > 0) ? this.userProfileData.villagearea_id : ''],
  //     village_area_expand: [false],
  //     status: [(this.userProfileData && this.userProfileData.status) ? this.userProfileData.status : ''],
  //   });
  //   this.setInitApiCalls();
  // }

  buildForm() {
    this.commonForm = this.formBuilder.group({
      addressDetails: this.formBuilder.array([]),
    });

    this.setContactPerson();
    this.setInitApiCalls();
   LogData("commonform_address", this.commonForm);
    this.isFormSubmit = false;
    if(this.isVillageMultiple){
      this.setUpdatatedVillageList();
    }
  }

  createCommonForm(data?): FormGroup {
    if(data && data.taluka_id && data.taluka_id.length > 0){
      this.onChangeTaluka.emit(true);
    }
    return this.formBuilder.group({
      id: [(data && data.id) ? data.id : ''],
      state_id: [(data && data.state_id && data.state_id.length > 0) ? data.state_id : ''],
      state_expand: [false],
      district_id: [(data && data.district_id && data.district_id.length > 0) ? data.district_id : ''],
      district_expand: [false],
      districtList: [],
      taluka_id: [(data && data.taluka_id && data.taluka_id.length > 0) ? data.taluka_id : ''],
      taluka_expand: [false],
      is_city: [(data && data.taluka_id && data.taluka_id.length > 0 && data.taluka_id[0].is_city === 1) ? data.taluka_id[0].is_city : ''],
      talukaList: [],
      zone_name: [],
      village_id: [(data && data.village_id && data.village_id.length > 0) ? data.village_id : ''],
      village_expand: [false],
      villagearea_id: [(data && data.villagearea_id && data.villagearea_id.length > 0) ? data.villagearea_id : ''],
      village_area_expand: [false],
      status: [(data && data.status) ? data.status : ''],
    });

   

  }


  setContactPerson() {
   LogData("userprofile", this.userProfileData);
    if (this.isMultiple) {
      const commonform = this.commonForm.get('addressDetails') as FormArray;
      if (this.userProfileData && this.userProfileData.userregisterareas &&
        this.userProfileData.userregisterareas.length > 0) {
        this.userProfileData.userregisterareas.forEach(element => {
          commonform.push(this.createCommonForm(element));
          // this.contactPersonCount++;
        });
        setTimeout(() => {
          this.scrollToBottomOnInit();
        }, 500);
      } else {
        commonform.push(this.createCommonForm());
      }
    } else {
      const commonform = this.commonForm.get('addressDetails') as FormArray;
     LogData("addres_userprofile", this.userProfileData);
      if (this.userProfileData) {
        commonform.push(this.createCommonForm(this.userProfileData));
      } else {
        commonform.push(this.createCommonForm());
      }

    }

    this.setAddressValidation();
  }

  setAddressValidation() {
    if (this.isValidation) {
      this.addressDetails.controls.forEach((element, index) => {
        this.utilsService.setValidation(this.addressDetails.at(index).get('state_id'));
        if (this.isDistrictVal) {
          this.utilsService.setValidation(this.addressDetails.at(index).get('district_id'));
        } else {
          this.utilsService.clearValidation(this.addressDetails.at(index).get('district_id'));
        }
        if (this.isTalukaVal) {
          this.utilsService.setValidation(this.addressDetails.at(index).get('taluka_id'));
        } else {
          this.utilsService.clearValidation(this.addressDetails.at(index).get('taluka_id'));
        }
        if (this.isVillageVal) {
          this.utilsService.setValidation(this.addressDetails.at(index).get('village_id'));
        } else {
          this.utilsService.clearValidation(this.addressDetails.at(index).get('village_id'));
        }
      });
    } else {
      this.addressDetails.controls.forEach((element, index) => {
        this.utilsService.clearValidation(this.addressDetails.at(index).get('state_id'));
        this.utilsService.clearValidation(this.addressDetails.at(index).get('district_id'));
        this.utilsService.clearValidation(this.addressDetails.at(index).get('taluka_id'));
        this.utilsService.clearValidation(this.addressDetails.at(index).get('village_id'));
      });
    }
  }

  setValidationForDealer(){
    const isDealer=this.authServices.getUserRoll() === DEALER_ROLE_ID ? true:false;
    if(isDealer && this.module === USERREGISTERAREAS_SC){
      const index=0;
      if (this.districtList && this.districtList.length > 0) {
        this.utilsService.setValidation(this.addressDetails.at(index).get('district_id'));
      } else {
        this.utilsService.clearValidation(this.addressDetails.at(index).get('district_id'));
      }
      if (this.talukaList && this.talukaList.length > 0) {
        this.utilsService.setValidation(this.addressDetails.at(index).get('taluka_id'));
      } else {
        this.utilsService.clearValidation(this.addressDetails.at(index).get('taluka_id'));
      }
      if (this.addressDetails.at(index).get('zone_name').value && this.villageList && this.villageList.length > 0) {
        this.utilsService.setValidation(this.addressDetails.at(index).get('village_id'));
      } else {
        this.utilsService.clearValidation(this.addressDetails.at(index).get('village_id'));
      }
    }
  }

  scrollToBottomOnInit() {
    this.content.scrollToBottom(300);
  }


  addMoreDetail() {
    const commonform = this.commonForm.get('addressDetails') as FormArray;
    commonform.push(this.createCommonForm());
    // this.contactPersonCount++;
    this.setAddressValidation();
    setTimeout(() => {
      this.scrollToBottomOnInit();
    }, 500);
  }

  removeDetail(item, i: number) {
   LogData("item", item);

    const commonform = this.commonForm.get('addressDetails') as FormArray;
    if (commonform.length > 1) {
      this.deletedAddressList.push({ id: item.id, deleted: this.utilsService.getDateTimeFormat(new Date().toISOString(), "YYYY-MM-DD HH:mm:ss") });
      commonform.removeAt(i);
      this.setAddressValidation();
      // this.contactPersonCount--;
    }
  }

  removeDetailDirect(i: number) {
    const commonform = this.commonForm.get('addressDetails') as FormArray;
    commonform.removeAt(i);
    // this.contactPersonCount--;
  }

  async openConfirmation(item, i) {
   LogData("openConfirmation...", item);

    if (item && item.id) {
      const modal = await this.popCtrl.create({
        component: ConfirmationComponent,
        componentProps: { msg: "are_you_sure_want_to_delete_the_additional_regiter_area_details", icon: 'ic_delete' },
        cssClass: 'custom-popover',
        showBackdrop: true,
        backdropDismiss: false,
        // breakpoints: [0, 0.3, 0.5, 0.8],
        // initialBreakpoint: 0.5
      });

      modal.onDidDismiss().then((data) => {
        LogData("onDidDismiss...", data);
        if (data.data != null) {
          this.removeDetail(item, i);
        }
      });

      await modal.present();
    } else {
      this.removeDetailDirect(i);
    }

  }

  get addressDetails(): FormArray {
    return this.commonForm.get('addressDetails') as FormArray;
  }

  clearForm() {
    this.commonForm.patchValue({
      state_name: '', state_id: '', state_expand: false,
      district_name: '', district_id: '', district_expand: false, taluka_name: '', taluka_id: '',
      taluka_expand: false, village_name: '', village_id: '', village_expand: false,
      village_area_name: '', villagearea_id: '', village_area_expand: false, status: ''
    });
    this.utilsService.setAddressMaster({ page: this.pageName, value: true, isClear: true });
  }

  setInitApiCalls() {
    if (this.userProfileData) {
      if (this.isMultiple) {
        if (this.userProfileData && this.userProfileData.userregisterareas &&
          this.userProfileData.userregisterareas.length > 0) {
          this.userProfileData.userregisterareas.forEach((element, index) => {
            this.apiCalls(element, index);
          });

        }
      } else {
        this.apiCalls(this.userProfileData, 0);
      }

    }
  }

  apiCalls(user_profileData: UserprofileData, formIndex) {
    if (user_profileData.state_id && user_profileData.state_id.length > 0 && this.isDistrict) {
      this.getDistrictById(this.userProfileData.state_id[0].value, false, formIndex);
    }
    if (user_profileData.district_id && user_profileData.district_id.length > 0 && this.isTaluka) {
      this.getTaukaById(this.userProfileData.district_id[0].value, false, formIndex);
    }
    // if (user_profileData.taluka_id && user_profileData.taluka_id.length > 0 && this.isVillage) {
    //   this.getVillagesById(this.userProfileData.taluka_id[0].value, false);
    // }
    if (user_profileData.taluka_id && user_profileData.taluka_id.length > 0) {
      if (user_profileData.taluka_id[0].is_city && user_profileData.taluka_id[0].is_city === 1) {
        this.isVillage = true;
      }
      if (this.isVillage) {
        this.getVillagesById(this.userProfileData.taluka_id[0].value, false);
      }
    }
    if (user_profileData.village_id && user_profileData.village_id.length > 0 && this.isVillageArea) {
      this.getVillagesAreaById(this.userProfileData.village_id[0].value, false);
    }
  }

  async openSingleSelection(index, array, title, formControlId: any, formControlName: any, formControlExpand: any, formIndex) {
    LogData("array", array);
    if(index === 3 && this.isVillageMultiple){
      this.openMultiSelect(index,array,title,formControlId,formControlExpand);
    }else{
      if (array.length > 0) {
        formControlExpand.setValue(true);
        const modal = await this.modalCtrl.create({
          component: SingleSearchFilterComponent,
          componentProps: { title: title, selectedData: array, selectedId: formControlId.value },
          cssClass: 'custom-modal-filter',
          showBackdrop: true,
          backdropDismiss: false,
          // breakpoints: [0, 0.3, 0.5, 0.8],
          // initialBreakpoint: 0.5
        });
  
        modal.onDidDismiss().then((data) => {
          LogData("onDidDismiss...", data);
          LogData("onDidDismiss...idx", index);
          if (data.data != null) {
            if (data.data !== SELECT_LIST_ITEM) {
              const selectedId = data.data.value;
              let tempArr = [];
              tempArr.push(data.data);
              formControlId.setValue(tempArr);
              // formControlName.setValue(data.data.text);
              switch (index) {
                case 0:
                  if (this.isDistrict) {
                    this.setClearDistrict(formIndex);
                    this.getDistrictById(selectedId, true, formIndex);
                  }
                  break;
                case 1:
                  if (this.isTaluka) {
                    this.setClearTaluka(formIndex);
                    this.getTaukaById(selectedId, true, formIndex);
                  }
                  break;
                case 2:
                 LogData("sel_taluka_,is_city", data.data);
                  this.setClearZoneData(formIndex);
                  this.addressDetails.at(formIndex).patchValue({ is_city: data.data.is_city });
                  if (this.isTalukaisCity) {
                    //village_id remove validation after zone implement in branch level
                    // if(data.data.is_city && data.data.is_city === 1){
                    //   this.utilsService.setValidation(this.addressDetails.at(formIndex).get('village_id'));
                    //   this.isVillage=true;
                    // }else{
                    //   this.utilsService.clearValidation(this.addressDetails.at(formIndex).get('village_id'));
                    //   this.isVillage=false;
                    // }
                    if (data.data.is_city && data.data.is_city === 1) {
                      if (data.data.has_zone && data.data.has_zone.length > 0) {
                        this.zoneList = data.data.has_zone;
                        this.utilsService.setValidation(this.addressDetails.at(formIndex).get('zone_name'));
                      } else {
                        this.zoneList = [];
                        this.utilsService.clearValidation(this.addressDetails.at(formIndex).get('zone_name'));
                      }
                    }else{
                      this.utilsService.clearValidation(this.addressDetails.at(formIndex).get('zone_name'));
                    }
                  }
                  if (this.isVillage) {
                    this.setClearVillages(formIndex);
                    this.getVillagesById(selectedId, true);
                  } else {
                    if (this.isZoneLevelVillages) {
                      this.setClearVillages(formIndex);
                      this.getVillagesById(selectedId, true);
                    }
                  }
                  this.onChangeTaluka.emit(true);
                  break;
                case 3:
                  if (this.isVillageArea) {
                    this.setClearVillagesArea(formIndex);
                    this.getVillagesAreaById(selectedId, true);
                  }
                  break;
                case 4:
                  break;
              }
            } else {
              switch (index) {
                case 0:
                  this.addressDetails.at(formIndex).patchValue({ state_name: '', state_id: '' });
                  this.setClearDistrict(formIndex);
                  break;
                case 1:
                  this.addressDetails.at(formIndex).patchValue({ district_name: '', district_id: '' });
                  this.setClearTaluka(formIndex);
                  break;
                case 2:
                  this.setisCityTaluka();
                  this.addressDetails.at(formIndex).patchValue({ taluka_name: '', taluka_id: '', is_city: '' });
                  this.setClearVillages(formIndex);
                  break;
                case 3:
                  this.addressDetails.at(formIndex).patchValue({ village_name: '', village_id: '' });
                  this.setClearVillagesArea(formIndex);
                  break;
                case 4:
                  this.addressDetails.at(formIndex).patchValue({ village_area_name: '', villagearea_id: '' });
                  break;
              }
            }
  
          }
          formControlExpand.setValue(false);
        });
  
        await modal.present();
      } else {
        this.utilsService.presentToastNoMoreData('no_data_found');
      }
    }
   
  }

  async openMultiSelect(index, array, title, formControlId: any, formControlExpand: any) {
    if (array && array.length > 0) {
      array = this.getCheckedList(array);
      const modal = await this.modalCtrl.getTop();
      if (!modal) {
        formControlExpand = true;
        const modal = await this.modalCtrl.create({
          component: MultiSelectionComponent,
          componentProps: { itemList: array, headername: title },
          cssClass: 'custom-modal-filter',
          showBackdrop: true,
          backdropDismiss: false
        });

        modal.onDidDismiss().then((data) => {
          LogData("onDidDismiss...", data);
          if (data.data != null) {
            const dataArry = data.data;
            formControlId.patchValue(dataArry);
            this.selectedVillagesList = dataArry;
          }
          formControlExpand = false;
        });

        await modal.present();
      }
    }else{
      this.utilsService.presentToastNoMoreData('no_data_found'); 
    }

  }

  getCheckedList(dataArry) {
    dataArry.forEach(element => {
      const find_obj = this.selectedVillagesList.find((trad_find) => trad_find.value === element.value);
      if (find_obj) {
        element.isChecked = true;
      } else {
        element.isChecked = false;
      }
    });
    return dataArry;
  }

  setUpdatatedVillageList() {
    if (this.userProfileData && this.userProfileData.village_id &&
      this.userProfileData.village_id.length > 0) {
      this.userProfileData.village_id.forEach(element => {
        this.selectedVillagesList.push(element);
      });
      this.addressDetails.at(0).patchValue({ village_id: this.selectedVillagesList });
      // this.compneyName = '';
    } else {
      this.addressDetails.at(0).patchValue({ village_id: [] });
    }
  }

  getVillageList(ev) {
    LogData("getVillageList",ev);
    if (ev) {
      this.addressDetails.at(0).patchValue({ village_id: this.selectedVillagesList });
    }

  }

  setisCityTaluka() {
    if (this.isTalukaisCity) {
      this.utilsService.clearValidation(this.addressDetails.at(0).get('village_id'));
      this.isVillage = false;
    }
  }

  setUserProfileNull(){
    if(this.userProfileData){
      this.userProfileData=null;
    }
  }

  setClearDistrict(formIndex) {
    // this.districtList = [];
    this.talukaList = [];
    this.villageList = [];
    this.villageAreaList = [];
    this.setisCityTaluka();
    this.setClearZoneData(formIndex);
    this.setUserProfileNull();
    this.addressDetails.at(formIndex).patchValue({
      district_name: '', district_id: '', taluka_name: '', taluka_id: '',
      village_name: '', village_id: '', village_area_name: '', villagearea_id: '', is_city: '',
    });
    if(this.isVillageMultiple){
      this.selectedVillagesList=[];
    }
    // this.commonForm.patchValue({
    //   district_name: '', district_id: '', taluka_name: '', taluka_id: '',
    //   village_name: '', village_id: '', village_area_name: '', villagearea_id: ''
    // });
  }

  setClearTaluka(formIndex) {
    // this.talukaList = [];
    this.villageList = [];
    this.villageAreaList = [];
    this.setisCityTaluka();
    this.setClearZoneData(formIndex);
    this.setUserProfileNull();
    this.addressDetails.at(formIndex).patchValue({
      taluka_name: '', taluka_id: '', village_name: '',
      village_id: '', village_area_name: '', villagearea_id: '', is_city: ''
    });
    // this.commonForm.patchValue({
    //   taluka_name: '', taluka_id: '', village_name: '',
    //   village_id: '', village_area_name: '', villagearea_id: ''
    // });
  }

  setClearVillages(formIndex) {
    // this.villageList = [];
    this.villageAreaList = [];
    this.addressDetails.at(formIndex).patchValue({ village_name: '', village_id: '', village_area_name: '', villagearea_id: '' });
    // this.commonForm.patchValue({ village_name: '', village_id: '', village_area_name: '', villagearea_id: '' });
    if(this.isVillageMultiple){
      this.selectedVillagesList=[];
    }
  }

  setClearVillagesArea(formIndex) {
    // this.villageAreaList = [];
    this.addressDetails.at(formIndex).patchValue({ village_area_name: '', villagearea_id: '' });
    // this.commonForm.patchValue({ village_area_name: '', villagearea_id: '' });
  }

  setClearZoneData(formIndex) {
    this.zoneLevelVillageList = [];
    this.zoneList = [];
    this.addressDetails.at(formIndex).patchValue({ zone_name: '' });
    this.utilsService.clearValidation(this.addressDetails.at(formIndex).get('zone_name'));
    if(this.isZoneLevelVillages){
      this.isVillage=false;
    }
    if(this.isVillageMultiple){
      this.selectedVillagesList=[]
    }
  }

  getZonelevelVillages(zoneName) {
    this.villageList = [];
    if (this.isZoneLevelVillages && zoneName) {
      // this.villageList = this.zoneLevelVillageList.filter((element) => (element.zone_name && element.zone_name === zoneName));
      this.villageList = this.zoneLevelVillageList.filter((element) => ((element.zone_name && element.zone_name === zoneName) || (element.mzone_name && element.mzone_name === zoneName)));
    }
  }

  onChangeZone(ev) {
    LogData("onChangeZone", ev.detail.value);
    const zone_name = ev.detail.value;
    if (zone_name) {
      this.setClearVillages(0);
      this.isVillage = true;
      this.getZonelevelVillages(zone_name);
    }
  }

  async getMasters() {
    const loading = await this.loadingCtrl.create(
      this.utilsService.getLoaderUI()
    );
    await loading.present();
    LogData("loader_start");
    let list = '';
    if (this.isStatus) {
      if(this.statusMaster){
        list=this.statusMaster;
      }else{
        list = getStatusArr;
      }
      
    }
    if (this.isState) {
      list = (list ? list + "," : '') + getStatesArr;
    }
    const formData = new FormData();
    formData.append("master", list);
    this.userServices.getMasters(formData,(this.module ? this.module:''))
      .pipe(
        finalize(() => {
          LogData("loader_dismiss");
          loading.dismiss();
        })
      )
      .subscribe((res) => {
        LogData("getMasters_res", res);
        res = this.utilsService.getDecriptReasponse(res);
          if (res && res.result && res.result.responce) {
          if (res.result.responce.getStatesArr &&
            res.result.responce.getStatesArr.length > 0) {
            this.stateList = res.result.responce.getStatesArr;
          } else {
            this.stateList = [];
          }
          const status_list=this.statusMaster ? res.result.responce[this.statusMaster] :res.result.responce.getStatusArr;
          if (status_list &&
            status_list.length > 0) {
            this.statusList = status_list;
            if (this.userProfileData && this.userProfileData.status) {
              // setTimeout(() => {
              //   this.commonForm.patchValue({ status: this.userProfileData.status });
              //  LogData("form", this.commonForm.value);

              // }, 300);
            }
          } else {
            this.statusList = [];
          }
        } else {
          this.stateList = [];
          this.statusList = [];
        }

      }, err => {
        LogData("loader_dismiss");
        loading.dismiss();
        LogData("getState_error", err);
      });
  }
  async getDistrictById(id, isLoader, formIndex) {
    let loading;
    if (isLoader) {
      loading = await this.loadingCtrl.create(
        this.utilsService.getLoaderUI()
      );
      await loading.present();
    }


    LogData("loader_start");
    const formData = new FormData();
    // if(this.authServices.getLoginUserDistrictId()){
    //   formData.append(REQUESTARR_ID_KEY,this.authServices.getLoginUserDistrictId());
    // }
    this.userServices.getDistrictById(id, formData,(this.module ? this.module:''))
      .pipe(
        finalize(() => {
          LogData("loader_dismiss");
          if (isLoader) {
            loading.dismiss();
          }
        })
      )
      .subscribe((res) => {
        LogData("getDistrictById_res", res);
        res = this.utilsService.getDecriptReasponse(res);
          if (res && res.result && res.result.responce && res.result.responce.getDistrictsArr &&
          res.result.responce.getDistrictsArr.length > 0) {
          this.districtList = res.result.responce.getDistrictsArr;
          this.addressDetails.at(formIndex).patchValue({ districtList: this.districtList });
        } else {
          this.districtList = [];
          this.addressDetails.at(formIndex).patchValue({ districtList: '' });
        }
      }, err => {
        LogData("loader_dismiss");
        if (isLoader) {
          loading.dismiss();
        }
        LogData("getDistrictById_error", err);
      });
  }

  async getTaukaById(id, isLoader, formIndex) {
    let loading;
    if (isLoader) {
      loading = await this.loadingCtrl.create(
        this.utilsService.getLoaderUI()
      );
      await loading.present();
    }
    LogData("loader_start");
    const formData = new FormData();
    // if(this.authServices.getLoginUserTalukaId()){
    //   formData.append(REQUESTARR_ID_KEY,this.authServices.getLoginUserTalukaId());
    // }
    this.userServices.getTalukaById(id, formData,(this.module ? this.module:''))
      .pipe(
        finalize(() => {
          LogData("loader_dismiss");
          loading.dismiss();
        })
      )
      .subscribe((res) => {
        LogData("getTaukaById_res", res);
        res = this.utilsService.getDecriptReasponse(res);
          if (res && res.result && res.result.responce && res.result.responce.getTalukasArr &&
          res.result.responce.getTalukasArr.length > 0) {
          this.talukaList = res.result.responce.getTalukasArr;
          this.addressDetails.at(formIndex).patchValue({ talukaList: this.talukaList });
        } else {
          this.talukaList = [];
          this.addressDetails.at(formIndex).patchValue({ talukaList: this.talukaList });
        }
      }, err => {
        LogData("loader_dismiss");
        loading.dismiss();
        LogData("getTaukaById_error", err);
      });
  }

  async getVillagesById(id, isLoader) {
    let loading;
    if (isLoader) {
      loading = await this.loadingCtrl.create(
        this.utilsService.getLoaderUI()
      );
      await loading.present();
    }
    LogData("loader_start");
    const formData = new FormData();
    // if(this.authServices.getLoginUserVillageId()){
    //   formData.append(REQUESTARR_ID_KEY,this.authServices.getLoginUserVillageId());
    // }
    if (this.isNoOther) {
      formData.append(NOOTHER_KEY, "1");
    }
    this.userServices.getVillagesById(id, formData, (this.module ? this.module : ''))
      .pipe(
        finalize(() => {
          LogData("loader_dismiss");
          if (isLoader) {
            loading.dismiss();
          }
        })
      )
      .subscribe((res) => {
        LogData("getVillagesById_res", res);
        res = this.utilsService.getDecriptReasponse(res);
          if (res && res.result && res.result.responce && res.result.responce.getVillagesArr &&
          res.result.responce.getVillagesArr.length > 0) {
          this.villageList = res.result.responce.getVillagesArr;
          if (this.isZoneLevelVillages) {
            this.zoneLevelVillageList = this.villageList;
            if(this.userProfileData){
              if (this.userProfileData.taluka_id[0].is_city && this.userProfileData.taluka_id[0].is_city === 1) {
                if (this.userProfileData.taluka_id[0].has_zone && this.userProfileData.taluka_id[0].has_zone.length > 0) {
                  this.zoneList = this.userProfileData.taluka_id[0].has_zone;
                  if(this.userProfileData.zone_name && this.userProfileData.zone_name.length > 0){
                    this.addressDetails.at(0).patchValue({zone_name:this.userProfileData.zone_name[0].value});
                    this.getZonelevelVillages(this.userProfileData.zone_name[0].value);
                  }
                 
                  this.utilsService.setValidation(this.addressDetails.at(0).get('zone_name'));
                } else {
                  this.zoneList = [];
                  this.utilsService.clearValidation(this.addressDetails.at(0).get('zone_name'));
                }
              }
            }
            
          }
        } else {
          this.villageList = [];
        }
      }, err => {
        LogData("loader_dismiss");
        if (isLoader) {
          loading.dismiss();
        }
        LogData("getVillagesById_error", err);
      });
  }

  async getVillagesAreaById(id, isLoader) {
    let loading;
    if (isLoader) {
      loading = await this.loadingCtrl.create(
        this.utilsService.getLoaderUI()
      );
      await loading.present();
    }
    LogData("loader_start");
    const formData = new FormData();
    // if(this.authServices.getLoginUserVillageAreaId()){
    //   formData.append(REQUESTARR_ID_KEY,this.authServices.getLoginUserVillageAreaId());
    // }
    if (this.isNoOther) {
      formData.append(NOOTHER_KEY, "1");
    }
    this.userServices.getVillageAreaById(id, formData, (this.module ? this.module : ''))
      .pipe(
        finalize(() => {
          LogData("loader_dismiss");
          if (isLoader) {
            loading.dismiss();
          }

        })
      )
      .subscribe((res) => {
        LogData("getVillagesAreaById_res", res);
        res = this.utilsService.getDecriptReasponse(res);
          if (res && res.result && res.result.responce && res.result.responce.getVillageareasArr &&
          res.result.responce.getVillageareasArr.length > 0) {
          this.villageAreaList = res.result.responce.getVillageareasArr;
        } else {
          this.villageAreaList = [];
        }
      }, err => {
        LogData("loader_dismiss");
        if (isLoader) {
          loading.dismiss();
        }
        LogData("getVillagesAreaById_error", err);
      });
  }

}
