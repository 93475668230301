import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Capacitor } from '@capacitor/core';
import { Observable } from 'rxjs';
import { CommonData } from '../models/common-data';
import { CurrentUserData, UserData } from '../models/user-data';
import { MAIN_HOME, NO_PAGE_FOUND, TAB1_ROUTE } from '../utils/routes';
import { VISITORS_TAB, VISITORS_TAB1_ROUTE } from '../utils/routes';
import { LogData } from '../utils/utils';
import { AuthServices } from './auth.services';
import { UtilsService } from './uitils.services';

export interface CanComponentDeactivate {
  canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable()
export class AuthGuard implements CanActivate {


  constructor(private router: Router, public authService: AuthServices,
    private utilServices: UtilsService) {

  }
  // canActivate() {
  //   const user: CurrentUserData = JSON.parse(localStorage.getItem(USER_KEY));
  //   if (user && user.role_id) {
  //     if (user.role_id === 1 || user.role_id === 2) {
  //        this.utilServices.setNavigationRoute('/tabs/tabs/tab1');
  //       return true;
  //     } else if (user.role_id === 3) {
  //       this.utilServices.setNavigationRoute('/admin/admin/dashboard');
  //       return true;
  //     } else {
  //       this.utilServices.setNavigationRoute(NO_PAGE_FOUND);
  //       return true; // will do later page not found
  //     }
  //   } else {
  //     this.utilServices.setNavigationRoute(this.utilServices.isWeb() ? MAIN_HOME : TAB1_ROUTE);
  //     return false;
  //   }
  //   return false;
  // }

  //  canActivate() {
  //    LogData("AuthGuard..call");

  //   if (this.authService.getUserLoginData()) {
  //     return true;
  //   }
  //   return this.router.navigateByUrl('/login');
  // }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser = this.authService.getUserLoginData();

    if (currentUser) {
      LogData("route.data...", route.data);
      LogData("route.data.roles...", route.data.roles);
      LogData("route.data.roles_roleId...", currentUser.role_id);
      // LogData("route.data.roles_index...",route.data.roles.indexOf(currentUser.role_id));
      // check if route is restricted by role
      
      if (route.data.roles && route.data.roles.indexOf(currentUser.role_id) === -1) {
        // role not authorised so redirect to home page
        // this.router.navigate(['/']);
        LogData("logno");

        // this.router.navigate([this.utilServices.isWeb() ? MAIN_HOME:VISITORS_TAB1_ROUTE])
        this.router.navigate([VISITORS_TAB1_ROUTE])
        return false;
      }

      // authorised so return true
      // return true;
      if(!this.hasAccess(route.data)){
        this.router.navigate([VISITORS_TAB1_ROUTE])
        return false;
      }
      return true;
    }

    // not logged in so redirect to login page with the return url
    // this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
    // this.router.navigate([this.utilServices.isWeb() ? MAIN_HOME:VISITORS_TAB1_ROUTE])
    this.router.navigate([VISITORS_TAB1_ROUTE])
    return false;
  }
  hasAccess(routeData) {
    let flag = false;
    LogData("allPermissions",routeData);
    if(routeData && routeData.hasAccess){
      const allPermissions: Array<CommonData> = this.authService.getAllowPermission();
      
      if(allPermissions && allPermissions.length > 0){
        const findAcces = allPermissions.find((element) => element.value === routeData.hasAccess);
        LogData("findAcces",findAcces);
        
        if(findAcces){
          flag=true;
        }
      }
    }else{
      flag=true;
    }
    LogData("hasAccess",flag);
    
    return flag;
  }
}



