import { Component, Input, OnInit } from "@angular/core";
import { PopoverController } from "@ionic/angular";
import { BusinessDirectoryDetails } from "src/app/models/business-director-data";
import { SocialResponseObj } from "src/app/models/social-obj";
import { AuthServices } from "src/app/services/auth.services";
import { UtilsService } from "src/app/services/uitils.services";
import { UserServices } from "src/app/services/user.services";
import { FOLLOWLIKE_API } from "src/app/utils/api-end-points";
import { PAGE_BUSINESS_DETAIL } from "src/app/utils/constant";
import { FOLLOWED_KEY, LIKED_KEY, MODEL_ID_KEY, MODEL_KEY, USERPROFILES_MODEL_KEY } from "src/app/utils/params-data";
import { LogData } from "src/app/utils/utils";
import { CommentPopoverComponent } from "../comment-popover/comment-popover.component";

@Component({
  selector: "app-overview-card",
  templateUrl: "./overview-card.component.html",
  styleUrls: ["./overview-card.component.scss"],
})
export class OverviewCardComponent implements OnInit {
  typeList = [1, 2, 3];
  name;
  businessTimeings = [];
  @Input() userprofiledetails = [];
  @Input() businessDetail: BusinessDirectoryDetails;
  @Input() isFollow:boolean=false;
  @Input() isLike:boolean=false;
  @Input() isComment:boolean=false;
  constructor(public authServices: AuthServices,
    private popCtrl:PopoverController,
    private userServices:UserServices,
    private utilsService:UtilsService) { }

  ngOnInit() {
    this.initFollowLikes();
  }

  initFollowLikes(){
    this.isFollow=(this.businessDetail.is_followed && this.businessDetail.is_followed === 1) ? true:false;
    this.isLike=(this.businessDetail.is_liked && this.businessDetail.is_liked === 1) ? true:false;
  }


  followClick(){
    // this.isFollow=!this.isFollow;
    if(!this.authServices.getUserLoginData()){
      this.openLoginMsg();
      return;
    }
    const formData=new FormData();
    formData.append(MODEL_ID_KEY, '' + this.businessDetail.id);
    formData.append(MODEL_KEY, USERPROFILES_MODEL_KEY);
      formData.append(FOLLOWED_KEY,""+(!this.isFollow ? 1:2));
      const userObj={
        isToast:true,
        api:FOLLOWLIKE_API,
        formData:formData,
        page:PAGE_BUSINESS_DETAIL,
        isResponse:true
      } as SocialResponseObj;
      this.userServices.userSocilaBusinessApi(userObj);
  }

  likeClick(){
    // this.isLike=!this.isLike;
    if(!this.authServices.getUserLoginData()){
      this.openLoginMsg();
      return;
    }
    const formData=new FormData();
    formData.append(MODEL_ID_KEY, '' + this.businessDetail.id);
    formData.append(MODEL_KEY, USERPROFILES_MODEL_KEY);
      formData.append(LIKED_KEY,""+(!this.isLike ? 1:2));
      const userObj={
        isToast:true,
        api:FOLLOWLIKE_API,
        formData:formData,
        page:PAGE_BUSINESS_DETAIL,
        isResponse:true,
        // is_liked:this.isLike ? 1:2
      } as SocialResponseObj;
      this.userServices.userSocilaBusinessApi(userObj);
  }

  comClick(){
    this.isComment=!this.isComment;
  }

  isLink() {
    let flag = false;
    if (this.businessDetail.fb_url || this.businessDetail.twitter_url || this.businessDetail.linkdin_url
      || this.businessDetail.insta_url || this.businessDetail.google_business_url ||
       this.businessDetail.youtube_url) {
      flag = true;
    }
    return flag;
  }

  isDesinationDetails(item){
    let flag=false;
    if(item.designation_id && item.designation_id.length > 0 && (item.full_name || item.phone || item.email)){
      flag=true;
    }
    return flag;
  }

  isBusinessHours() {
    let flag=false;
    if (this.businessDetail && this.businessDetail.businesshours &&
      this.businessDetail.businesshours.length > 0) {
        this.businessDetail.businesshours.forEach(element => {
          if(element.v === 1){
            flag=true;
          }
        });
    }
    return flag;
  }

  openLoginMsg(){
    this.utilsService.presentToast(this.utilsService.getLanguageValue('txt_msg_login_first_use_this_feature'));
  }

  async openComment(ev) {
    if(!this.authServices.getUserLoginData()){
      this.openLoginMsg();
      return;
    }
    const modal = await this.popCtrl.create({
      component: CommentPopoverComponent,
      componentProps: {
        title: "dg_lbl_comment",
        btn1: "submit",
        btn2: "cancel",
        businessDetailid:this.businessDetail.id
      },
      cssClass: "custom-popover",
      showBackdrop: true,
      backdropDismiss: false,
    });

    modal.onDidDismiss().then((data) => {
      LogData("onDidDismiss...", data);
      if (data.data != null) {
       this.utilsService.setSocialResponse({value:true,page:PAGE_BUSINESS_DETAIL});
      }
    });

    await modal.present();
  }

  submit(ev) { }
}
