import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { LoadingController, ModalController, PopoverController } from '@ionic/angular';
import { finalize } from 'rxjs/operators';
import { CommonData } from 'src/app/models/common-data';
import { UtilsService } from 'src/app/services/uitils.services';
import { UserServices } from 'src/app/services/user.services';
import { getGroupsArr, getItemgroupsArr, getStatusArr, PAGE_ACC_LEDGER, PAGE_ITEM_GROUP, PAGE_ITEM_LADGER, SELECT_LIST_ITEM } from 'src/app/utils/constant';
import { LogData } from 'src/app/utils/utils';
import { SingleSearchFilterComponent } from '../single-search-filter/single-search-filter.component';

@Component({
  selector: 'app-acc-ladger-filter',
  templateUrl: './acc-ladger-filter.component.html',
  styleUrls: ['./acc-ladger-filter.component.scss'],
})
export class AccLadgerFilterComponent implements OnInit {

  commonForm:FormGroup;
  @Input() title;
  @Input() ladgerData;
  @Input() btn1="apply";
  @Input() btn2="clear_all";
  @Input() isItem:boolean=false;
  statusList:Array<CommonData>=[];
  groupList:Array<CommonData>=[];
  constructor(private formBuilder:FormBuilder,
    public utilsService:UtilsService,
    private popoverCtrl:PopoverController,
    private userServices:UserServices,
    private loadingCtrl:LoadingController,
    private modalCtrl:ModalController) { }

  ngOnInit() {
    this.buildForm();
    this.getMasters();
  }

  async openSingleSelection() {
    if (this.groupList.length > 0) {
      this.commonForm.value.group_id_expand=true;
      const modal = await this.modalCtrl.create({
        component: SingleSearchFilterComponent,
        componentProps: { title: 'select', selectedData: this.groupList, selectedId: 
        (this.commonForm.value.group_id && this.commonForm.value.group_id.length > 0) ? this.commonForm.value.group_id[0].value:'' },
        cssClass: 'custom-modal-filter',
        showBackdrop: true,
        backdropDismiss: false,
        // breakpoints: [0, 0.3, 0.5, 0.8],
        // initialBreakpoint: 0.5
      });

      modal.onDidDismiss().then((data) => {
        LogData("onDidDismiss...", data);
        if (data.data != null) {
          if (data.data !== SELECT_LIST_ITEM) {
            let tempArr = [];
            tempArr.push(data.data);
            this.commonForm.patchValue({group_id:tempArr});

          } else {
            this.commonForm.patchValue({group_id:''});
          }

        }
        this.commonForm.value.group_id_expand=false;
      });

      await modal.present();
    }
  }

  apply(ev?){
    this.popoverCtrl.dismiss(this.commonForm.value);
  }

  clearAll(ev?){
    this.buildForm();
    this.utilsService.setAddressMaster({isClear:true,page:this.isItem ? PAGE_ITEM_LADGER:PAGE_ACC_LEDGER });
    this.closeModal();
  }

  closeModal(ev?) {
    this.popoverCtrl.dismiss();
  }

  buildForm() {
    this.commonForm = this.formBuilder.group({
      group_id: [(this.ladgerData && this.ladgerData.group_id) ? this.ladgerData.group_id:""],
      group_id_expand:[false],
      status: [(this.ladgerData && this.ladgerData.status) ? this.ladgerData.status:""],
    });
  }

  async getMasters() {
    const loading = await this.loadingCtrl.create(
      this.utilsService.getLoaderUI()
    );
    await loading.present();
    LogData("loader_start");
    const list = getStatusArr +","+ (this.isItem ? getItemgroupsArr:getGroupsArr);
    const formData = new FormData();
    formData.append("master", list);
    this.userServices.getMasters(formData)
      .pipe(
        finalize(() => {
          LogData("loader_dismiss");
          loading.dismiss();
        })
      )
      .subscribe((res) => {
        LogData("getMasters_res", res);
        res = this.utilsService.getDecriptReasponse(res);
          if (res && res.result && res.result.responce) {
          if (res.result.responce.getStatusArr &&
            res.result.responce.getStatusArr.length > 0) {
            this.statusList = res.result.responce.getStatusArr;
            if(this.ladgerData && this.ladgerData.status){
              this.commonForm.patchValue({
                status:this.ladgerData.status
              })
            }
          } else {
            this.statusList = [];
          }
          let groupArray=[];
          if(this.isItem){
            groupArray=res.result.responce.getItemgroupsArr;
          }else{
            groupArray=res.result.responce.getGroupsArr;
          }
          if (groupArray &&
            groupArray.length > 0) {
            this.groupList = groupArray;
            if(this.ladgerData && this.ladgerData.group_id){
              this.commonForm.patchValue({
                group_id:this.ladgerData.group_id
              })
            }
           
          } else {
            this.groupList = [];
          }
          
        } else {
          this.groupList = [];
          this.statusList = [];
        }

      }, err => {
        LogData("loader_dismiss");
        loading.dismiss();
        LogData("getState_error", err);
      });
  }

}
