import { Component, Input, OnInit } from '@angular/core';
import { ComplaininquiryData } from 'src/app/models/complaints';
import { DATE_TIME_FORMAT_FOR_USER, DEALERS_USER_TYPE } from 'src/app/utils/constant';

@Component({
  selector: 'app-inquirie-card',
  templateUrl: './inquirie-card.component.html',
  styleUrls: ['./inquirie-card.component.scss'],
})
export class InquirieCardComponent implements OnInit {

  @Input() isComplain:boolean=false;
  @Input() complaininquiryData:ComplaininquiryData;
  @Input() isBusinessUser:boolean=false;
  CONST_DATA={
    DATE_TIME_FORMAT_FOR_USER
  };
  constructor() { }

  ngOnInit() {}

  isDealar(){
    return (this.complaininquiryData && this.complaininquiryData.userprofile_id && 
      this.complaininquiryData.userprofile_id.length > 0 && this.complaininquiryData.userprofile_id[0].user_type === DEALERS_USER_TYPE) ? true:false
  }


  isClosedStatus() {
    return (this.complaininquiryData && this.complaininquiryData.status &&
      this.complaininquiryData.status.length > 0 && this.complaininquiryData.status[0].value === 3) ? true : false;
  }


}
