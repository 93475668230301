<div class="container cursor-ptr">
  <div [ngClass]="getStatus(ladgerData.status) === 2 ? 'bus-item':'bus-item-sel'">
    <div class="flex flex-col p-16 ion-text-start" (press)="onPress($event)" (pressup)="onPressUp($event)"
    (click)="onClickItem()">
    <ion-text class="created-by-lbl mb-4" *ngIf="ladgerData.ledger_for">{{'lbl_ledger_for' | getLanguagePipe}} : {{ladgerData.ledger_for}}</ion-text>
      <ion-text class="lbl-bold-18" *ngIf="ladgerData.name">{{ladgerData.name}}</ion-text>
      <ng-container *ngIf="ladgerData.cl_balance">
        <ion-text class="lbl_cl-bal mt-8">{{'lbl_acc_led_cl_bal' | getLanguagePipe}}</ion-text>
        <ion-text [ngClass]="isMinusAmt() ? 'val-cl-bal-minus':'val-cl-bal-plus'">{{ladgerData.cl_balance_text}}</ion-text>
      </ng-container>
      <div class="flex flex-row mt-8" *ngIf="(ladgerData.group_id && ladgerData.group_id.length > 0) || ladgerData.phone">
        <div *ngIf="ladgerData.group_id && ladgerData.group_id.length > 0" class="mr-16">
          <ion-icon [src]="'inv_led' | getImagePipe"></ion-icon>
          <ion-text class="lbl_disc ml-8">{{ladgerData.group_id[0].text}}</ion-text>
        </div>
        <div  *ngIf="ladgerData.phone">
          <ion-icon [src]="'call_led' | getImagePipe"></ion-icon>
          <ion-text class="lbl_disc ml-8">{{ladgerData.phone}}</ion-text>
        </div>
      </div>
    </div>
    <div class="top-left">
      <ion-icon [src]="'ic_sel' | getImagePipe" class="ft-24" *ngIf="ladgerData.isChecked"></ion-icon>
    </div>
  </div>
</div>