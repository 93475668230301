import { Animation, AnimationController, createAnimation } from '@ionic/angular';

const DURATION = 300;
export const enterAnimation = (baseEl: HTMLElement, opts?: any): Animation => {
  

    // LogData('baseEl', baseEl);
    // LogData('opts', opts);

    // const animationCtrl = new AnimationController();
    // if (opts.direction === 'forword') {
    //     return animationCtrl.create()
    //         .addElement(opts.enteringEl)
    //         .duration(DURATION)
    //         // .easing('ease-in')
    //         .easing('cubic-bezier(0.3,0,0.66,1)')
    //         .fromTo('opacity', '0', '1');
    // }else{
    //     const rootAnimation= animationCtrl.create()
    //     .addElement(opts.enteringEl)
    //     .duration(DURATION)
    //     // .easing('ease-in')
    //     .easing('cubic-bezier(0.3,0,0.66,1)')
    //     .fromTo('opacity', '0', '1');   

    //     const leavingAnimation= animationCtrl.create()
    //     .addElement(opts.leavingEl)
    //     .duration(DURATION)
    //     // .easing('ease-in')
    //     .easing('cubic-bezier(0.3,0,0.66,1)')
    //     .fromTo('opacity', '0', '1');   

    //     return animationCtrl.create().addAnimation([rootAnimation,leavingAnimation]);
    // }

    // root animation with common setup for the whole transition
    // opts.duration="1000";
  const rootTransition = createAnimation()
  .duration(opts.duration || DURATION)
// .duration(1000)
  .easing('cubic-bezier(0.3,0,0.66,1)');

// ensure that the entering page is visible from the start of the transition
const enteringPage = createAnimation()
  .addElement(getIonPageElement(opts.enteringEl))
  .beforeRemoveClass('ion-page-invisible');

// create animation for the leaving page
const leavingPage = createAnimation().addElement(
  getIonPageElement(opts.leavingEl)
);

// actual customized animation
if (opts.direction === 'forward') {
  enteringPage.fromTo('transform', 'translateX(100%)', 'translateX(0)');
  leavingPage.fromTo('opacity', '1', '0.25');
} else {
  leavingPage.fromTo('transform', 'translateX(0)', 'translateX(100%)');
  enteringPage.fromTo('opacity', '0.25', '1');
}

// include animations for both pages into the root animation
rootTransition.addAnimation(enteringPage);
rootTransition.addAnimation(leavingPage);
return rootTransition;

}

export const getIonPageElement = (element: HTMLElement) => {
    if (element.classList.contains('ion-page')) {
      return element;
    }
  
    const ionPage = element.querySelector(
      ':scope > .ion-page, :scope > ion-nav, :scope > ion-tabs,:scope > ion-popover'
    );
    if (ionPage) {
      return ionPage;
    }
    // idk, return the original element so at least something animates
    // and we don't have a null pointer
    return element;
  };

  
   

  export const modalEnterAnimation = (rootElement: HTMLElement): Animation => {
    return createAnimation()
    .addElement(rootElement.querySelector('.modal-wrapper'))
    // .easing('ease-in-out')
    .easing('cubic-bezier(0.3,0,0.66,1)')
    .duration(DURATION)
    .beforeStyles({transform: 'none'})
    .fromTo('transform', 'translateX(100%)', 'translateX(0)')
    .fromTo('opacity', 0, 1);
  }

  export const popoverEnterAnimation = (rootElement: HTMLElement): Animation => {
    return createAnimation()
    .addElement(rootElement.querySelector('.popover-wrapper'))
    // .easing('ease-in-out')
    .easing('cubic-bezier(0.3,0,0.66,1)')
    .duration(DURATION)
    // .beforeStyles({transform: 'none'})
    // .fromTo('transform', 'translateX(0)', 'translateX(100%)')
    .fromTo('opacity', 0, 1);
  }