import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-testsam',
  templateUrl: './testsam.component.html',
  styleUrls: ['./testsam.component.scss'],
})
export class TestsamComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
