import { Component, Input, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { UtilsService } from 'src/app/services/uitils.services';

@Component({
  selector: 'app-ladger-details',
  templateUrl: './ladger-details.component.html',
  styleUrls: ['./ladger-details.component.scss'],
})
export class LadgerDetailsComponent implements OnInit {

  @Input() title;
  @Input() ladgerDetails;
  constructor(private popCtrl:PopoverController,
    public utilServices:UtilsService) { }

  ngOnInit() {}

  closeModal(){
    this.popCtrl.dismiss();
  }

}
