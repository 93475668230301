
<app-dg-header [title]="title" (onClickClose)="closeModal($event)"></app-dg-header>
<ion-content>
  <hr class="divider1 w-100" />
  <form [formGroup]="commonform">
    <ion-grid class="pl-16 pr-16">
      <ion-row class="center-row">
        <ion-col style="align-self: center" class="no-margin-padding">
          <app-address [userProfileData]="userProfileData" [isState]="isState" [isDistrict]="isDistrict"
            [isTaluka]="isTaluka" [isVillage]="isVillage" [isVillageArea]="isVillageArea" [isStatus]="isStatus" [pageName]="pageName"
            [isNoOther]="true" [isFilter]="true"></app-address>
          <app-dg-buttons [btn1]="btn1" [btn2]="btn2" (onClickCloseBtn1)="apply($event)"
            (onClickCloseBtn2)="clearAll($event)">
          </app-dg-buttons>
        </ion-col>
      </ion-row>
    </ion-grid>
  </form>
</ion-content>