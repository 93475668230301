import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ADD_ACCESS,UPDATESTATUS_ACCESS,DELETE_ACCESS, EDIT_ACCESS } from 'src/app/utils/constant';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
})
export class ToolbarComponent implements OnInit {

  @Input() title;
  @Input() isBackButton;
  @Input() isDeleteBtn;
  @Input() deleteIcon;
  @Input() isCreateBtn;
  @Input() isMobileHeaderCss:boolean=false;
  @Input() isDoneBtnText;
  @Input() doneText;
  @Input() isMore:boolean=true;
  @Input() checkedCount=0;
  @Input() menuCode;
  @Input() isBackMenu:boolean=true;
  // @Input() addAccess;
  // @Input() updateStatusAccess;
  // @Input() deleteAccess;
  @Output() onClickDeleteBtn: EventEmitter<any> = new EventEmitter();
  @Output() onClickCreateBtn: EventEmitter<any> = new EventEmitter();
  @Output() onClickDoneBtn: EventEmitter<any> = new EventEmitter();
  @Output() onClickSelDeleteBtn: EventEmitter<any> = new EventEmitter();
  @Output() onClickUnSelItemBtn: EventEmitter<any> = new EventEmitter();
  @Output() onClickMoreItemBtn: EventEmitter<any> = new EventEmitter();
  @Output() onClickBackBtn: EventEmitter<any> = new EventEmitter();
  CONST_DATA={
    ADD_ACCESS,UPDATESTATUS_ACCESS,DELETE_ACCESS,EDIT_ACCESS
  };
  constructor() { }

  ngOnInit() {}

  deleteSingleItem(){
    this.onClickDeleteBtn.emit(true);
  }

  unselectItem(){
    this.onClickUnSelItemBtn.emit(true);
  }

  selectedDeleteItem(){
    this.onClickSelDeleteBtn.emit(true);
  }

  openMoreItems(ev){
    this.onClickMoreItemBtn.emit(ev);
  }

  create(){
    this.onClickCreateBtn.emit();
  }

  done(){
    this.onClickDoneBtn.emit();
  }

  onBack(){
    this.onClickBackBtn.emit(true);
  }

}
