<div class="price-card valid-item flex flex-col ion-text-start">
  <ion-text class="lbl-med-24" *ngIf="pricingCard">{{pricingCard.text}}</ion-text>
  <ion-text class="mt-16" *ngIf="pricingCard.subscription_cost_symbol"><span
      class="lbl-med-30">{{pricingCard.subscription_cost_symbol}}</span><sub class="lbl-14">/{{'sub_year' | getLanguagePipe}}</sub></ion-text>
  <hr class="mt-24 divider1 w-100" />
  <ng-container *ngIf="pricingCard.benifits && pricingCard.benifits.length > 0">
    <ion-text class="lbl-med-16 mt-24">{{'txt_pric_benefit_join_amarucity' | getLanguagePipe}}</ion-text>
    <ion-item class="item-0 ml-8" [ngClass]="isfirst ? 'mt-16':'mt-4'" lines="none" *ngFor="let item of pricingCard.benifits;let isfirst=first">
      <ion-avatar class="avt-24 mr-4 ion-align-self-start" slot="start">
        <img [src]="'ic_sel' | getImagePipe" class="avt-24" />
      </ion-avatar>
      <ion-text class="ml-8 lbl-14 lh-24">{{item.text}}</ion-text>
    </ion-item>
  </ng-container>
  <ion-button *ngIf="!authservices.getUserLoginData()" class="btn-1 hp-45 mt-24" expand="block" mode="ios"
    (click)="registerBusiness()">
    <ion-text class="lbl-med-14">
      {{ 'lbl_reg_bus' | getLanguagePipe }}
    </ion-text>
  </ion-button>
</div>