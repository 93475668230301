import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoadingController, PopoverController } from '@ionic/angular';
import { finalize } from 'rxjs/operators';
import { InputTypeEnum } from 'src/app/enums/inputtypes-enum';
import { AccountsDetails } from 'src/app/models/accounts-data';
import { AuthServices } from 'src/app/services/auth.services';
import { UtilsService } from 'src/app/services/uitils.services';
import { UserServices } from 'src/app/services/user.services';
import { ACCOUNT_ID_KEY, END_DATE_KEY } from 'src/app/utils/params-data';
import { LogData } from 'src/app/utils/utils';
import { ValidationMessages } from 'src/app/validators/validations';

@Component({
  selector: 'app-enddate-change',
  templateUrl: './enddate-change.component.html',
  styleUrls: ['./enddate-change.component.scss'],
})
export class EnddateChangeComponent implements OnInit,AfterViewInit {

  @Input() btn1;
  @Input() title;
  @Input() paymentsData: AccountsDetails;
  commonForm: FormGroup;
  inputTypeEnum = InputTypeEnum;
  isFormSubmit: boolean = false;
  validationMessages = ValidationMessages;
  constructor(private popCtrl: PopoverController,
    private formBuilder: FormBuilder,
    private userServices: UserServices,
    private utilsService: UtilsService,
    private lodingCtrl: LoadingController,
    private authServices: AuthServices) { }


  ngOnInit() { this.buildForm(); }

  ngAfterViewInit(): void {
    LogData("paymentsData",this.paymentsData);
    setTimeout(()=>{
      if(this.paymentsData && this.paymentsData.end_date){
        this.commonForm.patchValue({end_date:new Date(this.paymentsData.end_date)});
        LogData("commonForm",this.commonForm);
      }
    },100);
     
  }

  getMinDate(){
    const date=new Date();
    date.setDate(date.getDate() + 1);
    return date;
  }

  buildForm() {
    this.commonForm = this.formBuilder.group({
      end_date: ["", Validators.required],
    });
   
  }
  closeModal(ev) {
    this.popCtrl.dismiss();
  }

  onDateClear(ev) {
    LogData("onDateClear", ev);
    this.commonForm.patchValue({ end_date: "" });
  }

  onDateChange(ev) {
    LogData("onDateChange", ev);
  }

  apply(ev) {
    this.isFormSubmit = true;
    LogData("commonForm", this.commonForm);
    if (this.commonForm.valid) {
      this.endDateChnageApiCall();
      // this.popCtrl.dismiss(this.commonForm.value.end_date);
      LogData("API_CALL");
    } else {
      LogData("INVALID");
    }
  }

  async endDateChnageApiCall() {
    const loading = await this.lodingCtrl.create(
      this.utilsService.getLoaderUI()
    );
    await loading.present();
    LogData("loader_start");
    const formData = new FormData();
    formData.append(ACCOUNT_ID_KEY,""+this.paymentsData.id);
    formData.append(END_DATE_KEY, this.utilsService.getDateTimeFixFormat(this.commonForm.value.end_date));
    this.userServices.endDateChange(formData, this.authServices.getUserToken())
      .pipe(
        finalize(() => {
          LogData("loader_dismiss");
          loading.dismiss();
        })
      )
      .subscribe(
        (res) => {
          LogData("endDateChnageApiCall_res", res);
          res = this.utilsService.getDecriptReasponse(res);
          if (res && res.result && res.result.responce) {
            this.utilsService.openForSuccessError(false, res.result.success);
            this.popCtrl.dismiss(res.result.responce.Accounts);
          }

        },
        (err) => {
          LogData("loader_dismiss");
          loading.dismiss();
          LogData("endDateChnageApiCall_error", err);
          this.userServices.logoutApiCall(err);

        }
      );
  }

}
