<ion-content>
  <ion-row class="line-container">
    <ion-col>
      <div class="top-line">
        <div class="div-line"></div>
      </div>
    </ion-col>
  </ion-row>
  <div class="ion-padding-start ion-padding-end ion-padding-bottom">
    <ion-row class="ion-margin">
      <ion-col class="ion-text-center">
        <ion-label class="label-med-20">{{versionMsgData.title}}</ion-label>
      </ion-col>
    </ion-row>
    <ion-row  style="margin: 12px;">
      <ion-col class="ion-text-center">
        <ion-label class="label-16 line-height-cls">{{versionMsgData.msg}}</ion-label>
      </ion-col>
    </ion-row>
  </div>
  <ng-container *ngIf="!isMaintenance">
    <ion-row class="row-pad ion-justify-content-center" >
      <ion-col size="6" *ngIf="!isMajor">
        <ion-button class="btn-2 hp-45" expand="block" mode="ios" (click)="cancel()">
          <ion-text>
           {{'cancel' | getLanguagePipe}}
          </ion-text>
        </ion-button>
      </ion-col>
      <ion-col size="6">
        <ion-button class="btn-1 hp-45" expand="block" mode="ios"  class="btn-white" expand="block"  mode="ios" (click)="submitData()">
          <ion-text>
           {{'lbl_update' | getLanguagePipe}} 
          </ion-text>
        </ion-button>
      </ion-col>
    </ion-row>
  </ng-container>
  
  
</ion-content>
