import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Observable, Subscription } from "rxjs";
import { BusinessDirectoryDetails } from "src/app/models/business-director-data";
import { CommonData } from "src/app/models/common-data";
import { AuthServices } from "src/app/services/auth.services";
import { UtilsService } from "src/app/services/uitils.services";
import { UserServices } from "src/app/services/user.services";
import { ADD_ACCESS } from "src/app/utils/constant";
import { INQUIRIES_MC } from "src/app/models/app-menu/menu-code";
import { DATA_FOR_INQUIRY_KEY, DATA_FOR_KEY, EMAIL_KEY, INQUIRY_FOR_BUSINESS_KEY, INQUIRY_FOR_KEY, MESSAGE_KEY, NAME_KEY, PHONE_KEY, SUBJECT_ID_OTHER_KEY, USERPROFILE_ID_FOR_KEY, USERPROFILE_ID_KEY } from "src/app/utils/params-data";
import { LogData } from "src/app/utils/utils";
import {
  AlphaNumeric,
  AlphaNumericTextArea,
  EmailValidationNew,
  MobileValidation,
  ValidationMessages,
} from "src/app/validators/validations";
import { CurrentUserData } from "src/app/models/user-data";

@Component({
  selector: "app-contact-us-card",
  templateUrl: "./contact-us-card.component.html",
  styleUrls: ["./contact-us-card.component.scss"],
})
export class ContactUsCardComponent implements OnInit,OnDestroy {
  commonform: FormGroup;
  validationMessages = ValidationMessages;
  isFormSubmit: boolean = false;
  @Input() isTopbar: boolean = true;
  @Input() isCardBg: boolean = true;
  @Input() businessDetail: BusinessDirectoryDetails;
  isAddAccess: boolean = true;
  isSuccess$: Observable<boolean>;
  isSuccessSubscription: Subscription;
  constructor(
    private formBuilder: FormBuilder,
    public utilsService: UtilsService,
    private userServices: UserServices,
    private authServices: AuthServices
  ) { 
    this.subScriptionCall();
  }

  ngOnInit() {
    // if (this.authServices.getUserLoginData()) {
    //   const allPermissions: Array<CommonData> = this.authServices.getAllowPermission();
    //   LogData("Allowpermission_menu_contactcard", allPermissions);
    //   if (allPermissions && allPermissions.length > 0) {
    //     const findContactus = allPermissions.find((element) => element.value === INQUIRIES_MC);
    //     if (!findContactus) {
    //       this.isAddAccess = false;
    //     } else {
    //       const actionArr: Array<CommonData> = findContactus.actions_arr;
    //       if (actionArr && actionArr.length > 0) {
    //         const findAccess = actionArr.find((access_el) => access_el.value === ADD_ACCESS);
    //         if (!findAccess) {
    //           this.isAddAccess = false;
    //         }
    //       }
    //     }
    //   } else {
    //     this.isAddAccess = false;
    //   }
    // } else {
    //   this.isAddAccess = true;
    // }
    this.buildForm();
  }

  subScriptionCall() {
    //when success
    this.isSuccess$ = this.utilsService.isSuccess$;
    this.isSuccessSubscription = this.isSuccess$.subscribe(
      (value: any) => {
        LogData("isSuccessSubscription...call_business", value);
        if (value) {
          this.buildForm();
        }
      }
    );
  }

  buildForm() {
    LogData("buildForm_call");
    const userData:CurrentUserData=this.authServices.getUserLoginData();
    this.isFormSubmit = false;
    this.commonform = this.formBuilder.group({
      name: [userData ? userData.f_name+" "+userData.l_name:"", AlphaNumeric],
      email: [userData ? userData.email:"", EmailValidationNew],
      phone: [userData ? userData.mobile:"", MobileValidation],
      subject_id_other: ["", AlphaNumeric],
      message: ["", AlphaNumericTextArea],
    });
  }

  submit(ev) {
    this.isFormSubmit = true;
    if (this.commonform.valid) {
      LogData("Call api");
      const formData = new FormData();
      formData.append(DATA_FOR_KEY, DATA_FOR_INQUIRY_KEY);
      formData.append(INQUIRY_FOR_KEY, INQUIRY_FOR_BUSINESS_KEY);
      formData.append(NAME_KEY, this.commonform.value.name);
      formData.append(EMAIL_KEY, this.commonform.value.email);
      formData.append(PHONE_KEY, this.commonform.value.phone);
      formData.append(SUBJECT_ID_OTHER_KEY, this.commonform.value.subject_id_other);
      formData.append(MESSAGE_KEY, this.commonform.value.message);
      if (this.authServices.getUserLoginData()) {
        formData.append(USERPROFILE_ID_KEY, "" + this.authServices.getForId());
      }else{
        formData.append(USERPROFILE_ID_KEY, "");
      }
      formData.append(USERPROFILE_ID_FOR_KEY, '' + this.businessDetail.id);
      this.userServices.createInqForUserApi(formData,true);
    } else {
      LogData("INVALID");
    }
  }

  ngOnDestroy(): void {
    if(this.isSuccessSubscription){
      this.isSuccessSubscription.unsubscribe();
    }
  }
}
