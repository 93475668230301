<div class="container cursor-ptr">
  <div class="p-16" [ngClass]="getStatus(settingData.status) === 2 ? 'bus-item':'bus-item-sel'">
    <div class="flex flex-col" (press)="onPress($event)" (pressup)="onPressUp($event)" (click)="onClickItem()">
      <ion-item lines="none" class="item-trans item-0"  *ngIf="type === CONST_DATA.BANNER_SLUG">
        <ion-text slot="start" class="lbl-bold-16" *ngIf="settingData.display && settingData.display.length > 0">
          {{settingData.display[0].text}}</ion-text>
        <div slot="end" class="ml-4">
          <!-- <ion-icon [src]="'adv_level' | getImagePipe"></ion-icon> -->
          <ion-text class="lbl-med-12 ml-8"
            *ngIf="settingData.advertise_space && settingData.advertise_space.length > 0">
            {{settingData.advertise_space[0].text}}</ion-text>
        </div>
      </ion-item>
      <ion-item lines="none" class="item-trans item-0"  *ngIf="type !== CONST_DATA.BANNER_SLUG">
        <ion-text class="lbl-med-12"
            *ngIf="settingData.advertise_space && settingData.advertise_space.length > 0">
            {{settingData.advertise_space[0].text}}</ion-text>
      </ion-item>

      <ion-item lines="none" class="item-0 item-trans area-div mt-8" *ngIf="isAddressStr()">
        <ion-avatar slot="start" class="ion-align-self-start avt-24">
          <ion-img [src]="'ic_ro_location' | getImagePipe"></ion-img>
        </ion-avatar>
        <div class="flex flex-col w-100">
          <ion-text class="lbl-med-12">
            <span
              *ngIf="settingData.state_id && settingData.state_id.length > 0">{{settingData.state_id[0].text}}&nbsp;</span>
            <span
              *ngIf="settingData.district_id && settingData.district_id.length > 0">,&nbsp;{{settingData.district_id[0].text}}</span>
            <span
              *ngIf="settingData.taluka_id && settingData.taluka_id.length > 0">,&nbsp;{{settingData.taluka_id[0].text}}</span>
            <span
              *ngIf="settingData.zone_name && settingData.zone_name.length > 0">,&nbsp;{{settingData.zone_name[0].text}}</span>
            <span
              *ngIf="settingData.village_id && settingData.village_id.length > 0">,&nbsp;{{settingData.village_id[0].text}}</span>
          </ion-text>
        </div>
      </ion-item>

      <!-- <ng-container *ngIf="type === CONST_DATA.BANNER_SLUG">
        <hr class="divider1 mt-8 mb-12" />
        <ng-container>
          <ion-row>
            <ion-col class="ion-text-start flex flex-col">
              <ion-text class="lbl-bold-12">{{'lbl_adv_freq' | getLanguagePipe}}</ion-text>
              <ion-text class="mt-4">
                <span class="lbl-12">{{'lbl_adv_limit' | getLanguagePipe}}&nbsp;</span><span class="lbl-bold-12">({{settingData.noofadds}})</span>
              </ion-text>
            </ion-col>
          </ion-row>
          <ion-row *ngIf="settingData.advertise && settingData.advertise.length > 0">
            <ng-container *ngFor="let item of settingData.advertise">
              <ion-col size="6" class="ion-text-start">
                <ion-text *ngIf="item.advertise && item.advertise.length > 0">
                  <span class="lbl-12">{{item.advertise[0].text}}&nbsp;</span><span class="lbl-bold-12">({{item.cost_symbol}})</span>
                </ion-text>
              </ion-col>
            </ng-container>
          </ion-row>
          <ion-row>
            <ion-col class="ion-text-start">
              <ion-text class="lbl-bold-12">{{'lbl_push_adv' | getLanguagePipe}}</ion-text>
            </ion-col>
          </ion-row>
          <ng-container *ngIf="settingData.push && settingData.push.length > 0">
            <ion-row>
              <ng-container *ngFor="let item of settingData.push">
                <ion-col size="6" class="ion-text-start">
                  <ion-text class="mt-4">
                    <span class="lbl-12">{{'lbl_adv_price' | getLanguagePipe}}&nbsp;</span><span class="lbl-bold-12">({{item.cost_symbol}})</span>
                  </ion-text>
                </ion-col>
              </ng-container>
            </ion-row>
          </ng-container>
  
        </ng-container>
      </ng-container>
       -->
    </div>
    <div class="top-left">
      <ion-icon [src]="'ic_sel' | getImagePipe" class="ft-24" *ngIf="settingData.isChecked"></ion-icon>
    </div>
  </div>
</div>