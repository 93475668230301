import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { LoadingController, ModalController, PopoverController } from '@ionic/angular';
import { UtilsService } from 'src/app/services/uitils.services';
import { UserServices } from 'src/app/services/user.services';
import { LogData } from 'src/app/utils/utils';

@Component({
  selector: 'app-ledger-report-filter',
  templateUrl: './ledger-report-filter.component.html',
  styleUrls: ['./ledger-report-filter.component.scss'],
})
export class LedgerReportFilterComponent implements OnInit {

  commonForm:FormGroup;
  @Input() title;
  @Input() pageName;
  @Input() reportData;
  @Input() btn1="apply";
  @Input() btn2="clear_all";
  maxDateStart: Date;
  maxDateEnd: Date;
  constructor(private formBuilder:FormBuilder,
    public utilsService:UtilsService,
    private popoverCtrl:PopoverController,
    private userServices:UserServices,
    private loadingCtrl:LoadingController,
    private modalCtrl:ModalController) { }

  ngOnInit() {
    this.buildForm();
  }

  orgValueChange(data){
    LogData("orgValueChange",data.value);
    this.maxDateEnd=data.value;
    this.commonForm.patchValue({end_date:''})
  }

  apply(ev?){
    this.popoverCtrl.dismiss(this.commonForm.value);
  }

  clearAll(ev?){
    this.buildForm();
    this.popoverCtrl.dismiss(true);
  }

  closeModal(ev?) {
    this.popoverCtrl.dismiss();
  }

  buildForm() {
    this.commonForm = this.formBuilder.group({
      start_date:[(this.reportData && this.reportData.start_date) ? new Date(this.reportData.start_date):""],
      end_date:[(this.reportData && this.reportData.end_date) ? new Date(this.reportData.end_date):""],
    });
  }

}

