import { Component, Input, OnInit } from '@angular/core';
import { BusinessDirectoryDetails } from 'src/app/models/business-director-data';
import { AuthServices } from 'src/app/services/auth.services';
import { UtilsService } from 'src/app/services/uitils.services';
import { LOGIN_ROUTE } from 'src/app/utils/routes';
import { LogData } from 'src/app/utils/utils';
declare var google;
@Component({
  selector: 'app-location-card',
  templateUrl: './location-card.component.html',
  styleUrls: ['./location-card.component.scss'],
})
export class LocationCardComponent implements OnInit {

  @Input() businessDetail: BusinessDirectoryDetails;
  @Input() addressStr = '';
  isLoadMap: boolean = true;
  CONST_DATA={
    LOGIN_ROUTE
  };
  constructor(public authServices:AuthServices,
    public utilsService:UtilsService) { }

  ngOnInit() {
    this.loadMap();
  }

  openLogin(){
    this.utilsService.setNavigationRoute(LOGIN_ROUTE);
  }

  loadMap() {
    if (this.businessDetail.lat && this.businessDetail.lng) {
      this.isLoadMap = true;
      let map = new google.maps.Map(document.getElementById("map"), {});
      var myLatlng = new google.maps.LatLng(this.businessDetail.lat, this.businessDetail.lng);
      var myOptions = {
        zoom: 15,
        center: myLatlng,
        mapTypeId: google.maps.MapTypeId.ROADMAP
      }
      map = new google.maps.Map(document.getElementById("map"), myOptions);
      var marker = new google.maps.Marker({
        position: myLatlng,
        map: map,
        title: "marker"
      });
    } else {
      this.isLoadMap = false;
    }

  }

  // if(this.addressStr){
  //   this.isLoadMap=true;
  //   var geocoder = new google.maps.Geocoder();
  //   this.codeAddress(geocoder, map);
  // }else{
  //   this.isLoadMap=false;
  // }

  codeAddress(geocoder, map) {
    // geocoder.geocode({'address': 'new york city'}, function(results, status) {
    //   LogData("codeAddress..results",results);
    //   LogData("codeAddress..status",status);
    //   if (status === 'OK') {
    //     map.setCenter(results[0].geometry.location);
    //     var marker = new google.maps.Marker({
    //       map: map,
    //       position: results[0].geometry.location
    //     });
    //   } else {
    //     // alert('Geocode was not successful for the following reason: ' + status);
    //   }
    // });

    geocoder.geocode({ 'address': this.addressStr }, function (results, status) {
      LogData(results);
      var latLng = { lat: results[0].geometry.location.lat(), lng: results[0].geometry.location.lng() };
      LogData(latLng);
      if (status == 'OK') {
        var myOptions = {
          zoom: 4,
          center: latLng,
          mapTypeId: google.maps.MapTypeId.ROADMAP
        }
        map = new google.maps.Map(document.getElementById("map"), myOptions);
        var marker = new google.maps.Marker({
          position: latLng,
          map: map,
          title: "marker"
        });
      } else {
        this.isLoadMap = false;
        // alert('Geocode was not successful for the following reason: ' + status);
      }
    });
  }

  // google.maps.event.addDomListener(window,'load', initialize);

}
