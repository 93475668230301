import { Component, HostListener, Input, NgZone, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { LoadingController, Platform } from '@ionic/angular';
import { SlickCarouselComponent } from 'ngx-slick-carousel';
import { finalize } from 'rxjs/operators';
import { CommonData } from 'src/app/models/common-data';
import { HomeDataResponce, WebhomepageTestimonial } from 'src/app/models/home-data';
import { UserprofileData } from 'src/app/models/user-profile-data';
import { AuthServices } from 'src/app/services/auth.services';
import { UtilsService } from 'src/app/services/uitils.services';
import { UserServices } from 'src/app/services/user.services';
import { SENDLINK_KEY } from 'src/app/utils/params-data';
import { ADMIN_TABS_BUSINESS_DIRECTORY_LIST, BUSINESS_DETAIL, DEALER_TABS_BUSINESS_DIRECTORY_LIST, STAFF_TABS_BUSINESS_DIRECTORY_LIST, TABS_BUSINESS_DIRECTORY_LIST, VISITOR_BUSINESS_DIRECTORY_LIST } from 'src/app/utils/routes';
import { BusinessSlideConfig, UserSaySliderConfig, WebCategorySliderConfig } from 'src/app/utils/slider.config';
import { LogData } from 'src/app/utils/utils';
import { MobileOrEmailValidation, ValidationMessages } from 'src/app/validators/validations';
import { ALL, AUTO_MOBILES, REAL_ESTATE, TRAVEL, ELECTRICIANS, SEARCH_MORE, SUPER_ADMIN_ROLE_ID, ADMIN_ROLE_ID, BUSINESS_ROLE_ID, CUSTOMER_ROLE_ID, DEALER_ROLE_ID, STAFFS_ROLL_ID } from '../../utils/constant';

@Component({
  selector: 'app-web-home',
  templateUrl: './web-home.component.html',
  styleUrls: ['./web-home.component.scss'],
})
export class WebHomeComponent implements OnInit {

  sendlink;
  categoryList: Array<CommonData> = [];
  businessList = [];
  benefitsList1 = [];
  benefitsList2 = [];
  CONST_DATA = {
    ALL, AUTO_MOBILES, REAL_ESTATE, TRAVEL, ELECTRICIANS, SEARCH_MORE
  }
  selectedTab = ALL;
  //
  webCategorySlideConfig = WebCategorySliderConfig;
  userSaySlideConfig = UserSaySliderConfig;
  businessSlideConfig = BusinessSlideConfig;
  isLoaded: boolean = true;
  isFormSubmit: boolean = false;
  commonForm: FormGroup;
  serverSideError = '';
  validationMessages = ValidationMessages;
  @Input() homeDataResponse: HomeDataResponce;
  businessDirectoryList: Array<UserprofileData> = [];
  totalBusinessDirectoryList: Array<UserprofileData> = [];
  webhomepageTestimonials:Array<WebhomepageTestimonial>=[];
  topAdv;
  middileAdv;
  bottomAdv;
  @ViewChild("slickModal",{ static: false }) slickModal: SlickCarouselComponent;
  constructor(private platform: Platform,
    private ngZone: NgZone,
    private userServices: UserServices,
    private authservices: AuthServices,
    private lodingCtrl: LoadingController,
    public utilServices: UtilsService,
    private formBuilder: FormBuilder) {
  }

  ngOnInit(): void {
    this.setInitData();
    this.buildForm();
    this.benefitsList1 = BenefitsListData1;
    this.benefitsList2 = BenefitsListData2;
  }

  buildForm() {
    this.isFormSubmit = false;
    this.commonForm = this.formBuilder.group({
      sendlink: ['', MobileOrEmailValidation],
    });
  }

  setInitData() {
    if (this.homeDataResponse) {
      if (this.homeDataResponse.tradingtypesArr && this.homeDataResponse.tradingtypesArr.length > 0) {
        this.categoryList = this.homeDataResponse.tradingtypesArr;
      }
      if (this.homeDataResponse.userprofilesArr && this.homeDataResponse.userprofilesArr.length > 0) {
        this.homeDataResponse.userprofilesArr.forEach(element => {
          if (element.userprofilesData && element.userprofilesData.length > 0) {
            this.totalBusinessDirectoryList.push(...element.userprofilesData);
          }
        });
      }
      if(this.homeDataResponse.webhomepageTestimonials && this.homeDataResponse.webhomepageTestimonials.length > 0){
        this.webhomepageTestimonials=this.homeDataResponse.webhomepageTestimonials;
      }
      if(this.homeDataResponse.advertisementsArr){
        if(this.homeDataResponse.advertisementsArr.h_top && 
          this.homeDataResponse.advertisementsArr.h_top.length > 0){
            if(this.homeDataResponse.advertisementsArr.h_top[0].documents && 
              this.homeDataResponse.advertisementsArr.h_top[0].documents.length > 0 && 
              this.homeDataResponse.advertisementsArr.h_top[0].documents[0].display_files &&
              this.homeDataResponse.advertisementsArr.h_top[0].documents[0].display_files.advertisement_pic && 
              this.homeDataResponse.advertisementsArr.h_top[0].documents[0].display_files.advertisement_pic.main_url){
                this.topAdv=this.homeDataResponse.advertisementsArr.h_top[0].documents[0].display_files.advertisement_pic.main_url;
            }else{
              this.topAdv="assets/img/adv_d_1.png";
            }
        }else{
          this.topAdv="assets/img/adv_d_1.png";
        }
        if(this.homeDataResponse.advertisementsArr.h_middle && 
          this.homeDataResponse.advertisementsArr.h_middle.length > 0){
            if(this.homeDataResponse.advertisementsArr.h_middle[0].documents && 
              this.homeDataResponse.advertisementsArr.h_middle[0].documents.length > 0 && 
              this.homeDataResponse.advertisementsArr.h_middle[0].documents[0].display_files &&
              this.homeDataResponse.advertisementsArr.h_middle[0].documents[0].display_files.advertisement_pic && 
              this.homeDataResponse.advertisementsArr.h_middle[0].documents[0].display_files.advertisement_pic.main_url){
                this.middileAdv=this.homeDataResponse.advertisementsArr.h_middle[0].documents[0].display_files.advertisement_pic.main_url;
            }else{
              this.middileAdv="assets/img/adv_d_2.png";
            }
        }else{
          this.middileAdv="assets/img/adv_d_2.png";
        }
        if(this.homeDataResponse.advertisementsArr.h_bottom && 
          this.homeDataResponse.advertisementsArr.h_bottom.length > 0){
            if(this.homeDataResponse.advertisementsArr.h_bottom[0].documents && 
              this.homeDataResponse.advertisementsArr.h_bottom[0].documents.length > 0 && 
              this.homeDataResponse.advertisementsArr.h_bottom[0].documents[0].display_files &&
              this.homeDataResponse.advertisementsArr.h_bottom[0].documents[0].display_files.advertisement_pic && 
              this.homeDataResponse.advertisementsArr.h_bottom[0].documents[0].display_files.advertisement_pic.main_url){
                this.bottomAdv=this.homeDataResponse.advertisementsArr.h_bottom[0].documents[0].display_files.advertisement_pic.main_url;
            }else{
              this.bottomAdv="assets/img/adv_d_1.png"; 
            }
        }else{
          this.bottomAdv="assets/img/adv_d_1.png";
        }
      }
      this.getBusinessDirectoryData(this.selectedTab);
    }
    setTimeout(() => {
      this.webCategorySlideConfig = WebCategorySliderConfig;
      this.userSaySlideConfig = UserSaySliderConfig;
      this.businessSlideConfig = BusinessSlideConfig;
      const width = this.platform.width();
      this.businessList = BusinessListData;
      this.toggleSlideIcon(width);

    }, 4000);
  }

  sliderInit(){
    setTimeout(() => {
      this.webCategorySlideConfig = WebCategorySliderConfig;
      this.userSaySlideConfig = UserSaySliderConfig;
      this.businessSlideConfig = BusinessSlideConfig;
      const width = this.platform.width();
      this.businessList = BusinessListData;
      this.toggleSlideIcon(width);
      LogData("slider_init");
    }, 1000);
  }

  getBusinessDirectoryData(selectedType) {
    if (selectedType !== SEARCH_MORE) {
      if (selectedType === ALL) {
        this.businessDirectoryList = this.totalBusinessDirectoryList;
      } else {
        const findObj = this.homeDataResponse.userprofilesArr.find((element) => element.tradingtypesData.value === parseInt(selectedType));
        LogData("findObj..", findObj);
        if (findObj) {
          this.businessDirectoryList = findObj.userprofilesData;
        }
      }
    }
  }

  openSearchMore() {
   this.utilServices.openBusinesListPage();
  }

  onclickBusiness(id,businesstypeId) {
    this.utilServices.openBusinesListPage({tradingtype_id:id,businesstype_id:businesstypeId});
    // 
  }


  toggleSlideIcon(width) {
    if (width > 768) {
      this.ngZone.run(() => {
        this.businessSlideConfig.arrows = true;
        this.isLoaded = false;
        setTimeout(() => {
          this.isLoaded = true;
        }, 500)
      });
    } else {

      this.ngZone.run(() => {
        this.businessSlideConfig.arrows = false;
        this.isLoaded = false;
        setTimeout(() => {
          this.isLoaded = true;
        }, 500)
      });
    }
  }

  @HostListener('window:resize', ['$event'])
  private onResize(event) {
    const newWidth = event.target.innerWidth;
    LogData("newWidth..", newWidth);

    this.toggleSlideIcon(newWidth);
    this.slickInit();
  }

  slickInit() {
    if (this.slickModal !== undefined) {
      if (window.innerWidth > 575) {
        if (!this.slickModal.initialized) {
          this.slickModal.initSlick();
        }
      } else if (this.slickModal.initialized) {
        this.slickModal.unslick();
      }
   }
  }
  breakpoint(e) {
    LogData('breakpoint');
  }

  afterChange(e) {
    LogData('afterChange');
  }

  beforeChange(e) {
    LogData('beforeChange');
  }

  getAppLink() {
    this.isFormSubmit = true;
    if (this.commonForm.valid) {
      LogData("API_CALL");
      this.getApplinkApiCall();
    } else {
      LogData("INVALID");
    }
  }

  seeAll() {
    this.utilServices.openBusinesListPage();
  }

  onChangeTab(ev) {
    LogData("onChangeTab_ev", ev);

    this.selectedTab = ev.detail.value;
    this.getBusinessDirectoryData(this.selectedTab);
  }

  async getApplinkApiCall() {
    const formData = new FormData();
    formData.append(SENDLINK_KEY, this.commonForm.value.sendlink);
    const loading = await this.lodingCtrl.create(
      this.utilServices.getLoaderUI()
    );
    await loading.present();
    LogData("loader_start");

    this.userServices.getApplink(formData, this.authservices.getUserToken())
      .pipe(
        finalize(() => {
          LogData("loader_dismiss");
          loading.dismiss();
        })
      )
      .subscribe(
        (res) => {
          LogData("getApplinkApiCall_res", res);
           res = this.utilServices.getDecriptReasponse(res);
          if (res) {
            this.utilServices.openForSuccessError(false, res.result.success);
            setTimeout(() => {
              this.buildForm();
            }, 500)
          }
        },
        (err) => {
          LogData("loader_dismiss");
          loading.dismiss();
          LogData("userProfileUpdateApicall_error", err);
          this.serverSideError = this.utilServices.getServerError(err);
          this.userServices.logoutApiCall(err);
        }
      );
  }

}

export const WebCategoryData = [
  {
    id: 1,
    name: 'Food & Beverages'
  },
  {
    id: 2,
    name: 'Education'
  },
  {
    id: 3,
    name: 'Apparels & Accessories'
  },
  {
    id: 4,
    name: 'Health'
  },
  {
    id: 5,
    name: 'Wedding & Events'
  },
  {
    id: 6,
    name: 'Logistic Service'
  },
  {
    id: 7,
    name: 'Printing & Stationaries'
  },
  {
    id: 8,
    name: 'Health 1'
  },
  {
    id: 9,
    name: 'Wedding 1'
  },
  {
    id: 10,
    name: 'Education 1'
  }
]

export const CompniesData = [
  {
    id: 1,
    name: 'company 1'
  },
  {
    id: 2,
    name: 'company 2'
  },
  {
    id: 3,
    name: 'company 3'
  },
  {
    id: 4,
    name: 'company 4'
  },
  {
    id: 5,
    name: 'company 5'
  },
  {
    id: 6,
    name: 'company 6'
  },
  {
    id: 7,
    name: 'company 7'
  },
  {
    id: 8,
    name: 'company 8'
  },
  {
    id: 9,
    name: 'company 9'
  },
  {
    id: 10,
    name: 'company 10'
  }
]

export const BusinessListData = [
  {
    id: 1,
    type: 'hm_join_lbl_1',
    title: 'hm_join_tl_1',
    discription: 'hm_join_disc_1',
    icon: 'business_list'
  },
  {
    id: 2,
    type: 'hm_join_lbl_2',
    title: 'hm_join_tl_2',
    discription: 'hm_join_disc_2',
    icon: 'repution'
  },
  {
    id: 3,
    type: 'hm_join_lbl_3',
    title: 'hm_join_tl_3',
    discription: 'hm_join_disc_3',
    icon: 'services'
  },
  {
    id: 4,
    type: 'hm_join_lbl_4',
    title: 'hm_join_tl_4',
    discription: 'hm_join_disc_4',
    icon: 'push_ads'
  }
];

export const BenefitsListData1 = [
  {
    id: 1,
    title: 'hm_bus_owner_tl_1',
    discription: 'hm_bus_owner_disc_1'
  },
  {
    id: 2,
    title: 'hm_bus_owner_tl_2',
    discription: 'hm_bus_owner_disc_2'
  },
  {
    id: 3,
    title: 'hm_bus_owner_tl_3',
    discription: 'hm_bus_owner_disc_3'
  }
]

export const BenefitsListData2 = [
  {
    id: 1,
    title: 'hm_some_info_tl_1',
    discription: 'hm_some_info_disc_1'
  },
  {
    id: 2,
    title: 'hm_some_info_tl_2',
    discription: 'hm_some_info_disc_2'
  },
  {
    id: 3,
    title: 'hm_some_info_tl_3',
    discription: 'hm_some_info_disc_3'
  }
]
