import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PopoverController } from '@ionic/angular';
import { UtilsService } from 'src/app/services/uitils.services';
import { LogData } from 'src/app/utils/utils';
import { AlphaNumeric, ValidationMessages } from 'src/app/validators/validations';

@Component({
  selector: 'app-transfer-bal-popover',
  templateUrl: './transfer-bal-popover.component.html',
  styleUrls: ['./transfer-bal-popover.component.scss'],
})
export class TransferBalPopoverComponent implements OnInit {

  commonForm:FormGroup;
  validationMessages=ValidationMessages;
  isFormSubmit:boolean=false;
  selectedData;
  title;
  constructor(private formBuilder:FormBuilder,
    private popCtrl:PopoverController,
    public utilsService:UtilsService) { }

  ngOnInit() {
    this.buildForm();
  }

  buildForm() {
    this.commonForm = this.formBuilder.group({
      uniqueid: [(this.selectedData && this.selectedData.uniqueid) ? this.selectedData.uniqueid:'',AlphaNumeric],
      amount:[this.selectedData ? this.selectedData.amount:'',AlphaNumeric],
    });

  }

  transfer(){
    this.isFormSubmit=true;
    if(this.commonForm.valid){
      LogData("API_CALL");
    }else{
      LogData("INVALID");
      
    }
  }

  closeModal(){
    this.popCtrl.dismiss();
  }

  cancel(){
    this.popCtrl.dismiss();
  }

}
