import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-dg-buttons',
  templateUrl: './dg-buttons.component.html',
  styleUrls: ['./dg-buttons.component.scss'],
})
export class DgButtonsComponent implements OnInit {

  @Input() btn1; //apply
  @Input() btn2; //clear
  @Output() onClickCloseBtn1: EventEmitter<any> = new EventEmitter();
  @Output() onClickCloseBtn2: EventEmitter<any> = new EventEmitter();
  constructor() { }

  ngOnInit() {}

  apply(){
    this.onClickCloseBtn1.emit();
  }
  clearAll(){
    this.onClickCloseBtn2.emit();
  }

}
