<form [formGroup]="commonForm">
  <ion-row class="mt-12">
    <ion-col size="6" *ngIf="isKm">
      <app-amct-input [controlName]="'km'" [commonForm]="commonForm" [inputType]="inputTypeEnum.NUMERIC"
        [lbl]="'lbl_set_km_other'" [isValidation]="true" [isRSSymbol]="false" [isFormSubmit]="isFormSubmit"
        [valMessage]="validationMessages.km_val">
      </app-amct-input>
    </ion-col>
    <ion-col size="6" [size]="isKm ? '6':'12'">
      <app-amct-input [controlName]="'cost'" [commonForm]="commonForm" [inputType]="inputTypeEnum.DECIMAL"
        [lbl]="'lbl_adv_price'" [isValidation]="true" [isRSSymbol]="true" [isFormSubmit]="isFormSubmit"
        [valMessage]="validationMessages.price_val">
      </app-amct-input>
    </ion-col>
  </ion-row>
  <ion-row class="mt-4" *ngIf="isKm">
    <ion-col size="6">
      <app-amct-input [controlName]="'city_km'" [commonForm]="commonForm" [inputType]="inputTypeEnum.NUMERIC"
        [lbl]="'lbl_set_km_city'" [isValidation]="true" [isRSSymbol]="false" [isFormSubmit]="isFormSubmit"
        [valMessage]="validationMessages.km_val">
      </app-amct-input>
    </ion-col>
    <ion-col size="6">
      <app-amct-input (keydown.Tab)="onKey($event);false;" [controlName]="'city_cost'" [commonForm]="commonForm" [inputType]="inputTypeEnum.DECIMAL"
        [lbl]="'lbl_adv_price'" [isValidation]="true" [isRSSymbol]="true" [isFormSubmit]="isFormSubmit"
        [valMessage]="validationMessages.price_val">
      </app-amct-input>
    </ion-col>
  </ion-row>
  <ion-row class="mt-12">
    <!-- <ion-col> -->
      <div class="mt-12 flex-row-center ion-justify-content-between w-100" lines="none">
        <ion-text class="lbl-bold-16">{{'lbl_dis_setting' | getLanguagePipe}}</ion-text>
        <ion-icon *ngIf="discount.length === 0"  name="add-circle" (click)="onClickAddMore.emit(mode)" color="secondary" class="avt-24"></ion-icon>  
      </div>
    <!-- </ion-col> -->
  </ion-row>
  <ng-container formArrayName="discount">
    <ng-container *ngIf="commonForm.get('discount')">
      <ng-container *ngFor="let item of discount.controls; let i = index;let isLast=last;let isFirst=first">
        <ng-container [formGroupName]="i" class="ion-align-items-center">
          <ion-row class="mt-12">
            <ion-col size="6">
              <app-amct-input *ngIf="!isPushAdv" [controlName]="'noof'" [commonForm]="item" [inputType]="inputTypeEnum.SELECTION"
                [lbl]="'lbl_no_month'" [isValidation]="true" [valMessage]="validationMessages.month_val"
                [isFormSubmit]="isFormSubmit" [dataList]="monthList">
              </app-amct-input>
              <app-amct-input *ngIf="isPushAdv" [isRSSymbol]="false" [controlName]="'noof'" [commonForm]="item" [inputType]="inputTypeEnum.NUMERIC"
              [lbl]="'lbl_noof_noti'" [isValidation]="true" [valMessage]="validationMessages.noof_noti"
              [isFormSubmit]="isFormSubmit">
            </app-amct-input>
            </ion-col>
        
            <ion-col size="6">
              <app-amct-input (keydown.Tab)="onKey($event);false;" [controlName]="'dis_type_val'" [commonForm]="item" [isRSSymbol]="false"
                [inputType]="inputTypeEnum.DECIMAL" [lbl]="'lbl_dis'" [isValidation]="true" (onInputChange)="onDiscountValueChange($event)"
                [isFormSubmit]="isFormSubmit" [valMessage]="validationMessages.discount_val">
              </app-amct-input>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col size="12">
              <ion-item lines="none" class="item-0" >
                <ion-text *ngIf="isLast" class="lbl-sec-med-14 cursor-ptr" (click)="onClickAddMore.emit(mode)">+
                  {{'lbl_add_dis' | getLanguagePipe}}
                </ion-text>
                <!-- *ngIf="i !== 0" -->
                <ion-text  slot="end" class="lbl-dg-14 cursor-ptr ml-8" (click)="onClickRemove.emit({value:item.value,index:i,mode:mode})">
                  - {{'lbl_remove_dis' | getLanguagePipe}}
                </ion-text>
              </ion-item>
            </ion-col>
          </ion-row>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
</form>