import { Component, Input, OnInit } from '@angular/core';
import { CommonData } from 'src/app/models/common-data';

@Component({
  selector: 'app-role-card',
  templateUrl: './role-card.component.html',
  styleUrls: ['./role-card.component.scss'],
})
export class RoleCardComponent implements OnInit {
  @Input() roleData:CommonData
  constructor() { }

  ngOnInit() {}

}
