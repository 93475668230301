
<app-dg-header [title]="title" (onClickClose)="closeModal($event)"></app-dg-header>
<ion-content>
  <hr class="divider1 w-100" />
  <form [formGroup]="commonForm">
    <ion-grid class="pl-16 pr-16">
      <ion-row class="center-row">
        <ion-col style="align-self: center" class="no-margin-padding">

            <ion-col *ngIf="paymentMethodList && paymentMethodList.length > 0 && CONST_DATA.PAGE_RECEIVED_PAYMENTS !== pageName" class="mt-12" size="12">
              <ion-label class="lbl-med-12">{{ 'dg_lbl_pay_method' | getLanguagePipe }} :</ion-label>
              <ion-item class="mt-8"
                [ngClass]="utilsService.getValidCss(commonForm.get('payment_mode'))" lines="none">
                <ion-select interface="popover" formControlName="payment_mode">
                  <ion-select-option [value]="item.value" *ngFor="let item of paymentMethodList">{{ item.text }}
                  </ion-select-option>
                </ion-select>
              </ion-item>
            </ion-col>
            <ion-col *ngIf="statusList && statusList.length > 0 && CONST_DATA.PAGE_RECEIVED_PAYMENTS === pageName" class="mt-12" size="12">
              <ion-label class="lbl-med-12">{{ 'status' | getLanguagePipe }} :</ion-label>
              <ion-item class="mt-8"
                [ngClass]="utilsService.getValidCss(commonForm.get('status'))" lines="none">
                <ion-select interface="popover" formControlName="status">
                  <ion-select-option [value]="item.value" *ngFor="let item of statusList">{{ item.text }}
                  </ion-select-option>
                </ion-select>
              </ion-item>
            </ion-col>
            <ion-row >
              <ion-col class="mt-12" size="6">
                <ion-label class="lbl-med-12">{{'dg_lbl_start_date' | getLanguagePipe}} :</ion-label>
                <ion-item lines="none" class="mt-4" 
                  [ngClass]="utilsService.getValidCss(commonForm.get('start_date'),false,true)">
                  <mat-form-field class="w-100 mtn-8">
                    <input matInput class="lbl-med-15" (click)="pickerStart.open()" readonly [max]="maxDateStart" [matDatepicker]="pickerStart"
                      formControlName="start_date" (dateChange)="orgValueChange($event)">
                    <mat-datepicker-toggle matSuffix [for]="pickerStart">
                      <mat-icon matDatepickerToggleIcon>
                        <ion-icon [src]="'cal' | getImagePipe" class="ft-20"></ion-icon>
                      </mat-icon>
                    </mat-datepicker-toggle>
                    <mat-datepicker #pickerStart></mat-datepicker>
                  </mat-form-field>
                </ion-item>
              </ion-col>
              <ion-col class="mt-12" size="6">
                <ion-label class="lbl-med-12">{{ 'dg_lbl_end_date' | getLanguagePipe}} :</ion-label>
                <ion-item lines="none" class="mt-4" 
                  [ngClass]="utilsService.getValidCss(commonForm.get('end_date'),false,true)">
                  <mat-form-field class="w-100 mtn-8">
                    <input matInput class="lbl-med-15" (click)="pickerEnd.open()" readonly [min]="maxDateEnd" [max]="maxDateStart" [matDatepicker]="pickerEnd"
                      formControlName="end_date">
                    <mat-datepicker-toggle matSuffix [for]="pickerEnd" [disabled]="(!commonForm.value.start_date) ? true:false">
                      <mat-icon matDatepickerToggleIcon>
                        <ion-icon [src]="'cal' | getImagePipe" class="ft-20"></ion-icon>
                      </mat-icon>
                    </mat-datepicker-toggle>
                    <mat-datepicker #pickerEnd></mat-datepicker>
                  </mat-form-field>
  
                </ion-item>
              </ion-col>
              </ion-row>
          <app-dg-buttons [btn1]="btn1" [btn2]="btn2" (onClickCloseBtn1)="apply($event)"
            (onClickCloseBtn2)="clearAll($event)">
          </app-dg-buttons>
        </ion-col>
      </ion-row>
    </ion-grid>
  </form>
</ion-content>