<!-- <ion-select interface="popover" [style.marginTop.px]="marginTop">
  <ion-select-option value="brown">Brown</ion-select-option>
  <ion-select-option value="blonde">Blonde</ion-select-option>
  <ion-select-option value="black">Black</ion-select-option>
  <ion-select-option value="red">Red</ion-select-option>
</ion-select> -->

<ion-card class="card-layout">
  <ion-list>
    <ion-item *ngFor="let item of dataList" (click)="itemClick(item)">
      <ion-label>{{item}}</ion-label>
    </ion-item>
  </ion-list>
</ion-card>
