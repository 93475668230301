<ion-grid>
  <ion-row class="center-row">
    <ion-col style="align-self: center" class="no-margin-padding" [attrSizeXl]="'5'" appSizeAttribute>
      <div class="bus-details-card pl-16 pr-16 pb-16">
        <ion-row>
          <ion-col class="mt-12">
            <app-name-icon [icon]="'gallery_bl'" [lable1]="'lbl_bus_de_tl_gal'"></app-name-icon>
            <hr class="divider2 w-100 mt-16 mb-8" />
            <!-- <div class="container">
        <ion-slides pager="false" [options]="slideOpts" class="slides" (ionSlideDidChange)="slideChanged($event)"
          #slides>
          <ion-slide>
            <ion-img [src]="'bus_detail' | getImagePipe:2" class="img-bus"></ion-img>
          </ion-slide>
          <ion-slide>
            <ion-img [src]="'bus_detail' | getImagePipe:2" class="img-bus"></ion-img>
          </ion-slide>
          <ion-slide>
            <ion-img [src]="'bus_detail' | getImagePipe:2" class="img-bus"></ion-img>
          </ion-slide>
          <ion-slide>
            <ion-img [src]="'bus_detail' | getImagePipe:2" class="img-bus"></ion-img>
          </ion-slide>
        </ion-slides>


        <div class="centered">
          <div class="flex" style="justify-content: center;">
            <div [ngClass]="currentIndex === 0 ? 'bullet-sel':'bullets'"></div>
            <div [ngClass]="currentIndex === 1 ? 'bullet-sel':'bullets'" class="ml-8"></div>
            <div [ngClass]="currentIndex === 2 ? 'bullet-sel':'bullets'" class="ml-8"></div>
            <div [ngClass]="currentIndex === 3 ? 'bullet-sel':'bullets'" class="ml-8"></div>
          </div>
        </div>
      </div> -->

            <!-- <div class="container" *ngIf="images && images.length > 0">
              <ion-slides pager="false" [options]="slideOpts" class="slides" (ionSlideDidChange)="slideChanged($event)"
                #slides>
                <ion-slide *ngFor="let item of images">
                  <div class="slide-img-div">
                    <ion-img [src]="(item && item.display_files && item.display_files.business_gallery
            && item.display_files.business_gallery.main_url ? item.display_files.business_gallery.main_url:'')"
                      class="img-bus max-width-100"></ion-img>
                  </div>
                </ion-slide>
              </ion-slides>
            </div> -->
            <div>
              <ng-container>
                <!-- <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="gallerySliderConfig">
                  <div ngxSlickItem *ngFor="let item of images;let isLast=last">
                    <div class="slide-img-div mb-16 ml-8">
                      <img [src]="(item && item.display_files && item.display_files.business_gallery
            && item.display_files.business_gallery.main_url ? item.display_files.business_gallery.main_url:'')"
                        class="max-width-100" />
                    </div>
                  </div>
                </ngx-slick-carousel> -->
                <ion-row>
                  <ion-col size="4" sizeXl="3" *ngFor="let item of images;let isLast=last">
                    <ion-thumbnail class="hp-100 w-auto mt-16" (click)="openFilter($event)"> 
                      <ion-img [src]="(item && item.display_files && item.display_files.business_gallery
                      && item.display_files.business_gallery.main_url ? item.display_files.business_gallery.main_url:'')" alt="Photo" class="round-10"></ion-img></ion-thumbnail>
                  </ion-col>
                </ion-row>
              </ng-container>
              <!-- <ng-template #noImages>
                <div>
                  <app-no-data-found [width]="'100'"></app-no-data-found>
                </div>
              </ng-template> -->
            </div>
          </ion-col>
        </ion-row>
      </div>
    </ion-col>