<div class="container cursor-ptr">
  <ion-item lines="none" class="mt-12" [ngClass]="getStatus(subjectData.status) === 2 ? 'bus-item':'bus-item-sel'"
  (press)="onPress($event)" (click)="onClickItem()">
    <ng-container *ngIf="UIType === 1">
      <ion-text [ngClass]="getStatus(subjectData.status) === 2 ? 'lbl-med-op05-15':'lbl-med-15 '">{{subjectData.name}}</ion-text>
    </ng-container>
  </ion-item>
  <div class="top-left">
    <ion-icon [src]="'ic_sel' | getImagePipe" class="ft-24" *ngIf="subjectData.isChecked"></ion-icon>
  </div>
</div>