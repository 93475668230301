import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { LoadingController, PopoverController } from '@ionic/angular';
import { finalize } from 'rxjs/operators';
import { CommonData } from 'src/app/models/common-data';
import { UtilsService } from 'src/app/services/uitils.services';
import { UserServices } from 'src/app/services/user.services';
import { getComplaininquiriesStatusArr, PAGE_COMPLAINS } from 'src/app/utils/constant';
import { LogData } from 'src/app/utils/utils';

@Component({
  selector: 'app-inquirie-filter',
  templateUrl: './inquirie-filter.component.html',
  styleUrls: ['./inquirie-filter.component.scss'],
})
export class InquirieFilterComponent implements OnInit {

  commonForm: FormGroup;
  modelist = [];
  @Input() lblStartDate;
  @Input() lblEndDate;
  @Input() btn1 = "apply";
  @Input() btn2 = "clear_all";
  @Input() title = "dg_tl_more_filters";
  @Input() selectedFilter;
  @Input() pageName;
  statusList: Array<CommonData> = [];
  maxDateStart: Date;
  maxDateEnd: Date;
  isHideField: boolean = false;
  constructor(public utilsService: UtilsService,
    private formBuilder: FormBuilder,
    private popoverCtrl: PopoverController,
    private userServices:UserServices,
    private loadingCtrl:LoadingController) {
    const currentYear = new Date();
    this.maxDateStart = new Date(currentYear);
    this.maxDateEnd = new Date(currentYear);
  }

  ngOnInit() {
    this.getMasters();
    this.buildForm();
  }

  buildForm() {
    this.commonForm = this.formBuilder.group({
      start_date: [""],
      end_date: [""],
      status: [""],
    });
  }


  orgValueChange(data) {
    LogData("orgValueChange", data.value);
    this.maxDateEnd = data.value;
    this.commonForm.patchValue({ end_date: '' })
  }
  updateBuildForm(){
    LogData("selectedFilter",this.selectedFilter);
    if(this.selectedFilter){
      this.commonForm.patchValue(this.selectedFilter);
    }
  }

  closeModal(ev) {
    this.popoverCtrl.dismiss();
  }

  apply(ev) {
    this.popoverCtrl.dismiss(this.commonForm.value);
  }

  clearAll(ev) {
    this.buildForm();
    this.utilsService.setAddressMaster({
      page: this.pageName,
      value: true,
    });
    this.popoverCtrl.dismiss();
  }

   //api call
   async getMasters() {
    const loading = await this.loadingCtrl.create(
      this.utilsService.getLoaderUI()
    );
    await loading.present();
    LogData("loader_start");
    let list = "";
    // list = getComplaininquiriesStatusArr + "," + getComplainUsersArr + "," + getComplainInquiriesStaffsArr;
    list = getComplaininquiriesStatusArr;
    const formData = new FormData();
    formData.append("master", list);
    this.userServices
      .getMasters(formData)
      .pipe(
        finalize(() => {
          LogData("loader_dismiss");
          loading.dismiss();
        })
      )
      .subscribe(
        (res) => {
          LogData("getMasters_res", res);
          res = this.utilsService.getDecriptReasponse(res);
          if (res && res.result && res.result.responce) {
            if (res.result.responce.getComplaininquiriesStatusArr && res.result.responce.getComplaininquiriesStatusArr.length > 0) {
              this.statusList = res.result.responce.getComplaininquiriesStatusArr;
            } else {
              this.statusList = [];
            }
            this.updateBuildForm();
          }
        },
        (err) => {
          LogData("loader_dismiss");
          loading.dismiss();
          LogData("getState_error", err);
        }
      );
  }
}
