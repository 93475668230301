<div class="div-card cursor-ptr">
  <div class="card-div p-8"><ion-text class="lbl-med-wh-14">{{getType().lbl | getLanguagePipe}}</ion-text></div>
  <div class="pad-div">
    <ion-item class="item-0 mt-4" lines="none">
      <ion-thumbnail slot="start" class="m-0 ion-align-self-start avt-36">
        <ion-img [src]="'rec_pay_sq' | getImagePipe"></ion-img>
      </ion-thumbnail>
      <div class="flex flex-col ml-12 w-100">
        <ion-item class="item-0" lines="none">
          <ion-label>
            <p class="created-by-lbl" *ngIf="paymentsData.account_date">
              {{ paymentsData.account_date | dynamicDateTimePipe: 2 }}
            </p>
          </ion-label>
          <ion-text class="ml-4 lbl-sec-12" slot="end">{{
          paymentsData.grand_amount_symbol
        }}</ion-text>
        </ion-item>
        <ion-text class="lbl-sb-14" *ngIf="
          paymentsData.module_json && paymentsData.module_json.business_name
        ">
          {{ paymentsData.module_json.business_name }}</ion-text>
        <ion-text class="lbl-sb-14" *ngIf="
          paymentsData.module_json && paymentsData.module_json.user && paymentsData.module_json.user.business_name
        ">
          {{ paymentsData.module_json.user.business_name }}</ion-text>

        <ion-text class="lbl-op05-12" *ngIf="paymentsData.uniqueid">{{
        paymentsData.uniqueid
      }}</ion-text>
        <div class="flex flex-row W-100 ion-justify-content-end" *ngIf="isBtnDisplayForQR() || isBtnDisplayForOnline()">
          <ion-button class="btn-3" *ngIf="isBtnDisplayForQR()" (click)="openEdit(); $event.stopPropagation()">
            <ion-text>{{ "lbl_edt" | getLanguagePipe }}</ion-text>
          </ion-button>
          <ng-container *ngIf="!getType().isBanner && !getType().isPush">
            <ion-button class="btn-3 ml-8" *ngIf="isBtnDisplayForQR() || isBtnDisplayForOnline()"
              (click)="openNew(); $event.stopPropagation()">
              <ion-text>{{ "lbl_nw" | getLanguagePipe }}</ion-text>
            </ion-button>
          </ng-container>

        </div>
      </div>
    </ion-item>
    <ion-item class="item-0" lines="none" *ngIf="
      paymentsData.module_json &&
      (paymentsData.module_json.phone || paymentsData.module_json.email)
    ">
      <div class="flex-row-center" slot="start" *ngIf="paymentsData.module_json.phone">
        <ion-icon [src]="'ic_ro_call_g' | getImagePipe" class="ft-16"></ion-icon>
        <ion-text class="ml-8 lbl-10">{{
        paymentsData.module_json.phone
      }}</ion-text>
      </div>
      <div class="flex-row-center" slot="end" *ngIf="paymentsData.module_json.email">
        <ion-icon [src]="'ic_ro_msg_g' | getImagePipe" class="ft-16"></ion-icon>
        <ion-text class="ml-8 lbl-10">{{
        paymentsData.module_json.email
      }}</ion-text>
      </div>
    </ion-item>
    <ion-item class="item-0" lines="none" *ngIf="
      paymentsData.module_json && paymentsData.module_json.user &&
      (paymentsData.module_json.user.phone || paymentsData.module_json.user.email)
    ">
      <div class="flex-row-center" slot="start" *ngIf="paymentsData.module_json.user.phone">
        <ion-icon [src]="'ic_ro_call_g' | getImagePipe" class="ft-16"></ion-icon>
        <ion-text class="ml-8 lbl-10">{{
        paymentsData.module_json.user.phone
      }}</ion-text>
      </div>
      <div class="flex-row-center" slot="end" *ngIf="paymentsData.module_json.user.email">
        <ion-icon [src]="'ic_ro_msg_g' | getImagePipe" class="ft-16"></ion-icon>
        <ion-text class="ml-8 lbl-10">{{
        paymentsData.module_json.user.email
      }}</ion-text>
      </div>
    </ion-item>
    <hr class="divider1 mt-8 mb-8 w-100" />
    <div class="flex flex-col">
      <ion-text *ngIf="paymentsData.reference_no"><span
          class="lbl-op05-12">{{ "lbl_ref_no" | getLanguagePipe }}:&nbsp;</span><span
          class="lbl-12">{{ paymentsData.reference_no }}</span></ion-text>
      <!-- *ngIf="(paymentsData.start_date || paymentsData.end_date) && isBusinessUser -->
      <ion-item class="item-0" lines="none" *ngIf="paymentsData.start_date || paymentsData.end_date">
        <div slot="start" *ngIf="paymentsData.start_date">
          <ion-label>
            <p class="lbl-op05-12">{{ "lbl_start_dt" | getLanguagePipe }}</p>
            <p class="lbl-12">
              {{ paymentsData.start_date | dynamicDateTimePipe }}
            </p>
          </ion-label>
        </div>
        <div slot="end" *ngIf="paymentsData.end_date">
          <ion-label>
            <p class="lbl-op05-12">{{ "lbl_end_dt" | getLanguagePipe }}</p>
            <p class="lbl-12">
              {{ paymentsData.end_date | dynamicDateTimePipe }}
            </p>
          </ion-label>
        </div>
      </ion-item>
      <ion-item class="item-0" lines="none">
        <ion-text slot="start" class="lbl-12" *ngIf="paymentsData.status && paymentsData.status.length > 0"><span
            class="lbl-op05-12">{{ "status" | getLanguagePipe }}</span>
          :&nbsp;<span>{{ paymentsData.status[0].text }}</span></ion-text>
        <ion-text slot="end" class="lbl-12 ml-4" *ngIf="
          paymentsData.payment_type && paymentsData.payment_type.length > 0
        ">
          <span class="lbl-op05-12">{{ "lbl_type" | getLanguagePipe }}
            :&nbsp;</span><span>{{ paymentsData.payment_type[0].text }}</span>
        </ion-text>
      </ion-item>
      <ion-item class="item-0 w-100 mt-4" lines="none" *ngIf="paymentsData.account_notes">
        <ion-icon slot="start" class="mr-12 avt-20" [src]="'naration' | getImagePipe"></ion-icon>
        <ion-text class="lbl-op05-11">{{ paymentsData.account_notes }}</ion-text>
      </ion-item>
      <ion-item class="item-0 w-100 mt-4" lines="none" *ngIf="paymentsData.advertisement">
        <ion-text slot="start" class="lbl-12">
          <span class="lbl-op05-12">{{ "lbl_ad_address" | getLanguagePipe }}
            :&nbsp;</span><span>{{ paymentsData.advertisement | getAddressStrPipe }}</span>
        </ion-text>
      </ion-item>
      <ion-item class="item-0 w-100 mt-4" lines="none">
        <ion-text slot="start" class="created-by-lbl-sm mt-4 mr-2"
          *ngIf="paymentsData.createdby && paymentsData.createdby.length > 0">{{ "lbl_creatd_by" | getLanguagePipe }} :
          {{ paymentsData.createdby[0].text }}</ion-text>
      </ion-item>
      <ng-container *ngIf="!getType().isBanner && !getType().isPush">
        <ion-button style="width: fit-content" slot="end" class="btn-3 btn-height"
          *ngIf="rollId === CONST_DATA.ADMIN_ROLE_ID && paymentsData.end_date_ch !== 2"
          (click)="endDateChange(); $event.stopPropagation()">
          <ion-text>{{ "lbl_end_date_ch" | getLanguagePipe }}</ion-text>
        </ion-button>
      </ng-container>
      <div class="flex flex-row ion-align-items-end" slot="end"
        *ngIf="rollId === CONST_DATA.BUSINESS_ROLE_ID && paymentsData.subs_btn">
        <!-- <ion-button class="btn-3"
          (click)="openPaymentPage();$event.stopPropagation();">
          <ion-text>{{'lbl_fre_tr_pay' | getLanguagePipe}}</ion-text>
        </ion-button> -->
        <!-- <ion-button class="btn-3"
          (click)="openPaymentPage();$event.stopPropagation();">
          <ion-text>{{'lbl_adv_pay' | getLanguagePipe}}</ion-text>
        </ion-button>
        <ion-button class="btn-3"
          (click)="openPaymentPage();$event.stopPropagation();">
          <ion-text>{{'lbl_renew' | getLanguagePipe}}</ion-text>
        </ion-button> -->
        <ion-button class="btn-3 btn-height" (click)="
          openPaymentPage(paymentsData.subs_btn); $event.stopPropagation()
        ">
          <ion-text>{{ getLable() | getLanguagePipe }}</ion-text>
        </ion-button>
      </div>

    </div>
  </div>
</div>