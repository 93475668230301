<div class="container cursor-ptr">
<div [ngClass]="getStatus(accountData.status) === 2 ? 'bus-item':'bus-item-sel'">
    <ion-item lines="none" class="pt-12 pb-12 item-trans" (press)="onPress($event)" (pressup)="onPressUp($event)"
    (click)="onClickItem()">
      <div class="flex flex-col">
        <ion-text class="lbl-bold-15">{{accountData.name}}</ion-text>
        <ion-text class="lbl-sec-12" *ngIf="accountData.parent_id && accountData.parent_id.length > 0">{{accountData.parent_id[0].text}}</ion-text>
      </div>
    </ion-item>
    <div class="top-left">
      <ion-icon [src]="'ic_sel' | getImagePipe" class="ft-24" *ngIf="accountData.isChecked"></ion-icon>
    </div>
  </div>
</div>
