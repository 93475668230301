<!-- <app-dg-header [title]="title" (onClickClose)="closeModal($event)"></app-dg-header> -->
<ion-content>
  <!-- <hr class="divider1 w-100" /> -->
  <ion-grid class="h-100">
    <div  class="flex flex-row ion-justify-content-end pt-8 pl-12 close-icon"> <ion-icon color="white" name="close-circle-outline" (click)="closeModal($event)" class="ft-36"></ion-icon></div>
    <ion-row class="center-row h-100">
      <ion-col style="align-self: center" class="no-margin-padding">
        <ion-row>
          <ion-col size="1.5" sizeXl="1" class="ion-align-self-center">
            <ion-icon [src]="(sliderThree.isBeginningSlide? 'prev_pr_d':'prev_pr') | getImagePipe" class="avt-32 cursor-ptr" (click)="slidePrev(slideWithNav3)"></ion-icon>
          </ion-col>
          <ion-col size="9"  sizeXl="10" class="flex">
            <swiper
              pager="false"
              [initialSlide]="0"
              [slidesPerView]="1"
              [autoplay]="false"
              [effect]="'coverflow'"
              #slideWithNav3=""
              [zoom]="true"
              (swiper)="onSwiper($event)"
              (slideChange)="onSlideChange(sliderThree,slideWithNav3)"
            >
              <ng-template swiperSlide *ngFor="let item of sliderThree.slidesItems">
                <div class="img-div">
                  <div class="swiper-zoom-container">
                    <img tappable [src]="(item && item.display_files && item.display_files.business_gallery
                    && item.display_files.business_gallery.main_url ? item.display_files.business_gallery.main_url:'')" />
                    </div>
                  </div>
              </ng-template>
            </swiper>
          </ion-col>
          <ion-col size="1.5"  sizeXl="1" class="ion-align-self-center">
            <ion-icon [src]="(sliderThree.isEndSlide ? 'next_pr_d':'next_pr') | getImagePipe" class="avt-32 cursor-ptr"  (click)="slideNext(slideWithNav3)"></ion-icon>
          </ion-col>
        </ion-row>
        <!-- <app-dg-buttons [btn1]="btn1" [btn2]="btn2" (onClickCloseBtn1)="apply($event)" (onClickCloseBtn2)="clearAll($event)">
        </app-dg-buttons> -->
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>