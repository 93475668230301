import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-ui-cmp',
  templateUrl: './ui-cmp.component.html',
  styleUrls: ['./ui-cmp.component.scss'],
})
export class UiCmpComponent implements OnInit {

  @Input() title;
  @Input() isNoHeght:boolean=false;
  @Input() isNoIcon:boolean=false;
  @Input() fontFamily='text-bold';
  @Input() fontSize='text-16';
  @Input() iconSlot='end';
  @Input() icon="ic_login";
  @Input() btnBg="btn-1";
  @Input() iconCss;
  @Output() buttonClicked: EventEmitter<any> = new EventEmitter();
  constructor() { }

  ngOnInit() {}

  onButtonClick(){
  this.buttonClicked.emit(true)
  }

}
