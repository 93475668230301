<div class="flex flex-col p-24 gs-card ion-justify-content-center">
  <div class="ion-text-center">
    <ion-text class="lbl-med-14 lh-24">Number’s of gallary photos 
      upload by business is:</ion-text>
  </div>
  <div class="flex flex-row mt-24 ion-justify-content-center">
    <ion-icon [src]="'minus_ro' | getImagePipe" class="ion-align-self-center ft-24 mr-12"
    (click)="decCount()"></ion-icon>
    <div class="valid-item">
      <ion-input class="gs-inp" [(ngModel)]="gsCount" type="number"></ion-input>
    </div>
    <ion-icon [src]="'add_ro' | getImagePipe" class="ion-align-self-center ft-24 ml-12"
    (click)="incCount()"></ion-icon>
  </div>
  </div>
