import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BusinessDetails } from 'src/app/models/business-data';
import { CommonData } from 'src/app/models/common-data';
import { UtilsService } from 'src/app/services/uitils.services';
import { RUPPES } from 'src/app/utils/constant';
import { DISCOUNTS_VIEW_RT, EDIT_BUSINESS, EDIT_BUSINESS_TYPE, EDIT_PRODUCT, EDIT_PRODUCT_COMPNEY, EDIT_TRADING } from 'src/app/utils/routes';
import { LogData } from 'src/app/utils/utils';

@Component({
  selector: 'app-business-name',
  templateUrl: './business-name.component.html',
  styleUrls: ['./business-name.component.scss'],
})
export class BusinessNameComponent implements OnInit {

  CONST_DATA={
    RUPPES
  }
  @Input() businessData:BusinessDetails;
  @Output() getItems: EventEmitter<any> = new EventEmitter();
  @Input() checkedCount;
  @Input() UIType=1;
  @Input() urlIdx;
  isLongPressClick:boolean=false;
  @Input() isViewBtn:boolean=false;
  constructor(private utilsService:UtilsService) { }

  ngOnInit() {}

  openView(){
    this.utilsService.setNavigationRouteQuery(DISCOUNTS_VIEW_RT,{ id: this.businessData.id });
  }

  setCheckedData(){
    if(this.businessData.isChecked){
      this.businessData.isChecked=false;
    }else{
      this.businessData.isChecked=true;
    }
    this.getItems.emit(true);
  }

  getStatus(status:Array<CommonData>){
    return (status && status.length > 0) ? status[0].value:'';
  }

  // onClickItem(){
  //   // this.setCheckedData();
  //   if(!this.isLongPressClick){
  //     if(this.checkedCount === 0){
  //       this.utilsService.setNavigationRoute((this.urlIdx === 1 ? EDIT_BUSINESS:this.urlIdx === 2 ? 
  //         EDIT_BUSINESS_TYPE:this.urlIdx === 3 ? EDIT_TRADING:this.urlIdx === 4 ? EDIT_PRODUCT:
  //         this.urlIdx === 5 ? EDIT_PRODUCT_COMPNEY:'')  +this.businessData.id,{data:this.businessData});
  //     }else{
  //       this.setCheckedData();
  //     }
  //   }else{
  //     this.isLongPressClick=false;
  //   }
  // }

  onClickItem(){
    // this.setCheckedData();
    if(!this.isLongPressClick){
      if(this.checkedCount === 0){
        this.utilsService.setNavigationRoute((this.urlIdx === 1 ? EDIT_BUSINESS:this.urlIdx === 2 ? 
          EDIT_BUSINESS_TYPE:this.urlIdx === 3 ? EDIT_TRADING:this.urlIdx === 4 ? EDIT_PRODUCT:
          this.urlIdx === 5 ? EDIT_PRODUCT_COMPNEY:'')  +this.businessData.id);
      }else{
        this.setCheckedData();
      }
    }else{
      this.isLongPressClick=false;
    }
  }


  onPress($event) {
    LogData("onPress", $event);
    this.isLongPressClick=true;
    this.setCheckedData();
}

onPressUp($event) {
    LogData("onPressUp", $event);

}
// Controller Functions

}
