<ion-grid>
  <ion-row class="center-row">
    <ion-col style="align-self: center" class="no-margin-padding" [attrSizeXl]="'5'" appSizeAttribute>
      <div class="flex-col-center services-bg">

        <div class="bus-details-card"
          *ngIf="businessDetail.productcompany_id && businessDetail.productcompany_id.length > 0">
          <ion-row>
            <ion-col class="mt-12">
              <!-- [lable2]="'lbl_bus_de_comp_disc'" -->
              <app-name-icon [icon]="'compney_bl'" [lable1]="'lbl_bus_de_comp'"></app-name-icon>
              <hr class="divider2 w-100 mt-16 mb-8" />
              <ng-container>
                <ngx-slick-carousel class="carousel" #slickModal="slick-carousel"
                  [config]="servicesCardCompneySliderConfig">
                  <div ngxSlickItem *ngFor="let slide of businessDetail.productcompany_id;let isLast=last"
                    class="slide">
                    <app-product-compney-card [productCompneyData]="slide" [isLast]="isLast"></app-product-compney-card>
                  </div>
                </ngx-slick-carousel>
              </ng-container>
              <!-- <ng-template #noProcom>
                <div>
                  <app-no-data-found [width]="'100'"></app-no-data-found>
                </div>
              </ng-template> -->
            </ion-col>
          </ion-row>
        </div>

        <!-- <div class="bus-details-card mt-16" *ngIf="businessDetail.TradingtypesSales && businessDetail.TradingtypesSales.data &&
        businessDetail.TradingtypesSales.data.length > 0">
          <ion-row>
            <ion-col class="mt-12">
              <app-name-icon  [icon]="'sale_bl'" [lable1]="'lbl_bus_de_sales_product'" ></app-name-icon>
              <hr class="divider2 w-100 mt-16 mb-8" />
             <app-according-card  [dataArray]="businessDetail.TradingtypesSales.data"></app-according-card>
            </ion-col>
          </ion-row>
        </div>

        <div class="bus-details-card mt-16" *ngIf="businessDetail.TradingtypesService && businessDetail.TradingtypesService.data && 
        businessDetail.TradingtypesService.data.length > 0">
          <ion-row>
            <ion-col class="mt-12">
              <app-name-icon  [icon]="'services_bl'" [lable1]="'lbl_bus_de_services'"></app-name-icon>
              <hr class="divider2 w-100 mt-16 mb-8" />
             <app-according-card  [dataArray]="businessDetail.TradingtypesService.data"></app-according-card>
            </ion-col>
          </ion-row>
        </div>

        <div class="bus-details-card mt-16" *ngIf="businessDetail.TradingtypesManufacturer && businessDetail.TradingtypesManufacturer.data && 
        businessDetail.TradingtypesManufacturer.data.length > 0">
          <ion-row>
            <ion-col class="mt-12">
              <app-name-icon  [icon]="'services_bl'" [lable1]="'lbl_bus_de_manuf'"></app-name-icon>
              <hr class="divider2 w-100 mt-16 mb-8" />
             <app-according-card  [dataArray]="businessDetail.TradingtypesManufacturer.data"></app-according-card>
            </ion-col>
          </ion-row>
        </div> -->
        <!-- new_code_start -->
        <ng-container *ngIf="tradingData">
          <ng-container *ngFor="let item of tradingData">
            <ng-container *ngIf=" item.Businesstypes">
              <div class="flex flex-col w-100">

                <ion-accordion-group class="mt-12" *ngIf="isTabAvailable(item,1) || isTabAvailable(item,2) || isTabAvailable(item,3)">
                  <ion-accordion value="first">
                    <ion-item slot="header">
                      <ion-text class="lbl-20">{{item.text}}</ion-text>
                    </ion-item>
                    <div class="ion-padding" slot="content">
                      <!-- salse -->
                      <div class="bus-details-card mt-16" *ngIf="isTabAvailable(item,1)">
                        <ion-row>
                          <ion-col class="mt-12">
                            <app-name-icon [icon]="'sale_bl'" [lable1]="'lbl_bus_de_sales_product'"></app-name-icon>
                            <hr class="divider2 w-100 mt-16 mb-8" />
                            <app-according-card [dataArray]="item.Businesstypes[1].Tradingtypes"></app-according-card>
                          </ion-col>
                        </ion-row>
                      </div>
                      <!-- services -->
                      <div class="bus-details-card mt-16" *ngIf="isTabAvailable(item,2)">
                        <ion-row>
                          <ion-col class="mt-12">
                            <app-name-icon [icon]="'services_bl'" [lable1]="'lbl_bus_de_services'"></app-name-icon>
                            <hr class="divider2 w-100 mt-16 mb-8" />
                            <app-according-card [dataArray]="item.Businesstypes[2].Tradingtypes"></app-according-card>
                          </ion-col>
                        </ion-row>
                      </div>
                      <!-- manuf -->
                      <div class="bus-details-card mt-16" *ngIf="isTabAvailable(item,3)">
                        <ion-row>
                          <ion-col class="mt-12">
                            <app-name-icon [icon]="'ic_manuf'" [lable1]="'lbl_bus_de_manuf'"></app-name-icon>
                            <hr class="divider2 w-100 mt-16 mb-8" />
                            <app-according-card [dataArray]="item.Businesstypes[3].Tradingtypes"></app-according-card>
                          </ion-col>
                        </ion-row>
                      </div>
                    </div>
                  </ion-accordion>
                </ion-accordion-group>

              </div>
            </ng-container>
          </ng-container>

        </ng-container>

        <!-- new_code_end -->
      </div>

    </ion-col>
  </ion-row>
</ion-grid>