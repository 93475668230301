<div class="container">
  <div [ngClass]="getStatus(addressData.status) === 2 ? 'addres-item':'addres-item-sel'">
    <!-- state -->
    <ng-container *ngIf="uiType === 1">

      <ion-item lines="none" class="pt-12 pb-12 item-trans cursor-ptr" (press)="onPress($event)" (pressup)="onPressUp($event)"
        (click)="onClickItem()">
        <ion-thumbnail slot="start" class="avt-36">
          <img [src]="'address_data' | getImagePipe" />
        </ion-thumbnail>
        <div class="flex flex-col">
          <ion-text class="lbl-med-16">{{ addressData.state_name }}</ion-text>
          <ion-text class="lbl-op">
            {{'lbl_state_code' | getLanguagePipe}} : {{ addressData.state_code }}</ion-text>
        </div>
      </ion-item>

    </ng-container>
    <!-- districts -->
    <ng-container *ngIf="uiType === 2">
      <ion-item lines="none" class="pt-12 pb-12 item-trans cursor-ptr" (press)="onPress($event)" (pressup)="onPressUp($event)"
        (click)="onClickItem()">
        <ion-thumbnail slot="start" class="avt-36 ion-align-self-start">
          <img [src]="'address_data' | getImagePipe" />
        </ion-thumbnail>
        <div class="flex flex-col">
          <ion-text class="lbl-med-16">{{ addressData.district_name }}</ion-text>
          <div class="flex flex-col" *ngIf="addressData.state_id && addressData.state_id.length > 0">
            <ion-text class="lbl-op">
              <span>{{'lbl_state_name' | getLanguagePipe}} :&nbsp;</span>
              <span class="lbl-11">{{addressData.state_id[0].text}}</span>
            </ion-text>

          </div>
          <!-- <div class="flex flex-row mt-8">
            <div class="flex flex-col">
              <ion-text class="lbl-op">{{'lbl_district_name' | getLanguagePipe}} :</ion-text>
              <ion-text class="lbl-11">{{ addressData.district_name }}</ion-text>
            </div>
            <div class="flex flex-col ml-16" *ngIf="addressData.state_id && addressData.state_id.length > 0">
              <ion-text class="lbl-op">{{'lbl_state_name' | getLanguagePipe}} :</ion-text>
              <ion-text class="lbl-11">{{
                addressData.state_id[0].text
                }}</ion-text>
            </div>
          </div> -->
        </div>
      </ion-item>
    </ng-container>

    <!-- talukas -->
    <ng-container *ngIf="uiType === 3">
      <ion-item lines="none" class="pt-12 pb-12 item-trans cursor-ptr" (press)="onPress($event)" (pressup)="onPressUp($event)"
        (click)="onClickItem()">
        <ion-thumbnail slot="start" class="avt-36 ion-align-self-start">
          <img [src]="'address_data' | getImagePipe" />
        </ion-thumbnail>
        <div class="flex flex-col">
          <ion-text class="lbl-med-16">{{ addressData.taluka_name }}</ion-text>
          <ion-text *ngIf="addressData.district_id && addressData.district_id.length > 0">
            <span class="lbl-op">{{'lbl_district_name' | getLanguagePipe}} :&nbsp;</span>
            <span class="lbl-11">{{addressData.district_id[0].text}}</span>
          </ion-text>
          <ion-text *ngIf="addressData.state_id && addressData.state_id.length > 0">
            <span class="lbl-op">{{'lbl_state_name' | getLanguagePipe}} :&nbsp;</span>
            <span class="lbl-11">{{addressData.state_id[0].text}}</span>
          </ion-text>

          <!-- <div class="flex flex-row mt-8">
            <div class="flex flex-col" *ngIf="
              addressData.district_id && addressData.district_id.length > 0
            ">
              <ion-text class="lbl-op">{{'lbl_district_name' | getLanguagePipe}} :</ion-text>
              <ion-text class="lbl-11">{{
                addressData.district_id[0].text
                }}</ion-text>
            </div>
            <div class="flex flex-col ml-16" *ngIf="addressData.state_id && addressData.state_id.length > 0">
              <ion-text class="lbl-op">{{'lbl_state_name' | getLanguagePipe}} :</ion-text>
              <ion-text class="lbl-11">{{
                addressData.state_id[0].text
                }}</ion-text>
            </div>
          </div> -->
        </div>
      </ion-item>
    </ng-container>

    <!-- villages -->
    <ng-container *ngIf="uiType === 4">
      <div class="pt-12 pb-12" (press)="onPress($event)" (pressup)="onPressUp($event)" (click)="onClickItem()">
        <ion-item lines="none" class="item-trans cursor-ptr">
          <ion-thumbnail slot="start" class="avt-36 ion-align-self-start">
            <img [src]="'address_data' | getImagePipe" />
          </ion-thumbnail>
          <div class="flex flex-col">
            <ion-text class="lbl-med-16">{{
              addressData.village_name
              }}</ion-text>
            <ion-text class="lbl-pincode">{{ addressData.pincode }}</ion-text>
            <ion-text *ngIf="addressData.taluka_id && addressData.taluka_id.length > 0">
              <span class="lbl-op">{{'lbl_taluka_name' | getLanguagePipe}} :&nbsp;</span>
              <span class="lbl-11">{{addressData.taluka_id[0].text}}</span>
            </ion-text>
            <ion-text *ngIf="addressData.district_id && addressData.district_id.length > 0">
              <span class="lbl-op">{{'lbl_district_name' | getLanguagePipe}} :&nbsp;</span>
              <span class="lbl-11">{{addressData.district_id[0].text }}</span>
            </ion-text>
            <ion-text *ngIf="addressData.state_id && addressData.state_id.length > 0">
              <span class="lbl-op">{{'lbl_state_name' | getLanguagePipe}} :&nbsp;</span>
              <span class="lbl-11">{{addressData.state_id[0].text}}</span>
            </ion-text>

          </div>
        </ion-item>
        <!-- <hr class="divider1 w-100" /> -->
        <!-- <ion-row>
          <ion-col>
            <div class="flex flex-col ml-16" *ngIf="addressData.taluka_id && addressData.taluka_id.length > 0">
              <ion-text class="lbl-op">{{'lbl_taluka_name' | getLanguagePipe}} :</ion-text>
              <ion-text class="lbl-11">{{
                addressData.taluka_id[0].text
                }}</ion-text>
            </div>
          </ion-col>
          <ion-col>
            <div class="flex flex-col" *ngIf="
              addressData.district_id && addressData.district_id.length > 0
            ">
              <ion-text class="lbl-op">{{'lbl_district_name' | getLanguagePipe}} :</ion-text>
              <ion-text class="lbl-11">{{
                addressData.district_id[0].text
                }}</ion-text>
            </div>
          </ion-col>
          <ion-col>
            <div class="flex flex-col ml-16" *ngIf="addressData.state_id && addressData.state_id.length > 0">
              <ion-text class="lbl-op">{{'lbl_state_name' | getLanguagePipe}} :</ion-text>
              <ion-text class="lbl-11">{{
                addressData.state_id[0].text
                }}</ion-text>
            </div>
          </ion-col>
        </ion-row> -->
      </div>
    </ng-container>

    <!-- villages area -->
    <ng-container *ngIf="uiType === 5">
      <div class="pt-12 pb-12" (press)="onPress($event)" (pressup)="onPressUp($event)" (click)="onClickItem()">
        <ion-item lines="none" class="item-trans cursor-ptr">
          <ion-thumbnail slot="start" class="avt-36 ion-align-self-start">
            <img [src]="'address_data' | getImagePipe" />
          </ion-thumbnail>
          <div class="flex flex-col">
            <ion-text class="lbl-med-16">{{ addressData.area_name }}</ion-text>
            <ion-text class="lbl-pincode"><span *ngIf="
                addressData.village_id && addressData.village_id.length > 0
              ">
                {{ addressData.village_id[0].text }} </span>{{ addressData.pincode }}</ion-text>

            <ion-text *ngIf="addressData.taluka_id && addressData.taluka_id.length > 0">
              <span class="lbl-op">{{'lbl_taluka_name' | getLanguagePipe}} :&nbsp;</span>
              <span class="lbl-11">{{addressData.taluka_id[0].text}}</span>
            </ion-text>
            <ion-text *ngIf="addressData.district_id && addressData.district_id.length > 0">
              <span class="lbl-op">{{'lbl_district_name' | getLanguagePipe}} :&nbsp;</span>
              <span class="lbl-11">{{addressData.district_id[0].text}}</span>
            </ion-text>
            <ion-text *ngIf="addressData.state_id && addressData.state_id.length > 0">
              <span class="lbl-op">{{'lbl_state_name' | getLanguagePipe}} :</span>
              <span class="lbl-11">{{addressData.state_id[0].text}}</span>
            </ion-text>
          </div>
        </ion-item>
        <!-- <hr class="divider1 w-100" /> -->
        <!-- <ion-row>
          <ion-col>
            <div class="flex flex-col ml-16" *ngIf="addressData.taluka_id && addressData.taluka_id.length > 0">
              <ion-text class="lbl-op">{{'lbl_taluka_name' | getLanguagePipe}} :</ion-text>
              <ion-text class="lbl-11">{{
                addressData.taluka_id[0].text
                }}</ion-text>
            </div>
          </ion-col>
          <ion-col>
            <div class="flex flex-col" *ngIf="
              addressData.district_id && addressData.district_id.length > 0
            ">
              <ion-text class="lbl-op">{{'lbl_district_name' | getLanguagePipe}} :</ion-text>
              <ion-text class="lbl-11">{{
                addressData.district_id[0].text
                }}</ion-text>
            </div>
          </ion-col>
          <ion-col>
            <div class="flex flex-col ml-16" *ngIf="addressData.state_id && addressData.state_id.length > 0">
              <ion-text class="lbl-op">{{'lbl_state_name' | getLanguagePipe}} :</ion-text>
              <ion-text class="lbl-11">{{
                addressData.state_id[0].text
                }}</ion-text>
            </div>
          </ion-col>
        </ion-row> -->
      </div>
    </ng-container>
    <!-- allocated area -->
    <ng-container *ngIf="uiType === 6">
      <ion-item lines="none" class="pt-12 pb-12 item-trans cursor-ptr" (press)="onPress($event)" (pressup)="onPressUp($event)"
        (click)="onClickItem()">
        <ion-thumbnail slot="start" class="avt-36 ion-align-self-start">
          <img [src]="'address_data' | getImagePipe" />
        </ion-thumbnail>
        <div class="flex flex-col w-100">
          <ion-text *ngIf="addressData.state_id && addressData.state_id.length > 0">
            <span class="lbl-op">{{'lbl_state_name' | getLanguagePipe}} :</span>
            <span class="lbl-11">{{ addressData.state_id[0].text }}</span>
          </ion-text>
          <ion-text *ngIf="addressData.district_id && addressData.district_id.length > 0">
            <span class="lbl-op">{{'lbl_district_name' | getLanguagePipe}} :</span>
            <span class="lbl-11">{{ addressData.district_id[0].text }}</span>
          </ion-text>
          <ion-text *ngIf="addressData.taluka_id && addressData.taluka_id.length > 0">
            <span class="lbl-op">{{'lbl_taluka_name' | getLanguagePipe}} :</span>
            <span class="lbl-11">{{ addressData.taluka_id[0].text }}</span>
          </ion-text>
          <ion-text class="lbl-op" *ngIf="addressData.zone_name && addressData.zone_name.length > 0">
            <span class="lbl-op">{{'lbl_bd_list_zone_name' | getLanguagePipe}} :</span>
            <span class="lbl-11">{{ addressData.zone_name[0].text }}</span>
          </ion-text>
          <ion-text class="lbl-op" *ngIf="addressData.village_id && addressData.village_id.length > 0">
            <span class="lbl-op">{{'lbl_area_name' | getLanguagePipe}} :</span>
            <span class="lbl-11">{{ addressData.village_id[0].text }}</span>
          </ion-text>
          <ion-text class="created-by-lbl" *ngIf="addressData.createdby && addressData.createdby.length > 0">
            {{'lbl_creatd_by' | getLanguagePipe}} :{{addressData.createdby[0].text}}
            </ion-text>
        </div>
      </ion-item>
    </ng-container>
  </div>
  <div class="top-left">
    <ion-icon [src]="'ic_sel' | getImagePipe" class="ft-24" *ngIf="addressData.isChecked"></ion-icon>
  </div>
</div>