import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { LogData } from '../../utils/utils';
import { CommonData } from '../../models/common-data';
import { element } from 'protractor';
import { Tradingproductandcompany } from '../../models/trading-product-compnay';
import { SALSE_PRD_IDX, SERVICES_PRD_IDX, MANUF_PRD_IDX } from '../../utils/constant';

@Component({
  selector: 'app-product-services',
  templateUrl: './product-services.component.html',
  styleUrls: ['./product-services.component.scss'],
})
export class ProductServicesComponent implements OnInit {

  // list1=[1,2,3,4,5,6,7,8,9,10,1,2,3,4,5,6,7,8,9,10,1,2,3,4,5,6,7,8,9,10];
  list2=[1,2,3,4,5,6,7,8,9,10,1,2,3,4,5,6,7,8,9,10,1,2,3,4,5,6,7,8,9,10,1,2,1,2,3,4,5,6,7,8,9,10,1,2,3,4,5,6,7,8,9,10,1,2];
  headername;
  listData:Array<Tradingproductandcompany>=[];
  productList:Array<CommonData>=[];
  filteredProductList:Array<CommonData>=[];
  selectedPro:Array<CommonData>=[];
  index;
  constructor(private modalCtrl:ModalController) { }

  ngOnInit() {
    if(this.listData && this.listData.length > 0){
      this.listData.forEach(element => {
        element.isChecked=false
      });
      this.listData[0].isChecked=true;
    }
    this.getFilteredProductList(0);
    // if(this.productList && this.productList.length > 0){
    //   if(this.selectedPro && this.selectedPro.length > 0){
    //     this.productList.forEach(element => {
    //       const find_obj=this.selectedPro.find((element_inner)=>element_inner.value === element.value);
    //       if(find_obj){
    //         element.isChecked=true; 
    //       }
    //     });
    //   }
    // }
  }

  getSelectedBusinessTypeItem(ev) {
    LogData("getSelectedBusinessTypeItem", ev);
    if (ev && ev.data) {
      this.getCheckedValue(ev.data, 1);
    }
  }

  getCheckedValue(data: Array<CommonData>, index) {
    const ckArray = data.filter((element) => element.isChecked);
    LogData("ckArray", ckArray);
    // switch (index) {
    //   case 1:
    //     this.commonform.patchValue({ businesslist_id: ckArray });
    //     break;
    //   case 2:
    //     this.commonform.patchValue({ tradingtype_id_sales: ckArray });
    //     break;
    //   case 3:
    //     this.commonform.patchValue({ tradingtype_id_service: ckArray });
    //     break;
    //   case 4:
    //     this.commonform.patchValue({ productcompany_id: ckArray });
    //     break;
    // }
  }

  closeModalCross() {
    this.modalCtrl.dismiss();
  }

  onChangeCheckboxValue(ev){

  }

  getFilteredProductList(index){
    if(this.index === SALSE_PRD_IDX || this.index === SERVICES_PRD_IDX || this.index === MANUF_PRD_IDX){
      this.productList=this.listData[index].Productlists;
    }else{
      this.productList=this.listData[index].Productcompanies;
    }
    
  }

  onItem(item:CommonData,index){
    item.isChecked=true;
    this.getFilteredProductList(index);
    this.listData.forEach(element => {
      if(element.value !== item.value){
        element.isChecked=false;
      }
    });
  }

  saveData(){
    let array=[];
    this.modalCtrl.dismiss(this.listData);
  }

  cancelData(){
    this.modalCtrl.dismiss();
  }

}

