import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { LoadingController, ModalController, PopoverController } from '@ionic/angular';
import { finalize } from 'rxjs/operators';
import { CommonData } from 'src/app/models/common-data';
import { UtilsService } from 'src/app/services/uitils.services';
import { UserServices } from 'src/app/services/user.services';
import { getAccountStatusArr, getPaymentMethodArr, PAGE_BANK_TRANS, PAGE_RECEIVED_PAYMENTS } from 'src/app/utils/constant';
import { LogData } from 'src/app/utils/utils';

@Component({
  selector: 'app-transaction-filter',
  templateUrl: './transaction-filter.component.html',
  styleUrls: ['./transaction-filter.component.scss'],
})
export class TransactionFilterComponent implements OnInit {
  commonForm:FormGroup;
  @Input() title;
  @Input() pageName;
  @Input() transactionData;
  @Input() btn1="apply";
  @Input() btn2="clear_all";
  paymentMethodList:Array<CommonData>=[];
  statusList:Array<CommonData>=[];
  maxDateStart: Date;
  maxDateEnd: Date;
  CONST_DATA={
    PAGE_RECEIVED_PAYMENTS
  };
  constructor(private formBuilder:FormBuilder,
    public utilsService:UtilsService,
    private popoverCtrl:PopoverController,
    private userServices:UserServices,
    private loadingCtrl:LoadingController,
    private modalCtrl:ModalController) { }

  ngOnInit() {
    this.buildForm();
    this.getMasters();
  }

  orgValueChange(data){
    LogData("orgValueChange",data.value);
    this.maxDateEnd=data.value;
    this.commonForm.patchValue({end_date:''})
  }

  apply(ev?){
    this.popoverCtrl.dismiss(this.commonForm.value);
  }

  clearAll(ev?){
    this.buildForm();
    this.utilsService.setTransactionMaster({isClear:true,page:this.pageName });
    this.closeModal();
  }

  closeModal(ev?) {
    this.popoverCtrl.dismiss();
  }

  buildForm() {
    this.commonForm = this.formBuilder.group({
      payment_mode: [(this.transactionData && this.transactionData.payment_mode) ? this.transactionData.payment_mode:""],
      status:[(this.transactionData && this.transactionData.status) ? this.transactionData.status:''],
      start_date:[(this.transactionData && this.transactionData.start_date) ? new Date(this.transactionData.start_date):""],
      end_date:[(this.transactionData && this.transactionData.end_date) ? new Date(this.transactionData.end_date):""],
    });
  }

  async getMasters() {
    const loading = await this.loadingCtrl.create(
      this.utilsService.getLoaderUI()
    );
    await loading.present();
    LogData("loader_start");
    const list = this.pageName === PAGE_RECEIVED_PAYMENTS ? getAccountStatusArr: getPaymentMethodArr;
    const formData = new FormData();
    formData.append("master", list);
    this.userServices.getMasters(formData)
      .pipe(
        finalize(() => {
          LogData("loader_dismiss");
          loading.dismiss();
        })
      )
      .subscribe((res) => {
        LogData("getMasters_res", res);
        res = this.utilsService.getDecriptReasponse(res);
          if (res && res.result && res.result.responce) {
          if (res.result.responce.getPaymentMethodArr &&
            res.result.responce.getPaymentMethodArr.length > 0) {
            this.paymentMethodList = res.result.responce.getPaymentMethodArr;
          } else {
            this.paymentMethodList = [];
          }
          if (res.result.responce.getAccountStatusArr &&
            res.result.responce.getAccountStatusArr.length > 0) {
            this.statusList = res.result.responce.getAccountStatusArr;
          } else {
            this.statusList = [];
          }
          if(this.transactionData){
            this.buildForm();
          }
         
        } else {
          this.paymentMethodList = [];
        }

      }, err => {
        LogData("loader_dismiss");
        loading.dismiss();
        LogData("getState_error", err);
        this.userServices.logoutApiCall(err);
      });
  }

}
