import { PRIVACY_POLICY, TERMS_CONDITION, VISITORS_TAB2_ROUTE, VISITORS_TAB5_ROUTE, VISITOR_PRICING } from "src/app/utils/routes";

export const WithoutLoginMenuDataForMobile = [
    {
      id: 1,
      title: "profile",
      url: VISITORS_TAB2_ROUTE,
      icon: "profile_sm",
      isChecked: true,
    },
    // {
    //   id: 2,
    //   title: "notification",
    //   // url: VISITORS_TAB4_ROUTE,
    //   url: '',
    //   icon: "notification_sm",
    //   isChecked: false,
    // },
    {
      id: 4,
      title: "mn_contactus",
      url: VISITORS_TAB5_ROUTE,
      icon: "mn_con_us",
      isChecked: false,
    },
    {
      id: 5,
      title: "privacy",
      url: PRIVACY_POLICY,
      icon: "privacy_sm",
      isChecked: false,
    },
    {
      id: 6,
      title: "terms_of_use",
      url: TERMS_CONDITION,
      icon: "term_sm",
      isChecked: false,
    },
    // {
    //   id: 7,
    //   title: "mn_pricing",
    //   url: VISITOR_PRICING,
    //   icon: "mn_pricing",
    //   isExpand: false,
    //   isChecked: false,
    // },
  ];
  