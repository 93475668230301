import { Pipe, PipeTransform } from '@angular/core';
import { AuthServices } from '../services/auth.services';
@Pipe({
    name: 'getLanguagePipe',
})
export class GetLanguagePipe implements PipeTransform {
    languageData={};
    constructor(private authServices:AuthServices) {
        this.languageData=this.authServices.getLanguageData();
     }

    transform(langKey:string): any {
        // LogData("lang_data_pipe",(this.languageData && this.languageData[langKey]) ? this.languageData[langKey] :langKey);
        // LogData("languageData",this.languageData);
        return (this.languageData && this.languageData[langKey]) ? this.languageData[langKey] :langKey;
    }
}
