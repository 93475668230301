import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonData } from 'src/app/models/common-data';
import { UserprofileData, UserProfileResponce } from 'src/app/models/user-profile-data';
import { UtilsService } from 'src/app/services/uitils.services';
import { BRANCH_DEALER_PROFILE } from 'src/app/utils/routes';
import { LogData } from 'src/app/utils/utils';

@Component({
  selector: 'app-bd',
  templateUrl: './bd.component.html',
  styleUrls: ['./bd.component.scss'],
})
export class BdComponent implements OnInit {

  @Output() getItems: EventEmitter<any> = new EventEmitter();
  @Input() bdData:UserprofileData;
  @Input() checkedCount;
  isLongPressClick:boolean=false;
  constructor(private utilServices:UtilsService) { }

  ngOnInit() {}

  getStatus(status: Array<CommonData>) {
    return status && status.length > 0 ? status[0].value : "";
  }

  getAddressStr(data): any {
    let str = '';
    if (data) {
        if (data.state_id && data.state_id.length > 0) {
            str = str + data.state_id[0].text+ ', ';
        }
        if (data.district_id && data.district_id.length > 0) {
            str = str  +data.district_id[0].text+ ', ';
        }
        if (data.taluka_id && data.taluka_id.length > 0) {
            str = str + data.taluka_id[0].text+ ', ';
        }
        if (data.zone_name && data.zone_name.length > 0) {
            str = str + data.zone_name[0].text+ ', ';
        }
        if (data.village_id && data.village_id.length > 0) {
            str = str + data.village_id[0].text;
        }
    }
    return str;
}

  setCheckedData(){
    if(this.bdData.isChecked){
      this.bdData.isChecked=false;
    }else{
      this.bdData.isChecked=true;
    }
    this.getItems.emit(true);
  }


  onClickItem(){
    // this.setCheckedData();
    if(!this.isLongPressClick){
      if(this.checkedCount === 0){
        this.utilServices.setNavigationRoute(BRANCH_DEALER_PROFILE + this.bdData.id);
      }else{
        this.setCheckedData();
      }
    }else{
      this.isLongPressClick=false;
    }
  }


  onPress($event) {
   LogData("onPress", $event);
    this.isLongPressClick=true;
    this.setCheckedData();
}

onPressUp($event) {
   LogData("onPressUp", $event);

}

}
