<div class="tab1-top-bg">
  <app-searchbar [isGeolocation]="true" [isFilter]="false" [isBigTitle]="true"
    (getSearchableText)="getSearchValue($event)" [isOnlyEmit]="true" (click)="openBusinessDirectory()"></app-searchbar>
</div>
<ion-grid>
  <ion-row class="center-row">
    <ion-col style="align-self: center" class="no-margin-padding"   [attrSizeMd]="'6'" [attrSizeLg]="'6'" [attrSizeXl]="'6'" appSizeAttribute>
      <ion-row class="pl-8 pr-8">
        <ion-col size="12">
          <ion-text class="lbl-med-14">{{'lbl_mh_brow_by_cate' | getLanguagePipe}}</ion-text>
          <div class="h-row mt-16">
            <ng-container *ngFor="let item of categoryList">
              <app-category-card [categoryData]="item" (click)="onclickBusiness(item.id,item.businesstype_id)"></app-category-card>
            </ng-container>
          </div>
        </ion-col>
      </ion-row>
      <ion-row class="mt-12" *ngIf="topAdv">
        <ion-col size="12">
          <!-- <ion-img [src]="'bslist_banner' | getImagePipe:2"></ion-img> -->
          <div class="banner-img-container">
            <div class="img-div adv-top-height">
              <img [src]="topAdv" />
            </div>
            <div class="banner-img-top-right">
              <ion-text class="ads-bg">Ad</ion-text>
            </div>
          </div>
          
        </ion-col>
      </ion-row>
      <!-- <ion-row class="mt-12 tab1-mid-bg">
        <ion-col size="4">
          <div class="flex flex-col">
            <ion-img [src]="'bs_bag' | getImagePipe"></ion-img>
            <ion-text class="lbl-bold-24 lh-28">Best
              Business
              Service</ion-text>
            <ion-text class="mt-8 lbl-0">Provide near your
              location.</ion-text>
            <ion-button class="register-btn mt-8" expand="block" mode="ios">
              Register Business
            </ion-button>
          </div>
        </ion-col>
        <ion-col size="8">
          <div class="h-row mt-16">
            <ng-container *ngFor="let item of locationBusinessList">
              <ion-col size="12">
                <app-business-services-card [businessData]="item"></app-business-services-card>
              </ion-col>
            </ng-container>
          </div>

        </ion-col>
      </ion-row> -->

      <ion-row  *ngIf="locationBusinessList && locationBusinessList.length > 0">
        <!-- <ion-col size="3" class="pr-0">
          <div class="flex flex-col">
            <ion-img [src]="'bs_bag' | getImagePipe" class="avt-36"></ion-img>
            <ion-text class="lbl-bold-12 lh-12">Best
              Business
              Service</ion-text>
            <ion-text class="mt-2 lbl-10 lh-12">Provide near your
              location.</ion-text>
            <ion-button class="register-btn mt-2" expand="block" mode="ios" (click)="openLoginPage()">
              Register Business
            </ion-button>
          </div>
        </ion-col> -->
        <ion-col size="12">
          <div class="tab1-mid-bg">
            <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="businessListSlideConfig">
              <div ngxSlickItem *ngFor="let slide of locationBusinessList;let isLast=last" class="slide mr-16 mb-16">
                <app-business-services-card [businessData]="slide"></app-business-services-card>
              </div>
            </ngx-slick-carousel>
          </div>
        </ion-col>
      </ion-row>

      <ion-row class="mt-12 pl-8 pr-8" *ngIf="salesType || servicesType">
        <ion-col size="12">
          <app-see-all [lable1]="('lbl_mh_near_ser' | getLanguagePipe)"
            [lable2]="('lbl_mh_check_most_services' | getLanguagePipe)" [isSeeAll]="false" [isArrow]="false">
          </app-see-all>
          <ion-row>
            <!-- sales -->
            <ion-col size="6" class="pl-0" *ngIf="salesType">
              <ion-item class="item-0 item-bg-sale" lines="none" (click)="onclickSalesServices()">
                <ion-thumbnail slot="start">
                  <ion-img [src]="'sales_sq' | getImagePipe"></ion-img>
                </ion-thumbnail>
                <div class="flex flex-col ion-align-self-center pb-4">
                  <ion-text class="lbl-med-10">{{salesType.text}}</ion-text>
                  <ion-text class="lbl-9  lh-normal">{{'lbl_mh_best_sale_bus' | getLanguagePipe}}</ion-text>
                </div>
                <div slot="end" class="ml-0 item-sale-end">
                  <ion-icon name="chevron-forward-outline" class="icon-wh"></ion-icon>
                </div>
              </ion-item>
            </ion-col>
            <!-- services -->
            <ion-col size="6" class="pr-0" *ngIf="servicesType">
              <ion-item class="item-0 item-bg-services" lines="none" (click)="onclickSalesServices()">
                <ion-thumbnail slot="start">
                  <ion-img [src]="'services_sq' | getImagePipe"></ion-img>
                </ion-thumbnail>
                <div class="flex flex-col ion-align-self-center pb-4">
                  <ion-text class="lbl-med-10">{{servicesType.text}}</ion-text>
                  <ion-text class="lbl-9 lh-normal">{{'lbl_mh_best_services_bus' | getLanguagePipe}}</ion-text>
                </div>
                <div slot="end" class="ml-0 item-services-end">
                  <ion-icon name="chevron-forward-outline" class="icon-wh"></ion-icon>
                </div>
              </ion-item>
            </ion-col>
          </ion-row>
        </ion-col>
      </ion-row>
      <ion-row class="mt-12" *ngIf="middileAdv">
        <ion-col size="12">
          <!-- <ion-img [src]="'bslist_banner' | getImagePipe:2"></ion-img> -->
          <!-- <ion-img [src]="middileAdv"></ion-img> -->
          <!-- <div class="img-div">
            <img [src]="middileAdv" />
          </div> -->
          <div class="banner-img-container">
            <div class="img-div adv-top-height">
              <img [src]="middileAdv" />
            </div>
            <div class="banner-img-top-right">
              <ion-text class="ads-bg">Ad</ion-text>
            </div>
          </div>
        </ion-col>
      </ion-row>
      <ng-container
        *ngIf="homeDataResponse && homeDataResponse.userprofilesArr && homeDataResponse.userprofilesArr.length > 0">
        <ng-container *ngFor="let homeData of  homeDataResponse.userprofilesArr">
          <ng-container
            *ngIf="homeData.tradingtypesData && homeData.userprofilesData && homeData.userprofilesData.length > 0">
            <div class="bus-list-bg ">
              <ion-row class="mt-20 pl-8 pr-8">
                <ion-col size="12">
                  <app-see-all [lable1]="('lbl_mh_look_for' | getLanguagePipe)+' '+homeData.tradingtypesData.text"
                    [lable2]="('txt_ch_high_ratting' | getLanguagePipe)+' '+homeData.tradingtypesData.text"
                    [isArrow]="true" (onClickSeeAllBtn)="openBusinessListPage($event)">
                  </app-see-all>
                </ion-col>
              </ion-row>

              <!-- <div class="flex flex-row w-100" style="overflow: auto">
              <ng-container *ngFor="let item of homeData.userprofilesData">
             <div class="p-8">
              <app-business-services-card [businessData]="item"></app-business-services-card>
             </div>
              </ng-container>
            </div> -->
              <ion-row class="pl-8 pr-8">
                <ion-col size="12">
                  <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="businessListSlideConfig">
                    <div ngxSlickItem *ngFor="let slide of homeData.userprofilesData;let isLast=last"
                      class="slide mr-16 mb-16">
                      <app-business-services-card [businessData]="slide"></app-business-services-card>
                    </div>
                  </ngx-slick-carousel>
                  <!-- <div class="flex flex-row w-100" style="overflow: auto">
                <ng-container *ngFor="let item of homeData.userprofilesData">
               <div class="p-8">
                <app-business-services-card [businessData]="item"></app-business-services-card>
               </div>
                </ng-container>
              </div> -->
                </ion-col>
              </ion-row>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>

      <ion-row class="mt-12" *ngIf="bottomAdv">
        <ion-col size="12">
          <!-- <ion-img [src]="'bslist_banner' | getImagePipe:2"></ion-img> -->
          <!-- <ion-img [src]="middileAdv"></ion-img> -->
          <!-- <div class="img-div">
            <img [src]="bottomAdv" />
          </div> -->
          <div class="banner-img-container">
            <div class="img-div adv-top-height">
              <img [src]="bottomAdv" />
            </div>
            <div class="banner-img-top-right">
              <ion-text class="ads-bg">Ad</ion-text>
            </div>
          </div>
        </ion-col>
      </ion-row>
      <!-- <ion-row class="mt-12">
        <ion-col [attrSizeXl]="'12'" appSizeAttribute>
          <app-see-all [lable1]="'Looking for Sweet Shop'" [lable2]="'Checkout high ratting shops.'" [isArrow]="true">
          </app-see-all>
        </ion-col>
      </ion-row>
      <ion-row class="mt-12">
        <ion-col [attrSizeXl]="'12'" appSizeAttribute>
          <app-see-all [lable1]="'Looking for Accountant'" [lable2]="'Checkout high ratting accountants.'"
            [isArrow]="true"></app-see-all>
        </ion-col>
      </ion-row>
      <ion-row class="mt-12">
        <ion-col [attrSizeXl]="'12'" appSizeAttribute>
          <app-see-all [lable1]="'Looking for Tailor'" [lable2]="'Checkout high ratting tailor.'" [isArrow]="true">
          </app-see-all>
        </ion-col>
      </ion-row> -->
      <ion-row class="visit-ftr pl-8 pr-8">
        <ion-col>
          <div>
            <img [src]="'visitor_ftr' | getImagePipe:2" />
          </div>
        </ion-col>
        <ion-col>
          <div class="flex flex-col ion-align-items-center">
            <ion-text class="lbl-bold-12">{{'lbl_mh_grow_bus' | getLanguagePipe}}</ion-text>
            <ion-text class="lbl-10">{{'lbl_mh_with' | getLanguagePipe}}</ion-text>
            <img [src]="'main_home_logo' | getImagePipe:2" class="mt-8 mb-12" />
            <ion-button class="btn-1 get-btn" expand="block" mode="ios" (click)="openLoginPage()">
              <ion-text class="lbl-bold-9">
                {{'get_started' | getLanguagePipe}}
              </ion-text>
              <ion-icon slot="end" class="ml-12 avt-18" [src]="'ic_login' | getImagePipe"></ion-icon>
            </ion-button>
          </div>
        </ion-col>
      </ion-row>
    </ion-col>
  </ion-row>
</ion-grid>