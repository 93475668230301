<div class="div-dash">
  <ion-item class="item-0" lines="none">
    <ion-avatar>
      <img [src]="'promo_ro' | getImagePipe" />
    </ion-avatar>
    <ion-text class="lbl-bold-18 ml-8">ACITY1</ion-text>
  </ion-item>
  <ion-row>
    <ion-col>
      <div class="flex flex-col">
        <ion-text class="lbl-op05-11">Start Date</ion-text>
        <ion-text class="lbl-12">25-08-2022</ion-text>
      </div>
    </ion-col>
    <ion-col>
      <div class="flex flex-col">
        <ion-text class="lbl-op05-11">End Date</ion-text>
        <ion-text class="lbl-12">27-08-2022</ion-text>
      </div>
    </ion-col>
    <ion-col>
      <div class="flex flex-col">
        <ion-text class="lbl-op05-11">Status</ion-text>
        <ion-text class="status-act">Active</ion-text>
      </div>
    </ion-col>
  </ion-row>
</div>