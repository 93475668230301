import { Component, Input, OnInit } from "@angular/core";
import { SafariViewController } from "@awesome-cordova-plugins/safari-view-controller/ngx";
import { LoadingController } from "@ionic/angular";
import { Observable, Observer } from "rxjs";
import { finalize } from "rxjs/operators";
import { ChatSettings } from "src/app/models/chat-setting";
import { ImageExtension } from "src/app/models/doc-ext";
import { MessageTableData } from "src/app/models/message-data";
import { AuthServices } from "src/app/services/auth.services";
import { UtilsService } from "src/app/services/uitils.services";
import { UserServices } from "src/app/services/user.services";
import { DOWNLOADFILE_API } from "src/app/utils/api-end-points";
import { IMAGE_DOC } from "src/app/utils/constant";
import { VIEWER_RT } from "src/app/utils/routes";
import { LogData } from "src/app/utils/utils";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-chat-msg",
  templateUrl: "./chat-msg.component.html",
  styleUrls: ["./chat-msg.component.scss"],
})
export class ChatMsgComponent implements OnInit {
  @Input() messageData: MessageTableData;
  @Input() userMsg: string;
  @Input() dateCss;
  @Input() chatSetting: ChatSettings;
  @Input() checkedCount: number = 0;
  selectedId;
  base64Image;
  fileName;
  constructor(
    public utilsService: UtilsService,
    private authServices: AuthServices,
    private userServices: UserServices,
    private loadingCtrl: LoadingController,
    private safariViewController:SafariViewController
  ) {}

  ngOnInit() {}

  openPage() {
    LogData("checkedCount_page", this.checkedCount);
    if (this.checkedCount === 0) {
      // this.utilsService.openBrowserCap(this.getImage());
      this.utilsService.setNavigationRouteQuery(VIEWER_RT, {
        url: this.getImage(),
        type:IMAGE_DOC
      });
    }
  }

 async  downloadDoc() {
    if (this.checkedCount === 0) {
      // window.open(
      //   environment.apiUrl +
      //     DOWNLOADFILE_API+"/" +
      //     this.selectedId +
      //     "?token=" +
      //     this.authServices.getUserToken(),
      //   "_self"
      // );
      // this.openSfariView(environment.apiUrl +
      //   DOWNLOADFILE_API+"/" +
      //   this.selectedId +
      //   "?token=" +
      //   this.authServices.getUserToken());
      // this.downloadFile();
     this.utilsService.downloadImageFromServer(this.getImage(),this.fileName);
    }
  }

  openSfariView(url) {
    LogData("openSfariView",url);
    this.safariViewController.isAvailable().then((available: boolean) => {
      if (available) {
        this.safariViewController
          .show({
            url: url,
            hidden: true,
            animated: false,
            transition: "curl",
            enterReaderModeIfAvailable: true,
            tintColor: "#ff0000",
          })
          .subscribe(
            (result: any) => {
              LogData("safari_res", result);
              if (result.event) {
                switch (result.event) {
                  case "opened":
                    LogData("opened");
                    break;
                  case "loaded":
                    LogData("loaded");
                    break;
                  case "closed":
                    LogData("closed");
                    break;
                  default:
                    LogData("default");
                    break;
                }
              }
            },
            (error: any) => {
              LogData("safariview_error", error);
            }
          );
      } else {
        // use fallback browser, example InAppBrowser
      }
    });
  }



  async downloadFile() {
    const loading = await this.loadingCtrl.create(
      this.utilsService.getLoaderUI()
    );
    await loading.present();
    LogData("loader_start");
    this.userServices
      .downLoadFile(this.selectedId, null, this.authServices.getUserToken())
      .pipe(
        finalize(() => {
          LogData("loader_dismiss");
          loading.dismiss();
        })
      )
      .subscribe(
        (res) => {
          LogData("getPlanDetails_res", res);
          res = this.utilsService.getDecriptReasponse(res);
        },
        (err) => {
          LogData("loader_dismiss");
          loading.dismiss();
          // LogData("getPlanDetails_error", err);
          // this.userServices.logoutApiCall(err);
        }
      );
  }

  isDocument() {
    let isDoc = false;
    if (this.messageData.documents && this.messageData.documents.length > 0) {
      isDoc = true;
    }
    return isDoc;
  }
  getImage() {
    let image = "";
    if (
      this.messageData.documents[0].display_files &&
      this.messageData.documents[0].display_files.messagedetail_doc
    ) {
      this.selectedId = this.messageData.documents[0].id;
      this.fileName= this.messageData.documents[0].messagedetail_doc;
      if (
        this.messageData.documents[0].display_files.messagedetail_doc.main_url
      ) {
        image =
          this.messageData.documents[0].display_files.messagedetail_doc
            .main_url;
      }
      if (
        this.messageData.documents[0].display_files.messagedetail_doc.square_url
      ) {
        image =
          this.messageData.documents[0].display_files.messagedetail_doc
            .square_url;
      }
    }
    return image;
  }

  isImage() {
    let isImage = false;
    const file_ext = this.messageData.documents[0].messagedetail_doc
      .split(".")
      .pop();
    if (ImageExtension.indexOf(file_ext) !== -1) {
      isImage = true;
    }
    return isImage;
  }
}
