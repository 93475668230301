import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-payment-option',
  templateUrl: './payment-option.component.html',
  styleUrls: ['./payment-option.component.scss'],
})
export class PaymentOptionComponent implements OnInit {
  isQrcode: boolean = false;
  isPayment: boolean = false;
  isWallet: boolean = false;
  @Output() onClickPaymentMethod: EventEmitter<any> = new EventEmitter();
  constructor() { }

  ngOnInit() {}

  selectPayment(index) {
    switch (index) {
      case 1:
        this.isQrcode = true;
        this.isPayment = false;
        this.isWallet = false;
        break;
      case 2:
        this.isQrcode = false;
        this.isPayment = true;
        this.isWallet = false;
        break;
      case 3:
        this.isQrcode = false;
        this.isPayment = false;
        this.isWallet = true;
        break;

    }
    this.onClickPaymentMethod.emit(index);
  }

  isValid(){
    return (this.isQrcode || this.isPayment || this.isWallet) ? true:false;
  }

}
