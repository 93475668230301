import { Component, Input, OnInit } from '@angular/core';
import { CommonData } from 'src/app/models/common-data';
import { HomeDataResponce } from 'src/app/models/home-data';
import { UserprofileData } from 'src/app/models/user-profile-data';
import { AuthServices } from 'src/app/services/auth.services';
import { UtilsService } from 'src/app/services/uitils.services';
import { BUSINESS_DIRECTORY_LIST, LOGIN_ROUTE, SEARCH_BUSINESS_LIST, TAB2_ROUTE, TAB3_ROUTE, VISITORS_TAB2_ROUTE, VISITORS_TAB3_ROUTE } from 'src/app/utils/routes';
import { BusinessListSlideConfig } from 'src/app/utils/slider.config';

@Component({
  selector: 'app-mobile-home',
  templateUrl: './mobile-home.component.html',
  styleUrls: ['./mobile-home.component.scss'],
})
export class MobileHomeComponent implements OnInit {

  categoryList:Array<CommonData> = [];
  businessServicesList = [1, 2,3,4];
  locationBusinessList:Array<UserprofileData>=[];
  salesType:CommonData
  servicesType:CommonData;
  businessListSlideConfig=BusinessListSlideConfig;
  topAdv;
  middileAdv;
  bottomAdv;
  @Input() homeDataResponse:HomeDataResponce;
  constructor(private utilservices:UtilsService,
    private authServices:AuthServices) { }

  ngOnInit() {
    this.setInitData();
  }

  openBusinessDirectory(){
    this.utilservices.setNavigationRouteQuery(SEARCH_BUSINESS_LIST,null);
  }


  onclickBusiness(id,businesstypeId) {
    this.utilservices.openBusinesListPage({tradingtype_id:id,businesstype_id:businesstypeId});
    // 
  }


  setInitData() {
    if (this.homeDataResponse) {
      if (this.homeDataResponse.tradingtypesArr && this.homeDataResponse.tradingtypesArr.length > 0) {
        this.categoryList = this.homeDataResponse.tradingtypesArr;
      }
      if(this.homeDataResponse.businesstypesArr && this.homeDataResponse.businesstypesArr.length>0){
        this.salesType=this.homeDataResponse.businesstypesArr[0];
        if( this.homeDataResponse.businesstypesArr.length>1){
          this.servicesType=this.homeDataResponse.businesstypesArr[1];
        }
      }
      if(this.homeDataResponse.locationUserprofilesArr && this.homeDataResponse.locationUserprofilesArr.length > 0){
        this.locationBusinessList=this.homeDataResponse.locationUserprofilesArr;
      }
      if(this.homeDataResponse.advertisementsArr){
        if(this.homeDataResponse.advertisementsArr.h_top && 
          this.homeDataResponse.advertisementsArr.h_top.length > 0){
            if(this.homeDataResponse.advertisementsArr.h_top[0].documents && 
              this.homeDataResponse.advertisementsArr.h_top[0].documents.length > 0 && 
              this.homeDataResponse.advertisementsArr.h_top[0].documents[0].display_files &&
              this.homeDataResponse.advertisementsArr.h_top[0].documents[0].display_files.advertisement_pic && 
              this.homeDataResponse.advertisementsArr.h_top[0].documents[0].display_files.advertisement_pic.main_url){
                this.topAdv=this.homeDataResponse.advertisementsArr.h_top[0].documents[0].display_files.advertisement_pic.main_url;
            }else{
              this.topAdv="assets/img/adv_d_1.png";
            }
        }else{
          this.topAdv="assets/img/adv_d_1.png";
        }
        if(this.homeDataResponse.advertisementsArr.h_middle && 
          this.homeDataResponse.advertisementsArr.h_middle.length > 0){
            if(this.homeDataResponse.advertisementsArr.h_middle[0].documents && 
              this.homeDataResponse.advertisementsArr.h_middle[0].documents.length > 0 && 
              this.homeDataResponse.advertisementsArr.h_middle[0].documents[0].display_files &&
              this.homeDataResponse.advertisementsArr.h_middle[0].documents[0].display_files.advertisement_pic && 
              this.homeDataResponse.advertisementsArr.h_middle[0].documents[0].display_files.advertisement_pic.main_url){
                this.middileAdv=this.homeDataResponse.advertisementsArr.h_middle[0].documents[0].display_files.advertisement_pic.main_url;
            }else{
              this.middileAdv="assets/img/adv_d_2.png";
            }
        }else{
          this.middileAdv="assets/img/adv_d_2.png";
        }
        if(this.homeDataResponse.advertisementsArr.h_bottom && 
          this.homeDataResponse.advertisementsArr.h_bottom.length > 0){
            if(this.homeDataResponse.advertisementsArr.h_bottom[0].documents && 
              this.homeDataResponse.advertisementsArr.h_bottom[0].documents.length > 0 && 
              this.homeDataResponse.advertisementsArr.h_bottom[0].documents[0].display_files &&
              this.homeDataResponse.advertisementsArr.h_bottom[0].documents[0].display_files.advertisement_pic && 
              this.homeDataResponse.advertisementsArr.h_bottom[0].documents[0].display_files.advertisement_pic.main_url){
                this.bottomAdv=this.homeDataResponse.advertisementsArr.h_bottom[0].documents[0].display_files.advertisement_pic.main_url;
            }else{
              this.bottomAdv="assets/img/adv_d_1.png"; 
            }
        }else{
          this.bottomAdv="assets/img/adv_d_1.png";
        }
      }
      // if (this.homeDataResponse.userprofilesArr && this.homeDataResponse.userprofilesArr.length > 0) {
      //   this.homeDataResponse.userprofilesArr.forEach(element => {
      //     if (element.userprofilesData && element.userprofilesData.length > 0) {
      //       this.totalBusinessDirectoryList.push(...element.userprofilesData);
      //     }
      //   });
      // }
      // this.getBusinessDirectoryData(this.selectedTab);
    }
  }

  openLoginPage(){
    this.utilservices.setNavigationRoute(LOGIN_ROUTE);
  }

  openBusinessListPage(ev){
    if(ev){
      this.utilservices.openBusinesListPage();
    } 
  }

  getSearchValue(event){
  }

  onclickSalesServices(){
    this.utilservices.setNavigationRoute((this.authServices.getUserLoginData() ? TAB3_ROUTE:VISITORS_TAB3_ROUTE));
  }

  onButtonClick(){

  }

}
