import { Component, Input, OnInit } from '@angular/core';
import { RegistrationplanData } from 'src/app/models/registerplans';
import { AuthServices } from 'src/app/services/auth.services';
import { UtilsService } from 'src/app/services/uitils.services';
import { BUSINESS_TYPE } from 'src/app/utils/routes';

@Component({
  selector: 'app-pricing-card',
  templateUrl: './pricing-card.component.html',
  styleUrls: ['./pricing-card.component.scss'],
})
export class PricingCardComponent implements OnInit {

  @Input() pricingCard:RegistrationplanData;
  constructor(public authservices:AuthServices,
    private utilServices:UtilsService) { }

  ngOnInit() {}

  registerBusiness(){
    this.utilServices.setNavigationRoute(BUSINESS_TYPE);
  }

}
