import { Component, Input, OnInit, Output, ViewChild,EventEmitter } from '@angular/core';
import { IonInfiniteScroll } from '@ionic/angular';
import { LogData } from 'src/app/utils/utils';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent implements OnInit {
  @Output() onSelectedItem: EventEmitter<any>;
  @Input() dataList=[];
  @Input() totalRecordPages;
  @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;
  constructor() {
    this.onSelectedItem = new EventEmitter<any>();
   }

  ngOnInit() {
   
  }
 
  loadData(event) {
    setTimeout(() => {
      LogData('Done = PAGI',this.dataList.length);
      this.totalRecordPages -= 1;  
      event.target.complete();
      this.onSelectedItem.emit(true);
      if(this.totalRecordPages <= 0){
        // this.infiniteScroll.disabled=true;
      }
      // if (this.dataList.length === 0) {
      //   // this.onSelectedItem.emit(true);
      // }else{
      //   this.onSelectedItem.emit(true);
      // }
    }, 500);
  }

  toggleInfiniteScroll() {
    this.infiniteScroll.disabled = !this.infiniteScroll.disabled;
  }

  refresPagination(){
    // this.infiniteScroll.disabled=false;
  }

}
