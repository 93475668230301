//Routes name
//Common Name
export const HOME_ROUTE = "home";
export const PROFILE_ROUTE = "profile";
export const SEARCH_ROUTE = "search";
export const NOTIFICATIONS_ROUTE = "notifications";
export const INQUIRY_ROUTE = "inquiry";
export const CHAT_ROUTE = "chat";
export const DASHBOARD_ROUTE = "dashboard";
export const LEDGER_ROUTE = "ledger";
export const BUSINESS_ROUTE = "business";
export const CUSTOMER_ROUTE = "customer";
export const TICKETS_ROUTE = "tickets";
export const ADMIN_PROFILE_ROUTE = "admin/profile";
export const BUSINESS_DIRECTORY_LIST = "business-directory";
export const SEARCH_BUSINESS_LIST = "search-business";
export const CONTACTUS_ROUTE = "contact-us";
export const PRICING_RT = "pricing";

// Visitors Tab
export const VISITORS_TAB = "visitor";
export const VISITORS_TAB1_ROUTE = "/" + VISITORS_TAB + "/" + HOME_ROUTE;
export const VISITORS_TAB2_ROUTE = "/" + VISITORS_TAB + "/" + PROFILE_ROUTE;
export const VISITORS_TAB3_ROUTE = "/" + VISITORS_TAB + "/" + SEARCH_ROUTE;
export const VISITORS_TAB4_ROUTE =
  "/" + VISITORS_TAB + "/" + NOTIFICATIONS_ROUTE;
export const VISITORS_TAB5_ROUTE = "/" + VISITORS_TAB + "/" + CONTACTUS_ROUTE;
export const VISITOR_BUSINESS_DIRECTORY_LIST =
  "/" + VISITORS_TAB + "/" + BUSINESS_DIRECTORY_LIST;
export const VISITOR_PRICING = "/" + VISITORS_TAB + "/" + PRICING_RT;
// Customer or business Login
export const TABS = "pages";
export const TAB1_ROUTE = "/" + TABS + "/" + HOME_ROUTE;
export const TAB2_ROUTE = "/" + TABS + "/" + PROFILE_ROUTE;
export const TAB3_ROUTE = "/" + TABS + "/" + SEARCH_ROUTE;
export const TAB4_ROUTE = "/" + TABS + "/" + NOTIFICATIONS_ROUTE;
export const TAB5_ROUTE = "/" + TABS + "/" + CHAT_ROUTE;
export const TAB6_ROUTE = "/" + TABS + "/" + CONTACTUS_ROUTE;
export const TABS_BUSINESS_DIRECTORY_LIST =
  "/" + TABS + "/" + BUSINESS_DIRECTORY_LIST;
export const TABS_PRICING = "/" + TABS + "/" + PRICING_RT;

//Role based tabs (Delaers,staffs,employee)
//staff
export const STAFF_TABS = "staff";
export const STAFF_TAB1_ROUTE = "/" + STAFF_TABS + "/" + HOME_ROUTE;
export const STAFF_TAB2_ROUTE = "/" + STAFF_TABS + "/" + PROFILE_ROUTE;
export const STAFF_TAB3_ROUTE = "/" + STAFF_TABS + "/" + SEARCH_ROUTE;
export const STAFF_TAB4_ROUTE = "/" + STAFF_TABS + "/" + NOTIFICATIONS_ROUTE;
export const STAFF_TAB5_ROUTE = "/" + STAFF_TABS + "/" + CHAT_ROUTE;
export const STAFF_TAB6_ROUTE = "/" + STAFF_TABS + "/" + CONTACTUS_ROUTE;
export const STAFF_BUSINESS_ROUTE = "/" + STAFF_TABS + "/" + BUSINESS_ROUTE;
export const STAFF_CUSTOMER_ROUTE = "/" + STAFF_TABS + "/" + CUSTOMER_ROUTE;
export const STAFF_TABS_BUSINESS_DIRECTORY_LIST =
  "/" + STAFF_TABS + "/" + BUSINESS_DIRECTORY_LIST;
export const STAFF_TABS_PRICING = "/" + STAFF_TABS + "/" + PRICING_RT;

//dealer
export const DEALER_TABS = "dealer";
export const DEALER_TAB1_ROUTE = "/" + DEALER_TABS + "/" + HOME_ROUTE;
export const DEALER_TAB2_ROUTE = "/" + DEALER_TABS + "/" + PROFILE_ROUTE;
export const DEALER_TAB3_ROUTE = "/" + DEALER_TABS + "/" + SEARCH_ROUTE;
export const DEALER_TAB4_ROUTE = "/" + DEALER_TABS + "/" + NOTIFICATIONS_ROUTE;
export const DEALER_TAB5_ROUTE = "/" + DEALER_TABS + "/" + CHAT_ROUTE;
export const DEALER_TAB6_ROUTE = "/" + DEALER_TABS + "/" + CONTACTUS_ROUTE;
export const DEALER_BUSINESS_ROUTE = "/" + DEALER_TABS + "/" + BUSINESS_ROUTE;
export const DEALER_CUSTOMER_ROUTE = "/" + DEALER_TABS + "/" + CUSTOMER_ROUTE;
export const DEALER_TABS_BUSINESS_DIRECTORY_LIST =
  "/" + DEALER_TABS + "/" + BUSINESS_DIRECTORY_LIST;
export const DEALER_TABS_PRICING = "/" + DEALER_TABS + "/" + PRICING_RT;

//employee
export const EMPLOYEE_TABS = "employee";
export const EMPLOYEE_TAB1_ROUTE = "/" + EMPLOYEE_TABS + "/" + HOME_ROUTE;
export const EMPLOYEE_TAB2_ROUTE = "/" + EMPLOYEE_TABS + "/" + PROFILE_ROUTE;
export const EMPLOYEE_TAB3_ROUTE = "/" + EMPLOYEE_TABS + "/" + SEARCH_ROUTE;
export const EMPLOYEE_TAB4_ROUTE =
  "/" + EMPLOYEE_TABS + "/" + NOTIFICATIONS_ROUTE;
export const EMPLOYEE_TAB5_ROUTE = "/" + EMPLOYEE_TABS + "/" + CHAT_ROUTE;
export const EMPLOYEE_TAB6_ROUTE = "/" + EMPLOYEE_TABS + "/" + CONTACTUS_ROUTE;
export const EMPLOYEE_BUSINESS_ROUTE =
  "/" + EMPLOYEE_TABS + "/" + BUSINESS_ROUTE;
export const EMPLOYEE_CUSTOMER_ROUTE =
  "/" + EMPLOYEE_TABS + "/" + CUSTOMER_ROUTE;
export const EMPLOYEE_TABS_BUSINESS_DIRECTORY_LIST =
  "/" + EMPLOYEE_TABS + "/" + BUSINESS_DIRECTORY_LIST;
export const EMPLOYEE_TABS_PRICING = "/" + EMPLOYEE_TABS + "/" + PRICING_RT;

// Admin Tab
export const ADMIN_TABS = "admin";
export const ADMIN_HOME_ROUTE = "/" + ADMIN_TABS + "/" + HOME_ROUTE;
export const ADMIN_TAB1_ROUTE = "/" + ADMIN_TABS + "/" + DASHBOARD_ROUTE;
export const ADMIN_TAB2_ROUTE = "/" + ADMIN_TABS + "/" + LEDGER_ROUTE;
export const ADMIN_TAB3_ROUTE = "/" + ADMIN_TABS + "/" + BUSINESS_ROUTE;
export const ADMIN_TAB4_ROUTE = "/" + ADMIN_TABS + "/" + CUSTOMER_ROUTE;
export const ADMIN_TAB5_ROUTE = "/" + ADMIN_TABS + "/" + TICKETS_ROUTE;
export const ADMIN_TABS_BUSINESS_DIRECTORY_LIST =
  "/" + ADMIN_TABS + "/" + BUSINESS_DIRECTORY_LIST;
export const ADMIN_TABS_PRICING = "/" + ADMIN_TABS + "/" + PRICING_RT;
export const ADMIN_CHAT_ROUTE = "/" + ADMIN_TABS + "/" + CHAT_ROUTE;
export const ADMIN_NOTIFICATION_ROUTE =
  "/" + ADMIN_TABS + "/" + NOTIFICATIONS_ROUTE;

// Login Module
export const LOGIN_ROUTE = "login";
export const FORGOT_PASSWORD = "forgot-password";
export const RESET_PASSWORD = "reset-password";
export const SUCCESS_ERROR_MSG = "success-error-msg";
export const PAYMENT_PROOF = "payment-proof";
export const FORGOT_MEMBER_ID = "forgot-memberid";
export const CHANGE_PASSWORD = "change-password";

export const MAIN_HOME = "home";

export const SELECT_TYPE = "profile-type";
export const REG_STEP1 = "personal-details";
export const REG_STEP1_ID = "personal-details/:id";
export const REG_STEP2 = "address-details";
export const ADDRESS_DETAILS_ROUTE = "address-details/:id";
export const PERSONAL_INFO = "personal-information";
export const ADDRESS_MASTER = "address-master";
export const ADDRESS_MASTER_ID = "address-master/:id";
export const BUSINESS_TYPE = "business-type";
export const BUSINESS_TYPE_PROFILE = "business-type-profile";
export const CONGRATULATION = "congratulation";
export const UPGRADE_BUSINESS = "upgrade-business";
export const UPGRADE_BUSINESS_BUS = "upgrade-business-bus";

export const BUSINESS_SUBSCRIPTION = "business-subscription";
export const BUSINESS_SUBSCRIPTION_ID = "business-subscription/:id";
export const PAYMENT_SUMMARY = "payment-summary";
export const PAYMENT_SUMMARY_ID = "payment-summary/:id";
export const ADMIN_BUSINESS_LIST = "admin-business-list";
export const ADMIN_BUSINESS_DETAIL = "admin-business-detail";
export const ADMIN_BUSINESS_DETAIL_ID = "admin-business-detail/:id";
export const ADMIN_CUSTOMER_DETAIL_ROUTE = "admin-customer-detail";
export const ADMIN_CUSTOMER_DETAIL_ROUTE_ID = "admin-customer-detail/:id";
export const ADMIN_CUSTOMER_LIST = "customer-list";
export const ADMIN_CUSTOMER_DETAIL = "customer-detail";

export const BUSINESS_LIST = "business-list";
export const CREATE_BUSINESS = "create-business";
export const EDIT_BUSINESS_ID = "edit-business/:id";
export const EDIT_BUSINESS = "edit-business/";
export const EDIT_BUSINESS_TYPE_ID = "edit-business-type/:id";
export const EDIT_BUSINESS_TYPE = "edit-business-type/";

export const PRODUCT_LIST = "prosuct-list";
export const CREATE_PRODUCT = "create-product";
export const EDIT_PRODUCT_ID = "edit-product/:id";
export const EDIT_PRODUCT = "edit-product/";

export const PRODUCT_COMPNEY_LIST = "product-compney-list";
export const CREATE_PRODUCT_COMPNEY = "create-product-compney";
export const EDIT_PRODUCT_COMPNEY_ID = "edit-product-compney/:id";
export const EDIT_PRODUCT_COMPNEY = "edit-product-compney/";

export const TRADING_LIST = "trading-list";
export const CREATE_TRADING = "create-trading";
export const EDIT_TRADING_ID = "edit-trading/:id";
export const EDIT_TRADING = "edit-trading/";
export const BUSINESS_INFORMATION = "business-information";
export const BUSINESS_INFORMATION_ID = "business-information/:id";
export const UPLOAD_PICTURES = "upload-pictures";
export const UPLOAD_PICTURES_ID = "upload-pictures/:id";
export const TRADING_TYPE = "trading-type";
export const TRADING_TYPE_ID = "trading-type/:id";
export const SOCIAL_MEDIA_LINKS = "social-media-links";
export const SOCIAL_MEDIA_LINKS_ID = "social-media-links/:id";
export const CONTACT_INFORMATION = "contact-information";
export const CONTACT_INFORMATION_ID = "contact-information/:id";
export const INQUIRE_LIST = "inquirie-list";
export const LANGUAGE = "languge";
export const BUSINESS_DETAIL = "business-detail";
export const BUSINESS_DETAIL_ID = "business-detail/:id";
export const CONTACTUS_HOME_ROUTE = "contact-us-home";
export const NO_PAGE_FOUND = "no-page-found";
export const PRIVACY_POLICY = "privacy-policy";
export const TERMS_CONDITION = "terms-condition";
export const ABOUT_US = "about-us";
export const GROUP_LIST = "group";
export const ITEM_GROUP = "item-group";
export const BRANCH_DEALER = "branch-dealer";
export const STAFF_ROUTE = "staffs";
export const EMPLOYEES_ROUTE = "employess";
export const ADVERTISE_BANNER = "advertise-banner";
export const SMS_ROUTE = "sms";
export const EMAIL_ROUTE = "email";
export const GALARRY_SETTINGS_ROUTE = "gallary-settings";

export const STATES_ROUTE = "states";
export const DISTRCT_ROUTE = "distrct";
export const TALUKAS_ROUTE = "talukas";
export const VILLAGES_ROUTE = "villages";
export const VILLAGES_AREA_ROUTE = "villages-area";

export const CREATE_STATE = "create-state";
export const EDIT_STATE_ID = "edit-state/:id";
export const EDIT_STATE = "edit-state/";

export const CREATE_DISTRICT = "create-district";
export const EDIT_DISTRICT_ID = "edit-district/:id";
export const EDIT_DISTRICT = "edit-district/";

export const CREATE_TALUKA = "create-taluka";
export const EDIT_TALUKA_ID = "edit-taluka/:id";
export const EDIT_TALUKA = "edit-taluka/";

export const CREATE_VILLAGE = "create-village";
export const EDIT_VILLAGE_ID = "edit-village/:id";
export const EDIT_VILLAGE = "edit-village/";

export const CREATE_VILLAGESAREA = "create-villagesarea";
export const EDIT_VILLAGESAREA_ID = "edit-villagesarea/:id";
export const EDIT_VILLAGESAREA = "edit-villagesarea/";

export const CREATE_BD_STEP1 = "registration";
export const EDIT_BD_STEP1_ID = "edit-details/:id";
export const EDIT_BD_STEP1 = "edit-details/";
export const EDIT_DEALER_DETAILS_BD = "edit-dealer-details";

export const CREATE_BD_STEP2 = "register-area";
export const EDIT_BD_STEP2_ID = "edit-register-area/:id";
export const EDIT_BD_STEP2 = "edit-register-area/";

export const CREATE_BD_STEP3 = "contact-person";
export const EDIT_BD_STEP3_ID = "edit-contact-person/:id";
export const EDIT_BD_STEP3 = "edit-contact-person/";
export const EDIT_DEALER_CONTACT_BD = "edit-dealer-contact";

export const BRANCH_DEALER_PROFILE = "dealer-profile/";
export const BRANCH_DEALER_PROFILE_ID = "dealer-profile/:id";

export const CREATE_GROUP = "create-group";
export const EDIT_GROUP_ID = "edit-group/:id";
export const EDIT_GROUP = "edit-group/";

export const CREATE_ITEM_GROUP = "create-item-group";
export const EDIT_ITEM_GROUP_ID = "edit-item-group/:id";
export const EDIT_ITEM_GROUP = "edit-item-group/";

export const COMPLAIN_SUBJECTS = "complain-subjects";

export const CREATE_COMPLAIN_SUBJECTS = "create-complain-subjects";
export const EDIT_COMPLAIN_SUBJECTS_ID = "edit-complain-subjects/:id";
export const EDIT_COMPLAIN_SUBJECTS = "edit-complain-subjects/";

export const CREATE_STAFF = "create-staff";
export const EDIT_STAFF_ID = "edit-staff/:id";
export const EDIT_STAFF = "edit-staff/";
export const EDIT_STAFF_DETAILS = "edit-staff-details";

export const ADD_EDIT_EMPLOYEEE = "employee";
export const EDIT_EMPLOYEE_DETAILS = "edit-employee-details";

export const BANK_ENTRY = "bank-entry";
export const EDIT_BANK_ENTRY_ID = "edit-bank-entry/:id";

export const PAYMENT_ENTRY = "payment-entry";
export const EDIT_PAYMENT_ENTRY_ID = "edit-payment-entry/:id";

export const RECEIPT_ENTRY = "receipt-entry";
export const EDIT_RECEIPT_ENTRY_ID = "edit-receipt-entry/:id";

export const JOURNAL_ENTRY = "journal-entry";
export const EDIT_JOURNAL_ENTRY_ID = "edit-journal-entry/:id";

export const SALES_ENTRY = "sales-entry";
export const PURCHASE_ENTRY = "purchase-entry";
export const SALES_RETURN = "sales-return";
export const PURCHASE_RETURN = "purchase-return";
export const ACCOUNT_LADGER_REPORT = "account-ladger-report";
export const ITEM_LADGER_REPORT = "item-ladger-report";

export const REGISTRATION_REPORT = "registration-report";
export const ADVERTISE_PUSH_BANNER = "advertise-push-banner";
export const ACCOUNT_LADGER = "account-ladger";
export const ITEM_LADGER = "item-ladger";
export const CREATE_ITEM_LADGER = "create-item-ladger";
export const EDIT_ITEM_LADGER_ID = "edit-item-ladger/:id";

export const ACCOUNT_SETTING = "account-setting";
export const CREATE_ACCOUNT_SETTING = "create-account-setting";
export const EDIT_ACCOUNT_SETTING_ID = "edit-account-setting/:id";
export const EDIT_ACCOUNT_SETTING = "edit-account-setting/";

export const CREATE_GALLRY_SETTING = "create-gallery-setting";
export const APPLY_GALLRY_SETTING = "apply-gallery-setting";

export const CREATE_ADV_BANNER = "create-adv-banner";
export const EDIT_ADV_BANNER_ID = "edit-adv-banner/:id";
export const EDIT_ADV_BANNER = "edit-adv-banner/";

export const ADV_UPLOAD_PIC = "adv-upload-pic";
export const CREATE_ADVERTISE_ID = "create-advertise/:id";
export const CREATE_ADVERTISE = "create-advertise";

export const CHAT_CONVERSATION = "chat";

export const BANK_TRANSACTIONS = "bank-transactions";
export const PAYMENT_TRANSACTIONS = "payment-transactions";
export const RECEIPT_TRANSACTIONS = "receipt-transactions";
export const JOURNAL_TRANSACTIONS = "journal-transactions";

// export const BANK_PAYMENT_METHOD = "bank-payment-method";
// export const EDIT_BANK_PAYMENT_METHOD_ID = "edit-bank-payment-method/:id";
// export const EDIT_BANK_PAYMENT_METHOD = "edit-bank-payment-method";
// export const PAY_PAYMENT_METHOD = "pay-payment-method";
// export const RECEIPT_PAYMENT_METHOD = "receipt-payment-method";
// export const JOURNAL_PAYMENT_METHOD = "journal-payment-method";

export const PAYMENT_METHOD = "payment-method";
export const EDIT_PAYMENT_METHOD = "edit-payment-method";

export const LADGER_STEP1 = "create-ladger";
export const EDIT_LADGER_STEP1 = "edit-ladger/";
export const EDIT_LADGER_STEP1_ID = "edit-ladger/:id";

export const LADGER_STEP2 = "ladger-address";
export const EDIT_LADGER_STEP2 = "edit-ladger-address/";
export const EDIT_LADGER_STEP2_ID = "edit-ladger-address/:id";

export const LADGER_STEP3 = "opeaning-balances";
export const EDIT_LADGER_STEP3 = "edit-opeaning-balances/";
export const EDIT_LADGER_STEP3_ID = "edit-opeaning-balances/:id";

export const TRANSACTION_ROUTE = "transaction";
export const EDIT_TRANSACTION_ROUTE_ID = "transaction/:id";

export const LANGUAGE_LIST = "language";
export const ADD_LANGUAGE = "add-language";
export const EDIT_LANGUAGE_ID = "edit-language/:id";
export const EDIT_LANGUAGE = "edit-language/";

export const CREATE_COMPLAINS = "create-complains";
export const EDIT_COMPLAINS = "edit-complains/";
export const EDIT_COMPLAINS_ID = "edit-complains/:id";
export const COMPLAINS_LIST = "complains";

export const CREATE_INQUIRE = "create-inquiries";
export const EDIT_INQUIRE = "edit-inquiries/";
export const EDIT_INQUIRE_ID = "edit-inquiries/:id";

export const CREATE_ALLOCATE_AREA = "create-allocated-area";
export const EDIT_ALLOCATED_AREA_ID = "edit-allocated-area/:id";
export const EDIT_ALLOCATED_AREA = "edit-allocated-area";

export const ROLES_ROUTE = "roles";
export const ADD_ROLE = "add-role";
export const EDIT_ROLE = "edit-role/";
export const EDIT_ROLE_ID = "edit-role/:id";

export const KYC_DETAILS_RT = "kyc-details";
export const BANK_DETAILS_RT = "bank-details";
export const INVITE_EARN_RT = "invite-earn";

export const RECEIVED_PAYMENTS = "received-payments";
export const EDIT_RECEIVED_PAYMENTS = "edit-received-payments/:id";
export const WALLET_RT = "wallet";
export const WITHDRAW_MONEY = "withdraw-money";
export const ADVERTISE_SETTINGS = "advertise-settings";
export const CREATE_ADVERTISE_SETTINGS = "create-advertise-settings";
export const EDIT_ADVERTISE_SETTINGS_ID = "edit-advertise-settings";
export const PUSH_ADVERTISE_SETTINGS = "push-advertise-settings";

export const CREATE_ADV_SCHEDULE = "create-adv-schedule";
export const PROMO_CODES_RT = "promo-codes";
export const FREE_TRIAL_DISCOUNT_RT = "free-trial-discount";
export const SELECT_METHOD_RT = "select-method";
export const DISCOUNTS_VIEW_RT = "discounts-view";
export const COMMENTS_RT = "comments";
export const NEW_CHAT_RT = "new-chat";
export const VIEWER_RT = "viewer";
export const ADV_PUSH_DETAIL_RT = "adv-push-detail";
export const USER_BUSINESS_LIST_RT = "user-businesslist";

export const PROMOCODE_VARIFICATION_RT = "promocode-varfication";

export const BANKS_RT = "banks";
export const ADD_BANK_RT = "add-bank";
export const EDIT_BANK_ID = "edit-bank/:id";
export const EDIT_BANK = "edit-bank/";

export const POLICE_STATIONS_RT = "police-stations";
export const ADD_POLICE_STATION_RT = "add-police-station";
export const EDIT_POLICE_STATION_ID = "edit-police-station/:id";
export const EDIT_POLICE_STATION = "edit-police-station/";

export const POST_OFFICE_RT = "post-offices";
export const ADD_POST_OFFICE_RT = "add-post-office";
export const EDIT_POST_OFFICE_ID = "edit-post-office/:id";
export const EDIT_POST_OFFICE = "edit-post-office/";
