<ion-header class="ion-no-border">
  <ion-toolbar>
    <ion-title mode="md">
      {{ headername | getLanguagePipe }}
    </ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="closeModalCross()">{{'close' | getLanguagePipe}}</ion-button>
    </ion-buttons> 
  </ion-toolbar>
</ion-header>
<ion-content>
<ion-row>
  <ion-col size="4.5" class="auto-sc">
    <ion-list>
      <ion-item *ngFor="let item of listData;let i=index" (click)="onItem(item,i)">
        <ion-label class="ion-text-wrap" [ngClass]="!item.isChecked ? 'lbl-14':'lbl-sec-14'">{{item.text}}</ion-label>
      </ion-item>
    </ion-list>
    </ion-col>
    <ion-col size="7.5" [ngClass]="(productList && productList.length > 0) ? 'auto-sc':''">
      <ion-list *ngIf="productList && productList.length > 0 else noData;">
        <!-- <ion-item *ngFor="let item of productList">
          <ion-checkbox slot="start" class="m-0 ui-checkbox" [(ngModel)]="item.isChecked" 
            (ionChange)="onChangeCheckboxValue($event)"></ion-checkbox>
            <ion-text class="lbl-14 ml-12">{{item.text}}</ion-text>
        </ion-item> -->
        <app-item-selection [commonItems]="productList" [isCheckboxUI]="true"
              (onSelectedItem)="getSelectedBusinessTypeItem($event)">
            </app-item-selection>
      </ion-list>
      <ng-template #noData>
        <app-no-data-found [width]="'100'" [height]="'0'"></app-no-data-found>
      </ng-template>
    </ion-col>
  </ion-row>
</ion-content>
<ion-footer class="ion-no-border pl-16 pr-16 pb-16 pt-8 bg-white" >
  <ion-row>
    <ion-col size="6">
      <ion-button class="btn-1 hp-45" expand="block" mode="ios" (click)="saveData()">
        <ion-text class="lbl-12">
          {{'save' | getLanguagePipe}}
        </ion-text>
      </ion-button>
    </ion-col>
    <ion-col size="6">
      <ion-button class="btn-2 hp-45" expand="block" mode="ios" (click)="cancelData()">
        <ion-text class="lbl-12">
          {{'cancel' | getLanguagePipe}}
        </ion-text>
      </ion-button>
    </ion-col>
  </ion-row>
</ion-footer>