import { Component, Input, NgZone, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { LoadingController, ModalController, PopoverController } from '@ionic/angular';
import { finalize } from 'rxjs/operators';
import { CommonData } from 'src/app/models/common-data';
import { UtilsService } from 'src/app/services/uitils.services';
import { UserServices } from 'src/app/services/user.services';
import { getGroupsArr, getItemgroupsArr, getStatusArr, PAGE_ACC_GROUP, PAGE_ITEM_GROUP, SELECT_LIST_ITEM } from 'src/app/utils/constant';
import { LogData } from 'src/app/utils/utils';
import { SingleSearchFilterComponent } from '../single-search-filter/single-search-filter.component';

@Component({
  selector: 'app-group-filter',
  templateUrl: './group-filter.component.html',
  styleUrls: ['./group-filter.component.scss'],
})
export class GroupFilterComponent implements OnInit {

  commonForm:FormGroup;
  @Input() page;
  @Input() title;
  @Input() groupData;
  @Input() btn1="apply";
  @Input() btn2="clear_all";
  statusList:Array<CommonData>=[];
  parentGroupList:Array<CommonData>=[];
  constructor(private formBuilder:FormBuilder,
    public utilsService:UtilsService,
    private popoverCtrl:PopoverController,
    private userServices:UserServices,
    private loadingCtrl:LoadingController,
    private modalCtrl:ModalController,
    private ngZone:NgZone) { }

  ngOnInit() {
    this.buildForm();
    this.getMasters();
  }


  apply(ev?){
    this.popoverCtrl.dismiss(this.commonForm.value);
  }

  clearAll(ev?){
    this.buildForm();
    this.utilsService.setAddressMaster({isClear:true,page:this.page });
    this.closeModal();
  }

  closeModal(ev?) {
    this.popoverCtrl.dismiss();
  }

  buildForm() {
    this.commonForm = this.formBuilder.group({
      parent_id: [(this.groupData && this.groupData.parent_id) ? this.groupData.parent_id:""],
      parent_id_expand:[false],
      status: [(this.groupData && this.groupData.status) ? this.groupData.status:""],
    });
  }

  async openSingleSelection() {
    if (this.parentGroupList.length > 0) {
      this.commonForm.value.parent_id_expand=true;
      const modal = await this.modalCtrl.create({
        component: SingleSearchFilterComponent,
        componentProps: { title: 'dg_tl_sel_group', selectedData: this.parentGroupList, selectedId: 
        (this.commonForm.value.parent_id && this.commonForm.value.parent_id.length > 0) ? this.commonForm.value.parent_id[0].value:'' },
        cssClass: 'custom-modal-filter',
        showBackdrop: true,
        backdropDismiss: false,
        // breakpoints: [0, 0.3, 0.5, 0.8],
        // initialBreakpoint: 0.5
      });

      modal.onDidDismiss().then((data) => {
        LogData("onDidDismiss...", data);
        if (data.data != null) {
          if (data.data !== SELECT_LIST_ITEM) {
            let tempArr = [];
            tempArr.push(data.data);
            this.commonForm.patchValue({parent_id:tempArr});

          } else {
            this.commonForm.patchValue({parent_id:''});
          }

        }
        this.commonForm.value.parent_id_expand=false;
      });

      await modal.present();
    }
  }

  async getMasters() {
    const loading = await this.loadingCtrl.create(
      this.utilsService.getLoaderUI()
    );
    await loading.present();
    LogData("loader_start");
    const list = getStatusArr +","+ (this.page === PAGE_ACC_GROUP ? getGroupsArr:this.page === PAGE_ITEM_GROUP ? getItemgroupsArr:'');
    const formData = new FormData();
    formData.append("master", list);
    this.userServices.getMasters(formData)
      .pipe(
        finalize(() => {
          LogData("loader_dismiss");
          loading.dismiss();
        })
      )
      .subscribe((res) => {
        LogData("getMasters_res", res);
        res = this.utilsService.getDecriptReasponse(res);
          if (res && res.result && res.result.responce) {
          if (res.result.responce.getStatusArr &&
            res.result.responce.getStatusArr.length > 0) {
            this.statusList = res.result.responce.getStatusArr;
            if(this.groupData && this.groupData.status){
              this.commonForm.patchValue({
                status:this.groupData.status
              })
            }
          } else {
            this.statusList = [];
          }
          if ((res.result.responce.getGroupsArr &&
            res.result.responce.getGroupsArr.length > 0) || (res.result.responce.getItemgroupsArr &&
              res.result.responce.getItemgroupsArr.length > 0)) {
            this.parentGroupList = res.result.responce.getGroupsArr ? res.result.responce.getGroupsArr:
            res.result.responce.getItemgroupsArr ? res.result.responce.getItemgroupsArr:[];
            if(this.groupData && this.groupData.parent_id){
              this.commonForm.patchValue({
                parent_id:this.groupData.parent_id
              })
            }
           
          } else {
            this.parentGroupList = [];
          }
          
        } else {
          this.parentGroupList = [];
          this.statusList = [];
        }

      }, err => {
        LogData("loader_dismiss");
        loading.dismiss();
        LogData("getState_error", err);
      });
  }

}
