<div class="flex flex-col ion-align-items-center cursor-ptr hvr-float mt-16 cate">
 <div class="com-div">
  <ion-img class="img-max" *ngIf="getProductCompneyLogo();else noLogo" [src]="getProductCompneyLogo()"></ion-img>
   </div>
  <ng-template #noLogo>
    <!-- <div class="logo-div">
      {{productCompneyData.text}}
    </div> -->
    <div class="container">
      <img [src]="'prod_com_d' | getImagePipe:2"  style="width:100%;">
      <div class="centered">
        <ion-text class="lbl-wh-12">{{productCompneyData.text}}</ion-text>
      </div>
    </div>
  </ng-template>
  <ion-text class="ion-text-center wb-cate-text mb-16 mt-8">{{productCompneyData.text}}</ion-text>
</div>