import { Component, Input, OnInit } from '@angular/core';
import { CommonData } from 'src/app/models/common-data';

@Component({
  selector: 'app-product-compney-card',
  templateUrl: './product-compney-card.component.html',
  styleUrls: ['./product-compney-card.component.scss'],
})
export class ProductCompneyCardComponent implements OnInit {

  @Input() isLast;
  @Input() productCompneyData: CommonData;
  constructor() { }

  ngOnInit() { }

  getProductCompneyLogo() {
    let str = '';
    if (this.productCompneyData && this.productCompneyData.documents &&
      this.productCompneyData.documents.length > 0 &&
      this.productCompneyData.documents[0].display_files &&
      this.productCompneyData.documents[0].display_files.company_logo) {
      if (this.productCompneyData.documents[0].display_files.company_logo.square_url) {
        str=this.productCompneyData.documents[0].display_files.company_logo.square_url;
      }else if(this.productCompneyData.documents[0].display_files.company_logo.main_url){
        str=this.productCompneyData.documents[0].display_files.company_logo.main_url;
      }
    }

    return str;

  }

}
