import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-name-icon',
  templateUrl: './name-icon.component.html',
  styleUrls: ['./name-icon.component.scss'],
})
export class NameIconComponent implements OnInit {

  @Input() icon;
  @Input() lable1;
  @Input()lable2;
  @Input() marginLeft='8';
  @Input() isTranslate:boolean=true;
  @Input() isTranslatelbl2:boolean=true;
  constructor() { }

  ngOnInit() {}

}
