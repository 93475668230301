<ion-header class="ion-no-border">
  <ion-toolbar class="center-toolbar-filter">
    <ion-title>{{title | getLanguagePipe}}</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="closeModal()">
        <ion-label color="secondary">{{'close' | getLanguagePipe}}</ion-label>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header >  

<ion-content [scrollEvents]="true" class="bg-white">
  <ng-container *ngIf="!isImageLayout">
    <ion-item-divider sticky="fixed" class="ion-no-border">
      <ion-searchbar  mode="ios" class="search-cls" placeholder="Search" (ionClear)="onClear()" animated
        (input)='onSearchInput($event)'></ion-searchbar>
    </ion-item-divider>
    <ng-container *ngIf="items && items.length > 0">
      <ion-item  (click)="onClickSelect()">
        <ion-label class="ion-text-center lbl-med-16">{{'select' | getLanguagePipe}}</ion-label>
      </ion-item>
      <ion-list>
        <ion-item *ngFor="let item of items" (click)="onClickItem(item)" id='{{item.value}}'>
          <!-- <ion-label class="ion-text-center" [ngClass]="item.isChecked ? 'lbl-primary-med-16':'lbl-med-16'"> {{
            item.text
            }}</ion-label> -->
            <ion-label class="ion-text-center lbl-hvr cursor-ptr" [ngClass]="item.value === selectedId ? 'lbl-sec-bold-16':'lbl-med-16'"> {{
              item.text
              }}</ion-label>
        </ion-item>
      </ion-list>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="isImageLayout">
    <ng-container *ngIf="items && items.length > 0">
      <ion-list>
        <ng-container *ngFor="let item of items">
          <ion-item lines="none" (click)="onClickItem(item)" id='{{item.value}}' class="pt-4 pb-4">
            <ion-label style="display: contents;">
              <img [src]="(item.documents && item.documents.car_icon &&
              item.documents.car_icon.length > 0 && item.documents.car_icon[0].display_files &&
               item.documents.car_icon[0].display_files.car_icon.square_url) ? item.documents.car_icon[0].display_files.car_icon.square_url:''"
               class="car_img" />
            </ion-label>
            <ion-label class="ion-text-start ml-4" [ngClass]="item.isChecked ? 'lbl-primary-med-16':'lbl-med-16'"> {{
              item.text
              }}</ion-label>
          </ion-item>
          <hr class="divider1" />
        </ng-container>
      </ion-list>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="!items || items.length === 0">
    <div>
      <app-no-data-found [width]="'100'" [message]="'no_data_found'"></app-no-data-found>
    </div>
  </ng-container>
</ion-content>