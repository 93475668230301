import { Component, Input, OnInit } from '@angular/core';
import { BusinessDirectoryDetails } from 'src/app/models/business-director-data';
import { AuthServices } from 'src/app/services/auth.services';
import { UtilsService } from 'src/app/services/uitils.services';
import { BUSINESS_DETAIL } from 'src/app/utils/routes';

@Component({
  selector: 'app-dmb-card',
  templateUrl: './dmb-card.component.html',
  styleUrls: ['./dmb-card.component.scss'],
})
export class DmbCardComponent implements OnInit {

  @Input() businessDetail: BusinessDirectoryDetails;
  constructor(public authServices: AuthServices,
    public utilsService: UtilsService) { }

  ngOnInit() { }

  onclickBusiness() {
    this.utilsService.setNavigationRoute(BUSINESS_DETAIL + "/" + this.businessDetail.id);
  }

}
