import { Component, Input, OnInit } from '@angular/core';
import { UtilsService } from 'src/app/services/uitils.services';

@Component({
  selector: 'app-warning-card',
  templateUrl: './warning-card.component.html',
  styleUrls: ['./warning-card.component.scss'],
})
export class WarningCardComponent implements OnInit {

  @Input() icon1;
  @Input() lbl1;
  @Input() routerUrl;
  constructor(private utilServices:UtilsService) { }

  ngOnInit() {}

  onClickCard(){
    if(this.routerUrl){
      // if(this.utilServices.isIos()){
      //   this.utilServices.openSfariViewForReg();
      // }else{
      //   this.utilServices.setNavigationRoute(this.routerUrl);
      // }
      this.utilServices.setNavigationRoute(this.routerUrl);
    }
   
  }

}
