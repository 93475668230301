<ion-content class="bg-white">
  <ion-row class="line-container">
    <ion-col>
      <div class="top-line">
        <div class="div-line"></div>
      </div>
    </ion-col>
  </ion-row>
  <div class="ion-padding">
    <ion-row class="ion-margin" *ngIf="message1">
      <ion-col class="ion-text-center">
        <ion-label class="lbl-bold-20  line-height-cls">{{message1}}</ion-label>
      </ion-col>
    </ion-row>

    <ion-row  style="margin: 12px;" *ngIf="message2">
      <ion-col class="ion-text-center">
        <ion-label class="lbl-20 line-height-cls">{{message2}}</ion-label>
      </ion-col>
    </ion-row>


    <ion-row class="margin-top-24" style="margin-bottom: 8px;">
      <ion-col *ngIf="!isSingle" size="5.5" class="ion-no-padding">
        <ion-button fill="outline" mode="ios" expand="block" class="outline-btn" (click)="clearData()">
          <ion-text>
            {{btn1}}
          </ion-text>
        </ion-button>
        
      </ion-col>
      <ion-col size="1" *ngIf="!isSingle">
      </ion-col>
      <ion-col [size]="isSingle ?'12':'5.5'" class="ion-no-padding">
        <ion-button class="btn-1" expand="block" type="submit" mode="ios" (click)="apply()">
          <ion-text>
            {{btn2}}
          </ion-text>
        </ion-button>
      </ion-col>
    </ion-row>
  </div>
</ion-content>