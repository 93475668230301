import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { LogData } from 'src/app/utils/utils';

@Component({
  selector: 'app-language-popover',
  templateUrl: './language-popover.component.html',
  styleUrls: ['./language-popover.component.scss'],
})
export class LanguagePopoverComponent implements OnInit {

  
  @Input () listData = [];
  @Input() selectedFilter;
  @Input() sellbl;
  @Input() unsellbl;
  dropdown = false;
  selectedValue;
  @Input() isSelectAll:any;
  @Input() isTraslate:boolean=false;
  @ViewChild('productbtn', { read: ElementRef }) productbtn: ElementRef;
  constructor(private popoverCtrl: PopoverController) {
    // this.listData = this.navParam.get('data');
    // this.selectedFilter= this.navParam.get('selData')
   }

  ngOnInit() {
    LogData("isSelectAll",this.isSelectAll);
  }
  
  onClickItem(item){
    this.popoverCtrl.dismiss(item);
  }

  // async  hideDropdown(event) {
  //   const xTouch = event.clientX;
  //   const yTouch = event.clientY;

  //   const rect = this.productbtn.nativeElement.getBoundingClientRect();
  //   const topBoundary = rect.top + 2;
  //   const leftBoundary = rect.left + 2;
  //   const rightBoundary = rect.right - 2;

  //   if (xTouch < leftBoundary || xTouch > rightBoundary || yTouch < topBoundary) {
  //     this.dropdown = false;
  //   }
  //   LogData("dropdown,..LIst",this.dropdown);
  //   if(!this.dropdown){
  //     this.popoverCtrl.dismiss();
  //   }else{
  //     this.popoverCtrl.dismiss();
  //   }
  // }
}
