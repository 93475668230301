<ion-item-divider sticky="fixed" class="no-border">
  <ion-searchbar  mode="ios" class="search-cls" [placeholder]="'search_here' | getLanguagePipe" (ionClear)="onClear()" animated
    (input)='getSearchValue($event)'></ion-searchbar>
</ion-item-divider>
<ion-row class="center-row">
  <ion-col>
    <ion-row style="margin-left: 8px;margin-right: 8px;">
      <ng-container *ngIf="!isCheckboxUI">
        <ion-col *ngFor="let item of commonItems" size="auto" style="margin-bottom: 8px;">
          <div [ngClass]="item.isChecked ? 'seleted-item':'unselected-item'" (click)="selectedItem(item)">
            {{item.text}}
          </div>

        </ion-col>
      </ng-container>
      <ng-container *ngIf="isCheckboxUI">
        <ion-col>
          <ion-item *ngFor="let item of commonItems;let isFirst=first" lines="none" class="item-0 ion-text-wrap"
           [ngClass]="!isFirst ? 'mt-16':''" >
            <ion-checkbox slot="start" class="m-0 ui-checkbox" [(ngModel)]="item.isChecked" 
            (ionChange)="onChangeCheckboxValue($event)"></ion-checkbox>
            <ion-text class="lbl-14 ml-12">{{item.text}}</ion-text>
          </ion-item>
        </ion-col>
      </ng-container>

    </ion-row>

    <div class="error-message validation-errors ion-margin-top" style="margin-left: 24px;"
      *ngIf=" isFormSave && !isSelectedItem">
      {{message}}
    </div>

    <ng-container *ngIf="!isHideOtherValue">
      <ion-row *ngIf="isCustomeCheck" style="margin-left: 8px;margin-right: 8px;margin-top: 8px;">
        <ion-col>
          <div>
            <ion-row>
              <ion-col>
                <ion-item lines="none">
                  <ion-input [(ngModel)]="otherData" placeholder="Other">
                  </ion-input>
                </ion-item>
                <div class="error-message validation-errors ion-margin-top" *ngIf="isFormSubmit && !otherData">
                  Plaese enter other value.
                </div>
              </ion-col>
            </ion-row>
          </div>
        </ion-col>
      </ion-row>
    </ng-container>
  </ion-col>
</ion-row>