import { Component, Input, OnInit } from '@angular/core';
import { Translation } from 'src/app/models/translation-data';

@Component({
  selector: 'app-language-card',
  templateUrl: './language-card.component.html',
  styleUrls: ['./language-card.component.scss'],
})
export class LanguageCardComponent implements OnInit {

  @Input()  languageData:Translation;
  constructor() { }

  ngOnInit() {}

}
