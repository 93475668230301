
<ion-content>
  <ion-grid class="pl-16 pr-16">
    <ion-row class="center-row">
      <ion-col style="align-self: center;" class="no-margin-padding">
        <ion-row class="line-container">
          <ion-col>
            <div class="top-line">
              <div class="div-line"></div>
            </div>
          </ion-col>
        </ion-row>
        <ion-row>

          <ion-col class="mt-12 flex flex-col" >
            <ion-img [src]="icon | getImagePipe" class="hp-50 mt-24"></ion-img>
            <ion-text class="lbl-14 lh-20 mt-24 ion-text-center" *ngIf="msg">{{isTranslateUsed ? (msg | getLanguagePipe):msg}}</ion-text>
          <ng-container *ngIf="userProfileCloseMsg">
           <div class="flex flex-col ion-text-center">
            <ion-text class="lbl-14 lh-20 mt-24">{{userProfileCloseMsg | getLanguagePipe}}</ion-text>
            <ion-text class="lbl-12 lh-20 mt-12">1.{{'dele_acc_text1' | getLanguagePipe}}</ion-text>
            <ion-text class="lbl-12 lh-20">2.{{'dele_acc_text2' | getLanguagePipe}}</ion-text>
            <ion-text class="lbl-12 lh-20">3.{{'dele_acc_text3' | getLanguagePipe}}</ion-text>
            </div>
          </ng-container>
          </ion-col>
        </ion-row>

        <ion-row class="mt-16 mb-8">
          <ion-col size="6" >
            <ion-button class="btn-1 hp-45"  expand="block" mode="ios" (click)="confirm()">
              <ion-text class="lbl-sb-12">
                {{'confirm' | getLanguagePipe}}
              </ion-text>
            </ion-button>
          </ion-col>
          <!-- <ion-col size="0.4">
            </ion-col> -->
          <ion-col size="6" >
            <ion-button class="btn-2 hp-45"  expand="block" mode="ios" (click)="cancel()">
              <ion-text  class="lbl-12">
                {{'cancel' | getLanguagePipe}}
              </ion-text>
            </ion-button>
          </ion-col>
        </ion-row>

      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>