import { Component, Input, OnInit } from '@angular/core';
import { TransactionsDetails } from 'src/app/models/transaction-data';

@Component({
  selector: 'app-note-popover',
  templateUrl: './note-popover.component.html',
  styleUrls: ['./note-popover.component.scss'],
})
export class NotePopoverComponent implements OnInit {

  @Input() listData:TransactionsDetails;
  @Input() onlymsgView:boolean=false;
  @Input() message;
  constructor() { }

  ngOnInit() {}

}
