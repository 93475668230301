<div class="role-card" *ngIf="roleData.actions_arr && roleData.actions_arr.length > 0">
  <div class="flex flex-col">
    <ion-text class="lbl-med-14 ml-16 mb-16 mt-16">{{roleData.text}}</ion-text>
    <hr class="divider1"  />
  </div>
  <ion-row class="p-12">
    <ng-container *ngFor="let item of roleData.actions_arr">
      <ion-col size="6">
        <ion-item  mode="ios" lines="none" class="item-0 ion-text-wrap">
          <ion-checkbox color="secondary" slot="start" class="m-0 ui-checkbox-ro" [(ngModel)]="item.isChecked"></ion-checkbox>
          <ion-text class="lbl-med-14 ml-12">{{item.text}}</ion-text>
        </ion-item>
      </ion-col>
    </ng-container>
  </ion-row>
</div>