import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { PopoverController } from "@ionic/angular";
import { CommonData } from "src/app/models/common-data";
import { UserprofileData } from "src/app/models/user-profile-data";
import { UtilsService } from "src/app/services/uitils.services";
import { YES } from "src/app/utils/constant";
import { IS_CLEAR_KEY } from "src/app/utils/params-data";
import { LogData } from "src/app/utils/utils";
import { AddressComponent } from "../address/address.component";

@Component({
  selector: "app-address-filter",
  templateUrl: "./address-filter.component.html",
  styleUrls: ["./address-filter.component.scss"],
})
export class AddressFilterComponent implements OnInit {
  isExpand: boolean = false;
  isExpandType: boolean = false;
  @Input() selectedStatus;
  @Input() lbl1;
  @Input() lbl2;
  @Input() btn1;
  @Input() btn2;
  @Input() isState: boolean = false;
  @Input() isDistrict: boolean = false;
  @Input() isTaluka: boolean = false;
  @Input() isVillage: boolean = false;
  @Input() isVillageArea: boolean = false;
  statusId;
  selectedType;
  typeId;
  @Input() selectedFilter;
  statusList: Array<CommonData> = [];
  stateList: Array<CommonData> = [];
  businessTypeList = [];
  @Input() isBusinessType: boolean = false;
  @Input() title;
  @Input() pageName;
  commonform: FormGroup;
  isStateLbl: boolean = false;
  @Input() masterStr;
  @Input() userProfileData: UserprofileData;
  @Input() isStatus;
  @Input() isNoOther;
  @ViewChild(AddressComponent, { static: false }) addressComponent: AddressComponent;
  constructor(
    private popoverCtrl: PopoverController,
    public utilsService: UtilsService,
    private formBuilder: FormBuilder
  ) {
    LogData("add_filter", this.userProfileData);
  }

  ngOnInit() {
    this.buildForm();
  }

  closeModal(ev?) {
    this.popoverCtrl.dismiss();
  }

  buildForm() {
    this.commonform = this.formBuilder.group({
      businesstype_id: [""],
      state: [""],
      status: [""],
    });
  }

  getColumnOptions() {
    let options = [];
    options.push({ value: 0, text: "Select" });

    this.statusList.forEach((x) => {
      options.push({ text: x.text, value: x.value });
    });
    return options;
  }


  apply(ev?) {
    LogData("addressComp", this.addressComponent.commonForm.value);
    this.popoverCtrl.dismiss(this.addressComponent.commonForm.value.addressDetails[0]);
  }

  clearAll(ev?) {
    this.addressComponent.clearForm();
    this.closeModal();
  }
}
