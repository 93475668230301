<ng-container *ngIf="isDocument();else noDoc">
  <ng-container *ngIf="isImage();else noImage">
    <div class="container">
      <img [src]="getImage() ? getImage():('img_doc' | getAddressStrPipe)" 
      class="chat-img cursor-ptr round-8" (click)="openPage();$event.stopPropagation()" />
      <div class="top-right">
        <ion-icon *ngIf="selectedId" class="avt-32" [src]="'down_ro' | getImagePipe" (click)="downloadDoc();$event.stopPropagation()"></ion-icon>
      </div>
    </div>
    
  </ng-container>
  <ng-template #noImage>
    <app-chat-doc [checkedCount]="checkedCount" [chatSetting]="chatSetting" [docUrl]="getImage()" [fileName]="messageData.documents[0].messagedetail_doc" [messageData]="messageData"></app-chat-doc>
  </ng-template>
</ng-container>
<ng-template #noDoc>
  <ion-label>
    <p class="lbl-opp ion-text-wrap mt-4 mb-4 ion-text-start">
      <!-- {{userMsg}} -->
      <span *ngIf="userMsg.length < 400;else overData">{{userMsg}}</span>
      <ng-template #overData>
        <app-read-more [content]="userMsg" [limit]="300" [completeWords]="true">
        </app-read-more>
      </ng-template>
    </p>
    <!-- <p [ngClass]="dateCss" *ngIf="messageData.msgdate">{{messageData.msgdate |
      dynamicDateTimePipe:1}}</p> -->
  </ion-label>
</ng-template>
<p [ngClass]="dateCss" *ngIf="messageData.msgdate">{{messageData.msgdate |
  dynamicDateTimePipe:1}}</p>