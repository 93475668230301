import { Component, Input, OnInit } from "@angular/core";
import { CommonData } from "src/app/models/common-data";
import { UtilsService } from "src/app/services/uitils.services";

@Component({
  selector: "app-category-card",
  templateUrl: "./category-card.component.html",
  styleUrls: ["./category-card.component.scss"],
})
export class CategoryCardComponent implements OnInit {
  @Input() cateImg='mb-cate-img';
  @Input() cateLbl='mb-cate-text';
  @Input() cateMr='mr-24';
   @Input() categoryData:CommonData;
  constructor(public utilservices:UtilsService) {}

  ngOnInit() {}
}
