import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { IonContent, IonSearchbar, ModalController, NavParams } from '@ionic/angular';
import { finalize } from 'rxjs/operators';
import { AuthServices } from 'src/app/services/auth.services';
import { UtilsService } from 'src/app/services/uitils.services';
import { UserServices } from 'src/app/services/user.services';
import { GET_PRODUCTLISTS_API, GET_TRADINGTYPES_API, PRODUCTCOMPANIES_API } from 'src/app/utils/api-end-points';
import { PRODUCT_COMPNEY_INDEX, PRODUCT_SALES_INDEX, PRODUCT_SERVICES_INDEX, TRADING_SALES_INDEX, TRADING_SERVICES_INDEX } from 'src/app/utils/constant';
import { GET_ID1_KEY, GET_ID_KEY, REQUESTARR_ID_KEY, SEARCH_KEY } from 'src/app/utils/params-data';
import { LogData } from 'src/app/utils/utils';

@Component({
  selector: 'app-multi-selection',
  templateUrl: './multi-selection.component.html',
  styleUrls: ['./multi-selection.component.scss'],
})
export class MultiSelectionComponent implements OnInit {

  @Input() itemList:any=[];
  @Input() headername:string='';
  isCustomeCheck:boolean=false;
  isNoIntruction:boolean=false;
  isFormSubmit:boolean=false;
  apiCallIndex;
  public searchTerm: string = "";
  get_id;
  get_id1;
  searching;
  searchItems: any = [];
  @ViewChild(IonContent, { static: false }) private content: IonContent;
  @ViewChild(IonSearchbar, { static: false }) private search: IonSearchbar;
  constructor(private navParams:NavParams,private modalCtrl:ModalController) { 
    // this.itemList = this.navParams.get('value');
    // if(this.itemList && this.itemList.length){
    //   const findIndex = this.itemList.findIndex((res) => {
    //     return res.text === 'Other' && res.isChecked
    //   });
    //   if(findIndex !== -1){
    //     this.isCustomeCheck=true;
    //   }
    // }
  
    // this.headername = this.navParams.get('title');
 
  }

  ngOnInit() {
    this.searchItems=this.itemList;
    // LogData("searchItems",this.searchItems);
    
    this.setFilteredItems();
  }

  ngAfterViewInit(){
    LogData("ngAfterViewInit().....call");
    setTimeout(() => {
      this.search.setFocus();
    }, 500);
  }
  
  closeModalCross() {
    this.modalCtrl.dismiss();
  }

  // saveData(){
  //   let array=[];
  //    array = this.itemList.filter(function (s) {
  //     return s.isChecked;
  //   });
  //   LogData("array...", array);
  //  if(array.length || this.isNoIntruction){
  //   this.modalCtrl.dismiss(array,this.otherInstructions);
  //  }else{
  //    this.utilServices.presentToast("Select instructions");
  //  }
  // }

  getSearchValue(event){
    this.searching = false;
    if(event){
      this.searchTerm = event.target.value;
      this.setFilteredItems();
    }else{
      this.searchTerm='';
      this.setFilteredItems();
    }
    
  }

  setFilteredItems() {
    this.itemList = this.filterItemsByName(this.searchItems, this.searchTerm);
  }

  filterItemsByName(searchItems,searchTerm) {
    return searchItems.filter(item => {
      return item.text.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1;
    });
  }

  onClear() {
    this.searchTerm = '';
    this.setFilteredItems();
  }


  saveData(){
    let array=[];
    //searchItems take instaed of itemList
     array = this.searchItems.filter(function (s) {
      return s.isChecked;
    });
    LogData("array...", array);
    this.modalCtrl.dismiss(array);
  }

  cancelData(){
    this.modalCtrl.dismiss();
  }

  selectNoInstruction() {
    this.itemList.forEach(item => {
      item.isChecked=false;
    });

  }

  selectItem(value) {
    if (value.isChecked) {
      value.isChecked=true;
    } else {
      value.isChecked=false;
    }
  }

  


}
