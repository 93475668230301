import { Directive, ElementRef, HostListener, Input } from "@angular/core";
import { LogData } from "../utils/utils";
import { StringUtil } from "./input-integer.directive";
@Directive({
    selector: "[decimalOnlyDir]"
})
export class OnlyDecimalDirective {
    @Input() maxDigit: number;
    constructor(public element: ElementRef){
        
    }
    @HostListener("keypress")
    onkeypress(e) {
       
        const event = e || window.event;
        if (event) {
            LogData("decimalOnlyDir_kypress....",event);
            return this.isNumericKeyWithDecimal(event);
        }
    }

    // @HostListener('input', ['$event'])
    // onInput(event: any) {
    //     LogData("decimalOnlyDir_input....",event);
    //     event.target.value = StringUtil.removeNonNumerics(event.target.value);
    // }

    isNumericKeyWithDecimal(evt) {
        try {
            const charCode = (evt.which) ? evt.which : evt.keyCode;
            // const txt = (<HTMLInputElement>document.getElementById("txtChar")).value;
            const txt = this.element.nativeElement.value;
            // LogData("txt.indexOf",txt.indexOf("."));
            if (!(txt.indexOf(".") > -1)) {
                // const _dgt=this.maxDigit-1;
                if(this.maxDigit && txt.length > this.maxDigit){
                    return false;
                }
            }
            
            // LogData("txt...",txt);
            if(txt.indexOf(".")>-1 && (txt.split('.')[1].length > 1)){
                evt.preventDefault();
            }
            if (charCode == 46) {
                if (!(txt.indexOf(".") > -1)) {
                    return true;
                }
            }
            if (charCode > 31 && (charCode < 48 || charCode == 45 || charCode > 57))
                return false;

            return true;
        } catch (w) {
        }
    }
}