import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

// moment.locale('en', {
//   relativeTime: {
//     future: 'in %s',
//     past: '%s ago',
//     s:  'seconds',
//     ss: '%ss',
//     m:  'a minute',
//     mm: '%dm',
//     h:  'an hour',
//     hh: '%dh',
//     d:  'a day',
//     dd: '%dd',
//     M:  'a month',
//     MM: '%dM',
//     y:  'a year',
//     yy: '%dY'
//   }
// });

moment.locale('en', {
  relativeTime: {
    future: 'in %s',
    past: '%s',
    s:  'seconds',
    ss: '%ss',
    m:  'a minute',
    mm: '%dm',
    h:  'an hour',
    hh: '%dh',
    d:  'a day',
    dd: '%dd',
    M:  'a month',
    MM: '%dM',
    y:  'a year',
    yy: '%dY'
  }
});


@Pipe({
  name: 'daysAgo'
})
export class DaysAgo implements PipeTransform {

  transform(date:any): any {
    return moment(date).fromNow();
  }

}
