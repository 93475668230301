import { Component, Input, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { UtilsService } from 'src/app/services/uitils.services';
import { UserServices } from 'src/app/services/user.services';
import { BUSINESS_TO_CUSTOMER, CUSTOMERS_USER_TYPE, CUSTOMER_TO_DEALER, DEALERS_USER_TYPE, PAGE_ADMIN_BUSINESS, PAGE_ADMIN_CUSTOMERS } from 'src/app/utils/constant';
import { LogData } from 'src/app/utils/utils';
import { ConfirmationComponent } from '../confirmation/confirmation.component';

@Component({
  selector: 'app-item-card',
  templateUrl: './item-card.component.html',
  styleUrls: ['./item-card.component.scss'],
})
export class ItemCardComponent implements OnInit {

  @Input() menuItem;
  @Input() selectedId;
  constructor(private utilsService:UtilsService,
    private userServices:UserServices,
    private popCtrl:PopoverController) { }

  ngOnInit() {}


  openPage(item){
    if(item.url){
      // this.utilsService.setNavigationRoute(item.url+'/'+this.selectedId);
      this.utilsService.setNavigationRouteQuery(item.url,{id:this.selectedId});
    }else{
      if(item.code === BUSINESS_TO_CUSTOMER){
        this.userServices.alertForUserConvert(this.selectedId,CUSTOMERS_USER_TYPE,null,"txt_are_you_sure_you_want_business_to_customer",PAGE_ADMIN_BUSINESS);
      }else if(item.code === CUSTOMER_TO_DEALER){
        this.userServices.alertForUserConvert(this.selectedId,DEALERS_USER_TYPE,null,"txt_are_you_sure_you_want_customer_to_dealer",PAGE_ADMIN_CUSTOMERS);
      }
    }
    
  }

}
