import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouteReuseStrategy } from "@angular/router";

import { IonicModule, IonicRouteStrategy } from "@ionic/angular";

import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { ServiceWorkerModule } from "@angular/service-worker";
import { environment } from "../environments/environment";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { CommonComponentModule } from "./common/common-component.module";
// import { SafariViewController } from '@awesome-cordova-plugins/safari-view-controller/ngx';
import { NgCircleProgressModule } from "ng-circle-progress";
import { AuthGuard } from "./services/auth.guard";
import { LoginAuthGuard } from "./services/login.auth.guard.services";
import { HttpConfigInterceptor } from "./services/httpConfig.interceptor";
import { HashLocationStrategy, LocationStrategy } from "@angular/common";
import { Crop } from "@ionic-native/crop/ngx";
// import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';
// import { FileTransfer } from '@awesome-cordova-plugins/file-transfer/ngx';
// import { File } from '@awesome-cordova-plugins/file/ngx';
import { AppVersion } from "@awesome-cordova-plugins/app-version/ngx";
import { SafariViewController } from "@awesome-cordova-plugins/safari-view-controller/ngx";

import { AngularFireModule } from "@angular/fire/compat";
import { AngularFireMessagingModule } from "@angular/fire/compat/messaging";
// import { VideoPlayer } from '@awesome-cordova-plugins/video-player/ngx';

import { SocketIoModule, SocketIoConfig } from "ngx-socket-io";
const config: SocketIoConfig = { url: environment.socketUrl, options: {} };

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    SocketIoModule.forRoot(config),
    HttpClientModule,
    IonicModule.forRoot({
      backButtonIcon: "assets/img/ic_back.svg",
      backButtonText: "",
    }),
    AppRoutingModule,
    // ServiceWorkerModule.register("ngsw-worker.js", {
    //   enabled: environment.production,
    //   // Register the ServiceWorker as soon as the app is stable
    //   // or after 30 seconds (whichever comes first).
    //   registrationStrategy: "registerWhenStable:30000",
    // }),
    BrowserAnimationsModule,
    CommonComponentModule,
    NgCircleProgressModule.forRoot({
      // set defaults here
      radius: 100,
      outerStrokeWidth: 16,
      innerStrokeWidth: 8,
      outerStrokeColor: "#78C000",
      innerStrokeColor: "#C7E596",
      animationDuration: 300,
    }),
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireMessagingModule,
    ServiceWorkerModule.register("combined-sw.js", {
      enabled: environment.production,
    }),
    // ,SocketIoModule.forRoot(config)
  ],
  providers: [
    Crop,
    // SocialSharing,
    AppVersion,
    SafariViewController,
    // VideoPlayer,
    // FileTransfer,
    // File,
    // { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    AuthGuard,
    LoginAuthGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpConfigInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
