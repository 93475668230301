import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { tap } from 'rxjs/operators'
import { LogData } from '../utils/utils';

@Injectable({
  providedIn: 'root'
})
export class MessagingService {
  token = null;

  constructor(private afMessaging: AngularFireMessaging) {}

  requestPermission() {
    // LogData('requestPermission');
    return this.afMessaging.requestToken.pipe(
      tap(token => {
        // LogData('requestPermission_1: ', token);
      })
    );
  }

  getMessages() {
    return this.afMessaging.messages;
  }

  deleteToken() {
    if (this.token) {
      this.afMessaging.deleteToken(this.token);
      this.token = null;
    }
  }
}