import { ADVERTISEBANNERS_MC, BUSINESSES_MC, BUSINESSLISTS_MC, COMPLAINS_MC, CUSTOMERS_MC, DEALERS_MC, DISTRICTS_MC, EMAILTEMPLATES_MC, GROUPS_MC, INQUIRIES_MC, ITEMGROUPS_MC, LEDGERS_MC, PRODUCTCOMPANIES_MC, PRODUCTLISTS_MC, PUSHADVERTISEBANNERS_MC, ROLES_MC, SMSTEMPLATES_MC, STAFFS_MC, STATES_MC, SUBJECTS_MC, TALUKAS_MC, TRADINGTYPES_MC, TRANSACTIONS_MC, TRANSLATIONS_MC, VILLAGEAREAS_MC, VILLAGES_MC } from "./menu-code";
import {
  ACCOUNT_LADGER,
  ACCOUNT_LADGER_REPORT,
  ACCOUNT_SETTING,
  ADVERTISE_BANNER,
  ADVERTISE_PUSH_BANNER,
  BANK_TRANSACTIONS,
  BRANCH_DEALER,
  BUSINESS_LIST,
  CHANGE_PASSWORD,
  COMPLAINS_LIST,
  COMPLAIN_SUBJECTS,
  DISTRCT_ROUTE,
  EDIT_STAFF_DETAILS,
  EMAIL_ROUTE,
  GALARRY_SETTINGS_ROUTE,
  GROUP_LIST,
  INQUIRE_LIST,
  INVITE_EARN_RT,
  ITEM_GROUP,
  ITEM_LADGER,
  JOURNAL_TRANSACTIONS,
  PAYMENT_TRANSACTIONS,
  PRODUCT_COMPNEY_LIST,
  PRODUCT_LIST,
  PURCHASE_ENTRY,
  RECEIPT_TRANSACTIONS,
  RECEIVED_PAYMENTS,
  REGISTRATION_REPORT,
  ROLES_ROUTE,
  SALES_ENTRY,
  SMS_ROUTE,
  STAFF_BUSINESS_ROUTE,
  STAFF_CUSTOMER_ROUTE,
  STAFF_ROUTE,
  STAFF_TAB1_ROUTE,
  STAFF_TAB2_ROUTE,
  STAFF_TAB4_ROUTE,
  STAFF_TAB5_ROUTE,
  STAFF_TAB6_ROUTE,
  STATES_ROUTE,
  TALUKAS_ROUTE,
  TRADING_LIST,
  VILLAGES_AREA_ROUTE,
  VILLAGES_ROUTE,
} from "../../utils/routes";
import { Capacitor } from "@capacitor/core";

export const StaffMenuData = [
    {
      id: 1000,
      title: "mn_visit_website",
      url: STAFF_TAB1_ROUTE,
      icon: "ic_visit_web",
      isChecked: false,
    },
    {
      id: 3601,
      title: "profile",
      url: STAFF_TAB2_ROUTE,
      icon: "profile_sm",
      isChecked: false,
    },
    {
      id: 29,
      title: "information",
      url: "",
      icon: "info_sm",
      isChecked: false,
      subMenu: [
        {
          id: 2901,
          title: "address",
          url: "",
          icon: "address_sm",
          isChecked: false,
          isExpand: false,
          innerSubMenu: [
            // { id: 511, title: "countries", url: "" },
            { id: 29011, title: "state", url: STATES_ROUTE, menuCode: STATES_MC },
            { id: 29012, title: "districts", url: DISTRCT_ROUTE, menuCode: DISTRICTS_MC },
            { id: 29013, title: "talukas", url: TALUKAS_ROUTE, menuCode: TALUKAS_MC },
            { id: 29014, title: "villages", url: VILLAGES_ROUTE, menuCode: VILLAGES_MC },
            { id: 29015, title: "village_areas", url: VILLAGES_AREA_ROUTE, menuCode: VILLAGEAREAS_MC },
          ],
        },
        // {
        //   id: 2902,
        //   title: "banks",
        //   url: "'",
        //   icon: "bank_sm",
        //   isChecked: false,
        // },
        // {
        //   id: 2903,
        //   title: "post_offices",
        //   url: "",
        //   icon: "post_sm",
        //   isChecked: false,
        // },
        // {
        //   id: 2904,
        //   title: "police_stations",
        //   url: "",
        //   icon: "police_sm",
        //   isChecked: false,
        // },
      ],
    },
    {
      id: 34,
      title: "complains_tickets",
      url: '',
      icon: "complain_sm",
      isExpand: false,
      isChecked: false,
      subMenu: [
        {
          id: 3401,
          title: "mn_tickets",
          url: COMPLAINS_LIST,
          icon: "tkt_sm",
          isChecked: false,
          menuCode: COMPLAINS_MC
        },
        {
          id: 3402,
          title: "mn_subjects",
          url: COMPLAIN_SUBJECTS,
          icon: "subject_sm",
          isChecked: false,
          menuCode: SUBJECTS_MC
        }
      ],
    },
    {
      id: 80,
      title: "mn_billing_history",
      // url: RECEIVED_PAYMENTS,
      url: Capacitor.getPlatform() !== 'ios' ? RECEIVED_PAYMENTS:'',
      isBrowser:true,
      icon: "rec_pay_sm",
      isChecked: false,
      menuCode: ''
    },
    {
      id: 33,
      title: "inquiries",
      url: INQUIRE_LIST,
      icon: "inq_sm",
      isChecked: false,
      menuCode: INQUIRIES_MC,
      isCounter:true
    },
    {
      id: 1,
      title: 'personal_information',
      icon: 'ic_pur_info',
      url: EDIT_STAFF_DETAILS,
      isChecked: false,
    },
    {
      id: 3606,
      title: 'change_password',
      icon: 'mn_cp',
      url: CHANGE_PASSWORD,
      isChecked: false,
    },
    // {
    //   id: 2,
    //   title: "mn_kyc_bank_details",
    //   url: "",
    //   icon: "mn_kyc_bank",
    //   isChecked: false,
    //   subMenu: [
    //     {
    //       id: 201,
    //       title: "mn_kyc",
    //       // url: KYC_DETAILS_RT,
    //       url:'',
    //       icon: "mn_kyc",
    //       isChecked: false,
    //     },
    //     {
    //       id: 202,
    //       title: "mn_bank_details",
    //       // url: BANK_DETAILS_RT,
    //       url:'',
    //       icon: "mn_kyc_bank",
    //       isChecked: false,
    //     },
    //   ],
    // },
    // {
    //   id: 203,
    //   title: 'mn_wallet',
    //   icon: 'mn_wallet',
    //   //url: WALLET_RT,
    //  url:"",
    //   isChecked: false,
    // },
    // {
    //   id: 21,
    //   title: 'mn_business_reg',
    //   icon: 'mn_bus_reg',
    //   url: '',
    //   isChecked: false,
    // },
    // {
    //   id: 22,
    //   title: 'mn_customer_reg',
    //   icon: 'mn_cst_reg',
    //   url: '',
    //   isChecked: false,
    // },
    {
      id: 23,
      title: 'mn_invite_earn',
      icon: 'mn_invite',
       url: INVITE_EARN_RT,
      //url: '',
      isChecked: false,
    },
    {
      id: 24,
      title: "mn_contactus",
      url: STAFF_TAB6_ROUTE,
      icon: "mn_con_us",
      isChecked: false,
    },
    {
      id: 243,
      title: "notifications",
      url: STAFF_TAB4_ROUTE,
      // url: '',
      icon: "notification_sm",
      isChecked: false,
    },
    {
      id: 244,
      title: "chat",
      url: STAFF_TAB5_ROUTE,
      // url: '',
      icon: "chat_sm",
      isChecked: false,
    },
    {
      id: 25,
      title: "branch_dealers",
      url: BRANCH_DEALER,
      icon: "branch_sm",
      isChecked: false,
      menuCode: DEALERS_MC
    },
    {
      id: 26,
      title: "businesse",
      url: STAFF_BUSINESS_ROUTE,
      icon: "bus_sm",
      isChecked: false,
      menuCode: BUSINESSES_MC
    },
    {
      id: 27,
      title: "mn_customers",
      url: STAFF_CUSTOMER_ROUTE,
      icon: "cst_sm",
      isChecked: false,
      menuCode: CUSTOMERS_MC
    },
    {
      id: 28,
      title: "staff",
      url: STAFF_ROUTE,
      icon: "staff_sm",
      isChecked: false,
      menuCode: STAFFS_MC
    },
    {
      id: 4,
      title: "roles",
      url: ROLES_ROUTE,
      icon: "role_sm",
      isChecked: false,
      menuCode: ROLES_MC
    },
    {
      id: 30,
      title: "mn_business_master",
      url: "",
      icon: "bus_sm",
      isChecked: false,
      subMenu: [
        // {
        //   id: 61,
        //   title: 'businesses_types',
        //   url: '',
        //   icon: 'bt_sm',
        //   isChecked: false
        // },
        // {
        //   id: 62,
        //   title: 'trading_types',
        //   url: '',
        //   icon: 'trad_sm',
        //   isChecked: false
        // },
        {
          id: 301,
          title: "business_list",
          url: BUSINESS_LIST,
          icon: "bus_list_sm",
          isChecked: false,
          menuCode: BUSINESSLISTS_MC
        },
        {
          id: 302,
          title: "trading_types",
          url: TRADING_LIST,
          icon: "trad_sm",
          isChecked: false,
          menuCode: TRADINGTYPES_MC
        },
        {
          id: 303,
          title: "product",
          url: PRODUCT_LIST,
          icon: "product_sm",
          isChecked: false,
          menuCode: PRODUCTLISTS_MC
        },
        {
          id: 304,
          title: "product_company",
          url: PRODUCT_COMPNEY_LIST,
          icon: "pro_compl_sm",
          isChecked: false,
          menuCode: PRODUCTCOMPANIES_MC
        },
      ],
    },
    {
      id: 31,
      title: "advertise",
      url: "",
      icon: "adv_sm",
      isChecked: false,
      subMenu: [
        {
          id: 3101,
          title: "advertise_banners",
          url: ADVERTISE_BANNER,
          icon: "adv_banner_sm",
          isChecked: false,
          menuCode: ADVERTISEBANNERS_MC
        },
        {
          id: 3102,
          title: "push_notification",
          url: ADVERTISE_PUSH_BANNER,
          icon: "push_add_sm",
          isChecked: false,
          menuCode: PUSHADVERTISEBANNERS_MC
        },
      ],
    },
    {
      id: 32,
      title: "accounts",
      url: "",
      icon: "acc_sm",
      isExpand: false,
      isChecked: false,
      subMenu: [
        {
          id: 3201,
          title: "master",
          url: "",
          icon: "master_sm",
          innerSubMenu: [
            // { id: 811, title: "gst_master", url: "" },
            { id: 32011, title: "account_group", url: GROUP_LIST, menuCode: GROUPS_MC },
            { id: 32012, title: "item_group", url: ITEM_GROUP, menuCode: ITEMGROUPS_MC },
            { id: 32013, title: "account_ledger", url: ACCOUNT_LADGER, menuCode: LEDGERS_MC },
            { id: 32014, title: "item_ledger", url: ITEM_LADGER, menuCode: LEDGERS_MC },
          ],
        },
        {
          id: 3202,
          title: "transaction",
          url: "",
          icon: "trans_sm",
          menuCode: TRANSACTIONS_MC,
          innerSubMenu: [
            { id: 32021, title: "sales_entry", url: SALES_ENTRY },
            { id: 32022, title: "purchase_entry", url: PURCHASE_ENTRY },
            // { id: 32023, title: "sales_return", url: SALES_RETURN },
            // { id: 32024, title: "purchase_return", url: PURCHASE_RETURN },
            { id: 32025, title: "bank_entry", url: BANK_TRANSACTIONS },
            { id: 32026, title: "payment_entry", url: PAYMENT_TRANSACTIONS },
            { id: 32027, title: "receipt_entry", url: RECEIPT_TRANSACTIONS },
            { id: 32028, title: "journal_entry", url: JOURNAL_TRANSACTIONS },
          ],
        },
        {
          id: 3203,
          title: "report",
          url: "",
          icon: "report_sm",
          // menuCode: TRANSACTIONS_MC,
          innerSubMenu: [
            { id: 831, title: "account_ledger", url: ACCOUNT_LADGER_REPORT },
            // { id: 842, title: "item_ledger", url: ITEM_LADGER_REPORT },
          ],
        },
      ],
    },
    
   
    {
      id: 35,
      title: "mn_member_chart",
      url: REGISTRATION_REPORT,
      icon: "chart_sm",
      isExpand: false,
      isChecked: false,
    },
    {
      id: 36,
      title: "settings",
      url: "",
      icon: "setting_sm",
      isExpand: false,
      isChecked: false,
      subMenu: [
        {
          id: 3602,
          title: "mn_gal_sett",
          url: GALARRY_SETTINGS_ROUTE,
          icon: "gs_sm",
          isChecked: false,
        },
        {
          id: 3603,
          title: "mn_sms",
          url: SMS_ROUTE,
          icon: "sms_sm",
          isChecked: false,
          menuCode: SMSTEMPLATES_MC
        },
        {
          id: 3604,
          title: "mn_email",
          url: EMAIL_ROUTE,
          icon: "email_sm",
          isChecked: false,
          menuCode: EMAILTEMPLATES_MC
        },
        {
          id: 3605,
          title: "mn_accounts",
          url: ACCOUNT_SETTING,
          icon: "account_sm",
          isChecked: false,
        },
       
      ]
    },
    // {
    //   id: 37,
    //   title: "mn_pricing",
    //   url: STAFF_TABS_PRICING,
    //   icon: "mn_pricing",
    //   isExpand: false,
    //   isChecked: false,
    // },
  ];
  