import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { IonContent, IonSearchbar, ModalController, NavParams } from '@ionic/angular';
import { FilterUtilsService } from 'src/app/services/filter-utility.services';
import { UtilsService } from 'src/app/services/uitils.services';
import { SELECT_LIST_ITEM } from 'src/app/utils/constant';
import { LogData } from 'src/app/utils/utils';

@Component({
  selector: 'app-single-search-filter',
  templateUrl: './single-search-filter.component.html',
  styleUrls: ['./single-search-filter.component.scss'],
})
export class SingleSearchFilterComponent implements OnInit {
  public searchTerm: string = "";
  headername: '';
  searching: any = false;
  items: any[];
  searchItems: any = [];
   selectedData;
   selectedId;
  TIMEOUT=700;
   title;
  isImageLayout:boolean=false;
  @ViewChild(IonContent, { static: false }) private content: IonContent;
  @ViewChild(IonSearchbar, { static: false }) private search: IonSearchbar;
  constructor(private filterUtilsService: FilterUtilsService,
    private modalCtrl: ModalController,
    private navParam: NavParams,
    private utilServices:UtilsService) {
    this.selectedData = this.navParam.get("selectedData");
    this.selectedId = this.navParam.get("selectedId");
    this.title = this.navParam.get("title");
    this.isImageLayout = this.navParam.get("isImageLayout");
    LogData("selectedData...", this.selectedData);
    LogData("selectedId...", this.selectedId);
    this.searchItems = this.selectedData;
  }

  ngAfterViewInit(){
    LogData("ngAfterViewInit().....call");
    setTimeout(() => {
      this.search.setFocus();
    }, 500);
  }

  ngOnInit() {
    LogData("ngOnInit().....call");
    this.setFilteredItems();
    if(this.searchItems && this.searchItems.length > 0){
      if(this.searchItems.length > 200){
        this.TIMEOUT=1500;
      }else{
        this.TIMEOUT=700
      }
    }
    LogData("TIMEOUT.....",this.TIMEOUT);
    if (this.selectedId) {
      setTimeout(() => {
        this.scrollToLabel(this.selectedId);
        // this.ScrollToPoint(0,100);
      }, this.TIMEOUT);
    }
    // setTimeout(() => {
    //   this.search.setFocus();
    // }, 500);
    
  }

  closeModal(){
    this.modalCtrl.dismiss();
  }

  ScrollToPoint(X, Y) {
    this.content.scrollToPoint(X, Y, 1500);
  }

  scrollToLabel(label) {
    LogData("scrollToLabel...", label);

    var titleELe = document.getElementById(label);
    LogData("titleELe...",titleELe);
    LogData("offsetTop...",titleELe.offsetTop);
    
     this.content.scrollToPoint(0, titleELe.offsetTop-(this.utilServices.isPlatformiOs() ? 60 : 0), 1000);
  }

  onClear() {
    this.searchTerm = '';
    this.setFilteredItems();
  }

  setFilteredItems() {
    // this.items = this.filterItems();
    this.items = this.filterItemsByName(this.searchItems, this.searchTerm);
    // if(this.selectedId){
    //   this.items.forEach(element => {
    //     if(this.selectedId !== element.value){
    //       element.isChecked=false;
    //     }else{
    //       element.isChecked=true;
    //     }
    //   });
    // }
    
    
  }

  filterItemsByName(searchItems,searchTerm) {
    return searchItems.filter(item => {
      return item.text.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1;
    });
  }

  onSearchInput(event) {
    LogData("onSearchInput...", event);

    this.searching = false;
    this.searchTerm = event.target.value;
    this.setFilteredItems();
  }

  filterItems() {
    return this.searchItems.filter(item => {
      return item.text.toLowerCase().indexOf(this.searchTerm.toLowerCase()) > -1;
    });
  }

  onClickSelect(){
    this.items.forEach(element => {
      element.isChecked=false;
    });
    this.modalCtrl.dismiss(SELECT_LIST_ITEM);
  }

  onClickItem(item) {
    item.isChecked=true;
    LogData("onClickItem...", item);
    this.items.forEach(element => {
      if(item.value !== element.value){
        element.isChecked=false;
      }
    });
    this.modalCtrl.dismiss(item);
  }

}


