<div class="flex flex-col p-16 template-card cursor-ptr">
  <div class="flex flex-row ion-align-items-center">
    <ion-icon [src]="icon | getImagePipe"></ion-icon>
    <ion-text class="lbl-sec-med-14 ml-8" >
      First time login
    </ion-text>
  </div>
  <ion-text class="lbl-13 mt-12">
    Your otp for registration is 467355.Use this
    otp to validate your login.
  </ion-text>
</div>