import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { LoadingController, PopoverController } from '@ionic/angular';
import { finalize } from 'rxjs/operators';
import { CommonData } from 'src/app/models/common-data';
import { AuthServices } from 'src/app/services/auth.services';
import { UtilsService } from 'src/app/services/uitils.services';
import { UserServices } from 'src/app/services/user.services';
import { NEW_REFERRAL_CODE_KEY } from 'src/app/utils/params-data';
import { LogData } from 'src/app/utils/utils';
import { AlphaNumeric, ValidationMessages } from 'src/app/validators/validations';

@Component({
  selector: 'app-add-referalid',
  templateUrl: './add-referalid.component.html',
  styleUrls: ['./add-referalid.component.scss'],
})
export class AddReferalidComponent implements OnInit {

  commonForm:FormGroup;
  @Input() page;
  @Input() title;
  @Input() groupData;
  @Input() btn1="apply";
  @Input() btn2="clear_all";
  isFormSubmit=false;
  validationMessages=ValidationMessages;
  constructor(private formBuilder:FormBuilder,
    private popoverCtrl:PopoverController,
    public utilServices:UtilsService,
    private loadingController:LoadingController,private userServices:UserServices,
    private authServices:AuthServices) { }

  ngOnInit() {
    this.buildForm();
  }


  apply(ev?){
    this.isFormSubmit=true;
    // this.popoverCtrl.dismiss(this.commonForm.value);
    if(this.commonForm.valid){
      LogData("API_CALL");
      this.addReferalId();
    }else{
      LogData("INVALID");
    }
  }

  clearAll(ev?){
    this.buildForm();
    this.closeModal();
  }

  closeModal(ev?) {
    this.popoverCtrl.dismiss();
  }

  buildForm() {
    this.commonForm = this.formBuilder.group({
      new_referral_code: ['',AlphaNumeric],
    });
  }

  async addReferalId() {
    const formData = new FormData();
    formData.append(NEW_REFERRAL_CODE_KEY, this.commonForm.value.new_referral_code);
   
    const loading = await this.loadingController.create(
      this.utilServices.getLoaderUI()
    );
    await loading.present();
    LogData("loader_start");;
    this.userServices.editReferalId(this.authServices.getForId(),formData,this.authServices.getUserToken())
      .pipe(
        finalize(() => {
          LogData("loader_dismiss");
          loading.dismiss();
        })
      )
      .subscribe((res) => {
        LogData("login_res", res);
        res=this.utilServices.getDecriptReasponse(res);
        if (res && res.result) {
          this.utilServices.openForSuccessError(false, res.result.success);
           this.popoverCtrl.dismiss(this.commonForm.value);
        }
      }, err => {
        LogData("loader_dismiss");
        loading.dismiss();
        LogData("login_error", err);
        this.userServices.logoutApiCall(err);
      });
  }

}
