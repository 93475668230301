import { Component, Input, OnInit } from '@angular/core';
import { TransactionsDetails } from 'src/app/models/transaction-data';
import { UtilsService } from 'src/app/services/uitils.services';
import { INPUTTYPE_DR } from 'src/app/utils/constant';
import { DR_KEY } from 'src/app/utils/params-data';
import { LogData } from 'src/app/utils/utils';

@Component({
  selector: 'app-transaction-card',
  templateUrl: './transaction-card.component.html',
  styleUrls: ['./transaction-card.component.scss'],
})
export class TransactionCardComponent implements OnInit {

  @Input() transactionData: TransactionsDetails;
  @Input() routerUrl;
  CONST_DATA={
    DR_KEY
  };
  constructor(private utilServices: UtilsService) { }

  ngOnInit() { }

  openListItem(item) {
    LogData("openListItem",item);
    if(item.transactiondetails && item.transactiondetails.length > 2){
      if(item.isExpand){
        item.isExpand=false;
      }else{
        item.isExpand=true;
      }
    }
    
  }

  isDRInputType(item){
    let flag=false;
    if(item.inputtype === INPUTTYPE_DR){
      flag=true;
    }
    return flag;
  }


  isValidFirstData() {
    let flag = false;
    if (this.transactionData && this.transactionData.transactiondetails && this.transactionData.transactiondetails.length > 0
      && this.transactionData.transactiondetails[0].ledger_id && this.transactionData.transactiondetails[0].ledger_id.length > 0) {
        flag=true;
    }
    return flag;
  }

  isValidSecondData() {
    let flag = false;
    if (this.transactionData && this.transactionData.transactiondetails && this.transactionData.transactiondetails.length === 2
      && this.transactionData.transactiondetails[1].ledger_id && this.transactionData.transactiondetails[1].ledger_id.length > 0) {
        flag=true;
    }
    return flag;
  }

  openEditPage() {
    LogData("transactionData", this.transactionData);
    LogData("routerUrl..",this.routerUrl);
    this.utilServices.clearTransactionData();
    if(this.transactionData.edit_allow === 1){
      this.utilServices.setNavigationRoute("edit-" + this.routerUrl + "/" + this.transactionData.id);
    }
    
  }

}
