<ion-item-divider sticky="fixed" class="ion-no-border mt-16 search-ui">
  <!-- <ion-row class="ml-16">
    <ion-col [attrSizeXl]="'12'" appSizeAttribute>
      <div class="flex flex-row ion-align-items-center">
        <ion-icon [src]="'geo_sc' | getImagePipe"></ion-icon
        ><ion-text class="ml-8 lbl-sec-12">Bavla,Ahmedabad,Gujrat</ion-text>
      </div>
    </ion-col>
  </ion-row> -->
  <div *ngIf="isBackBtn">
    <ion-icon (click)="onBack()" class="avt-24" [src]="'ic_back' | getImagePipe"></ion-icon>
    </div>
  <ion-row class="center-row w-100 search-pt">
    <ion-col
      style="align-self: center"
      class="no-margin-padding"
      [attrSizeMd]="'6'" [attrSizeLg]="'6'"
      [attrSizeXl]="colSize"
      appSizeAttribute
    >
    <!-- temp comment -->
      <div
      (click)="openLoaction()"
        class="flex flex-row ion-align-items-center mb-8 ml-8 cursor-ptr"
        *ngIf="isGeolocation"
      >
        <ion-icon [src]="'geo_sc' | getImagePipe"></ion-icon
        ><ion-text class="ml-8 lbl-sec-12">{{displayAddresStr ? displayAddresStr:'No location yet'}}</ion-text>
      </div>
      
      <div *ngIf="isBigTitle" class="pl-8 pb-8">
        <ion-text class="lbl-bold-22 lh-32">
          {{'txt_explore_demanded_bus' | getLanguagePipe}}
        </ion-text>
      </div>
      <ion-row class="w-100">
        <ion-col
          [size]="isFilter ? '10' : '12'"
          [sizeXl]="isFilter ? '10.8' : '12'"
        >
          <div class="flex flex-row">
            <div class="search-input w-100">
              <ion-row class="pl-12 pt-2">
                <ion-col
                  size="1.5"
                  [sizeXl]="isFilter ? '0.5' : '1.5'"
                  class="ion-align-self-center p-0"
                >
                  <ion-icon
                    [src]="'ic_search' | getImagePipe"
                    class="ft-16"
                  ></ion-icon>
                </ion-col>
                <ion-col
                  size="10.5"
                  [sizeXl]="isFilter ? '11.5' : '10.5'"
                  class="p-0"
                >
                  <!-- (ionChange)="onChangeInputValue($event)" -->
                  <!-- <ion-label>test:{{isPageRefresh}}</ion-label> -->
                  <ion-input
                    class="lbl-med-12"
                    style="--padding-start: 0px"
                    [(ngModel)]="searchData"
                    [placeholder]="placeHolderText | getLanguagePipe"
                    [ngModelOptions]="{ standalone: true }"
                    (debouncedModelChange)="onChangeInputValue($event)"
                    debounced-input
                  >
                  </ion-input>
                </ion-col>
              </ion-row>
            </div>
          </div>
          <app-auto-suggest *ngIf="isAutoSuggestion && isDisplaySuggestion" [dataList]="items" 
          (onItemClick)="selectSuggestion($event)"></app-auto-suggest>
        </ion-col>
        <ion-col size="2" sizeXl="1.2" *ngIf="isFilter">
          <div class="sort-div cursor-ptr" (click)="openSortFilter()">
            <ion-icon [src]="'ic_sort' | getImagePipe" class="ft-20"></ion-icon>
            <!-- <ion-img [src]="'ic_sort' | getImagePipe:2" class="avt-20"></ion-img> -->
          </div>
        </ion-col>
      </ion-row>
    </ion-col>
  </ion-row>
</ion-item-divider>
