import { Component, Input, OnInit } from '@angular/core';
import { WebhomepageTestimonial } from 'src/app/models/home-data';

@Component({
  selector: 'app-user-say',
  templateUrl: './user-say.component.html',
  styleUrls: ['./user-say.component.scss'],
})
export class UserSayComponent implements OnInit {

  @Input() userSay:WebhomepageTestimonial;
  constructor() { }

  ngOnInit() {}

}
