<div class="wallet-card">
  <ion-item class="item-0" lines="none">
    <ion-thumbnail class="ion-align-self-start">
      <ion-img [src]="'wallet_transfer' | getImagePipe"></ion-img>
    </ion-thumbnail>
    <div class="flex flex-col ml-8">
      <ion-text class="date-lbl">15th August 2022
      </ion-text>
      <ion-text class="lbl-med-14">
        Vijay Dinanath Chauhan
      </ion-text>
      <ion-text class="code-lbl">
        AEGTDBTEQWER
      </ion-text>
      <ion-text class="status-lbl mt-4">Trasaction Proccess</ion-text>
    </div>
    <ion-text slot="end" class="amt-lbl ml-4 mt-0 mb-0">&#8377;1,580.00</ion-text>
  </ion-item>
</div>