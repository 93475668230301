<!-- 
<div class="main-bg cursor-ptr">
  <div class="dbs-card">
    <div class="flex flex-col m-4">
      <div class="container">
        <ion-img [src]="'db_img' | getImagePipe:2"></ion-img>
        <div class="top-right flex flex-row ion-align-items-center dealer-bg">
          <ion-icon [src]="'bag_bl' | getImagePipe"></ion-icon>
          <ion-text class="ml-4 dmb-tt">Dealer</ion-text>
        </div>
      </div>
    </div>
    <div class="p-12">
      <ion-text class="dmb-title ion-text-start">Vikas Service</ion-text>
      <ion-item class="item-0 mt-8" lines="none">
        <div class="rate-bg">4.5</div>
       <div class="ml-12"> <ionic5-star-rating
        #rating
        activeIcon="star"
        defaultIcon="star-outline"
        activeColor="#5BCCF6"
        defaultColor="#BBBBBB"
        [rating]="4.5"
        fontSize="15px"
        readonly="true"
        halfStar="true"
      >
      </ionic5-star-rating></div>
      </ion-item>
      <ion-item class="item-0 mt-8" lines="none">
        <ion-icon [src]="'location_grd' |getImagePipe"></ion-icon>
        <ion-text class="ml-12 dmb-subtitle">Bavla, Ahmedabad.</ion-text>
        <div class="show-btn" slot="end">Show</div>
      </ion-item>
      <ion-item class="item-0 mt-8" lines="none">
        <ion-icon [src]="'call_grd' |getImagePipe"></ion-icon>
        <ion-text class="ml-12 dmb-subtitle">+91 XXXXX XXX99.</ion-text>
        <div class="show-btn" slot="end">Show</div>
      </ion-item>
      <ion-item class="item-0 mt-8" lines="none">
        <ion-icon [src]="'trading_grd' |getImagePipe"></ion-icon>
        <ion-text class="ml-12 mlines-1 dmb-subtitle">Septic Tank Celaning Service, Cle</ion-text>
      </ion-item>
    </div>
  </div>
  
</div> -->


<div class="main-bg cursor-ptr" (click)="onclickBusiness();$event.stopPropagation();">
  <div class="dbs-card">
    <div class="flex flex-col m-4">
      <div class="container">
        <!-- <ion-img [src]="'db_img' | getImagePipe:2"></ion-img> -->
        <div class="top-img">
          <img [src]="utilsService.getCoverImageDisplay(businessDetail)"  class="img-max round-8" /> 
        </div>
       
        <div class="top-right flex flex-row ion-align-items-center dealer-bg" *ngIf="businessDetail.businesslist_id && businessDetail.businesslist_id.length > 0">
          <ion-icon [src]="'bag_bl' | getImagePipe"></ion-icon>
          <ion-text class="ml-4 dmb-tt">{{businessDetail.businesslist_id[0].text}}</ion-text>
        </div>
      </div>
    </div>
    <div class="p-12">
      <ion-text class="dmb-title ion-text-start" *ngIf="businessDetail.business_name">{{businessDetail.business_name}}</ion-text>
      <ion-item class="item-0 mt-8" lines="none">
        <div class="rate-bg">{{businessDetail.rating}}</div>
       <div class="ml-12"> <ionic5-star-rating
        #rating
        activeIcon="star"
        defaultIcon="star-outline"
        activeColor="#5BCCF6"
        defaultColor="#BBBBBB"
        [rating]="businessDetail.rating ? businessDetail.rating:-1"
        fontSize="15px"
        readonly="true"
        halfStar="true"
      >
      </ionic5-star-rating></div>
      </ion-item>
      <ion-item class="item-0 mt-8" lines="none">
        <ion-icon [src]="'location_grd' |getImagePipe"></ion-icon>
        <ion-text class="ml-12 dmb-subtitle  mlines-1">
            <span
              *ngIf="businessDetail.taluka_id && businessDetail.taluka_id.length > 0">{{businessDetail.taluka_id[0].text}},&nbsp;</span>
            <span
              *ngIf="businessDetail.district_id && businessDetail.district_id.length > 0">{{businessDetail.district_id[0].text}}</span>
        </ion-text>
        <!-- <div class="show-btn" slot="end">Show</div> -->
      </ion-item>
      <ion-item class="item-0 mt-8" lines="none" *ngIf="businessDetail.phone">
        <ion-icon [src]="'call_grd' |getImagePipe"></ion-icon>
        <a href="tel:{{businessDetail.phone}}">
          <ion-text class="ml-12 dmb-subtitle cursor-ptr ml-12 dmb-subtitle">{{businessDetail.phone}}</ion-text>
        </a>
        <!-- <ion-text class="ml-12 dmb-subtitle cursor-ptr" *ngIf="!authServices.getUserLoginData()">{{businessDetail.phone |
          hiddenNumberPipe}}</ion-text> -->
        <!-- <ion-text class="ml-12 dmb-subtitle">+91 XXXXX XXX99.</ion-text> -->
        <!-- <div class="show-btn" slot="end">Show</div> -->
      </ion-item>
      <ion-item class="item-0 mt-8" lines="none" *ngIf="businessDetail.tradingtype_only_id && businessDetail.tradingtype_only_id.length > 0">
        <ion-icon [src]="'trading_grd' |getImagePipe"></ion-icon>
        <ion-text class="ml-12 mlines-1 dmb-subtitle">{{ businessDetail.tradingtype_only_id | arrayToStringPipe}}</ion-text>
      </ion-item>
    </div>
  </div>
  
</div>