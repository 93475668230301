export const environment = {
  production: true,
  appVersion: "5.0.0",
  tokenFlag: 0, // 0 means header with token,1 means url with token,2 means both

  //public
  // apiUrl: "http://103.140.191.31/amarucity-api/api/",
  // domainUrl: "http://103.140.191.31/amarucity-api/",
  // paymentRedirectionUrl: "http://103.140.191.31:8100/#/login",
  // displayUrl:"103.140.191.31:8100"

  //Local
  // apiUrl:"http://192.168.1.100/amarucity-api/api/",
  // domainUrl:"http://192.168.1.100/amarucity-api/",
  // paymentRedirectionUrl: "http://192.168.1.110:8100/#/login",
  // displayUrl:"192.168.1.110:8100",
  // socketUrl: "http://192.168.1.100:3000/",

  //  BK
  // apiUrl: "http://192.168.1.103/amarucity-api/api/",
  // domainUrl: "http://192.168.1.103/amarucity-api/",

  // //demo url
  apiUrl: "https://www.demo.amarucity.com/api/",
  domainUrl: "https://www.demo.amarucity.com/",
  paymentRedirectionUrl: "https://www.web.amarucity.com/#/login",
  displayUrl: "www.web.amarucity.com",
  socketUrl: "https://www.demo.amarucity.com:3001/",

  // // Live url
  // apiUrl:"https://www.api.amarucity.com/api/",
  // domainUrl:"https://www.api.amarucity.com/",
  // paymentRedirectionUrl:"https://www.amarucity.com/#/login",
  // displayUrl:"www.amarucity.com"
  //demo
  firebaseConfig: {
    apiKey: "AIzaSyBYRAtiHGGLCOo8OV78r-AWyeQC-5tZ_3U",
    authDomain: "amarucity-demo.firebaseapp.com",
    projectId: "amarucity-demo",
    storageBucket: "amarucity-demo.appspot.com",
    messagingSenderId: "368403045726",
    appId: "1:368403045726:web:fc359373f744d6173583cf",
    measurementId: "G-8ZPFZ3C517",
  },
};
