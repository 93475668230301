import { Pipe, PipeTransform } from '@angular/core';
import { DR_KEY } from '../utils/params-data';

@Pipe({
  name: 'crdrDisplayPipe'
})
export class CRDRDisplayPipe implements PipeTransform {

  transform(inputtype): any {
   return inputtype ? (inputtype === DR_KEY ? 'lbl_dr':'lbl_cr'):"";
  }
}
