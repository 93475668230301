<ion-row>
  <ion-col class="ion-text-center">
    <div class="flex flex-col cursor-ptr" [ngClass]="isQrcode ? ' pm-div-sel':' pm-div'" (click)="selectPayment(1)">
      <ion-img class="h-p-56 mt-4" [src]="'ic_qc' | getImagePipe"></ion-img>
      <ion-text class="lbl-med-11 mt-12 h-p-20 lh-12">{{'lbl_us_qr' | getLanguagePipe}}</ion-text>
    </div>
  </ion-col>
  <ion-col class="ion-text-center">
    <div class="flex flex-col cursor-ptr" [ngClass]="isPayment ? ' pm-div-sel':' pm-div'" (click)="selectPayment(2)">
      <ion-img class="h-p-56 mt-4" [src]="'ic_op' | getImagePipe"></ion-img>
      <ion-text class="lbl-med-11 mt-12 h-p-20 lh-12">{{'lbl_us_online' | getLanguagePipe}}</ion-text>
    </div>
  </ion-col>
  <ion-col class="ion-text-center">
    <div class="flex flex-col cursor-ptr" [ngClass]="isWallet ? ' pm-div-sel':' pm-div'" (click)="selectPayment(3)">
      <ion-img class="h-p-56 mt-4" [src]="'ic_pw' | getImagePipe"></ion-img>
      <ion-text class="lbl-med-11 mt-12 h-p-20 lh-12">{{'lbl_us_wall' | getLanguagePipe}}</ion-text>
    </div>
  </ion-col>
</ion-row>