import { Component, Input, OnInit } from "@angular/core";
import { CommonData } from "src/app/models/common-data";

@Component({
  selector: "app-stepper",
  templateUrl: "./stepper.component.html",
  styleUrls: ["./stepper.component.scss"],
})
export class StepperComponent implements OnInit {
  @Input() slides: CommonData[];
  @Input() currentSlide: CommonData;
  constructor() {}

  ngOnInit() {}
}
