import { Component, Input, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { UserprofileData } from 'src/app/models/user-profile-data';
import { UtilsService } from 'src/app/services/uitils.services';
import { YES } from 'src/app/utils/constant';
import { BUSINESS_INFORMATION } from 'src/app/utils/routes';

@Component({
  selector: 'app-after-convert',
  templateUrl: './after-convert.component.html',
  styleUrls: ['./after-convert.component.scss'],
})
export class AfterConvertComponent implements OnInit {

  @Input() msg;
  @Input() icon;
  @Input() isTranslateUsed:boolean=true;
  @Input() userprofileData: UserprofileData;
  constructor(private popCtrl:PopoverController,
    private utilsService:UtilsService) { }

  ngOnInit() {}

  isBusinessName(){
    return (this.userprofileData && this.userprofileData.business_name) ? true:false;
  }

  isBusinesslistId(){
    return (this.userprofileData && this.userprofileData.businesslist_id && 
      this.userprofileData.businesslist_id.length > 0) ? true:false;
  }

  openBusinessInfo(){
    this.popCtrl.dismiss(1);
  }

  openBusinessType(){
    this.popCtrl.dismiss(2);
  }

  closeModal(ev){
    this.popCtrl.dismiss();
  }

}

