

<ion-content>
  <ion-row class="center-row mt-16">
  <ion-col [attrSizeXl]="'5'"  appSizeAttribute>
    <div class="flex flex-col ion-text-center ion-justify-content-center h-100">
      <ion-img [src]="(isError ? 'error':'success') | getImagePipe"  class="img-pad"></ion-img>
      <ion-text class="lbl-bold-25 lh-40 mt-24">{{isError ? 'Error':'Success!'}}</ion-text>
      <ion-text class="lbl-13 lh-20 mt-24 mb-24">{{msg}}</ion-text>
    </div>
    </ion-col>
  </ion-row>
  <ion-row class="center-row pl-16 pr-16 pb-16" *ngIf="!isButtonHide">
    <ion-col size="12" [attrSizeXl]="'5'"  class="ion-no-padding" appSizeAttribute>
      <app-ui-cmp *ngIf="!isError" [title]="'Let’s Login'" (buttonClicked)="backToLogin($event)"></app-ui-cmp>
      <app-ui-cmp *ngIf="isError" [title]="'Try Again'" (buttonClicked)="tryAgain($event)"></app-ui-cmp>
    </ion-col>
  </ion-row>
</ion-content>