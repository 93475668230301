import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoadingController } from '@ionic/angular';
import { finalize } from 'rxjs/operators';
import { BusinessDirectoryDetails, RatingGiven } from 'src/app/models/business-director-data';
import { AuthServices } from 'src/app/services/auth.services';
import { UtilsService } from 'src/app/services/uitils.services';
import { UserServices } from 'src/app/services/user.services';
import { MODEL_ID_KEY, MODEL_KEY, RATING_KEY, REVIEW_KEY, USERPROFILES_MODEL_KEY } from 'src/app/utils/params-data';
import { LOGIN_ROUTE } from 'src/app/utils/routes';
import { LogData } from 'src/app/utils/utils';
import { ValidationMessages } from 'src/app/validators/validations';

@Component({
  selector: 'app-rating-card',
  templateUrl: './rating-card.component.html',
  styleUrls: ['./rating-card.component.scss'],
})
export class RatingCardComponent implements OnInit {

  name;
  rating=2;
  isFormSubmit: boolean = false;
  validationMessages=ValidationMessages;
  commonForm: FormGroup;
  ratingGiven:RatingGiven;
  @Input() businessDetail: BusinessDirectoryDetails;
  constructor(private loadingCtrl: LoadingController,
    private utilsService: UtilsService,
    private userServices: UserServices,
    public authServices: AuthServices,
    private formBuilder: FormBuilder) { }

  ngOnInit() { 
    this.commonForm = this.formBuilder.group({
      // set default initial value
      rating: ['',Validators.required]
  });
  if(this.businessDetail && this.businessDetail.rating_given){
    this.ratingGiven=this.businessDetail.rating_given;
    this.commonForm.patchValue({rating:this.ratingGiven.rating});
  }

  }

  submit(ev) {
    this.isFormSubmit = true;
    LogData("rating..",this.commonForm.value);
    if (this.commonForm.value.rating) {
      LogData("API_CALL");
      this.addRating();
      // this.clearRating();
    }else{
      LogData("INVALID");
    }

  }

  openLogin(){
    this.utilsService.setNavigationRoute(LOGIN_ROUTE);
  }

  clearRating(){
    // this.rating=null;
    this.commonForm.patchValue({rating:''});
    this.isFormSubmit=false;
   
    // this.isValid=false;
  }

  onRatingChange(ev) {
    LogData("rating..", ev);
    // this.rating=ev;
  }

  async addRating() {
    const loading = await this.loadingCtrl.create(
      this.utilsService.getLoaderUI()
    );
    await loading.present();
    LogData("loader_start");
    const formData = new FormData();
    formData.append(RATING_KEY, '' +this.commonForm.value.rating);
    // formData.append(REVIEW_KEY,'');
    formData.append(MODEL_ID_KEY, '' + this.businessDetail.id);
    formData.append(MODEL_KEY, USERPROFILES_MODEL_KEY);
    this.userServices.addRating(formData, this.authServices.getUserToken())
      .pipe(
        finalize(() => {
          LogData("loader_dismiss");
          loading.dismiss();
        })
      )
      .subscribe(
        (res) => {
          LogData("addRating_res", res);
          res = this.utilsService.getDecriptReasponse(res);
          if (res) {
            
            if(res.result && res.result.responce && res.result.responce.Ratings){
              this.ratingGiven=res.result.responce.Ratings;
              LogData("ratingGiven",this.ratingGiven);
            }else{
              this.clearRating();
            }
            this.utilsService.openForSuccessError(false, res.result.success);
          }
        },
        (err) => {
          LogData("loader_dismiss");
          loading.dismiss();
          this.clearRating();
          this.userServices.logoutApiCall(err);
        }
      );
  }

}
