import { Pipe, PipeTransform } from '@angular/core';
import { UserDocument } from '../models/user-profile-data';
@Pipe({
    name: 'getServerImgDisplay'
})
export class GetServerImgDisplay implements PipeTransform {

    constructor() { }
    transform(image: UserDocument,imgKey,isMain?): any {
        return image.webPath ? image.webPath:image.display_files && (isMain ? image.display_files[imgKey].main_url :image.display_files[imgKey].square_url);
    }
}
