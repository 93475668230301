import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FilterUtilsService {

  constructor() { }

  filterItemsByName(searchItems,searchTerm) {
    return searchItems.filter(item => {
      return item.name.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1;
    });
  }

}
