import { Component, Input, OnInit } from '@angular/core';
import { CommonData } from 'src/app/models/common-data';
import { UtilsService } from 'src/app/services/uitils.services';

@Component({
  selector: 'app-web-category',
  templateUrl: './web-category.component.html',
  styleUrls: ['./web-category.component.scss'],
})
export class WebCategoryComponent implements OnInit {
  @Input() isLast;
  @Input() categoryData:CommonData;
  constructor(public utilservices:UtilsService) {}

  ngOnInit() {}

  
}

