import { Component, HostListener, NgZone, OnDestroy, OnInit } from "@angular/core";
import {
  MenuController,
  ModalController,
  Platform,
  PopoverController,
} from "@ionic/angular";
import { CommonData } from "src/app/models/common-data";
import { MenuDetail, SideMenu } from "src/app/models/side-menu";
import { AuthServices } from "src/app/services/auth.services";
import { UserServices } from "src/app/services/user.services";
import { UtilsService } from "src/app/services/uitils.services";
import {
  ADMIN_ROLE_ID,
  BUSINESS_ROLE_ID,
  CUSTOMER_ROLE_ID,
  DEALER_ROLE_ID,
  EMPLOYEE_ROLL_ID,
  MALE_GN,
  PAGE_SIDE_MENU,
  STAFFS_ROLL_ID,
  SUPER_ADMIN_ROLE_ID,
  YES,
} from "src/app/utils/constant";
import {
  ADMIN_PROFILE_ROUTE,
  LANGUAGE,
  LOGIN_ROUTE,
  RECEIVED_PAYMENTS,
  TAB2_ROUTE,
} from "src/app/utils/routes";
import { LogData } from "src/app/utils/utils";
import { ConfirmationComponent } from "../confirmation/confirmation.component";
import { Observable, Subscription } from "rxjs";
import { CurrentUserData } from "src/app/models/user-data";
import {
  UserDocument,
  UserprofileData,
} from "src/app/models/user-profile-data";
import { ImageviewerComponent } from "../imageviewer/imageviewer.component";
import { modalEnterAnimation } from "src/app/utils/enterAnimation";
import { OtherSettings } from "src/app/models/other-settings";
import { finalize } from "rxjs/operators";
import { AdminMenuData } from "src/app/models/app-menu/admin-menu-data";
import { DealerMenuData } from "src/app/models/app-menu/dealer-menu-data";
import { StaffMenuData } from "src/app/models/app-menu/staff-menu-data";
import { BusinessMenuData } from "src/app/models/app-menu/business-menu-data";
import { CustomerMenuData } from "src/app/models/app-menu/customer-menu-data";
import { EmployeeMenuData } from "src/app/models/app-menu/employee-menu-data";
import { WithoutLoginMenuDataForMobile } from "src/app/models/app-menu/without-login-menu-data-mobile";
import { environment } from "src/environments/environment";
import { INQUIRIES_MC } from '../../models/app-menu/menu-code';

@Component({
  selector: "app-side-menu",
  templateUrl: "./side-menu.component.html",
  styleUrls: ["./side-menu.component.scss"],
})
export class SideMenuComponent implements OnInit, OnDestroy {
  sideMenu: Array<SideMenu> = [];
  isLogoutClick: boolean = false;
  updateSideMenu$: Observable<boolean>;
  updateSideMenuSubscription: Subscription;
  userProfileData: UserprofileData;
  userProfileImg: UserDocument;
  isBusinessUser: boolean = false;
  CONST_DATA = {
    MALE_GN, RECEIVED_PAYMENTS
  };
  selectedLanguage: CommonData;
  platformWidth;
  otherSettings: OtherSettings;
  appVersion=environment.appVersion;
  counterDisplay=0;
  socialResponse$: Observable<boolean>;
  socialResponseSubscription: Subscription;
  constructor(
    public utilsService: UtilsService,
    private popCtrl: PopoverController,
    public authServices: AuthServices,
    private userServices: UserServices,
    private menuCtrl: MenuController,
    private modalCtrl: ModalController,
    private ngZone: NgZone,
    private plt: Platform
  ) {
   
    this.subScriptionCall();
  }

  ngOnInit() {
    
    this.platformWidth = this.plt.width();
    // this.toggleMenu(this.platformWidth);
    // this.userServices.getUserProfileUpdate();
    LogData("setMenu_init");
    this.initFuncnality();

  }
  initFuncnality() {
    this.setMenu();
    this.setCurrentUserData();
    this.initLanguage();
  }

  // isMenuEnable() {
  //   // const rollId = this.authServices.getUserRoll();
  //   let flag = false;
  //   if (this.isAdmin()) {
  //     flag = true;
  //   } else if (this.platformWidth < 768) {
  //     flag = true;
  //   } else {
  //     flag = false;
  //   }
  //   return flag;
  // }

  isMenuEnable() {
    // const rollId = this.authServices.getUserRoll();
    let flag = false;
    if (this.authServices.getUserLoginData()) {
      flag = true;
    } else {
      if (this.platformWidth < 768) {
        flag = true;
      }
    }
    return flag;
  }

  toggleMenu(width) {
    // const rollId = this.authServices.getUserRoll();
    if (width > 768) {
      if (this.isAdmin) {
        this.menuCtrl.enable(false, 'main-content');
      } else {
        this.menuCtrl.enable(true, 'main-content');
      }

    } else {
      this.menuCtrl.enable(true, 'main-content');
    }
  }

  isAdmin() {
    const rollId = this.authServices.getUserRoll();
    return rollId && (rollId === ADMIN_ROLE_ID || rollId === DEALER_ROLE_ID || rollId === STAFFS_ROLL_ID) ? true : false;
  }

  @HostListener('window:resize', ['$event'])
  private onResize(event) {
    this.platformWidth = event.target.innerWidth;
    // this.toggleMenu(this.platformWidth)
  }

  initLanguage() {
    if (this.authServices.getLanguage()) {
      this.selectedLanguage = this.authServices.getLanguage();
    }
  }

  async openImageViewrComponent() {
    if (
      this.userProfileImg &&
      this.userProfileImg.display_files.userprofiles_img &&
      this.userProfileImg.display_files.userprofiles_img.main_url
    ) {
      const modal = await this.modalCtrl.create({
        component: ImageviewerComponent,
        componentProps: {
          images: this.userProfileImg.display_files.userprofiles_img.main_url,
        },
        enterAnimation: modalEnterAnimation,
      });

      modal.onDidDismiss().then((data) => {
        LogData("imageviewr.ondismiss", data);
        if (data.data != null) {
        }
      });

      await modal.present();
    }
  }

  isDisplaycard() {
    return (this.isBusinessUser && this.otherSettings && this.otherSettings.business_active !== 1) ? true : false;
  }

  getCounterDisplay(item){
    let counter=0;
    if(item.menuCode === INQUIRIES_MC){
      counter=(this.otherSettings.CompInq && this.otherSettings.CompInq[1] && 
      this.otherSettings.CompInq.Inquiries[1].total_count)?this.otherSettings.CompInq.Inquiries[1].total_count:0;
    }
    return counter;
  }

  setCurrentUserData() {
    if (this.authServices.getUserLoginData()) {
      this.isBusinessUser =
        this.authServices.getUserRoll() === BUSINESS_ROLE_ID ? true : false;
      const currentuser = this.authServices.getUserLoginData();
      if (this.authServices.getUserOtherSettingsData()) {
        this.otherSettings = this.authServices.getUserOtherSettingsData();
        LogData("otherSettings...", this.otherSettings);
      }
      LogData("currentuser...", currentuser);
      this.userProfileData = currentuser.userprofile;
      if (this.userProfileData && this.userProfileData.documents) {
        if (this.userProfileData.documents.length > 0) {
          this.userProfileImg = this.utilsService.getUserProfileImg(
            this.userProfileData.documents
          );
        }
      }

    }else{
      this.userProfileData=null;
      this.otherSettings=null;
    }
  }

  subScriptionCall() {
    //when onDismiss
    this.updateSideMenu$ = this.utilsService.updateSideMenu$;
    this.updateSideMenuSubscription = this.updateSideMenu$.subscribe(
      (value) => {
        LogData("updateSideMenuSubscription....call", value);
        if (value) {
          this.setMenu();
          this.setCurrentUserData();
          this.initLanguage();
          this.getUserProfileUpdate();
        }
      }
    );

    //Read unread api call
    this.socialResponse$ = this.utilsService.socialResponse$;
    this.socialResponseSubscription = this.socialResponse$.subscribe(
      (value: any) => {
        LogData("socialResponseSubscription...call", value);
        if (value && value.page === PAGE_SIDE_MENU) {
          // this.counterDisplay=
        }
      }
    );
  }
  setMenu() {


    if (this.authServices.getUserLoginData()) {
      const rollId = this.authServices.getUserRoll();
      LogData("sideMenu_rollid", rollId);
      let permissionmenu = [];
      switch (rollId) {
        case SUPER_ADMIN_ROLE_ID:
        case ADMIN_ROLE_ID:
          const admin_menu: Array<SideMenu> = AdminMenuData;
          admin_menu.forEach((element) => {
            element.isChecked = false;

          });
          this.sideMenu = this.getAllPermissionMenu(admin_menu);
          setTimeout(() => {
            this.subMenuInnerMenuHide();
          }, 1000);

          break;
        case DEALER_ROLE_ID:
          const dealer_menu: Array<SideMenu> = DealerMenuData;
          dealer_menu.forEach((element) => {
            element.isChecked = false;

          });
          this.sideMenu = this.getAllPermissionMenu(dealer_menu);
          setTimeout(() => {
            this.subMenuInnerMenuHide();
          }, 1000);

          break;
        case STAFFS_ROLL_ID:
          const staff_menu: Array<SideMenu> = StaffMenuData;
          staff_menu.forEach((element) => {
            element.isChecked = false;

          });
          this.sideMenu = this.getAllPermissionMenu(staff_menu);
          setTimeout(() => {
            this.subMenuInnerMenuHide();
          }, 1000);

          break;
        case EMPLOYEE_ROLL_ID:
          const emp_menu: Array<SideMenu> = EmployeeMenuData;
          emp_menu.forEach((element) => {
            element.isChecked = false;

          });
          this.sideMenu = this.getAllPermissionMenu(emp_menu);
          setTimeout(() => {
            this.subMenuInnerMenuHide();
          }, 1000);

          break;
        case BUSINESS_ROLE_ID:
          const business__menu: Array<CommonData> = BusinessMenuData;
          business__menu.forEach((element) => {
            element.isChecked = false;
          });
          this.sideMenu = this.getAllPermissionMenu(business__menu);
          setTimeout(() => {
            this.subMenuInnerMenuHide();
          }, 1000);
          break;
        case CUSTOMER_ROLE_ID:
          const cst_menu: Array<CommonData> = CustomerMenuData;
          cst_menu.forEach((element) => {
            element.isChecked = false;
          });
          this.sideMenu = this.getAllPermissionMenu(cst_menu);
          break;
      }
    } else {
      const without_login_menu: Array<CommonData> =
        WithoutLoginMenuDataForMobile;
      without_login_menu.forEach((element) => {
        element.isChecked = false;
      });
      this.sideMenu = without_login_menu;
    }
  }

  getAllPermissionMenu(side_menu: Array<SideMenu>) {
    const allPermissions: Array<CommonData> = this.authServices.getAllowPermission();
    LogData("Allowpermission_menu", allPermissions);
    if (allPermissions.length > 0) {
      side_menu.forEach(mainMenu => {

        // menu code for main menu
        if (mainMenu.menuCode) {
          const findMain = allPermissions.find((element) => element.value === mainMenu.menuCode);
          if (!findMain) {
            mainMenu.isHideMenu = true;
          } else {
            mainMenu.isHideMenu = false;
          }
        }

        //menu code for submenu
        if (mainMenu.subMenu && mainMenu.subMenu.length > 0) {
          mainMenu.subMenu.forEach(subMenu => {

            if (subMenu.menuCode) {
              const findSub = allPermissions.find((element) => element.value === subMenu.menuCode);
              if (!findSub) {
                subMenu.isHideMenu = true;
              } else {
                subMenu.isHideMenu = false;
              }
            }

            //menu code for inner submenu
            if (subMenu.innerSubMenu && subMenu.innerSubMenu.length > 0) {
              subMenu.innerSubMenu.forEach(innerSubMenu => {
                if (innerSubMenu.menuCode) {
                  const findInnerSub = allPermissions.find((element) => element.value === innerSubMenu.menuCode);
                  if (!findInnerSub) {
                    innerSubMenu.isHideMenu = true;
                  } else {
                    innerSubMenu.isHideMenu = false;
                  }
                }
              });
            }

          });
        }

      });
    } else {
      side_menu = this.getMenuHideNotAllowPermission(side_menu);
    }
    return side_menu;
  }

  getMenuHideNotAllowPermission(side_menu) {
    side_menu.forEach(mainMenu => {

      // menu code for main menu
      if (mainMenu.menuCode) {
        mainMenu.isHideMenu = true;
      }

      //menu code for submenu
      if (mainMenu.subMenu && mainMenu.subMenu.length > 0) {
        mainMenu.subMenu.forEach(subMenu => {

          if (subMenu.menuCode) {
            subMenu.isHideMenu = true;
          }

          //menu code for inner submenu
          if (subMenu.innerSubMenu && subMenu.innerSubMenu.length > 0) {
            subMenu.innerSubMenu.forEach(innerSubMenu => {
              if (innerSubMenu.menuCode) {
                innerSubMenu.isHideMenu = true;
              }

            });
          }

        });
      }

    });
    return side_menu;
  }

  subMenuInnerMenuHide() {
    this.sideMenu.forEach(menu => {
      if (menu.subMenu && menu.subMenu.length > 0) {
        let subMenulen = menu.subMenu.length;
        menu.subMenu.forEach(subEl => {
          if (subEl.innerSubMenu && subEl.innerSubMenu.length > 0) {
            const isHide = subEl.innerSubMenu.find((innerEl) => !innerEl.isHideMenu);
            if (!isHide) {
              menu.isHideMenu = true;
            }
          } else {
            // const isHide=menu.subMenu.find((sub)=> !sub.isHideMenu);
            // if(!isHide){
            //   menu.isHideMenu=true;
            // }
            if (subEl.isHideMenu) {
              subMenulen--;
            }
          }
          if (subMenulen === 0) {
            menu.isHideMenu = true;
          }
        });
      }
    });

  }

  onItemClick(item?) {
    LogData("onItemClick", item);
    this.isLogoutClick = false;
    item.isChecked = true;
    if (item.isExpand) {
      item.isExpand = false;
    } else {
      item.isExpand = true;
    }
    this.sideMenu.forEach((element) => {
      if (item.id !== element.id) {
        element.isChecked = false;
      }
      if (element.subMenu && element.subMenu.length > 0) {
        element.subMenu.forEach((submenu) => {
          if (item.id !== submenu.id) {
            submenu.isChecked = false;
          }
          if (submenu.innerSubMenu && submenu.innerSubMenu.length > 0) {
            submenu.innerSubMenu.forEach((innersubmenu) => {
              innersubmenu.isChecked = false;
            });
          }
        });
      }
    });
    if (!item.url && (!item.subMenu || item.subMenu.length === 0)) {
      if(item.isBrowser){
        this.utilsService.openSfariViewForReg()
      }else{
        this.utilsService.presentToast("Comming soon.");
      }
      
    }
  }

  subMenuClick(item) {
    LogData("subMenuClick", item);

    // item.isExpand = true;
    // item.isChecked = true;
    item.isChecked = true;
    if (item.isExpand) {
      item.isExpand = false;
    } else {
      item.isExpand = true;
    }
    // this.sideMenu.forEach((element) => {
    //   if (item.id !== element.id) {
    //     element.isChecked = false;
    //   }
    // });
    this.setMenuChecked(item);
    if (!item.url && (!item.innerSubMenu || item.innerSubMenu.length === 0)) {
      this.utilsService.presentToast("Comming soon.");
    }
  }

  setMenuChecked(item) {
    this.sideMenu.forEach((element) => {
      if (element.subMenu && element.subMenu.length > 0) {
        element.subMenu.forEach((subEl) => {
          subEl.isChecked = false;
          if (subEl.innerSubMenu && subEl.innerSubMenu.length > 0) {
            subEl.innerSubMenu.forEach((inrsubEl) => {
              if (item.id !== inrsubEl.id) {
                inrsubEl.isChecked = false;
              }
            });
          }
        });
      }
    });
  }

  innersubMenuClick(item) {
    LogData("innersubMenuClick", item);
    item.isChecked = true;
    this.setMenuChecked(item);
    if (!item.url) {
      this.utilsService.presentToast("Comming soon.");
    }
  }

  opneLanguage() {
    this.utilsService.setNavigationRoute(LANGUAGE);
  }

  setUnCheckedMenu() {
    this.sideMenu.forEach((element) => {
      element.isChecked = false;
      if (element.subMenu && element.subMenu.length > 0) {
        element.subMenu.forEach((submenu) => {
          submenu.isChecked = false;
        });
      }
    });
  }
  loginClick() {
    this.menuCtrl.close();
    this.utilsService.setNavigationRoute(LOGIN_ROUTE);
  }

  async logoutClick() {
    this.isLogoutClick = true;
    this.setUnCheckedMenu();
    const modal = await this.popCtrl.create({
      component: ConfirmationComponent,
      componentProps: {
        msg: "are_you_sure_you_want_to_logout",
        icon: "ic_logout",
      },
      cssClass: "custom-popover",
      showBackdrop: true,
      backdropDismiss: false,
      // breakpoints: [0, 0.3, 0.5, 0.8],
      // initialBreakpoint: 0.5
    });

    modal.onDidDismiss().then((data) => {
      LogData("onDidDismiss...", data);
      if (data.data != null && data.data === YES) {
        this.menuCtrl.close();
        this.userServices.userLogoutApiCall();
      }
    });

    await modal.present();
  }

  openProfileImage() {
    LogData("openProfileImage...");
    this.menuCtrl.close();
    const rollId = this.authServices.getUserRoll();
    if (rollId) {
      switch (rollId) {
        case CUSTOMER_ROLE_ID:
          this.utilsService.setNavigationRoute(TAB2_ROUTE);
          break;
        case BUSINESS_ROLE_ID:
          this.utilsService.setNavigationRoute(TAB2_ROUTE);
          break;
        case ADMIN_ROLE_ID:
          this.utilsService.setNavigationRoute(ADMIN_PROFILE_ROUTE);
          break;
      }
    }
  }

  async getUserProfileUpdate() {
    if (this.authServices.getUserLoginData()) {
      this.userServices.getUserProfile(
        this.authServices.getForId(),
        this.authServices.getUserToken()
      )
        .pipe(
          finalize(() => {
            LogData("loader_dismiss");
          })
        )
        .subscribe(
          (res) => {
            LogData("getUserProfile_res", res);
            res = this.utilsService.getDecriptReasponse(res);
            if (
              res &&
              res.result &&
              res.result.responce
            ) {
              if (res.result.responce.otherSettings) {
                this.authServices.setUserOtherSettingsData(res.result.responce.otherSettings);
              }
              const userProfileData = res.result.responce.Userprofiles ? res.result.responce.Userprofiles : null;
              const currentUser: CurrentUserData =
                this.authServices.getUserLoginData();
              currentUser.userprofile = userProfileData;
              this.authServices.setUserLoginData(currentUser);
              this.initFuncnality();
            }
          },
          (err) => {
            LogData("loader_dismiss");
            LogData("getUserProfile_error", err);
            this.userServices.logoutApiCall(err);
          }
        );
    }
  }

  ngOnDestroy(): void {
    if (this.updateSideMenuSubscription) {
      this.updateSideMenuSubscription.unsubscribe();
    }
  }
}
