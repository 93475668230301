<div class="round-8 bg-light">
  <ion-item class="item-0 item-trans cursor-ptr" lines="none" (click)="openDoc();$event.stopPropagation()">
    <ion-thumbnail slot="start" class="avt-32">
      <img [src]="getIcon() | getImagePipe" />
    </ion-thumbnail>
    <ion-text class="lbl-12 mlines-2">{{fileName}}</ion-text>
    <ion-icon class="avt-32 ml-4" [src]="'down_ro' | getImagePipe" slot="end" (click)="downloadDoc();$event.stopPropagation()"></ion-icon>
  </ion-item>
 
</div>
<!-- <div class="top-right ml-8">
  <ion-icon class="avt-32" [src]="'down_ro' | getImagePipe" (click)="downloadDoc();$event.stopPropagation()"></ion-icon>
</div> -->
