import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import {
  LoadingController,
  ModalController,
  PickerController,
  PickerOptions,
  PopoverController,
} from "@ionic/angular";
import { finalize } from "rxjs/operators";
import { InputTypeEnum } from "src/app/enums/inputtypes-enum";
import { CommonData } from "src/app/models/common-data";
import { AuthServices } from "src/app/services/auth.services";
import { UtilsService } from "src/app/services/uitils.services";
import { UserServices } from "src/app/services/user.services";
import { GET_TRADINGTYPES_API } from "src/app/utils/api-end-points";
import {
  getBusinesstypesArr,
  getStatusArr,
  PAGE_BUSINESS_LIST,
  PAGE_PRODUCT_COMPNEY_LIST,
  PAGE_PRODUCT_LIST,
  PAGE_TRADING_LIST,
  SELECT_LIST_ITEM,
} from "src/app/utils/constant";
import { GET_ID_KEY } from "src/app/utils/params-data";
import { LogData, LogDataApi } from "src/app/utils/utils";
import { ListPopoverComponent } from "../list-popover/list-popover.component";
import { SingleSearchFilterComponent } from "../single-search-filter/single-search-filter.component";

@Component({
  selector: "app-sort-data",
  templateUrl: "./sort-data.component.html",
  styleUrls: ["./sort-data.component.scss"],
})
export class SortDataComponent implements OnInit {
  isExpand: boolean = false;
  isExpandType: boolean = false;
  @Input() selectedStatus;
  @Input() lbl1;
  @Input() lbl2;
  @Input() lbl3;
  @Input() btn1;
  @Input() btn2;
  statusId;
  selectedType;
  typeId;
  @Input() selectedFilter;
  statusList: Array<CommonData> = [];
  stateList: Array<CommonData> = [];
  businessTypeList = [];
  tradingTypeList: Array<CommonData> = [];
  @Input() isBusinessType: boolean = false;
  @Input() isTradingType: boolean = false;
  @Input() title;
  @Input() pageName;
  commonform: FormGroup;
  isStateLbl: boolean = false;
  @Input() masterStr;
  selected_tradingtype_id;
  inputTypeEnum=InputTypeEnum;
  constructor(
    private popoverCtrl: PopoverController,
    public utilsService: UtilsService,
    private pickerController: PickerController,
    private authServices: AuthServices,
    private userServices: UserServices,
    private loadingController: LoadingController,
    private formBuilder: FormBuilder,
    private modalCtrl:ModalController
  ) { }

  ngOnInit() {
    this.buildForm();
    // this.getStatus();
    this.getMasters();
  }

  closeModal() {
    this.popoverCtrl.dismiss();
  }

  buildForm() {
    this.commonform = this.formBuilder.group({
      businesstype_id: [""],
      tradingtype_id: [],
      tradingtype_id_expand:[false],
      state: [""],
      status: [""],
    });
  }

  onChangeBusinessType(ev) {
    LogData("onChangeBusinessType..", ev);
    const bus_type_id=ev.detail.value;
    if (this.isTradingType) {
      if (bus_type_id) {
        this.tradingTypeList = [];
        this.selected_tradingtype_id='';
        if (this.selectedFilter) {
          this.selected_tradingtype_id=this.selectedFilter.tradingtype_id;
          this.selectedFilter.tradingtype_id = null;
        }
        this.commonform.patchValue({ tradingtype_id: '' });
        LogData("cmmonForm", this.commonform);
        const formData = new FormData();
        formData.append(GET_ID_KEY,bus_type_id);
        this.getTradingTypeApicall(formData);
      } else {
        this.tradingTypeList = [];
      }
    }


  }

  // async openSingleSelection(index, array, title, formControlId: any, formControlExpand: any) {
  //   LogData("array", formControlId);
  //   if (array.length > 0) {
  //     formControlExpand.setValue(true);
  //     const modal = await this.modalCtrl.create({
  //       component: SingleSearchFilterComponent,
  //       componentProps: { title: title, selectedData: array, selectedId: (formControlId && formControlId.value.length > 0)?formControlId.value[0].value:'' },
  //       cssClass: 'custom-modal-filter',
  //       showBackdrop: true,
  //       backdropDismiss: false,
  //       // breakpoints: [0, 0.3, 0.5, 0.8],
  //       // initialBreakpoint: 0.5
  //     });

  //     modal.onDidDismiss().then((data) => {
  //       LogData("onDidDismiss...", data);
  //       LogData("onDidDismiss...idx", index);
  //       if (data.data != null) {
  //         if (data.data !== SELECT_LIST_ITEM) {
  //           const selectedId = data.data.value;
  //           let tempArr = [];
  //           tempArr.push(data.data);
  //           formControlId.setValue(tempArr);
  //           // formControlName.setValue(data.data.text);

  //         } else {
  //           formControlId.setValue([]);
  //         }

  //       }
  //       formControlExpand.setValue(false);
  //     });

  //     await modal.present();
  //   } else {
  //     this.utilsService.presentToastNoMoreData('no_data_found');
  //   }
  // }

  // async openPopover(ev) {
  //   const data = [{ id: 1, name: "Active" }, { id: 2, name: "Inactive" }];
  //   const modal = await this.popCtrl.create({
  //     component: ListPopoverComponent,
  //     componentProps: { data: data, selData: this.selectedFilter },
  //     event: ev,
  //     cssClass: 'custom-popover-3',
  //   });

  //   modal.onDidDismiss().then((data) => {
  //     LogData("onDidDismiss...", data);
  //     if (data && data.data) {
  //       this.selectedFilter = data.data;
  //       this.selectedValue = this.selectedFilter.name
  //     }
  //   });

  //   await modal.present();
  // }

  // async openStatus() {
  //   this.isExpand = true;
  //   let options: PickerOptions = {
  //     buttons: [
  //       {
  //         text: "Cancel",
  //         role: 'cancel'
  //       },
  //       {
  //         text: 'Ok',
  //         handler: (value: any) => {
  //           LogData(value);
  //           this.selectedValue = value.select.value !== 0 ? value.select.text : '';
  //           this.statusId = value.select.value !== 0 ? value.select.value : '';
  //           this.isExpand = false
  //         }
  //       }
  //     ],
  //     columns: [{
  //       name: 'select',
  //       options: this.getColumnOptions(),
  //       selectedIndex: this.utilsService.getIndex(this.statusList, this.statusId, 1)
  //     }]
  //   };
  //   let picker = await this.pickerController.create(options);
  //   picker.present()
  // }

  getColumnOptions() {
    let options = [];
    options.push({ value: 0, text: "Select" });

    this.statusList.forEach((x) => {
      options.push({ text: x.text, value: x.value });
    });
    return options;
  }

  // async openType() {
  //   this.isExpandType = true;
  //   let options: PickerOptions = {
  //     buttons: [
  //       {
  //         text: "Cancel",
  //         role: 'cancel'
  //       },
  //       {
  //         text: 'Ok',
  //         handler: (value: any) => {
  //           LogData(value);
  //           this.selectedType = value.select.value !== 0 ? value.select.text : '';
  //           this.typeId = value.select.value !== 0 ? value.select.value : '';
  //           this.isExpandType = false
  //         }
  //       }
  //     ],
  //     columns: [{
  //       name: 'select',
  //       options: this.getColumnOptionsType(),
  //       selectedIndex: this.utilsService.getIndex(this.typeList, this.typeId,1)
  //     }]
  //   };
  //   let picker = await this.pickerController.create(options);
  //   picker.present()
  // }

  // getColumnOptionsType() {
  //   let options = [];
  //   options.push({ value: 0, text: "Select" });
  //   this.typeList.forEach(x => {
  //     options.push({ text: x.text, value: x.value });
  //   });
  //   return options;
  // }

  apply() {
    this.popoverCtrl.dismiss(this.commonform.value);
  }

  clearAll() {
    if (this.selectedFilter) {
      this.selectedFilter = null;
      this.commonform.patchValue({ status: "", businesstype_id: "", tradingtype_id: "" });
      switch (this.pageName) {
        case PAGE_BUSINESS_LIST:
          this.utilsService.setUpdatedBusinessList({ isClear: true });
          break;
        case PAGE_TRADING_LIST:
          this.utilsService.setUpdatedTradingList({ isClear: true });
          break;
        case PAGE_PRODUCT_LIST:
          this.utilsService.setUpdatedProductList({ isClear: true });
          break;
        case PAGE_PRODUCT_COMPNEY_LIST:
          this.utilsService.setUpdatedProductCompneyList({ isClear: true });
          break;
      }
    } else {
      this.selectedFilter = null;
      this.commonform.patchValue({ status: "", businesstype_id: "" });
    }
    this.popoverCtrl.dismiss();
  }

  async getStatus() {
    const loading = await this.loadingController.create(
      this.utilsService.getLoaderUI()
    );
    await loading.present();
    LogData("loader_start");
    this.userServices
      .getStatus(this.authServices.getUserToken())
      .pipe(
        finalize(() => {
          LogData("loader_dismiss");
          loading.dismiss();
        })
      )
      .subscribe(
        (res) => {
          LogData("getStatus_res", res);
          res=this.utilsService.getDecriptReasponse(res);
          if (
            res &&
            res.result.responce &&
            res.result.responce.getStatusArr &&
            res.result.responce.getStatusArr.length > 0
          ) {
            this.statusList = res.result.responce.getStatusArr;
            if (this.selectedStatus) {
              setTimeout(() => {
                this.commonform.patchValue({ status: this.selectedStatus });
              }, 100);
            }
          } else {
            this.statusList = [];
          }
        },
        (err) => {
          LogData("loader_dismiss");
          loading.dismiss();
          LogData("getStatus_error", err);
          this.userServices.logoutApiCall(err);
        }
      );
  }

  async getMasters() {
    const loading = await this.loadingController.create(
      this.utilsService.getLoaderUI()
    );
    await loading.present();
    LogData("loader_start");
    let list;
    if (this.masterStr) {
      list = this.masterStr;
    } else {
      if (this.isBusinessType) {
        list = getStatusArr + "," + getBusinesstypesArr;
      } else {
        list = getStatusArr;
      }
    }

    const formData = new FormData();
    formData.append("master", list);
    this.userServices
      .getMasters(formData)
      .pipe(
        finalize(() => {
          LogData("loader_dismiss");
          loading.dismiss();
        })
      )
      .subscribe(
        (res) => {
          LogDataApi("getMasters_res....1", res);
          res = this.utilsService.getDecriptReasponse(res);
          if (res && res.status === 1) {
            if (res.result.responce.getBusinesstypesArr) {
              this.businessTypeList = res.result.responce.getBusinesstypesArr;
              if (this.selectedFilter && this.selectedFilter.businesstype_id) {
                setTimeout(() => {
                  this.commonform.patchValue({
                    businesstype_id: this.selectedFilter.businesstype_id,
                  });
                  // const formData = new FormData();
                  // formData.append(GET_ID_KEY, this.selectedFilter.businesstype_id);
                  // this.getTradingTypeApicall(formData);
                }, 100);

              }

            }
            if (res.result.responce.getStatusArr) {
              this.statusList = res.result.responce.getStatusArr;
              if (this.selectedFilter && this.selectedFilter.status) {
                setTimeout(() => {
                  this.commonform.patchValue({
                    status: this.selectedFilter.status,
                  });
                }, 100);
              }
            }
            if (res.result.responce.getStatesArr) {
              this.stateList = res.result.responce.getStatesArr;
              if (this.selectedFilter && this.selectedFilter.state) {
                setTimeout(() => {
                  this.commonform.patchValue({
                    state: this.selectedFilter.state,
                  });
                }, 100);
              }
            }
          }
        },
        (err) => {
          LogData("loader_dismiss");
          loading.dismiss();
          LogDataApi("getMasters_error.....", err);
          this.userServices.logoutApiCall(err);
        }
      );
  }

  async getTradingTypeApicall(formData) {
    const loading = await this.loadingController.create(
      this.utilsService.getLoaderUI()
    );
    await loading.present();
    LogData("loader_start");

    this.userServices.getMaster(GET_TRADINGTYPES_API, formData, this.authServices.getUserToken())
      .pipe(
        finalize(() => {
          LogData("loader_dismiss");
          loading.dismiss();
        })
      )
      .subscribe((res) => {
        LogData("getTradingTypeApicall_res", res);
        res=this.utilsService.getDecriptReasponse(res);
        if (res) {
          if (res.result.responce.getTradingtypesArr && res.result.responce.getTradingtypesArr.length > 0) {
            this.tradingTypeList = res.result.responce.getTradingtypesArr;
            if (this.selected_tradingtype_id) {
              setTimeout(() => {
                this.commonform.patchValue({ tradingtype_id:this.selected_tradingtype_id });
              }, 100);
            }

          } else {
            this.tradingTypeList = [];
          }
        }
      }, err => {
        LogData("loader_dismiss");
        loading.dismiss();
        LogData("getTradingTypeApicall_err", err);
        this.userServices.logoutApiCall(err);
      });
  }
}
