import { Pipe, PipeTransform } from '@angular/core';
import { FEMALE_GN, MALE_GN } from '../utils/constant';
@Pipe({
    name: 'getAddressStrPipe'
})
export class GetAddressStrPipe implements PipeTransform {

    constructor() { }

    transform(data): any {
        let str = '';
        if (data) {
            if (data.addressline1) {
                str = str + data.addressline1+ ', ';
            }
            if (data.villagearea_id && data.villagearea_id.length > 0) {
                str = str + data.villagearea_id[0].text+ ', ';
            }
            if (data.addi_address) {
                str = str + data.addi_address+ ', ';
            }
            if (data.village_id && data.village_id.length > 0) {
                str = str  +data.village_id[0].text+ ', ';
            }
            if (data.taluka_id && data.taluka_id.length > 0) {
                str = str + data.taluka_id[0].text+ ', ';
            }
            if (data.district_id && data.district_id.length > 0) {
                str = str + data.district_id[0].text+ ', ';
            }
            if (data.state_id && data.state_id.length > 0) {
                str = str + data.state_id[0].text;
            }
            if (data.pincode) {
                str = str + '-'+data.pincode;
            }
        }
        return str;
    }
}
