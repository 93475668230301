import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Capacitor } from '@capacitor/core';
import { Observable } from 'rxjs';
import { ADMIN_ROLE_ID, BUSINESS_ROLE_ID, CUSTOMER_ROLE_ID, DEALER_ROLE_ID, EMPLOYEE_ROLL_ID, STAFFS_ROLL_ID, SUPER_ADMIN_ROLE_ID } from '../utils/constant';
import { ADMIN_TAB1_ROUTE, DEALER_TAB1_ROUTE, EMPLOYEE_TAB1_ROUTE, MAIN_HOME, NO_PAGE_FOUND, STAFF_TAB1_ROUTE, TAB1_ROUTE, VISITORS_TAB1_ROUTE } from '../utils/routes';
import { LogData } from '../utils/utils';
import { AuthServices } from './auth.services';

export interface CanComponentDeactivate {
  canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable()
export class LoginAuthGuard implements CanActivate {


  constructor(private router: Router, public authService: AuthServices) {

  }
  //  canActivate() {
  //   LogData("LOGIN_auth",this.authService.getUserRoll());
  //   if (this.authService.getUserLoginData()) {
  //     return this.router.navigateByUrl((this.authService.getUserRoll() === CUSTOMER_ROLE_ID || this.authService.getUserRoll() === BUSINESS_ROLE_ID) ? TAB1_ROUTE:
  //     (this.isAdmin()) ? ADMIN_TAB1_ROUTE:'');
  //   }
  //   // this.router.navigate([this.utilServices.isWeb() ? MAIN_HOME:VISITORS_TAB1_ROUTE])
  //   return true;

  // }

  // canActivate() {
  //   LogData("LOGIN_auth",this.authService.getUserRoll());
  //   if (this.authService.getUserLoginData()) {
  //     const rollId = this.authService.getUserRoll();
  //     return this.router.navigateByUrl((rollId === CUSTOMER_ROLE_ID || rollId === BUSINESS_ROLE_ID) ? TAB1_ROUTE:
  //     (rollId === DEALER_ROLE_ID) ? DEALER_TAB1_ROUTE:(rollId === STAFFS_ROLL_ID) ? STAFF_TAB1_ROUTE:
  //     (rollId === SUPER_ADMIN_ROLE_ID || rollId === ADMIN_ROLE_ID) ? ADMIN_TAB1_ROUTE:VISITORS_TAB1_ROUTE);
  //   }
  //   return true;

  // }

  canActivate() {
    const rollId = this.authService.getUserRoll();
    LogData("LOGIN_auth", rollId);
    if (rollId) {
      return this.router.navigateByUrl((rollId === CUSTOMER_ROLE_ID || rollId === BUSINESS_ROLE_ID) ? TAB1_ROUTE :
      (rollId === DEALER_ROLE_ID) ? DEALER_TAB1_ROUTE : (rollId === STAFFS_ROLL_ID) ? STAFF_TAB1_ROUTE :
      (rollId === EMPLOYEE_ROLL_ID) ? EMPLOYEE_TAB1_ROUTE : (rollId === SUPER_ADMIN_ROLE_ID || rollId === ADMIN_ROLE_ID) ? ADMIN_TAB1_ROUTE : VISITORS_TAB1_ROUTE);
    }
    return true;

  }
}
