import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { AdvertisementDetails } from "src/app/models/advertisements-data";
import { LogData } from "../../utils/utils";
import {
  ADV_UPLOAD_PIC,
  PAYMENT_SUMMARY,
  CREATE_ADVERTISE,
} from "../../utils/routes";
import { UtilsService } from "../../services/uitils.services";
import { AuthServices } from "../../services/auth.services";
import { CREATE_ADV_SCHEDULE, ADV_PUSH_DETAIL_RT } from '../../utils/routes';
import { STAFFS_ROLL_ID } from '../../utils/constant';
import {
  ADMIN_ROLE_ID,
  SUPER_ADMIN_ROLE_ID,
  PUSH_SLUG,
  BANNER_SLUG,
} from "../../utils/constant";

@Component({
  selector: "app-adv-card",
  templateUrl: "./adv-card.component.html",
  styleUrls: ["./adv-card.component.scss"],
})
export class AdvCardComponent implements OnInit {
  @Input() isPushAdv: boolean = false;
  @Input() advertisementData: AdvertisementDetails;
  @Output() getItems: EventEmitter<any> = new EventEmitter();
  @Input() checkedCount;
  isLongPressClick: boolean = false;
  advUploadPicAllowList = [1, 9, 10, 11];
  constructor(
    public utilsService: UtilsService,
    private authServices: AuthServices
  ) {}

  ngOnInit() {}

  onClickPending() {
    if (this.advertisementData.status[0].value === 5) {
      if (
        this.authServices.getUserRoll() !== ADMIN_ROLE_ID &&
        this.authServices.getUserRoll() !== SUPER_ADMIN_ROLE_ID && 
        this.authServices.getUserRoll() !== STAFFS_ROLL_ID 
      ) {
        this.utilsService.setNavigationRouteQuery(PAYMENT_SUMMARY, {
          id: this.advertisementData.account_id,
        });
      }
    }
  }

  onClickEdit() {
    if (this.advertisementData.status[0].value === 5) {
      this.utilsService.setNavigationRouteQuery(CREATE_ADVERTISE, {
        id: this.advertisementData.id,
        type: this.isPushAdv ? PUSH_SLUG : BANNER_SLUG,
      });
    }
  }

 

  onClickItem() {
    // this.setCheckedData();
    LogData("onClickItem", this.isLongPressClick);
    LogData("advertisementData_item", this.advertisementData);
    if (!this.isLongPressClick) {
      if (this.checkedCount === 0) {
        if (
          this.advertisementData.status &&
          this.advertisementData.status.length > 0
        ) {
          if (
            this.advUploadPicAllowList.includes(
              this.advertisementData.status[0].value
            )
          ) {
            if(this.isPushAdv){
              // this.utilsService.setNavigationRouteQuery(CREATE_ADV_SCHEDULE, {
              //   id: this.advertisementData.id,
              // });
              this.utilsService.setNavigationRouteQuery(ADV_PUSH_DETAIL_RT, {
                id: this.advertisementData.id,
              });
            }else{
              this.utilsService.setNavigationRouteQuery(ADV_UPLOAD_PIC, {
                id: this.advertisementData.id,
              });
            }
           
          }
        }
      } else {
        this.setCheckedData();
      }
    } else {
      this.isLongPressClick = false;
    }
  }

  setCheckedData() {
    if (this.advertisementData.isChecked) {
      this.advertisementData.isChecked = false;
    } else {
      this.advertisementData.isChecked = true;
    }
    this.getItems.emit(true);
  }

  onPress($event) {
    LogData("onPress", $event);
    this.isLongPressClick = true;
    this.setCheckedData();
  }

  onPressUp($event) {
    LogData("onPressUp", $event);
  }
}
