<div class="flex ion-justify-content-between ion-margin-top pos-rel">
  <div class="pos-abs w-100 line"></div>
  <div class="flex flex-col ion-justify-content-center w-100"
      *ngFor="let slide of slides; let index = index; let first = first; let last = last;">
      <span [ngClass]="{ 'ion-align-self-end': last, 'ion-align-self-center': (!first && !last) }"
          class="flex flex-col ion-align-items-center ion-justify-content-center lbl-14 radius"
          style="width: 30px; height: 30px;"
          [style.background]="slide === currentSlide ? 'var(--ion-color-secondary)':'var(--ion-color-light)'"
          [style.color]="slide === currentSlide ? 'var(--ion-color-light)':'var(--ion-color-dark)'"
          [ngClass]="{ 'ion-align-self-end': last, 'ion-align-self-center': (!first && !last) }">
          {{ index + 1 }}
      </span>
      <span
          [style.color]="slide === currentSlide ? 'var(--ion-color-secondary)':'var(--ion-color-medium)'"
          [ngClass]="{ 'ion-align-self-end': last, 'ion-align-self-center': (!first && !last) }"
          class="flex lbl-14 mt-8">
          {{ slide.text | getLanguagePipe }}
      </span>
  </div>
</div>