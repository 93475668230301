import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-see-all',
  templateUrl: './see-all.component.html',
  styleUrls: ['./see-all.component.scss'],
})
export class SeeAllComponent implements OnInit {

  @Input() isSeeAll:boolean=true;
  @Input() isArrow:boolean=false;
  @Input() lable1;
  @Input() lable2;
  @Output() onClickSeeAllBtn: EventEmitter<any> = new EventEmitter();
  constructor() { }

  ngOnInit() {}

  onClickBtn(){
    this.onClickSeeAllBtn.emit(true);
  }

}
