<div class="container" (press)="onPress($event)" (pressup)="onPressUp($event)"
  (click)="onClickItem(); $event.stopPropagation()">
  <div [ngClass]="
      getStatus(commentsData.status) === 2 ? 'bus-item' : 'bus-item-sel'
    ">
    <div class="flex flex-col p-16">
      <ion-text class="lbl-bold-16 mb-8 ion-text-start" *ngIf="
          !isBusiness &&
          commentsData.receiver_id &&
          commentsData.receiver_id.length > 0 &&
          commentsData.receiver_id[0].business_name
        ">{{ commentsData.receiver_id[0].business_name }}</ion-text>
      <ion-item lines="none" class="item-trans cursor-ptr item-0">
        <div class="flex flex-col">
          <ion-text class="lbl-med-14">{{
            utilsService.getFullName(commentsData)
            }}</ion-text>
          <ion-text class="lbl-med-12 mt-4">{{
            utilsService.getComAddress(commentsData)
            }}</ion-text>
        </div>
        <div slot="end" class="flex flex-row ion-align-self-start" *ngIf="
            !isBusiness && commentsData.status && commentsData.status.length > 0
          ">
          <ion-label class="lbl-op05-13 ml-12">{{
            (isActive ? "lbl_publish" : "lbl_unpublish") | getLanguagePipe
            }}</ion-label>
          <ion-toggle mode="ios" (click)="onChangeToggel($event); $event.stopPropagation()" [(ngModel)]="isActive"
            class="pl-0 ml-12"></ion-toggle>
        </div>
      </ion-item>
      <app-comment-avtar class="mt-12" [avtCls]="'avt-32'" [img]="
          utilsService.getComImage(
            utilsService.getComUserDocments(commentsData)
          )
        " [date]="commentsData.created" [comment]="commentsData.review" [uiType]="2"></app-comment-avtar>
      <app-comment-avtar *ngIf="
          commentsData.ratingschilds && commentsData.ratingschilds.length > 0
        " class="mt-8" [avtCls]="'avt-32'" [comment]="commentsData.ratingschilds[0].review" [uiType]="2">
      </app-comment-avtar>
    </div>
  </div>
  <div class="top-left">
    <ion-icon [src]="'ic_sel' | getImagePipe" class="ft-24" *ngIf="commentsData.isChecked"></ion-icon>
  </div>
</div>