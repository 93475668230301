import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { IonSlides, ModalController, PopoverController } from '@ionic/angular';
import { BusinessDirectoryDetails } from 'src/app/models/business-director-data';
import { UserDocument } from 'src/app/models/user-profile-data';
import { GallerySliderConfig } from 'src/app/utils/slider.config';
import { LogData } from 'src/app/utils/utils';
import { GalleryPopoverComponent } from '../gallery-popover/gallery-popover.component';

@Component({
  selector: 'app-gallery-card',
  templateUrl: './gallery-card.component.html',
  styleUrls: ['./gallery-card.component.scss'],
})
export class GalleryCardComponent implements OnInit {

  slideOpts = {
    initialSlide: 0,
    speed: 400
  };
  currentIndex = 0;
  @Input() businessDetail: BusinessDirectoryDetails;
  @Input() images: Array<UserDocument>;
  gallerySliderConfig = GallerySliderConfig;
  @ViewChild('slides', { static: true }) slides: IonSlides;
  constructor(private popCtrl: PopoverController,
    private modalCtrl: ModalController) { }

  ngOnInit() { }


  slideChanged(e: any) {
    this.getActiveIndex();
  }

  getActiveIndex() {
    this.slides.getActiveIndex().then((index: number) => {
      this.currentIndex = index;
      LogData(index);
    });
  }

  //  async openFilter(ev) {
  //   const modal = await this.popCtrl.create({
  //     component: GalleryPopoverComponent,
  //     componentProps: {
  //       title:'dg_tl_bus_gallery',
  //       images:this.images
  //     },
  //     cssClass: "custom-popover-gallery",
  //     showBackdrop: true,
  //     backdropDismiss: false,
  //     // breakpoints: [0, 0.3, 0.5, 0.8],
  //     // initialBreakpoint: 0.5
  //   });

  //   modal.onDidDismiss().then((data) => {
  //     LogData("onDidDismiss...", data);
  //     if (data.data != null) {
  //     }
  //   });

  //   await modal.present();
  // }

  async openFilter(ev) {
    const modal = await this.modalCtrl.create({
      component: GalleryPopoverComponent,
      componentProps: {
        title: 'dg_tl_bus_gallery',
        images: this.images
      },
      cssClass: 'custom-modal-slider'
    });
    modal.onDidDismiss().then((data) => {
      LogData("onDidDismiss...", data);
      if (data.data) {
      }

    });
    return await modal.present();
  }


}
