import { Pipe, PipeTransform } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
    name: 'getKeysByPipe'
})
export class GetKeysByPipe implements PipeTransform {

    constructor() {

    }
    transform(object, value): string {
        return Object.keys(object).find(key => object[key] === value);
      }
}
