<ion-toolbar *ngIf="checkedCount === 0" class="image-toolbar ion-no-border" [ngClass]="isMobileHeaderCss ? 'mobile-header':''" mode="ios">
  <ion-title>{{ title | getLanguagePipe}}</ion-title>
  <ion-buttons slot="start" *ngIf="isBackMenu">
    <ion-menu-button *ngIf="!isBackButton">
      <ion-icon [src]="'ic_menu' | getImagePipe"></ion-icon>
    </ion-menu-button>
    <ion-back-button  *ngIf="isBackButton" class="block" (click)="onBack()"></ion-back-button>
  </ion-buttons>
  <ion-buttons  *ngIf="isDeleteBtn && (menuCode | hasAccessPipe:CONST_DATA.DELETE_ACCESS)" slot="end" class="mr-16" (click)="deleteSingleItem()">
    <ion-icon [src]="(deleteIcon ? deleteIcon :'ic_delete') | getImagePipe" class="ft-24"></ion-icon>
  </ion-buttons>
  <ion-buttons *ngIf="isCreateBtn && (menuCode | hasAccessPipe:CONST_DATA.ADD_ACCESS)" slot="end" class="mr-16" (click)="create()">
    <ion-icon [src]="'ic_add_ro' | getImagePipe" class="ft-24"></ion-icon>
  </ion-buttons>
  <ion-buttons *ngIf="isDoneBtnText && (menuCode | hasAccessPipe:CONST_DATA.EDIT_ACCESS)" slot="end" class="mr-16" (click)="done()">
    <ion-text class="lbl-med-16">{{doneText | getLanguagePipe}}</ion-text>
  </ion-buttons>
</ion-toolbar>

<ion-toolbar class="image-toolbar ion-no-border" mode="ios" *ngIf="checkedCount > 0">
  <ion-buttons slot="start">
    <ion-icon [src]="'ic_close_ro_bl' | getImagePipe" class="ft-24" (click)="unselectItem()"></ion-icon><span
      class="ml-16 lbl-med-18">{{checkedCount}} {{'pg_tl_msg_item_sel' | getLanguagePipe}}</span>
  </ion-buttons>
  <ion-buttons slot="end" class="mr-16" (click)="selectedDeleteItem()" *ngIf="menuCode | hasAccessPipe:CONST_DATA.DELETE_ACCESS">
    <ion-icon [src]="'ic_delete' | getImagePipe" class="ft-24"></ion-icon>
  </ion-buttons>
  <ion-buttons slot="end" class="mr-16" (click)="openMoreItems($event)" *ngIf="isMore && (menuCode | hasAccessPipe:CONST_DATA.UPDATESTATUS_ACCESS)">
    <ion-icon [src]="'ic_more' | getImagePipe" class="ft-24"></ion-icon>
  </ion-buttons>
</ion-toolbar>