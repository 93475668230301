<app-dg-header [title]="title" (onClickClose)="closeModal($event)"></app-dg-header>
<ion-content *ngIf="paymentsData">
  <hr class="divider1 w-100" />
  <form [formGroup]="commonForm">
    <ion-grid class="pl-16 pr-16">
      <ion-row class="center-row">
        <ion-col style="align-self: center" class="no-margin-padding">
          <ion-row class="mt-12">
            <ion-col size="12">
              <ion-item class="item-0 mt-4" lines="none">
                <ion-thumbnail slot="start" class="m-0 ion-align-self-start avt-36">
                  <ion-img [src]="'rec_pay_sq' | getImagePipe"></ion-img>
                </ion-thumbnail>
                <div class="flex flex-col ml-12 w-100">
                  <ion-item class="item-0" lines="none">
                    <ion-label>
                      <p class="created-by-lbl" *ngIf="paymentsData.account_date">{{paymentsData.account_date |
                        dynamicDateTimePipe:2}}</p>
                    </ion-label>
                    <ion-text class="ml-4 lbl-sec-12" slot="end">{{paymentsData.grand_amount_symbol}}</ion-text>
                  </ion-item>
                  <ion-text class="lbl-sb-14"
                    *ngIf="paymentsData.module_json && paymentsData.module_json.business_name">
                    {{paymentsData.module_json.business_name}}</ion-text>
                  <ion-text class="lbl-sb-14"
                    *ngIf="paymentsData.module_json && paymentsData.module_json.user &&paymentsData.module_json.user.business_name">
                    {{paymentsData.module_json.user.business_name}}</ion-text>
                  <ion-text class="lbl-op05-12" *ngIf="paymentsData.uniqueid">{{paymentsData.uniqueid}}</ion-text>
                </div>
              </ion-item>
              <ion-item class="item-0" lines="none" *ngIf="paymentsData.start_date || paymentsData.end_date">
                <div slot="start" *ngIf="paymentsData.start_date">
                  <ion-label>
                    <p class="lbl-op05-12">{{'lbl_start_dt' | getLanguagePipe}}</p>
                    <p class="lbl-12">{{paymentsData.start_date | dynamicDateTimePipe}}</p>
                  </ion-label>
                </div>
                <div slot="end" *ngIf="paymentsData.end_date">
                  <ion-label>
                    <p class="lbl-op05-12">{{'lbl_end_dt' | getLanguagePipe}}</p>
                    <p class="lbl-12">{{paymentsData.end_date | dynamicDateTimePipe}}</p>
                  </ion-label>
                </div>
              </ion-item>
            </ion-col>
          </ion-row>
          <ion-row class="mt-12">
            <ion-col size="12">
              <app-amct-input [controlName]="'end_date'" [commonForm]="commonForm" [inputType]="inputTypeEnum.DATE"
                [lbl]="'lbl_end_date'" [isValidation]="true" [isFormSubmit]="isFormSubmit" [minDate]="getMinDate()"
                [valMessage]="validationMessages.end_date" (onClearDateValue)="onDateClear($event)"
                (onDateValue)="onDateChange($event)">
              </app-amct-input>
            </ion-col>
          </ion-row>


          <app-dg-buttons [btn1]="btn1" (onClickCloseBtn1)="apply($event)">
          </app-dg-buttons>
        </ion-col>
      </ion-row>
    </ion-grid>
  </form>
</ion-content>