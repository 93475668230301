import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController, PopoverController } from '@ionic/angular';
import { AccCommonData } from 'src/app/models/common-data';
import { UtilsService } from 'src/app/services/uitils.services';
import { SELECT_LIST_ITEM } from 'src/app/utils/constant';
import { LogData } from 'src/app/utils/utils';
import { ValidationMessages } from 'src/app/validators/validations';
import { SingleSearchFilterComponent } from '../single-search-filter/single-search-filter.component';

@Component({
  selector: 'app-entry-field',
  templateUrl: './entry-field.component.html',
  styleUrls: ['./entry-field.component.scss'],
})
export class EntryFieldComponent implements OnInit {

  commonForm:FormGroup;
  @Input() lbl1;
  @Input() lbl2;
  @Input() title;
  isFormSubmit:boolean=false;
  // compnayList=[];
  validationMessages=ValidationMessages;
  @Input() enteryItem;
  @Input() selectedData;
  @Input() dataList:Array<AccCommonData>=[];
  filteredDataList:Array<AccCommonData>=[];
  constructor( private formBuilder: FormBuilder,
    private modalCtrl:ModalController,
    private popoverCtrl:PopoverController,
    public utilsService:UtilsService) { }

  ngOnInit() {
    this.buildForm();
  }



  closeModal(isClear?) {
    this.popoverCtrl.dismiss(isClear);
  }

  create(){
    this.isFormSubmit=true;
    if(this.commonForm.valid){
      this.popoverCtrl.dismiss(this.commonForm.value);
    }
    
  }

  cancel(){
    this.popoverCtrl.dismiss();
  }

  buildForm() {
    this.commonForm = this.formBuilder.group({
      id:[(this.selectedData && this.selectedData.id) ? this.selectedData.id:this.utilsService.getRandomNumber()],
      ledger_id: [(this.selectedData && this.selectedData.ledger_id) ? this.selectedData.ledger_id:'',Validators.required],
      ledger_id_expand:[false],
      amount:[this.selectedData ? this.selectedData.amount:'',Validators.required],
    });

  }

  async openSingleSelection() {
    if (this.dataList.length > 0) {
      this.commonForm.value.ledger_id_expand=true;
      const modal = await this.modalCtrl.create({
        component: SingleSearchFilterComponent,
        componentProps: { title: 'Ledger Company Name', selectedData: this.dataList, 
        selectedId:(this.commonForm.value.ledger_id ? this.commonForm.value.ledger_id[0].value:'') },
        cssClass: 'custom-modal-filter',
        showBackdrop: true,
        backdropDismiss: false,
        // breakpoints: [0, 0.3, 0.5, 0.8],
        // initialBreakpoint: 0.5
      });

      modal.onDidDismiss().then((data) => {
        LogData("onDidDismiss...", data);
        if (data.data != null) {
          if (data.data !== SELECT_LIST_ITEM) {
            let tempArray=[];
            tempArray.push(data.data);
            this.commonForm.patchValue({ledger_id:tempArray});
          } else {
            this.commonForm.patchValue({ledger_id:''});
          }

        }
        this.commonForm.value.ledger_id_expand=false
      });

      await modal.present();
    }
  }

}
