<ion-row>
  <ion-col [size]="btn2 ? '6':'12'">
    <ion-button class="btn-1 hp-45 ion-activatable ripple-parent" expand="block" mode="ios" (click)="apply()">
      <ion-text class="lbl-12">
        {{ btn1 | getLanguagePipe }}
      </ion-text>
      <ion-ripple-effect></ion-ripple-effect>
    </ion-button>
  </ion-col>
  <!-- <ion-col size="0.4">
  </ion-col> -->
  <ion-col size="6" *ngIf="btn2">
    <ion-button class="btn-2 hp-45 ion-activatable ripple-parent" expand="block" mode="ios" (click)="clearAll()">
      <ion-text class="lbl-12">
        {{ btn2 | getLanguagePipe }}
      </ion-text>
      <ion-ripple-effect></ion-ripple-effect>
    </ion-button>
  </ion-col>
</ion-row>