<div class="us-card flex flex-col mb-16">
  <div class="mt-16 ml-16">
    <ion-img [src]="'quote_bg' | getImagePipe:2" class="quote-bg"></ion-img>
  </div>
  <!-- lbl-15 lh-24 pl-24 pr-24 pb-24 ng-mt -->
  <ion-text class="usay-disc">{{userSay.testimonial_text}}</ion-text>
  <hr class="divider1 w-100" />

  <!-- <ion-item class="item-0 item-trans" lines="none"> -->
    <div class="flex flex-col m-24">
      <ion-text class="usay-title">{{userSay.name}}</ion-text>
      <ion-text class="usay-subtitle">{{userSay.business_name}}</ion-text>
      <div class="mt-4">
        <ionic5-star-rating #rating activeIcon="star" defaultIcon="star-outline" activeColor="#5BCCF6"
          defaultColor="#BBBBBB" [rating]="userSay.rating" fontSize="15px" readonly="true" halfStar="true">
        </ionic5-star-rating>
      </div>
    </div>
  <!-- </ion-item> -->
</div>