<app-dg-header [title]="title" (onClickClose)="closeModal($event)"></app-dg-header>
<ion-content>
  <hr class="divider1 w-100" />
  <form [formGroup]="commonForm">
    <ion-grid class="pl-16 pr-16">
      <ion-row class="center-row">
        <ion-col style="align-self: center" class="no-margin-padding">
          <ion-row class="mt-16">
            <ion-col>
              <ion-label class="lbl-med-12">{{'lbl_enter_ref_id' | getLanguagePipe}}  :</ion-label>
              <ion-item lines="none" class="mt-12" [ngClass]="utilServices.getValidCss(commonForm.get('new_referral_code'),isFormSubmit)">
                <ion-input id="ionInput" formControlName="new_referral_code"  type="text">
                  </ion-input>
              </ion-item>
              <app-validation [validationMessages]="validationMessages.referal_id_val" 
              [ValidationForm]="commonForm" [type]="'new_referral_code'" [isFormSubmit]="isFormSubmit"></app-validation>
              </ion-col>
          </ion-row>
          <app-dg-buttons [btn1]="btn1" [btn2]="btn2" (onClickCloseBtn1)="apply($event)"
            (onClickCloseBtn2)="clearAll($event)">
          </app-dg-buttons>
        </ion-col>
      </ion-row>
    </ion-grid>
  </form>
</ion-content>