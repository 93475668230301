<ion-item-divider sticky="fixed" class="ion-no-border" mode="md">
  <div class="flex flex-col w-100">
    <div class="top-line">
      <div class="div-line"></div>
    </div>
    <ion-row class="pt-24 pb-4">
      <ion-col size="10" sizeXl="9" class="ion-align-self-center">
        <ion-text class="lbl-bold-18">{{title}}</ion-text>
      </ion-col>
      <ion-col size="2" sizeXl="3" class="ion-text-center">
        <ion-icon [src]="'ic_close_ro' | getImagePipe" (click)="closeModal()" class="ft-36"></ion-icon>
      </ion-col>
    </ion-row>
  </div>
</ion-item-divider>
<ion-content>
  <hr class="divider1 w-100" />
  <div class="flex flex-col p-24">
    <ion-item lines="none" >
      <ion-icon slot="start"  class="mr-8" [src]="'address_bl' | getImagePipe"></ion-icon>
      <ion-text class="lbl-14" *ngIf="utilServices.getAddressDisplay(ladgerDetails)">{{utilServices.getAddressDisplay(ladgerDetails)}}</ion-text>
    </ion-item>
    <ion-item lines="none" class="mt-12" *ngIf="ladgerDetails.phone">
      <ion-icon slot="start" class="mr-8" [src]="'call_bl' | getImagePipe"></ion-icon>
      <a href="tel:{{ladgerDetails.phone}}"> <ion-text class="lbl-14">{{ladgerDetails.phone}}</ion-text></a>
    </ion-item>
    <ion-item lines="none" class="mt-12" *ngIf="ladgerDetails.email">
      <ion-icon slot="start" class="mr-8" [src]="'email_bl' | getImagePipe"></ion-icon>
      <a href="mailto:{{ladgerDetails.email}}"> <ion-text class="lbl-14">{{ladgerDetails.email}}</ion-text></a>
    </ion-item>
    <!-- <ion-row class="mt-12">
      <ion-col class="flex flex-col">
        <ion-text class="lbl-op05-14">Dealer Name :</ion-text>
        <ion-text class="lbl-14">Ubon Electroics</ion-text>
      </ion-col>
      <ion-col class="flex flex-col">
        <ion-text class="lbl-op05-14">Agent Name :</ion-text>
        <ion-text class="lbl-14">Parth Oza</ion-text>
      </ion-col>
    </ion-row>-->
  </div> 
</ion-content>