<app-dg-header [title]="title" (onClickClose)="closeModal($event)"></app-dg-header>
<ion-content>
  <hr class="divider1 w-100" />
  <form [formGroup]="commonForm">
    <ion-grid class="pl-16 pr-16">
      <ion-row class="center-row">
        <ion-col style="align-self: center" class="no-margin-padding">
          <ion-row *ngIf="isBusinessField">
            <ion-col class="mt-12" size="12">
              <!-- <app-amct-input [controlName]="'userprofile_id'" [commonForm]="commonForm" [inputType]="inputTypeEnum.SELECTION"
                [lbl]="'lbl_business'" (onInputChange)="onBusinessChange($event)" [dataList]="businessUser">
              </app-amct-input> -->
              <ion-label class="lbl-med-12">{{'lbl_business' | getLanguagePipe}} :</ion-label>
              <ion-item class=" mt-8" lines="none"
                [ngClass]="utilsService.getValidCss(commonForm.get('userprofile_id'))"
                (click)="openSingleSelection(businessUser,'select',commonForm.get('userprofile_id'),commonForm.get('userprofile_id_expand'))">
                <ion-text slot="start" class="lbl-med-15">
                  {{(commonForm.value.userprofile_id && commonForm.value.userprofile_id.length >
                  0)?commonForm.value.userprofile_id[0].text:''}}
                </ion-text>
                <ion-icon [name]="commonForm.value.userprofile_id_expand ? 'chevron-up-outline':'chevron-down-outline'"
                  class="ft-20 cursor-ptr" slot="end">
                </ion-icon>
              </ion-item>
            </ion-col>
          </ion-row>
          <ion-row class="mt-12">
            <ion-col [attrSizeMd]="'6'" [attrSizeLg]="'6'" [attrSizeXl]="'6'" appSizeAttribute>
              <app-amct-input [controlName]="'start_date'" [commonForm]="commonForm" [inputType]="inputTypeEnum.DATE"
                [lbl]="'lbl_start_date'" (onClearDateValue)="onStartDateClear($event)"
                (onDateValue)="onStartDateChange($event)">
              </app-amct-input>
            </ion-col>
            <ion-col [attrSizeMd]="'6'" [attrSizeLg]="'6'" [attrSizeXl]="'6'" appSizeAttribute>
              <app-amct-input [controlName]="'end_date'" [commonForm]="commonForm" [inputType]="inputTypeEnum.DATE"
                [lbl]="'lbl_end_date'" (onClearDateValue)="onEndDateClear($event)"
                (onDateValue)="onEndDateChange($event)" [minDate]="getMinDate()">
              </app-amct-input>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col class="mt-12" size="12">
              <app-amct-input [controlName]="'status'" [commonForm]="commonForm" [inputType]="inputTypeEnum.SELECTION"
                [lbl]="'lbl_status'" (onInputChange)="onStatusChange($event)" [dataList]="statusList">
              </app-amct-input>
            </ion-col>
          </ion-row>
          <app-dg-buttons [btn1]="btn1" [btn2]="btn2" (onClickCloseBtn1)="apply($event)"
            (onClickCloseBtn2)="clearAll($event)">
          </app-dg-buttons>
        </ion-col>
      </ion-row>
    </ion-grid>
  </form>
</ion-content>