<ion-grid>
  <ion-row class="center-row">
    <ion-col style="align-self: center" class="no-margin-padding" [attrSizeXl]="'5'" appSizeAttribute>
      <ion-row>
        <ion-col class="p-0" size="12">
          <div class="bus-details-card">
            <ion-col [attrSizeXl]="'6'" appSizeAttribute>
              <app-name-icon [marginLeft]="16" [icon]="'star_bl'" [lable1]="ratingGiven ? 'lbl_bus_rating':'lbl_bus_de_give_a_rat'"
                [lable2]="ratingGiven ? 'lbl_alredy_rating_given_to_business': 'lbl_bus_de_rat_disc'"></app-name-icon>
              <hr class="divider2 w-100 mt-16" />
              <div class="flex flex-col p-16" *ngIf="authServices.getUserLoginData()">
                <ion-text class="lbl-med-10" *ngIf="!ratingGiven">{{'lbl_bus_de_tl_give_rat' | getLanguagePipe}} :</ion-text>
                <ion-text class="lbl-med-16" *ngIf="ratingGiven">{{ratingGiven.rating}}</ion-text>
                <div class="mt-8">
                  <form [formGroup]="commonForm">
                  <ionic5-star-rating #rating activeIcon="star" defaultIcon="star-outline" activeColor="#5BCCF6"
                    defaultColor="#BBBBBB"  fontSize="32px"  halfStar="true" 
                    (ratingChanged)="onRatingChange($event)" formControlName="rating" [readonly]="ratingGiven ? 'true':'false'">
                  </ionic5-star-rating>
                  <app-validation *ngIf="isFormSubmit && !ratingGiven" [validationMessages]="validationMessages.rating_val" 
                  [ValidationForm]="commonForm" [type]="'rating'" [isFormSubmit]="isFormSubmit"></app-validation>
                  </form>
                </div>
                <ion-row>
                  <ion-col size="4" sizeXl="4" *ngIf="!ratingGiven">
                    <app-ui-cmp [title]="'submit'" [fontSize]="'text-10'" [fontFamily]="'text-semibold'" (buttonClicked)="submit($event)"></app-ui-cmp>
                  </ion-col>
                </ion-row>
              </div>
              <ng-container *ngIf="!authServices.getUserLoginData()">
                <div class="flex-col-center p-12 ion-text-center">
                  <ion-text><span class="lbl-16">{{'lbl_to_rating_to_this_business' | getLanguagePipe}}&nbsp;</span>
                    <span class="lbl-sec-16 cursor-ptr " (click)="openLogin()">{{'lbl_login_here' | getLanguagePipe}}</span></ion-text>
                  </div>
              </ng-container>
            </ion-col>
          </div>
        </ion-col>
      </ion-row>
    </ion-col>
  </ion-row>
</ion-grid>