import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { CommonData } from "src/app/models/common-data";
import { UserprofileData } from "src/app/models/user-profile-data";
import { UtilsService } from "src/app/services/uitils.services";
import { EDIT_SLUG } from "src/app/utils/constant";
import { ADD_EDIT_EMPLOYEEE, EDIT_STAFF } from "src/app/utils/routes";
import { LogData } from "src/app/utils/utils";

@Component({
  selector: "app-staff-card",
  templateUrl: "./staff-card.component.html",
  styleUrls: ["./staff-card.component.scss"],
})
export class StaffCardComponent implements OnInit {
  @Input() staffData: UserprofileData;
  @Output() getItems: EventEmitter<any> = new EventEmitter();
  @Input() checkedCount=0;
  @Input() UIType = 1;
  @Input() urlIdx;
  @Input() isEmployee:boolean=false;
  isLongPressClick: boolean = false;
  constructor(private utilsService: UtilsService) {}

  ngOnInit() {}

  setCheckedData() {
    if (this.staffData.isChecked) {
      this.staffData.isChecked = false;
    } else {
      this.staffData.isChecked = true;
    }
    this.getItems.emit(true);
  }

  getStatus(status: Array<CommonData>) {
    return status && status.length > 0 ? status[0].value : "";
  }

  onClickItem() {
    // this.setCheckedData();

    if (!this.isLongPressClick) {
      if (this.checkedCount === 0) {
        if(this.isEmployee){
          this.utilsService.setNavigationRouteQuery(ADD_EDIT_EMPLOYEEE,{mode:EDIT_SLUG,id:this.staffData.id});
        }else{
          this.utilsService.setNavigationRoute(EDIT_STAFF + this.staffData.id); 
        }
      } else {
        this.setCheckedData();
      }
    } else {
      this.isLongPressClick = false;
    }
  }

  onPress($event) {
    LogData("onPress", $event);
    this.isLongPressClick=true;
    this.setCheckedData();
  }

  onPressUp($event) {
    LogData("onPressUp", $event);
  }
  // Controller Functions
}
