<ion-content class="mt-24">
  <ion-grid class="mt-24 grid-px">
    <ion-row>
      <ion-col size="12" [attrSizeXl]="5" [attrSizeLg]="6" [attrSizeMd]="8" appSizeAttribute>
        <div class="flex flex-col menupage-mt">
          <ion-text class="tp-title">{{'hm_top_title'  | getLanguagePipe}}</ion-text>
          <ion-text class="tp-disc mt-16">{{'hm_top_discription' | getLanguagePipe}}</ion-text>
          <form [formGroup]="commonForm">
          <ion-row class="mt-16 applink-pad">
            <ion-col size="8" sizeXl="8">
              <ion-item lines="none" class="mt-4 item-inp item-inp-h">
                <ion-input id="ionInput" formControlName="sendlink" [placeholder]="'hm_ph_enter_mobile_email' | getLanguagePipe"
                (keyup.enter)="getAppLink()">
                </ion-input>
              </ion-item>
              <app-validation [validationMessages]="validationMessages.send_link" 
                [ValidationForm]="commonForm" [type]="'sendlink'" [isFormSubmit]="isFormSubmit"></app-validation>
                <app-validation *ngIf="serverSideError" [message]="serverSideError" 
                [isServerSide]="true" ></app-validation>
            </ion-col>
            <ion-col size="4" sizeXl="4">
              <ion-button class="btn-rating btn-h" expand="block" mode="ios" (click)="getAppLink()">
                <ion-text>
                  {{'hm_btn_getlink' | getLanguagePipe}}
                </ion-text>
              </ion-button>
            </ion-col>
          </ion-row>
          </form>
        </div>
      </ion-col>
      <!-- <ion-col class="ion-text-center" size="12" [attrSizeXl]="7" [attrSizeLg]="6" [attrSizeMd]="4" appSizeAttribute>
       <div>
        <img [src]="'top_side' | getImagePipe:2"  class="top-side-img"/>
         </div>
        </ion-col> -->
    </ion-row>
  </ion-grid>
  <div class="main-home-bg main-home-bg-height"></div>


  <ion-grid class="grid-px">
    <ion-row class="ion-justify-content-center">
      <!-- Section 1 -->
      <ion-col size="12" class="flex ion-justify-content-center cate-mt">
        <ngx-slick-carousel class="carousel" 
        #slickModal="slick-carousel" 
        [config]="webCategorySlideConfig">
      <div ngxSlickItem *ngFor="let slide of categoryList;let isLast=last" class="slide">
        <app-web-category [categoryData]="slide" [isLast]="isLast" (click)="onclickBusiness(slide.id,slide.businesstype_id)"></app-web-category>
      </div>
      </ngx-slick-carousel>
      </ion-col>
      <!-- Section 2 -->
      <ion-col class="sec-mt" size="12">
        <div class="flex flex-col ion-align-items-center ion-text-center">
          <ion-text class="bbs-title">{{'hm_txt_joining_amarucity_makes_sense' | getLanguagePipe}}</ion-text>
          <ion-text class="bbs-disc mt-16 small-width ion-text-center">{{'hm_txt_get_found_amarucity' | getLanguagePipe}}
          </ion-text>
        </div>
      </ion-col>
      <ion-col class="sec-inr-mt" size="12">
        <ion-row>
          <ng-container *ngFor="let item of businessList;let isfirst=first">
            <ion-col size="6" [attrSizeXl]="'3'" appSizeAttribute>
              <app-bbs-card [businessData]="item"></app-bbs-card>
            </ion-col>
          </ng-container>
        </ion-row>
      </ion-col>
       <!-- Section 2 top adv-->
      <ion-col class="sec-mt" size="12" *ngIf="topAdv">
        <div class="flex flex-col ion-align-items-center">
          <div class="banner-img-container">
            <!-- <ion-img [src]="'adv_banner' | getImagePipe:2" class="adv-banner"></ion-img> -->
            <div class="adv-top-height adv-banner">
              <img [src]="topAdv" class="max-adv" />
            </div>
            <div class="banner-img-top-right">
              <ion-text class="ads-bg">{{'hm_lbl_ad' | getLanguagePipe}}</ion-text>
            </div>
          </div>  
        </div>
        <!-- <div class="img-div">
          <img [src]="topAdv" />
          </div> -->
      </ion-col>
      <ng-container *ngIf="homeDataResponse.userprofilesArr && homeDataResponse.userprofilesArr.length > 0">
        <ion-col class="sec-mt" size="12">
          <div class="flex flex-col ion-align-items-center ion-text-center">
            <ion-text class="bbs-title">{{'hm_tl_the_information_search_you_love' | getLanguagePipe}}</ion-text>
            <ion-text class="bbs-disc mt-16 small-width ion-text-center">{{'hm_disc_information_search' | getLanguagePipe}}</ion-text>
          </div>
        </ion-col>
        <!-- <ion-row> -->
        <ion-col class="sec-inr-mt" size="12">
          <ion-segment class="home-tab-ui tab-label" scrollable [value]="selectedTab" (ionChange)="onChangeTab($event)"
            mode="md">
            <ion-segment-button [value]="CONST_DATA.ALL" layout="icon-start">
              <ion-label>{{'seg_all' | getLanguagePipe}}</ion-label>
            </ion-segment-button>
            <ng-container *ngIf="homeDataResponse.userprofilesArr && homeDataResponse.userprofilesArr.length > 0">
              <ng-container *ngFor="let item of homeDataResponse.userprofilesArr">
                <ng-container *ngIf="item.tradingtypesData && item.userprofilesData && item.userprofilesData.length > 0">
                  <ion-segment-button class="seg-pad" [value]="item.tradingtypesData.value" layout="icon-start">
                    <ion-label>{{item.tradingtypesData.text}}</ion-label>
                  </ion-segment-button>
                </ng-container>
              </ng-container>
            </ng-container>
            <ion-segment-button class="seg-pad" [value]="CONST_DATA.SEARCH_MORE" layout="icon-start" (click)="openSearchMore()">
              <ion-label>{{'seg_more' | getLanguagePipe}}</ion-label>
            </ion-segment-button>
          </ion-segment>
        </ion-col>
        <!-- </ion-row> -->
        <ion-col class="sec-inr-mt" size="12" >
          <ion-row>
            <ion-col size="12" class="flex ion-justify-content-center">
              <ngx-slick-carousel *ngIf="isLoaded" class="carousel slick-88" 
              #slickModal="slick-carousel" 
              [config]="businessSlideConfig">
            <div ngxSlickItem *ngFor="let slide of businessDirectoryList;let isLast=last" class="slide mr-16 mb-16">
              <app-dmb-card [businessDetail]="slide"></app-dmb-card>
            </div>
            </ngx-slick-carousel>
            </ion-col>
          </ion-row>
            <!-- Section 3 -->
          <div class="flex flex-col ion-align-items-center w-100 sec-mt">
            <ion-button class="btn-rating btn-h wp-154" expand="block" mode="ios" (click)="seeAll()">
              <ion-text>
                {{'hm_btn_viall' | getLanguagePipe}}
              </ion-text>
            </ion-button>
          </div>
        </ion-col>
      </ng-container>
      
     
        <!-- Section 4 -->
      <ion-col class="sec-big-mt" size="12">
        <div class="flex flex-col ion-align-items-center">
          <ion-text class="bbs-title">{{'hm_tl_y_join_amarucity' | getLanguagePipe}}</ion-text>
          <ion-text class="bbs-disc mt-16 normal-width ion-text-center">{{'hm_disc_y_join_amarucity' | getLanguagePipe}}</ion-text>
        </div>
      </ion-col>
         <!-- Section 5 -->
      <ion-col class="sec-mt" size="12">
        <div class="flex flex-col ion-align-items-start">
          <ion-text class="benefit-lbl">{{'hm_lbl_are_you_business_owner' | getLanguagePipe}}</ion-text>
          <ion-text class="benefit-title">{{'hm_tl_help_people_get_to_know_you' | getLanguagePipe}} </ion-text>
          <ion-img [src]="'benefit_rect' | getImagePipe:2" class="ftr-grad"></ion-img>
        </div>
      </ion-col>
      <ion-col class="sec-inr-mt" size="12">
        <ion-row>
          <ng-container *ngFor="let item of benefitsList1;let isfirst=first;let i=index;let isOdd=odd">
            <ion-col size="6" [attrSizeXl]="'4'" [attrSizeMd]="'4'" appSizeAttribute>
              <app-benefits-card [benefitData]="item" [index]="i" [isOdd]="isOdd"></app-benefits-card>
            </ion-col>
          </ng-container>
        </ion-row>
      </ion-col>
         <!-- Section 6 -->
      <ion-col class="sec-mt" size="12">
        <div class="flex flex-col ion-align-items-center">
          <ion-img [src]="'main_home_mobile' | getImagePipe:2"></ion-img>
        </div>
      </ion-col>
         <!-- Section 7 -->
      <ion-col class="sec-mt" size="12">
        <div class="flex flex-col ion-align-items-start">
          <ion-text class="benefit-lbl">{{'hm_lbl_are_you_looking_for_some_information' | getLanguagePipe}}</ion-text>
          <ion-text class="benefit-title">{{'hm_tl_find_bus_contact_info_local_info' | getLanguagePipe}} </ion-text>
          <ion-img [src]="'benefit_rect' | getImagePipe:2" class="ftr-grad"></ion-img>
        </div>
      </ion-col>
         <!-- Section 8 -->
      <ion-col class="sec-inr-mt" size="12">
        <ion-row>
          <ng-container *ngFor="let item of benefitsList2;let isfirst=first;let i=index;let isOdd=odd">
            <ion-col size="6" [attrSizeXl]="'4'" [attrSizeMd]="'4'" appSizeAttribute>
              <app-benefits-card [benefitData]="item" [index]="i" [isOdd]="isOdd"></app-benefits-card>
            </ion-col>
          </ng-container>
        </ion-row>
      </ion-col>
         <!-- Section 9 middle adv-->
      <ion-col class="sec-mt" size="12">
        <ion-row>
          <ion-col size="9" sizeLg="9" sizeMd="9" sizeSm="12" sizeXs="12">
            <div class="down-bg">
              <div class="flex flex-col">
                <div class="down-btn">
                  <ion-text>{{'hm_lbl_get_amarucity_app' | getLanguagePipe}}</ion-text>
                </div>
                <ion-text class="down-title">{{'hm_tl_directory_pocket' | getLanguagePipe}}</ion-text>
                <ion-text class="down-disc big-width">{{'hm_disc_direct_pocket' | getLanguagePipe}}</ion-text>
                <div class="flex flex-row mt-24">
                  <ion-img [src]="'play_store' | getImagePipe:2" class="cursor-ptr hvr-float" (click)="utilServices.openPlayStoreLink()"></ion-img>
                  <ion-img [src]="'app_store' | getImagePipe:2" class="ml-12 cursor-ptr hvr-float" (click)="utilServices.openAppStoreLink()"></ion-img>
                </div>
              </div>
            </div>

          </ion-col>
          <ion-col size="3" sizeLg="3" sizeMd="3" sizeSm="12" sizeXs="12" class="down-mt flex-col-center ion-align-self-center">
            <!-- <ion-img [src]="'down_banner' | getImagePipe:2" class="down-banner"></ion-img> -->
            <div class="adv-middle-height">
              <img [src]="middileAdv" class="max-adv" />
              </div>
          </ion-col>
        </ion-row>
      </ion-col>
        <!-- Section 9 -->
      <ion-col class="sec-mt ion-text-center" size="12">
        <ion-text class="user-title">{{'hm_tl_getting_acknowledge' | getLanguagePipe}}</ion-text>
      </ion-col>
      <ion-col class="sec-inr-mt" size="12" *ngIf="webhomepageTestimonials && webhomepageTestimonials.length > 0">
        <ion-row>
          <ion-col size="12" class="flex ion-justify-content-center">
            <ngx-slick-carousel class="carousel slick-95" 
            #slickModal="slick-carousel" 
            [config]="userSaySlideConfig">
          <div ngxSlickItem *ngFor="let slide of webhomepageTestimonials;let isLast=last" class="slide mr-16">
            <app-user-say [userSay]="slide"></app-user-say>
          </div>
          </ngx-slick-carousel>
          </ion-col>
        </ion-row>
      </ion-col>
        <!-- Section 9 bottom adv-->
      <ion-col class="sec-inr-mt" size="12">
        <!-- <div class="flex flex-col ion-align-items-center">
          <ion-img [src]="'adv_banner' | getImagePipe:2" class="adv-banner"></ion-img>
        </div> -->
        <div class="flex flex-col ion-align-items-center">
          <div class="banner-img-container">
            <!-- <ion-img [src]="'adv_banner' | getImagePipe:2" class="adv-banner"></ion-img> -->
            <div class="adv-top-height adv-banner">
              <img [src]="bottomAdv" class="max-adv" />
            </div>
            <div class="banner-img-top-right">
              <ion-text class="ads-bg">Ad</ion-text>
            </div>
          </div>
        </div>
      </ion-col>
    </ion-row>

  </ion-grid>
  <app-footer-card></app-footer-card>
</ion-content>