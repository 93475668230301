import { Pipe, PipeTransform } from '@angular/core';
import { CommonData } from '../models/common-data';
import { AuthServices } from '../services/auth.services';
import { FEMALE_GN, MALE_GN } from '../utils/constant';
@Pipe({
    name: 'hasAccessPipe'
})
export class HasAccesPipe implements PipeTransform {
    allPermissions: Array<CommonData> = [];
    constructor(private authServices: AuthServices) {
        this.allPermissions = this.authServices.getAllowPermission();
    }

    transform(menuCode, hasAccess): any {
        let isAccess = true;
        if (menuCode && hasAccess) {
            if (this.allPermissions && this.allPermissions.length > 0) {
                const findObj = this.allPermissions.find((element) => element.value === menuCode);
                if (findObj) {
                    const actionArr: Array<CommonData> = findObj.actions_arr;
                    if (actionArr && actionArr.length > 0) {
                        const findAccess = actionArr.find((access_el) => access_el.value === hasAccess);
                        if (!findAccess) {
                            isAccess = false;
                        }
                    }
                }
            }
        }
        return isAccess;
    }
}
