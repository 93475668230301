<!-- <div class="div-card bsc-mb cursor-ptr" (click)="onclickBusiness()" hidden>
  <div class="pl-8 pr-8 pt-8">
    <div class="img-brd">
      <ion-row>
        <ion-col size="3" class="p-0">
          <div class="img-div">
            <ion-img [src]="getLogoImageDisplay()" class="img-rad-logo img-max"></ion-img>
          </div>
        </ion-col>
        <ion-col size="9" class="p-0">
          <div class="img-div">
            <ion-img [src]="getCoverImageDisplay()" class="img-rad-bnr img-max"></ion-img>
          </div>
        </ion-col>
      </ion-row>
    </div>
    <ion-item class="item-0" lines="none">
      <div slot="start" class="flex flex-row ion-align-items-center mt-12 logo-bg">
        <div class="pl-4 pt-4">
          <ion-icon [src]="'ic_logo' | getImagePipe"></ion-icon>
        </div>
        <div class="flex flex-row ion-align-items-center star-bg">
          <ionic5-star-rating #rating activeIcon="star" defaultIcon="star-outline" activeColor="#FFFFFF"
            defaultColor="#BBBBBB" [rating]="businessData.rating" fontSize="10px" readonly="true" halfStar="true">
          </ionic5-star-rating>
          <ion-text class="lbl-sb-wh-10 ml-4">{{businessData.rating}}</ion-text>
        </div>
      </div>
      <div slot="end" class="flex flex-row ion-align-items-center ion-align-self-end">
        <ion-icon [src]="'per_g' | getImagePipe"></ion-icon>
        <ion-text class="ml-8 gst-lbl">GST : {{(businessData.isgst === 1 ? 'yes':'no') | getLanguagePipe}}</ion-text>
      </div>
    </ion-item>
    <div class="flex flex-col mt-8">
      <ion-text class="lbl-sb-14" *ngIf="businessData.business_name">{{businessData.business_name}}</ion-text>
      <div class="flex flex-row mt-8 mb-8 ion-align-items-center">
        <ion-icon [src]="'ic_loc_g' | getImagePipe"></ion-icon>
        <ion-text class="ml-8 type-lbl">
          <span
            *ngIf="businessData.villagearea_id && businessData.villagearea_id.length > 0">{{businessData.villagearea_id[0].text}},&nbsp;</span>
          <span
            *ngIf="businessData.village_id && businessData.village_id.length > 0">{{businessData.village_id[0].text}},&nbsp;</span>
          <span
            *ngIf="businessData.taluka_id && businessData.taluka_id.length > 0">{{businessData.taluka_id[0].text}}</span>
          <span>.</span>
        </ion-text>
      </div>
    </div>
  </div>
</div> -->

<div class="div-card bsc-mb cursor-ptr" (click)="onclickBusiness()">

  <div>
    <!-- <ion-item class="item-0" lines="none"> -->
    <!-- <div slot="end" class="flex flex-row ion-align-items-center logo-bg mr-8 mt-8">
      <div class="pl-4 pt-4">
        <ion-icon [src]="'ic_logo' | getImagePipe"></ion-icon>
      </div>
      <div class="flex flex-row ion-align-items-center star-bg">
        <ionic5-star-rating #rating activeIcon="star" defaultIcon="star-outline" activeColor="#FFFFFF"
          defaultColor="#BBBBBB" [rating]="businessData.rating" fontSize="10px" readonly="true" halfStar="true">
        </ionic5-star-rating>
        <ion-text class="lbl-sb-wh-10 ml-4">{{businessData.rating}}</ion-text>
      </div>
    </div> -->
    <!-- </ion-item> -->
    <ion-item lines="none" class="item-0 ml-4">

      <!-- ml-4 -->
      <ion-thumbnail slot="start" class="thumb-div ml-4">
        <img [src]="getLogoImageDisplay()" class="timg-rad" />
      </ion-thumbnail>

      <div class="flex flex-col pt-8 pb-8 w-100">
        <ion-text class="lbl-sb-14 mr-0 mlines-1" *ngIf="businessData.business_name">{{businessData.business_name}}
        </ion-text>

        <div class="flex flex-row mt-8 ion-align-items-center" *ngIf="isAddressAvailable()">
          <ion-icon [src]="'ic_loc_g' | getImagePipe" class="ion-align-self-start"></ion-icon>
          <div class="flex flex-col">
            <ion-text class="ml-8 type-lbl mlines-1 lh-14">
              <span
                *ngIf="businessData.village_id && businessData.village_id.length > 0">{{businessData.village_id[0].text}},&nbsp;</span>
              <span
                *ngIf="businessData.taluka_id && businessData.taluka_id.length > 0">{{businessData.taluka_id[0].text}},&nbsp;</span>
            </ion-text>
            <ion-text class="ml-8 type-lbl mlines-1">
              <span
                *ngIf="businessData.district_id && businessData.district_id.length > 0">{{businessData.district_id[0].text}},&nbsp;</span>
              <span
                *ngIf="businessData.state_id && businessData.state_id.length > 0">{{businessData.state_id[0].text}}</span>
            </ion-text>
          </div>

        </div>
        <!-- <ion-row class="mb-8"
          *ngIf="(businessData.phone && businessData.phone_on === 1) || (businessData.whats_no && businessData.whats_no_on === 1)">
          <ion-col *ngIf="businessData.phone && businessData.phone_on === 1">
            <div class="flex flex-row ion-align-items-center mr-0">
              <ion-icon [src]="'call_gray' | getImagePipe"></ion-icon>
              <ion-text class="ml-8 call-lbl">{{ !authServices.getUserLoginData() ? (businessData.phone |
                hiddenNumberPipe) : businessData.phone}}</ion-text>
            </div>
          </ion-col>
          <ion-col *ngIf="businessData.whats_no && businessData.whats_no_on === 1">
            <div class="flex flex-row ion-align-items-center ml-0">
              <ion-icon [src]="'whats_gray' | getImagePipe"></ion-icon>
              <ion-text class="ml-8 call-lbl">{{!authServices.getUserLoginData() ? (businessData.whats_no |
                hiddenNumberPipe) : businessData.whats_no}}</ion-text>
            </div>
          </ion-col>
        </ion-row> -->
        <!-- <ion-row class="mb-8">
          <div class="flex flex-row ml-12 wrap-div"
            *ngIf="businessData.businesslist_id && businessData.businesslist_id.length > 0">
            <div class="type-bg mr-8 mb-8" *ngFor="let item of businessData.businesslist_id">
              <ion-text class="type-lbl">{{item.text}}</ion-text>
            </div>
          </div>
        </ion-row> -->

        <div *ngIf="businessData.business_tag;else noTag" class="flex flex-row mt-2 ion-align-items-center">
          <ion-icon [src]="'bus_tag' | getImagePipe" class="ion-align-self-start"></ion-icon>
          <ion-text class="type-lbl ml-8 mlines-1">{{ businessData.business_tag}}</ion-text>
        </div>
        <ng-template #noTag>
          <div *ngIf="businessData.businesslist_id && businessData.businesslist_id.length > 0"
            class="flex flex-row mt-2 ion-align-items-center">
            <ion-icon [src]="'bus_type_g' | getImagePipe" class="ion-align-self-start"></ion-icon>
            <ion-text class="type-lbl ml-8 mlines-1">{{ businessData.businesslist_id | arrayToStringPipe}}</ion-text>
          </div>
        </ng-template>
      </div>
    </ion-item>
  </div>
</div>