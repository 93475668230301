<div class="flex flex-col ion-align-items-center cursor-ptr hvr-float" [ngClass]="cateMr">
  <div class="container">
    <!-- <ion-img [src]="'category_ro' | getImagePipe" [ngClass]="cateImg"></ion-img> -->
    <div  [ngClass]="cateImg" class="hvr-floa"></div>
    <div class="centered">
      <ion-img [src]="utilservices.getTradingIcon(categoryData)"></ion-img>
    </div>
  </div>
  <ion-text  [ngClass]="cateLbl" class="ion-text-center"
    >{{categoryData.trading_type}}</ion-text
  >
</div>
