<div class="flex flex-col">
  <div class="bg-card">
    <div class="flex flex-col">
      <ion-item class="item-0" lines="none">
        <ion-text class="lbl-med-16">My Wallet</ion-text>
        <ion-icon slot="end" class="avt-42 m-0" [src]="'wallet_ro_m' | getImagePipe"></ion-icon>
      </ion-item>
      <ion-text class="lbl-med-26 mt-4">&#8377;2000.00</ion-text>
      <ion-text class="lbl-11 mt-4">Available for widhdraw money or transer to wallet</ion-text>
    </div>
  </div>
  <div class="sec-card mt-16">
    <div class="flex flex-col">
      <ion-text class="lbl-12">
        Total amount widhdraw in this financial year
      </ion-text>
      <ion-text class="mt-4 lbl-bold-sec-14">
        &#8377;1,250.00
      </ion-text>
    </div>
  </div>
</div>
