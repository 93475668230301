<div class="container mb-12 cursor-ptr">
  <ion-item
    lines="none"
    [ngClass]="getStatus(staffData.status) === 2 ? 'bus-item' : 'bus-item-sel'"
    (press)="onPress($event)"
    (pressup)="onPressUp($event)"
    (click)="onClickItem()"
  >
    <div class="flex flex-col w-100 pt-12 pb-12">
      <ion-row>
        <ion-col>
          <ion-text class="lbl-med-14"><span *ngIf="staffData.f_name">{{staffData.f_name}}&nbsp;</span> <span *ngIf="staffData.l_name">{{staffData.l_name}}</span></ion-text>
        </ion-col>
        <ion-col *ngIf="staffData.role_id && staffData.role_id.length > 0">
          <div class="flex flex-row ion-justify-content-end">
            <div class="head-div flex flex-row ion-align-self-center">
              <ion-icon [src]="'head_user' | getImagePipe"></ion-icon>
              <ion-text class="ml-4 lbl-med-sec-10">{{staffData.role_id[0].text}}</ion-text>
            </div>
          </div>
        </ion-col>
      </ion-row>

      <ion-text class="staff-code" *ngIf="staffData.usercode">{{staffData.usercode}}</ion-text>
      <ion-text class="lbl-med-10">&#8377; {{staffData.salary}}</ion-text>
      <ion-row class="mt-8">
        <ion-col class="p-0" *ngIf="staffData.phone">
          <div class="flex flex-row">
            <ion-icon
              [src]="'ic_ro_call_g' | getImagePipe"
              class="ft-16"
            ></ion-icon>
            <ion-text class="ml-8 lbl-10">{{staffData.phone}}</ion-text>
          </div>
        </ion-col>
        <ion-col class="p-0" *ngIf="staffData.email">
          <div class="flex flex-row ion-align-items-center">
            <ion-icon
              [src]="'ic_ro_msg_g' | getImagePipe"
              class="ft-16"
            ></ion-icon>
            <ion-text class="ml-8 lbl-10">{{staffData.email}}</ion-text>
          </div>
        </ion-col>
      </ion-row>
    </div>
  </ion-item>

  <div class="top-left">
    <ion-icon
      [src]="'ic_sel' | getImagePipe"
      class="ft-24"
      *ngIf="staffData.isChecked"
    ></ion-icon>
  </div>
</div>
