import { Injectable } from "@angular/core";
import { Geolocation } from "@capacitor/geolocation";
import { LoadingController } from "@ionic/angular";
import { CommonData } from "../models/common-data";
import { GeoAddressComponent, GeoLocationData } from "../models/geolocation-data";
import { LogData } from "../utils/utils";
import { UtilsService } from "./uitils.services";
declare var google;

@Injectable({
    providedIn: 'root'
})
export class GeoLocationUtilsService {
    geoLocationData: Array<GeoLocationData> = [];
    constructor(private loadingController: LoadingController,
        private utilServices: UtilsService) {
        // this.initAddressData();
    }

    initAddressData() {
        this.getCurrentAddress();
    }

    async getCurrentAddress(isStartLoader?) {
        let loading;
        if (isStartLoader) {
            loading = await this.loadingController.create(
                this.utilServices.getLoaderUI()
            );
            await loading.present();
        }
        const coordinates = await Geolocation.getCurrentPosition();
        LogData('Current position:', coordinates);
        const currentLatitude = coordinates.coords.latitude;
        const currentLongitude = coordinates.coords.longitude;
        LogData("currentLatitude", currentLatitude);
        LogData("currentLongitude", currentLongitude);
        const data = await this.getReverseGeocodingData(currentLatitude, currentLongitude);
        LogData("getReverseGeocodingData", data);
        if(isStartLoader){
            loading.dismiss();
        }
        return data;
    }

    async getReverseGeocodingData(lat, lng) {
        var latlng = new google.maps.LatLng(lat, lng);
        // This is making the Geocode request
        var geocoder = new google.maps.Geocoder();
        await geocoder.geocode({ 'latLng': latlng }, (results, status) => {
            //   LogData("results", JSON.stringify(results));
            this.geoLocationData = results;
            LogData("results", results);
            if (status !== google.maps.GeocoderStatus.OK) {
            }
            // This is checking to see if the Geoeode Status is OK before proceeding
            if (status == google.maps.GeocoderStatus.OK) {
                console.log(results);
                var address = (results[0].formatted_address);
            }
        });
        return this.geoLocationData;
    }

    getGeolocationData() {
        return this.geoLocationData;
    }

    getAddressFormat(locationData: Array<GeoAddressComponent>) {
        let addressStr = "";
        let address = "", city = "", state = "";
        if (locationData && locationData.length > 0) {
            locationData.forEach(element => {
                if (element.types && element.types.length > 0) {
                    const findLocal = element.types.find((sub_local) => sub_local === "sublocality");
                    if (findLocal) {
                        address = element.long_name;
                    }
                    const findCity = element.types.find((locality) => locality === "locality");
                    if (findCity) {
                        city = element.long_name;
                    }
                    const findState = element.types.find((state) => state === "administrative_area_level_1");
                    if (findState) {
                        state = element.long_name;
                    }
                }

            });
            addressStr = (address ? address + ", " : "") + (city ? city + ", " : "") + (state ? state + "." : "");
        }
        return addressStr;
    }


}