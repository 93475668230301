<app-dg-header
  [title]="title"
  (onClickClose)="closeModal($event)"
></app-dg-header>
<ion-content>
  <hr class="divider1 w-100" />
  <form>
    <ion-grid class="pl-16 pr-16">
      <ion-row class="center-row">
        <ion-col style="align-self: center" class="no-margin-padding">
          <ion-text
            class="lbl-bold-16 mb-8 ion-text-start"
            *ngIf="
              this.authServices.isAdminMainRoll() && commentData && 
              commentData.receiver_id &&
              commentData.receiver_id.length > 0 &&
              commentData.receiver_id[0].business_name
            "
            >{{ commentData.receiver_id[0].business_name }}</ion-text
          >
          <ng-container *ngIf="isOnlyView">
            <ion-row *ngIf="isCommentLyt">
              <ion-col>
                <app-comment-avtar
                  [img]="
                    utilServices.getComImage(
                      utilServices.getComUserDocments(commentData)
                    )
                  "
                  [name]="utilServices.getFullName(commentData)"
                  [address]="utilServices.getComAddress(commentData)"
                  [comment]="commentData.review"
                ></app-comment-avtar>
                <div
                  class="mt-4"
                  *ngIf="
                    commentData &&
                    commentData.ratingschilds &&
                    commentData.ratingschilds.length > 0
                  "
                >
                  <app-comment-avtar
                    [img]="rpImg"
                    [name]="rpName"
                    [address]="rpAddress"
                  ></app-comment-avtar>
                </div>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col>
                <ion-text class="lbl-11 lh-20" *ngIf="rpReview">{{
                  rpReview
                }}</ion-text>
              </ion-col>
            </ion-row>
          </ng-container>
          <ng-container *ngIf="!isOnlyView">
            <ion-row *ngIf="isCommentLyt">
              <ion-col>
                <app-comment-avtar
                  [isFullWidthText]="isFullWidthText"
                  [img]="
                    utilServices.getComImage(
                      utilServices.getComUserDocments(commentData)
                    )
                  "
                  [name]="utilServices.getFullName(commentData)"
                  [address]="utilServices.getComAddress(commentData)"
                  [comment]="commentData.review"
                ></app-comment-avtar>
                <div
                  class="mt-4"
                  *ngIf="
                    isReply ||
                    (commentData &&
                      commentData.ratingschilds &&
                      commentData.ratingschilds.length > 0)
                  "
                >
                  <app-comment-avtar
                    [isFullWidthText]="isFullWidthText"
                    [img]="rpImg"
                    [name]="rpName"
                    [address]="rpAddress"
                  ></app-comment-avtar>
                </div>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col>
                <app-amct-input
                  *ngIf="!isReply || (isSame && isReply)"
                  [controlName]="'review'"
                  [commonForm]="commonForm"
                  [inputType]="inputTypeEnum.TEXT_AREA"
                  [isFormSubmit]="isFormSubmit"
                  [isValidation]="true"
                  [valMessage]="validationMessages.comment_txt"
                  [isCharCount]="true"
                  [placeHolder]="'ph_write_comment'"
                  [rows]="6"
                ></app-amct-input>
              </ion-col>
            </ion-row>
            <app-dg-buttons
              *ngIf="isSame || (!isSame && !isReply)"
              [btn1]="btn1"
              [btn2]="btn2"
              (onClickCloseBtn1)="apply($event)"
              (onClickCloseBtn2)="clearAll($event)"
            >
            </app-dg-buttons>
          </ng-container>
        </ion-col>
      </ion-row>
    </ion-grid>
  </form>
</ion-content>
