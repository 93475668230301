import { Component, Input, OnInit } from "@angular/core";
import { MessageTableData } from "src/app/models/message-data";
import { CurrentUserData } from "src/app/models/user-data";
import { ImageData } from "src/app/models/user-profile-data";
import { AuthServices } from "src/app/services/auth.services";
import { UtilsService } from "src/app/services/uitils.services";
import { UserServices } from "src/app/services/user.services";
import { DELETE_MESSAGES_API } from "src/app/utils/api-end-points";
import {
  AUDIO_DOC,
  BUSINESSES_USER_TYPE,
  DELETE_DATA,
  DOCUMENT_DOC,
  IMAGE_DOC,
  PAGE_CHAT_LIST,
  TEXT_DOC,
  VIDEO_DOC,
  DIRECT_DOC,
} from "src/app/utils/constant";
import {
  CURRENTSTATUS_KEY,
  DELETE_KEY,
  RECORDID_KEY,
} from "src/app/utils/params-data";
import { CHAT_CONVERSATION } from "src/app/utils/routes";
import { ValidationMessages } from "src/app/validators/validations";

@Component({
  selector: "app-message-card",
  templateUrl: "./message-card.component.html",
  styleUrls: ["./message-card.component.scss"],
})
export class MessageCardComponent implements OnInit {
  @Input() messageData: MessageTableData;
  userData: CurrentUserData;
  userMsg;
  userImage;
  CONST_DATA = {
    TEXT_DOC,
    AUDIO_DOC,
    VIDEO_DOC,
    IMAGE_DOC,
    DOCUMENT_DOC,
    DIRECT_DOC,
  };
  validationMessages = ValidationMessages;
  @Input() SendersReceivers;
  constructor(
    private authServices: AuthServices,
    private utilsService: UtilsService,
    private userServices: UserServices
  ) {}

  ngOnInit() {
    this.getUserData();
  }

  getTextMessage() {
    let message = "";
    if (this.messageData.lastmassage && this.messageData.lastmassage.msgtype) {
      if (this.messageData.lastmassage.msgtype === AUDIO_DOC) {
        message = this.validationMessages.audio_type_msg;
      } else if (this.messageData.lastmassage.msgtype === VIDEO_DOC) {
        message = this.validationMessages.video_type_msg;
      } else if (this.messageData.lastmassage.msgtype === IMAGE_DOC) {
        message = this.validationMessages.img_type_msg;
      } else if (this.messageData.lastmassage.msgtype === DOCUMENT_DOC) {
        message = this.validationMessages.document_type_msg;
      }
    }
    return message;
  }

  // getUserData() {
  //   if (
  //     (this.messageData &&
  //       this.messageData.receiver_id &&
  //       this.messageData.receiver_id.length > 0) ||
  //     (this.messageData.sender_id && this.messageData.sender_id.length > 0)
  //   ) {
  //     if (
  //       this.messageData.receiver_id[0].id !==
  //       this.authServices.getLoginUserId()
  //     ) {
  //       this.userData = this.messageData.receiver_id[0];
  //       this.getUserImage();
  //     } else if (
  //       this.messageData.sender_id[0].id !== this.authServices.getLoginUserId()
  //     ) {
  //       this.userData = this.messageData.sender_id[0];
  //       this.getUserImage();
  //     }
  //   }
  // }

  getUserData() {
    if (
      this.messageData &&
      (this.messageData.receiver_id || this.messageData.sender_id)
    ) {
      if (this.messageData.receiver_id !== this.authServices.getLoginUserId()) {
        this.userData = this.SendersReceivers[this.messageData.receiver_id];
        this.userMsg = this.messageData.lastmassage.smsg;
        this.getUserImage();
      } else if (
        this.messageData.sender_id !== this.authServices.getLoginUserId()
      ) {
        // this.userData = this.messageData.sender_id[0];
        this.userData = this.SendersReceivers[this.messageData.sender_id];
        this.userMsg = this.messageData.lastmassage.rmsg;
        this.getUserImage();
      }
    }
  }

  getUserImage() {
    // if(this.userData.user_type === BUSINESSES_USER_TYPE){
    //   this.userImage=this.utilsService.getLogoImageDisplay(this.userData);
    // }else{
    //   this.userImage=this.utilsService.getProfileDisplayImg(this.userData);
    // }
    if (this.userData.documents && this.userData.documents.length > 0) {
      let image_data: ImageData;
      if (
        this.userData.documents[0].field &&
        this.userData.documents[0].display_files[
          this.userData.documents[0].field
        ]
      ) {
        image_data =
          this.userData.documents[0].display_files[
            this.userData.documents[0].field
          ];
        if (image_data.square_url) {
          this.userImage = image_data.square_url;
        } else if (image_data.main_url) {
          this.userImage = image_data.main_url;
        }
      }
    }
  }

  openChat() {
    this.utilsService.setNavigationRouteQuery(CHAT_CONVERSATION, {
      id: this.userData.id,
    });
  }

  deletechat(data) {
    let tempArray = [];
    tempArray.push(data.id);
    const formData = new FormData();
    formData.append(RECORDID_KEY, JSON.stringify(tempArray));
    formData.append(CURRENTSTATUS_KEY, DELETE_KEY);
    this.userServices.addressMasterUpdate(
      DELETE_DATA,
      formData,
      null,
      DELETE_MESSAGES_API,
      PAGE_CHAT_LIST,
      "conf_txt_chat_delete",
      "ic_delete_conf"
    );
  }
}
