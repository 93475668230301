import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'dynamicDateTimePipe'
})
export class DynamicDateTimePipe implements PipeTransform {

  // transform(date:string,format:string='DD MMMM, YYYY',isTimeOnly?:boolean): any {
  //   if(isTimeOnly){
  //       return moment(date).format(format);
  //     }else{
  //       return moment(date).format(format);
  //     } 
  // }
  // 2 = Both date time 1 = only time
  DATE_TIME_FORMAT = "DD MMM, YYYY hh mm A";
  DATE_FORMAT = "DD MMM, YYYY";
  TIME_FORMAT = "hh:mm A";
  transform(date: string,index?): any {
    let format;
    switch (index) {
      case 1:
        format=this.TIME_FORMAT;
        break;
      case 2:
        format=this.DATE_TIME_FORMAT;
        break;
      default:
        format=this.DATE_FORMAT;
        break;
    }
    return moment(date).format(format);
  }
}
