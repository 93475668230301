<ion-header class="ion-no-border">
  <ion-toolbar>
    <ion-title mode="md">
      {{ headername | getLanguagePipe }}
    </ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="closeModalCross()">{{'close' | getLanguagePipe}}</ion-button>
    </ion-buttons> 
  </ion-toolbar>
</ion-header>
<ion-content class="bg-white">
  <ion-item-divider sticky="fixed" class="ion-no-border">
    <ion-searchbar  mode="ios" class="search-cls" [placeholder]="'search_here' | getLanguagePipe" (ionClear)="onClear()" animated
      (input)='getSearchValue($event)'></ion-searchbar>
  </ion-item-divider>

  <ion-list *ngIf="itemList && itemList.length" style="margin-top: 4px;margin-right: 12px" class="bg-trans">
    <ion-item *ngFor="let item of itemList" (click)="selectItem(item)" lines="none">
      <ion-label [ngClass]="item.isChecked ? 'lbl-primary-bold-14':'lbl-14'">{{ item.text }}</ion-label>
      <ion-checkbox slot="end" [(ngModel)]="item.isChecked" mode="ios"></ion-checkbox>
    </ion-item>
  </ion-list>
</ion-content>

<ion-footer class="ion-no-border pl-16 pr-16 pb-16 pt-8 bg-white" >
  <ion-row>
    <ion-col size="6">
      <ion-button class="btn-1 hp-45" expand="block" mode="ios" (click)="saveData()">
        <ion-text class="lbl-12">
          {{'save' | getLanguagePipe}}
        </ion-text>
      </ion-button>
    </ion-col>
    <ion-col size="6">
      <ion-button class="btn-2 hp-45" expand="block" mode="ios" (click)="cancelData()">
        <ion-text class="lbl-12">
          {{'cancel' | getLanguagePipe}}
        </ion-text>
      </ion-button>
    </ion-col>
  </ion-row>
  <!-- <ion-row>
    <ion-col size="5.5" class="ion-no-padding">
      <ion-button class="btn-1" expand="block" mode="ios" (click)="saveData()">
        <ion-text>
          {{'save' | getLanguagePipe}}
        </ion-text>
      </ion-button>
    </ion-col>
    <ion-col size="1">
    </ion-col>
    <ion-col size="5.5" class="ion-no-padding">
      <ion-button class="btn-primary" fill="outline" expand="block" mode="ios" (click)="cancelData()">
        <ion-text>
          {{'cancel' | getLanguagePipe}}
        </ion-text>
      </ion-button>
    </ion-col>
    </ion-row> -->
</ion-footer>