import { Component, Input, OnInit, Output,EventEmitter } from '@angular/core';
import { CommonData } from 'src/app/models/common-data';
import { GroupDetails } from 'src/app/models/group-data';
import { UtilsService } from 'src/app/services/uitils.services';
import { LogData } from 'src/app/utils/utils';

@Component({
  selector: 'app-group-name',
  templateUrl: './group-name.component.html',
  styleUrls: ['./group-name.component.scss'],
})
export class GroupNameComponent implements OnInit {

  @Input() accountData:GroupDetails;
  @Output() getItems: EventEmitter<any> = new EventEmitter();
  @Input() checkedCount;
  isLongPressClick:boolean=false;
  @Input() redirectUrl;
  constructor(private utilsService:UtilsService) { }

  ngOnInit() { }

  getStatus(status: Array<CommonData>) {
    return (status && status.length > 0) ? status[0].value : '';
  }
  onClickItem(){
    // this.setCheckedData();
    LogData("onClickItem",this.isLongPressClick);
    
    if(!this.isLongPressClick){
      if(this.checkedCount === 0){
        this.utilsService.setNavigationRoute(this.redirectUrl  +this.accountData.id,);
      }else{
        this.setCheckedData();
      }
    }else{
      this.isLongPressClick=false;
    }
    

  }

  setCheckedData() {
    if (this.accountData.isChecked) {
      this.accountData.isChecked = false;
    } else {
      this.accountData.isChecked = true;
    }
    this.getItems.emit(true);
  }

  onPress($event) {
    LogData("onPress", $event);
    this.isLongPressClick = true;
    this.setCheckedData();
  }

  onPressUp($event) {
    LogData("onPressUp", $event);

  }

}
