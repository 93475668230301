import { Component, Input, OnInit } from '@angular/core';
import { TEXT_AREA_LIMIT_M } from 'src/app/utils/constant';

@Component({
  selector: 'app-character-count',
  templateUrl: './character-count.component.html',
  styleUrls: ['./character-count.component.scss'],
})
export class CharacterCountComponent implements OnInit {

  @Input() formValue;
  @Input() allowLimit=TEXT_AREA_LIMIT_M;
  constructor() { }

  ngOnInit() {}

}
