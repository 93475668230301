<ion-item-divider sticky="fixed" class="ion-no-border" mode="md">
  <div class="flex flex-col w-100">
    <div class="top-line">
      <div class="div-line"></div>
    </div>
    <div class="pt-24 pb-4 pr-12">
      <ion-item class="item-0" lines="none">
        <ion-text class="lbl-bold-18">{{ title | getLanguagePipe }}</ion-text>
        <ion-icon slot="end" [src]="'ic_close_ro' | getImagePipe" (click)="closeModal()" class="ft-36 m-4"></ion-icon>
        </ion-item>
      <!-- <ion-col size="10" sizeXl="9" class="ion-align-self-center">
        <ion-text class="lbl-bold-18">{{ title | getLanguagePipe }}</ion-text>
      </ion-col>
      <ion-col size="2" sizeXl="3" class="ion-text-center">
        <ion-icon [src]="'ic_close_ro' | getImagePipe" (click)="closeModal()" class="ft-36"></ion-icon>
      </ion-col> -->
    </div>
  </div>
</ion-item-divider>