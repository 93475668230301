import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AdvscheduleData } from '../../models/adv-schedule';
import { UtilsService } from '../../services/uitils.services';
import { AuthServices } from '../../services/auth.services';
import { LogData } from '../../utils/utils';
import { CREATE_ADV_SCHEDULE } from '../../utils/routes';

@Component({
  selector: 'app-schedule-card',
  templateUrl: './schedule-card.component.html',
  styleUrls: ['./schedule-card.component.scss'],
})
export class ScheduleCardComponent implements OnInit {

  @Input() scheduleData:AdvscheduleData;
  @Input() isPushAdv: boolean = false;
  @Output() getItems: EventEmitter<any> = new EventEmitter();
  @Input() checkedCount;
  isLongPressClick: boolean = false;
  constructor(
    public utilsService: UtilsService,
    private authServices: AuthServices
  ) {}

  ngOnInit() {}

  getImage(){
    let image="";
    if(this.scheduleData.documents && this.scheduleData.documents.length > 0){
      if(this.scheduleData.documents[0].display_files){
        image=this.scheduleData.documents[0].display_files[this.scheduleData.documents[0].field].main_url;
      }
    }
    return image;
  }

  onClickItem() {
    // this.setCheckedData();
    LogData("onClickItem", this.isLongPressClick);
    LogData("advertisementData_item", this.scheduleData);
    if (!this.isLongPressClick) {
      if (this.checkedCount === 0) {
        this.utilsService.setNavigationRouteQuery(CREATE_ADV_SCHEDULE,{id:this.scheduleData.advertisement_id,scheduleId:this.scheduleData.id})
      } else {
        this.setCheckedData();
      }
    } else {
      this.isLongPressClick = false;
    }
  }

  setCheckedData() {
    if (this.scheduleData.isChecked) {
      this.scheduleData.isChecked = false;
    } else {
      this.scheduleData.isChecked = true;
    }
    this.getItems.emit(true);
  }

  onPress($event) {
    LogData("onPress", $event);
    this.isLongPressClick = true;
    this.setCheckedData();
  }

  onPressUp($event) {
    LogData("onPressUp", $event);
  }
}
