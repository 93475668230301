<div class="div-card p-16 cursor-ptr">
  <div class="flex flex-col">
    <!-- code  -->
    <ion-text class="lbl-med-sec-11 lh-18">
      {{languageData.msgid}} 
    </ion-text>
    <!-- english -->
    <ion-text class="lbl-med-16 mt-8 mb-8">
      {{languageData.msgstr}}
    </ion-text>
    <!-- languages -->
    <ng-container *ngIf="languageData.lng_data">
      <ion-text class="lbl-12 mt-4" *ngFor="let item of languageData.lng_data" ><b>{{item.lang_name}}</b> : {{item.msgstr}}</ion-text>
    </ng-container>

    <!-- <ng-container *ngIf="languageData.lng_data">
      <ng-container *ngFor="let item of languageData.lng_data" >
      <ng-container *ngIf="item.msgid !== languageData.msgid">
        <ion-text class="lbl-10 mt-8" >{{item.lang_name}} : {{item.msgstr}}</ion-text>
      </ng-container>
    </ng-container>
    </ng-container>
     -->
    <!-- <ion-text class="lbl-10 mt-8">Guj : Add Language</ion-text>
    <ion-text class="lbl-10 mt-4">Hindi : Add Language</ion-text>
    <ion-text class="lbl-10 mt-4">Eng : Add Language</ion-text> -->
  </div>
</div>