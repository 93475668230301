<ion-item class="item-0 item-trans" lines="none">
  <div class="flex flex-col">
    <div class="flex flex-row ion-align-items-center">
      <ion-text class="lbl-med-14">{{lable1}}</ion-text>
      <!-- <ion-icon name="chevron-forward-outline" class="ml-8" *ngIf="isArrow"></ion-icon> -->
    </div>
    <ion-text class="lbl-10">{{lable2}}</ion-text>
  </div>
  <div class="flex flex-row ion-align-items-center" slot="end" *ngIf="isSeeAll" (click)="onClickBtn()">
    <ion-text class="lbl-sec-10">{{'lbl_sea_al' | getLanguagePipe}}</ion-text>
    <ion-icon name="chevron-forward-outline" class="ml-4" color="secondary"></ion-icon>
  </div>
</ion-item>