<div class="main-bg cursor-ptr">
  <div class="bbs-bg  cursor-ptr">
    <div class="pl-16 pr-16 pt-16 pb-24">
      <ion-item lines="none" class="item-0 mb-12">
        <div class="flex flex-col">
          <ion-text class="subtitle">{{businessData.type | getLanguagePipe}}</ion-text>
          <ion-text class="title mt-8">{{businessData.title | getLanguagePipe}}</ion-text>
        </div>
        <ion-avatar slot="end" class="avt-56">
          <ion-img [src]="(businessData.icon) | getImagePipe"></ion-img>
        </ion-avatar>
      </ion-item>
      <ion-text class="subtitle mt-16">{{businessData.discription | getLanguagePipe}}</ion-text>
    </div>
    
  </div>
  </div>