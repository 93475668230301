<ion-item-divider sticky="fixed" class="ion-no-border" mode="md">
  <div class="flex flex-col w-100">
    <div class="top-line">
      <div class="div-line"></div>
    </div>
    <ion-row class="pt-24 pb-4">
      <ion-col size="10" sizeXl="9" class="ion-align-self-center">
        <ion-text class="lbl-bold-18">{{ 'lbl_img_crop' | getLanguagePipe }}</ion-text>
      </ion-col>
      <ion-col size="2" sizeXl="3" class="ion-text-center">
        <ion-icon [src]="'ic_close_ro' | getImagePipe" (click)="closeModal()" class="ft-36"></ion-icon>
      </ion-col>
    </ion-row>
  </div>
</ion-item-divider>
<ion-content>
  <hr class="divider1 w-100" />
  <ion-grid class="p-12">
    <ion-row>
      <ion-col size="12">
        <ion-label class="lbl-med-12">{{'lbl_sel_ratio' | getLanguagePipe}} :</ion-label>
        <ion-item class="mt-8 ion-item-bg" lines="none">
          <ion-select interface="popover" [(ngModel)]="selectedRatio" (ionChange)="onChangeRatio($event)">
            <ion-select-option [value]="item.value" *ngFor="let item of ratioList">{{item.text}}
            </ion-select-option>
          </ion-select>
        </ion-item>
      </ion-col>
    </ion-row>
    <ion-row class="mt-16 mb-8">
      <!-- <ion-col size="12">
        
      </ion-col> -->
      <div class="w-100 crop-div">
        <div [ngClass]="isImgLoading ? 'crop-div':'disp-hid crop-div'">
          <image-cropper [imageBase64]="imageURL" [maintainAspectRatio]="true" [aspectRatio]="selectedRatio" [format]="format"
            (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()" (cropperReady)="cropperReady()"
            (loadImageFailed)="loadImageFailed()"></image-cropper>
        </div>
        <div *ngIf="!isImgLoading">
          <app-no-data-found [width]="'100'"
            [message]="isImgLoadingFailed ? 'txt_msg_img_loading_failed':'txt_msg_img_loading'"></app-no-data-found>
        </div>
  
  
      </div>
    </ion-row>
  
    <ion-row class="mt-16 mb-8">
      <ion-col size="12">
        <ion-button class="btn-1 hp-45" expand="block" mode="ios" (click)="apply()">
          <ion-text class="lbl-12">
            {{ btn1 | getLanguagePipe }}
          </ion-text>
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
  
</ion-content>