import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AccountsDetails } from 'src/app/models/accounts-data';
import { ImageData } from 'src/app/models/user-profile-data';
import { LogData } from 'src/app/utils/utils';
import { ImageviewerComponent } from '../imageviewer/imageviewer.component';

@Component({
  selector: 'app-payments-details-card',
  templateUrl: './payments-details-card.component.html',
  styleUrls: ['./payments-details-card.component.scss'],
})
export class PaymentsDetailsCardComponent implements OnInit {

  @Input() accountsDetails:AccountsDetails;
  imageUrl:ImageData;
  constructor(private modalCtrl:ModalController) { }

  ngOnInit() {
    this.getPaymentImg();
  }


  async openImageViewrComponent() {
    const modal = await this.modalCtrl.create({
      component: ImageviewerComponent,
      // cssClass: 'custom-modal-filter',
      componentProps: {
        images: this.imageUrl.main_url,
      },
      // enterAnimation: modalEnterAnimation,
    });

    modal.onDidDismiss().then((data) => {
      LogData("imageviewr.ondismiss", data);
      if (data.data != null) {
      }
    });

    await modal.present();
  
}

getPaymentImg(){
     if (this.accountsDetails && this.accountsDetails.documents && this.accountsDetails.documents.length > 0) {
    const img_obj = this.accountsDetails.documents.find((element) => (element.display_files && element.display_files.qrpay_doc && element.display_files.qrpay_doc.square_url));
    if (img_obj) {
      this.imageUrl = img_obj.display_files.qrpay_doc;
    }
  }
}

}
