import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-icon-ui',
  templateUrl: './icon-ui.component.html',
  styleUrls: ['./icon-ui.component.scss'],
})
export class IconUiComponent implements OnInit {

  @Input() icon;
  @Input() lbl;
  @Input() isError:boolean=false;
  @Output() onItemClick: EventEmitter<any> = new EventEmitter();
  constructor() { }

  ngOnInit() {}

}
