import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-wallet-card',
  templateUrl: './wallet-card.component.html',
  styleUrls: ['./wallet-card.component.scss'],
})
export class WalletCardComponent implements OnInit {

  @Input() walletData=[];
  constructor() { }

  ngOnInit() {}

}
