<div class="acc-bg cursor-ptr">
  <div class="flex flex-col">
    <div class="flex flex-col">
      <ion-text class="acc-lbl">
        {{'lbl_acc_set_transaction_type' | getLanguagePipe}}
      </ion-text>
      <ion-text class="acc-value">
        Opening Balance
      </ion-text>
    </div>
    <div class="flex flex-row mt-12">
      <div class="flex flex-col">
        <ion-text class="acc-lbl">
          {{'lbl_prefix' | getLanguagePipe}}
        </ion-text>
        <ion-text class="acc-value">
          Op-
        </ion-text>
      </div>
      <div class="flex flex-col ml-16">
        <ion-text class="acc-lbl">
          {{'lbl_suffix' | getLanguagePipe}}
        </ion-text>
        <ion-text class="acc-value">
          Op_s-
        </ion-text>
      </div>
      <!-- <div class="flex flex-col ml-16">
        <ion-text class="acc-lbl">
          {{'lbl_manual_number' | getLanguagePipe}}
        </ion-text>
        <ion-text class="acc-value">
         123
        </ion-text>
      </div> -->
    </div>
  </div>
</div>