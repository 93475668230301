<!-- <form [formGroup]="commonForm" *ngIf="commonForm">
  <ng-container formArrayName="commonFormArray">
    <ng-container *ngIf="commonForm.get('commonFormArray')">
      <ng-container *ngFor="let item of commonFormArray.controls; let i = index;">
        <ng-container [formGroupName]="i" class="ion-align-items-center">
          <div class="mt-12 enable-selected">
            <ion-row class="row-padding">
              <ion-col size="1" sizeXl="0.5">
                <ion-checkbox formControlName="isChecked"></ion-checkbox>
              </ion-col>
              <ion-col size="4" sizeXl="2.5" >
                <ion-label [ngClass]="item.value.isChecked ? 'lbl-15' : 'lbl-op05-15' " style="margin-left: 6px;">
                  {{item.value.t}}</ion-label>
              </ion-col>
              <ion-col size="7" sizeXl="9" class="ion-align-self-center">
                <div style="display: flex;justify-content: space-evenly;">
                  <ion-item class="w-100"
                  [ngClass]="utilServices.getValidCss(commonFormArray.at(i).get('times'))" lines="none">
                  <ion-select interface="popover" formControlName="times">
                    <ion-select-option [value]="item.value" *ngFor="let item of businessTimings">{{ item.text }}
                    </ion-select-option>
                  </ion-select>
                </ion-item>
                <ion-label class="ml-8 mr-8 ion-align-self-center lbl-12">to</ion-label>
                <ion-item 
                class="w-100"
                [ngClass]="utilServices.getValidCss(commonFormArray.at(i).get('timee'))" lines="none">
                <ion-select interface="popover" formControlName="timee">
                  <ion-select-option [value]="item.value" *ngFor="let item of businessTimings">{{ item.text }}
                  </ion-select-option>
                </ion-select>
              </ion-item>
                </div>
              </ion-col>
            </ion-row>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
</form> -->

<form [formGroup]="commonForm" *ngIf="commonForm">
  <ng-container formArrayName="commonFormArray">
    <ng-container *ngIf="commonForm.get('commonFormArray')">
      <ng-container *ngFor="let item of commonFormArray.controls; let i = index;">
        <ng-container [formGroupName]="i" class="ion-align-items-center">
          <div class="mt-12 p-8 enable-selected">
            <ion-row>
              <ion-col size="1" sizeXl="0.5">
                <ion-checkbox formControlName="isChecked"></ion-checkbox>
              </ion-col>
              <ion-col>
                <ion-label [ngClass]="item.value.isChecked ? 'lbl-15' : 'lbl-op05-15' " style="margin-left: 6px;">
                  {{item.value.t}}</ion-label>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col size="12" class="ion-align-self-center">
                <div style="display: flex;justify-content: space-evenly;">
                  <ion-item class="w-100" [ngClass]="utilServices.getValidCss(commonFormArray.at(i).get('times'))"
                    lines="none">
                    <ion-select [disabled]="!item.value.isChecked" interface="popover" formControlName="times">
                      <ion-select-option [value]="item.value" *ngFor="let item of businessTimings">{{ item.text }}
                      </ion-select-option>
                    </ion-select>
                  </ion-item>
                  <ion-label class="ml-8 mr-8 ion-align-self-center lbl-12">{{'lbl_to' | getLanguagePipe}}</ion-label>
                  <ion-item class="w-100" [ngClass]="utilServices.getValidCss(commonFormArray.at(i).get('timee'))"
                    lines="none">
                    <ion-select [disabled]="!item.value.isChecked" interface="popover" formControlName="timee">
                      <ion-select-option [value]="item.value" *ngFor="let item of businessTimings">{{ item.text }}
                      </ion-select-option>
                    </ion-select>
                  </ion-item>
                </div>
              </ion-col>
            </ion-row>
            <!-- <app-validation *ngIf="!item.valid && isFormSubmit" [message]="'invalid'" 
            [isServerSide]="true" ></app-validation> -->
            <!-- <ion-text>dsdd</ion-text> -->
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
</form>